import { ChangeDetectorRef, Directive, OnDestroy, Optional } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormHelperDirective } from '../../components/form-helper/form-helper.directive';

/**
 * Directive that lets val-errors on nested formgroups work with ChangeDetection OnPush.
 * Cf. https://github.com/Ninja-Squad/ngx-valdemort/issues/317
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'val-errors',
})
export class MarkForCheckOnSubmitDirective implements OnDestroy {
  private destroySubj = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() formHelperDirective?: FormHelperDirective,
  ) {
    formHelperDirective?.submitButtonClicked$.pipe(takeUntil(this.destroySubj)).subscribe(() => {
      cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.destroySubj.next();
    this.destroySubj.complete();
  }
}
