<nxh-loading-button
  [showLabel]="showLabel"
  [label]="label"
  [loading]="showSpinner"
  [type]="btnType"
  btnType="submit"
  [disabled]="disabled"
  [icon]="icon"
  [id]="id"
  #button
/>
