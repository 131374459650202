import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { BulletDescriptionDirective } from './bullet-description.directive';

export type BulletStatus = 'success' | 'warning' | 'danger' | 'neutral' | 'info' | 'concerned' | 'purple' | 'absent';

@Component({
  selector: 'nxh-bullet',
  templateUrl: './bullet.component.html',
  styleUrls: ['./bullet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulletComponent implements AfterContentInit {
  // todo remove string https://jira.uz.kuleuven.ac.be/browse/FEG-1392
  status?: BulletStatus | null | string;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('status') set _status(status: BulletStatus | BulletStatusMap | string | null | undefined) {
    if (status) {
      if (isBulletStatusMap(status)) {
        const active = Object.entries(status).find(([, val]) => val) as [key: BulletStatus, val: boolean];
        this.status = active?.[0] ?? 'absent';
      } else {
        this.status = status;
      }
    } else {
      this.status = 'absent';
    }
  }

  @Input() showStatusAsText = false;
  @Input() showStatusAsTooltip = false;
  @Input() i18nPrefix = '';
  @Input() title?: string;
  @Input() description?: string;

  @ContentChild(BulletDescriptionDirective) bulletDescriptionRef?: BulletDescriptionDirective;

  get text(): string {
    return this.i18nPrefix ? `${this.i18nPrefix}${this.status}` : (this.status as string);
  }

  ngAfterContentInit(): void {
    if (this.bulletDescriptionRef && !this.title) {
      console.error('BulletDescriptionRef cannot be projected without title. Provide a title input.');
    }
  }
}

function isBulletStatusMap(statusMap: any): statusMap is BulletStatusMap {
  return typeof statusMap !== 'string';
}

// todo remove string https://jira.uz.kuleuven.ac.be/browse/FEG-1392
type BulletStatusMap = Partial<Record<BulletStatus | string, boolean>>;
