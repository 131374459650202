import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BloeddrukService {
  constructor() {}

  fakeBloeddrukWaardes() {
    return of([
      {
        date: 1,
        bloodValue1: 128,
        bloodValue2: 80,
      },
      {
        date: 2,
        bloodValue1: 118,
        bloodValue2: 81,
      },
      {
        date: 3,
        bloodValue1: 123,
        bloodValue2: 79,
      },
      {
        date: 4,
        bloodValue1: 114,
        bloodValue2: 77,
      },
      {
        date: 5,
        bloodValue1: 127,
        bloodValue2: 80,
      },
      {
        date: 6,
        bloodValue1: 122,
        bloodValue2: 86,
      },
      {
        date: 7,
        bloodValue1: 123,
        bloodValue2: 80,
      },
      {
        date: 8,
        bloodValue1: 118,
        bloodValue2: 81,
      },
      {
        date: 9,
        bloodValue1: 123,
        bloodValue2: 79,
      },
      {
        date: 10,
        bloodValue1: 114,
        bloodValue2: 77,
      },
      {
        date: 11,
        bloodValue1: 127,
        bloodValue2: 80,
      },
      {
        date: 12,
        bloodValue1: 122,
        bloodValue2: 86,
      },
      {
        date: 13,
        bloodValue1: 123,
        bloodValue2: 80,
      },
      {
        date: 14,
        bloodValue1: 118,
        bloodValue2: 81,
      },
      {
        date: 15,
        bloodValue1: 123,
        bloodValue2: 79,
      },
      {
        date: 16,
        bloodValue1: 114,
        bloodValue2: 77,
      },
      {
        date: 17,
        bloodValue1: 127,
        bloodValue2: 80,
      },
      {
        date: 18,
        bloodValue1: 122,
        bloodValue2: 86,
      },
      {
        date: 19,
        bloodValue1: 127,
        bloodValue2: 80,
      },
      {
        date: 20,
        bloodValue1: 122,
        bloodValue2: 86,
      },
    ]);
  }
}
