<ng-container *ngIf="selectedTab$ | async; else links">
  <div>selected tab = {{ selectedTab$ | async }}</div>
</ng-container>

<ng-template #links>
  <div>
    tabs:
    <ul>
      <li><a [routerLink]="'patient'">patient</a></li>
      <li><a [routerLink]="'billing'">billing</a></li>
    </ul>
  </div>

  <pre><code language="html" [highlight]="markupCode"></code>></pre>
  <pre><code language="typescript" [highlight]="routerCode"></code>></pre>
</ng-template>

<router-outlet></router-outlet>
