import { fromEvent, Observable, throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';

export function printFile(
  blob: Blob,
  iframe: HTMLIFrameElement = document.getElementById('print-iframe') as HTMLIFrameElement,
): Observable<Event> {
  let blobUrl;

  try {
    if (!iframe) {
      iframe = document.createElement('iframe');
      iframe.setAttribute('id', 'print-iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
    }

    blobUrl = window.URL.createObjectURL(blob);
    iframe.src = blobUrl;

    return fromEvent(iframe, 'load').pipe(
      take(1),
      tap(() => {
        iframe.contentWindow.print();
        if (blobUrl) {
          window.URL.revokeObjectURL(blobUrl);
        }
      }),
      catchError((e) => {
        console.error('error while printing blob', e);
        return throwError(new Error('error while printing blob'));
      }),
    );
  } catch (e) {
    if (blobUrl) {
      window.URL.revokeObjectURL(blobUrl);
    }
    console.error('error while printing blob', e);
    return throwError(new Error('error while printing blob'));
  }
}
