import { Component, Input, isDevMode, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

export enum TinyLoaderStatus {
  loading = 'loading',
  empty = 'empty',
  error = 'error',
  loaded = 'loaded',
  warning = 'warning',
  success = 'success',
}

@Component({
  selector: 'nxh-tiny-loader',
  templateUrl: './tiny-loader.component.html',
  styleUrls: ['./tiny-loader.component.scss'],
})
export class TinyLoaderComponent implements OnInit, OnChanges, OnDestroy {
  status$!: Observable<TinyLoaderStatus>;
  @Input() emptyStateTemplate?: TemplateRef<any>;
  @Input() errorStateTemplate?: TemplateRef<any>;
  @Input() warningStateTemplate?: TemplateRef<any>;
  @Input() successStateTemplate?: TemplateRef<any>;
  @Input() loading?: boolean | null; // cannot give default value because of usingInputApi() method
  @Input() data: unknown;
  @Input() loaded?: number | null; // cannot give default value because of usingInputApi() method
  @Input() error: unknown;

  @Input() set debounceDisabled(value: boolean) {
    if (value) {
      this._debounceTime = 0;
    }
  }

  @Input() loadingMessage = this.i18next.transform('_loading-states.loading');
  @Input() emptyMessage = this.i18next.transform('_loading-states.no-items-found');
  @Input() errorMessage = this.i18next.transform('general-timeout-error-title');

  @Input() set state(state: TinyLoaderStatus) {
    this.statusSubj.next(state);
  }

  private statusSubj = new BehaviorSubject<TinyLoaderStatus>(TinyLoaderStatus.loaded);
  private destroySubj = new Subject<void>();
  private _debounceTime = 300;
  active$ = this.statusSubj
    .asObservable()
    .pipe(
      map(
        (status) =>
          status === null ||
          status === TinyLoaderStatus.loading ||
          status === TinyLoaderStatus.empty ||
          status === TinyLoaderStatus.error,
      ),
    );

  constructor(private i18next: I18NextPipe) {}

  get status() {
    return this.statusSubj.getValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.usingInputApi()) {
      const status: TinyLoaderStatus = this.calcStatusFromInputApi();
      setTimeout(() => {
        this.statusSubj.next(status);
      });
    }
  }

  ngOnInit() {
    this.status$ = this.statusSubj.asObservable().pipe(
      debounce((status) => {
        return status === TinyLoaderStatus.loading ? timer(this._debounceTime) : timer(0);
      }),
      distinctUntilChanged(),
      takeUntil(this.destroySubj),
    );
  }

  public usingInputApi(): boolean {
    const inputs: (keyof this)[] = ['loading', 'data', 'error'];
    return inputs.some((key) => typeof this[key] !== 'undefined');
  }

  startLoading() {
    if (isDevMode() && this.usingInputApi()) {
      throw new Error("You're using the inputs api of PageLoader, startLoading is not supported");
    }
    this.statusSubj.next(TinyLoaderStatus.loading);
  }

  setError(err?: Error) {
    if (isDevMode() && this.usingInputApi()) {
      throw new Error("You're using the inputs api of PageLoader, setErrors is not supported");
    }
    this.statusSubj.next(TinyLoaderStatus.error);
  }

  setLoaded(length: number) {
    if (isDevMode() && this.usingInputApi()) {
      throw new Error("You're using the inputs api of PageLoader, setLoaded is not supported");
    }
    this.statusSubj.next(length === 0 ? TinyLoaderStatus.empty : TinyLoaderStatus.loaded);
  }

  setState(state: TinyLoaderStatus) {
    if (isDevMode() && this.usingInputApi()) {
      throw new Error("You're using the inputs api of PageLoader, setLoaded is not supported");
    }
    this.statusSubj.next(state);
  }

  isLoaded() {
    const status = this.statusSubj.getValue();
    return status === TinyLoaderStatus.loaded || status === TinyLoaderStatus.empty || status === TinyLoaderStatus.error;
  }

  canShowLoading() {
    const status = this.statusSubj.getValue();
    return status === TinyLoaderStatus.empty || status === TinyLoaderStatus.error;
  }

  ngOnDestroy(): void {
    this.destroySubj.next();
    this.destroySubj.complete();
  }

  private calcStatusFromInputApi() {
    if (this.loading) {
      return TinyLoaderStatus.loading;
    }
    if (this.error) {
      return TinyLoaderStatus.error;
    }
    if ((this.loaded && this.loaded > 0) || this.hasData()) {
      return TinyLoaderStatus.loaded;
    }
    return TinyLoaderStatus.empty;
  }

  private hasData() {
    if (typeof this.data === 'undefined') {
      return false;
    }
    if (Array.isArray(this.data)) {
      return this.data.length > 0;
    }
    return true;
  }
}
