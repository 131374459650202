import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RecurrencesFormService } from '../recurrences-form.service';

@Component({
  selector: 'nxh-recurrence-tab-day',
  templateUrl: './recurrence-tab-day.component.html',
  styleUrls: ['./recurrence-tab-day.component.scss'],
})
export class RecurrenceTabDayComponent {
  @Input() parent!: UntypedFormGroup;

  constructor(private recurrencesFormService: RecurrencesFormService) {}

  get validateDayPartOrTime() {
    return this.recurrencesFormService.getValidateDayPartOrTime();
  }

  get dayparts() {
    return this.recurrencesFormService.getDayParts();
  }

  get useTimeslots() {
    return this.recurrencesFormService.getUseTimeslots();
  }

  get useDayPartAllDay() {
    return this.recurrencesFormService.getUseDayPartAllDay();
  }

  get dayFormGroup() {
    return this.parent?.get('day') as UntypedFormGroup;
  }
}
