import { combineLatest, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { R } from '@fullcalendar/core/internal-common';

/**
 * RXJS operator that tracks the state of the given request: it spawns a LoadingState object on the several stages
 * of a request, i.e. loading, empty, error, data.
 *
 * For example:
 *
 * ```
 * readonly recentPatients$ = inject(AuthQuery)
 *     .selectUserContext()
 *     .pipe(
 *       mergeMap(trackLoadingState<UserContext, Patient>((context) => this.patientApi.fetchRecentPatients(context.name)))
 *     );
 * ```
 *
 * Or, when using as an Observable:
 *
 * ```
 * readonly cares$ = trackLoadingState<CareCatalogActivity>(inject(CareCatalogApiService).listActivities())
 * ```
 *
 * You can then use this in HTML like so:
 *
 * ```
 * <ng-container *ngIf=(recentPatients$ | async) as recentPatients">
 *   <nxh-loading *ngIf=recentPatients.loading/>
 *   <table *ngIf="recentPatients.data?.length > 0">...</table>
 *   <nxh-empty *ngIf="recentPatients.empty"/>
 *   <nxh-error *ngIf="recentPatients.error"/>
 * </nxh-container>
 * ```
 *
 * or, in an ng-select
 * ```
 * <ng-select [items]="options.data" [loading]="options.loading" *ngIf="options$ | async as options">
 * ```
 *
 * @experimental This is experimental and still subject to change!
 */
export function trackLoadingState<P, R>(request: FetchData<P, R>): (params: P) => Observable<LoadingState<R>>;
export function trackLoadingState<R>(source: Observable<R>): Observable<LoadingState<R>>;
export function trackLoadingState<P, R>(request: Observable<R> | FetchData<P, R>): unknown {
  if (typeof request === 'function') {
    return trackLoadingStateOperator(request);
  } else {
    return createTrackLoadingState(request);
  }
}

type FetchData<P, R> = (params: P) => Observable<R>;

function trackLoadingStateOperator<P, R>(
  request: (params: P) => Observable<R>,
): (params: P) => Observable<LoadingState<R>> {
  return (params) => {
    const source = request(params);
    return createTrackLoadingState(source);
  };
}

function createTrackLoadingState<R>(source: Observable<R>): Observable<LoadingState<R>> {
  return new Observable((subscriber) => {
    subscriber.next({ loading: true });
    const unsubscribed$$ = new Subject<void>();
    source.pipe(takeUntil(unsubscribed$$)).subscribe({
      next: (result) => {
        subscriber.next({
          loading: false,
          data: result,
          empty: (Array.isArray(result) && result.length === 0) || !result,
        });
      },
      error: (error) => subscriber.next({ loading: false, error: error }),
      complete: () => subscriber.complete(),
    });
    return function unsubscribe() {
      unsubscribed$$.next();
      unsubscribed$$.complete();
    };
  });
}

/**
 * Calculates LoadingState based on given observables
 *
 * @experimental This is experimental and still subject to change!
 */
export function selectLoadingState<R>(
  data$: Observable<R>,
  loading$: Observable<boolean>,
  error$: Observable<Error | null>,
): Observable<LoadingState<R>> {
  return combineLatest([data$, loading$, error$]).pipe(
    map(([data, loading, error]) => {
      return {
        loading,
        error: error ? error : undefined,
        empty: loading || error ? undefined : isEmpty(data),
        data,
      };
    }),
  );
}

export interface LoadingState<R> {
  loading?: boolean;
  data?: R;
  empty?: boolean;
  error?: Error;
}

export function isLoadingState<T>(object: any): object is LoadingState<T> {
  if (!object) {
    return false;
  }
  return (
    object['loading'] !== undefined ||
    object['data'] !== undefined ||
    object['empty'] !== undefined ||
    object['error'] !== undefined
  );
}

function isEmpty<T>(data: T[] | T) {
  if (data) {
    if (Array.isArray(data)) {
      return data.length === 0;
    }
    return false;
  } else {
    return true;
  }
}

export function isLoaded<T>(state: LoadingState<T>) {
  return state && !state.loading && !state.empty && !state.error;
}
