import { HttpHeaders } from '@angular/common/http';
import { Observable, Subject, TimeoutError, timer } from 'rxjs';
import { takeUntil, timeout } from 'rxjs/operators';

// later on these can be derived from the settings
export const default_timeout_warning_millis = 4 * 1000;
export const default_timeout_interval_millis = 10 * 1000;
export const default_timeout_error_millis = 20 * 1000;

/**
 * rxjs operator that calls the given TimeoutListener's warning() method after
 * given warningMillis, and times out the Observable + calls the given TimeoutListener's
 * error() method after given errorMillis
 */
export function timeouts(
  { warningMillis, errorMillis, intervalMillis = warningMillis }: TimeoutInfo,
  listener: TimeoutListener,
  stopWarning?: Observable<void>,
) {
  return function <T>(source: Observable<T>): Observable<T> {
    return new Observable((subscriber) => {
      const busy$ = new Subject<void>();

      if (warningMillis && warningMillis > 0) {
        timer(warningMillis, intervalMillis)
          .pipe(takeUntil(busy$))
          .subscribe(() => {
            listener.warning();
          });
      }

      if (stopWarning) {
        stopWarning.subscribe(() => {
          if (!busy$.closed) {
            busy$.next();
            busy$.complete();
          }
        });
      }

      return source.pipe(timeout(errorMillis)).subscribe({
        next(value) {
          subscriber.next(value);
        },
        error(error) {
          if (error instanceof TimeoutError) {
            listener.error();
          }
          busy$.next();
          busy$.complete();
          subscriber.error(error);
        },
        complete: () => {
          busy$.next();
          busy$.complete();
          subscriber.complete();
        },
      });
    });
  };
}

export interface TimeoutInfo {
  /**
   * Number of milliseconds before showing a warning message. Set -1 to disable.
   */
  warningMillis?: number;

  /**
   * Number of milliseconds before showing an error message. Set -1 to disable.
   */
  errorMillis?: number;

  /**
   * Number of milliseconds after which the warning will be repeated.
   */
  intervalMillis?: number;
}

export interface TimeoutListener {
  warning: () => void;
  error: () => void;
}

export const timeout_info_header = 'timeout-info';

/**
 * Utility function for passing timeout info to the HttpTimeoutInterceptor
 */
export function timeoutHeaders(info: TimeoutInfo, headers = new HttpHeaders()) {
  return headers.set(timeout_info_header, JSON.stringify(info));
}
