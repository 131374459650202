import { DayRecurrence, RecurrenceTiming } from '@nexuzhealth/shared-domain';
import { DayRecurrenceFormValue } from '../recurrences-form.domain';

export function mapToDayRecurrence(
  dayRecurrenceFormValue: DayRecurrenceFormValue,
  useDayPartAllDay: boolean,
): DayRecurrence {
  const timings: RecurrenceTiming[] = [];

  const daypartsFormValue = dayRecurrenceFormValue.day.dayparts;
  if (daypartsFormValue) {
    // Is allDay recurrence or Time = no recurrence
    if (daypartsFormValue.allDayOrTime) {
      if (useDayPartAllDay) {
        timings.push({ allDay: true });
      }
    } else {
      // Is day part
      daypartsFormValue.parts?.forEach((part) => {
        timings.push({ dayPartName: part });
      });
    }
  }

  const timingsFormValue = dayRecurrenceFormValue.day.timings;
  if (timingsFormValue) {
    timings.push(...timingsFormValue);
  }

  return {
    period: dayRecurrenceFormValue.period,
    day: { timings },
    time: dayRecurrenceFormValue.day.dayparts?.time,
  };
}
