import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[nxhDisableSubmitOnEnter]',
})
export class DisableSubmitOnEnterDirective {
  @HostListener('keydown.enter', ['$event'])
  preventSubmit(event: KeyboardEvent) {
    const element = event.target as HTMLElement;
    const defaultAllowed =
      this.isFormSafeButton(element) ||
      this.hasTag(element, 'TEXTAREA', 'A', 'SELECT') ||
      element.hasAttribute('contenteditable');

    if (!defaultAllowed) {
      event.preventDefault();
    }
  }

  private hasTag(element: HTMLElement, ...tags: string[]): boolean {
    return tags.includes(element.tagName);
  }

  private isFormSafeButton(element: HTMLElement): boolean {
    if (this.hasTag(element, 'BUTTON', 'INPUT')) {
      const buttonTypes = ['submit', 'button', 'reset'];
      const buttonLike = element as HTMLButtonElement | HTMLInputElement;
      return buttonTypes.includes(buttonLike.type);
    }
    return false;
  }
}
