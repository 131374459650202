import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { TaskCheckComponent } from './task-check.component';

@NgModule({
  imports: [CommonModule, NgbTooltipModule, IconsModule, FormsModule],
  exports: [TaskCheckComponent],
  declarations: [TaskCheckComponent],
})
export class TaskCheckModule {}
