import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
  name: 'formatPhoneNumber',
})
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(text: string | null | undefined) {
    if (!text) return '';

    try {
      const phonenumber = parsePhoneNumber(text);
      return phonenumber.formatInternational();
    } catch (e) {
      console.error('error formatting phonenumber', text);
      return text || '';
    }
  }
}
