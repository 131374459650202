import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nxh-form-exit-popup',
  templateUrl: './form-exit-popup.component.html',
  styleUrls: ['./form-exit-popup.component.scss'],
})
export class FormExitPopupComponent {
  constructor(private activeModal: NgbActiveModal) {}

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }
}
