<div
  class="form-group"
  [class.disabled]="disabled$ | async"
  [class.form-group--no-bottom-margin]="noBottomMargin"
  [class.form-group--row]="direction === 'row'"
>
  <ng-container *ngIf="!contentFirst">
    <label
      class="form-label"
      [class.form-label--row]="direction === 'row'"
      [for]="id"
      [class.required]="required"
      [class.disabled]="disabled$ | async"
      *ngIf="(label || reserveSpaceForLabel) && !noLabel"
      >{{ label }}</label
    >
    <fa-icon *ngIf="tooltip" [icon]="tooltipIcon" [ngbTooltip]="tooltip" class="form-label" />
  </ng-container>
  <div class="ng-content-container">
    <ng-content />
  </div>
  <ng-container *ngIf="contentFirst">
    <label
      class="form-label"
      [for]="id"
      [class.required]="required"
      [class.disabled]="disabled$ | async"
      *ngIf="label && !noLabel"
      >{{ label }}</label
    >
    <fa-icon *ngIf="tooltip" [icon]="tooltipIcon" [ngbTooltip]="tooltip" class="form-label" />
  </ng-container>
</div>
