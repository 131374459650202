import { ElementRef } from '@angular/core';
import { PopoverConfig } from './popover.model';

const DEFAULT_CONFIG = {
  hasBackdrop: true,
  allowClicksOutside: false,
  backdropClass: 'popover-backdrop',
  panelClass: '',
  scrollStrategy: 'reposition' as const,
  viewportMargin: 16,
};

type DefaultPopoverConfigFields = keyof typeof DEFAULT_CONFIG;
export type PopoverConfigMergedWithDefaultConfig = Required<Pick<PopoverConfig, DefaultPopoverConfigFields>> &
  Omit<PopoverConfig, DefaultPopoverConfigFields>;

export function mergeWithDefaults(popoverConfig: PopoverConfig): PopoverConfigMergedWithDefaultConfig {
  return { ...DEFAULT_CONFIG, ...popoverConfig };
}

export function getPopoverHeight(height: PopoverConfig['height']) {
  if (!height) {
    return undefined;
  }

  if (typeof height === 'string') {
    const percentageIndex = height.indexOf('%');
    if (percentageIndex > -1) {
      return `${(Number(height.substring(0, percentageIndex)) * window.innerHeight) / 100}px`;
    }
  }

  return height;
}

export function isElementRef(el: ElementRef | HTMLElement | undefined): el is ElementRef {
  return !!el && (el as ElementRef).nativeElement !== undefined;
}
