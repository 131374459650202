<h1>Empty states</h1>
<p>the empty state is used as a visual reminder that there is no data for the current view</p>
<ul>
  <li><b>icon</b>: what icon you ant as empty state !only use solid icons!! (default is folder open)</li>
  <li><b>template</b>: within the empty state element you can add a template this can include:</li>
  <li>
    <ul>
      <li>&lt;div class="empty__title"&gt;&lt;/div&gt;</li>
      <li>&lt;div class="empty__description"&gt;&lt;/div&gt;</li>
      <li>&lt;div class="empty__reasons"&gt;&lt;/div&gt;</li>
      <li>&lt;div class="empty__solution"&gt;&lt;/div&gt;</li>
    </ul>
  </li>
</ul>
<nxh-empty-state>
  <div class="empty__title">Deze patiënt heeft geen medicatie therapieën</div>
  <div class="empty__description">
    <a class="link-primary" [routerLink]="['/patients/list']">Registreer medicatie</a> om het dossier up-to-date te
    houden.
  </div>
</nxh-empty-state>
<br /><br />
<pre><code language="html" [highlight]="codeMedical"></code></pre>
<br /><br /><br />
<hr />
<br /><br /><br />
<nxh-empty-state icon="file-search">
  <div class="empty__title">Geen patiënt gevonden</div>
  <div class="empty__description">
    Er bestaat geen patiëntendossier voor zoekopdracht <strong>‘Sifie Verhshst’</strong>
  </div>
  <div class="empty__reasons">Hier komt eventueel een bepaalde reden</div>
  <div class="empty__solution">Hier komt eventueel een bepaalde oplossing</div>
</nxh-empty-state>
<br /><br />
<pre><code [highlight]="codePatients" language="html"></code></pre>
