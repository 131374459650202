import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { CdkTableModule } from '@angular/cdk/table';
import { SortTableDirective } from './sort-table.directive';
import { DisabledDirective } from './disabled.directive';
import { SortHeaderComponent } from './sort-header/sort-header.component';
import { ResponsiveComponent } from './responsive/responsive.component';
import { ResponsiveDirective } from './responsive/responsive.directive';
import { TableStylingDirective } from './styling/table-styling.directive';

@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    SharedUtilI18nModule,
    NgbTooltipModule,
    CdkTableModule,
    TableStylingDirective,
    SortHeaderComponent,
    DisabledDirective,
    SortTableDirective,
    ResponsiveComponent,
    ResponsiveDirective,
  ],
  exports: [
    SortHeaderComponent,
    DisabledDirective,
    SortTableDirective,
    ResponsiveDirective,
    CdkTableModule,
    TableStylingDirective,
  ],
})
export class TableModule {}
