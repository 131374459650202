<div [formGroup]="form" nxhToggle [pills]="pills">
  <ng-container *ngFor="let option of options; let index = index">
    <input
      type="checkbox"
      class="btn-check"
      [nxhDataTest]="'multi-toggle-list-item-' + index"
      [formControlName]="option.valueAsString"
      [id]="id + '_' + index"
      autocomplete="off"
    />
    <label
      class="btn btn-primary"
      nxhToggleOption
      [showCheckmark]="showCheckMarks"
      [class.active]="form.get(option.valueAsString)?.value"
      [class.disabled]="form.get(option.valueAsString)?.disabled"
      [for]="id + '_' + index"
    >
      <ng-container *ngIf="optionDirective?.template as template; else noTemplate">
        <ng-container *ngTemplateOutlet="template; context: { $implicit: option }" />
      </ng-container>
      <ng-template #noTemplate>
        <span *ngIf="option.label as label">{{ label | i18next }}</span>
      </ng-template>
    </label>
  </ng-container>
</div>
