import { Injectable } from '@angular/core';
import { ResourceName } from '@nexuzhealth/shared-domain';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AvatarConfig {
  dataSource!: AvatarDataSource;
}

export interface AvatarDataSource {
  loadAsDataUrl(pictureName: ResourceName, fromCache?: boolean): Observable<string>;
}
