<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Disable Submit on Enter</h1>
      <p class="sub-title"><code>[nxhDisableSubmitOnEnter]</code></p>
      <p>A form directive to disable submitting the form when pressing <b>enter</b> on any of its inputs.</p>
    </div>
  </div>
</div>

<div class="toolkit__body">
  <div class="toolkit__body__content">
    <div class="toolkit__body__content__block">
      <h2 class="page-title">Example</h2>
      <div class="card">
        <div class="card-header">
          <div class="card-header__title">Submit on enter disabled</div>
        </div>
        <div class="card-body">
          <div class="card-body__container nh-row">
            <div class="nh-col-4">
              <form (ngSubmit)="showAlert()" nxhDisableSubmitOnEnter>
                <input type="text" placeholder="Press enter" />
              </form>
            </div>
            <div class="nh-col-8">
              <code>&lt;form (ngSubmit)=&quot;showAlert()&quot; nxhDisableSubmitOnEnter&gt;</code><br />
              <code>&lt;input type=&quot;text&quot; placeholder=&quot;Press enter&quot; /&gt;</code><br />
              <code>&lt;/form&gt;</code>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <div class="card-header__title">Submit on enter enabled (no directive)</div>
        </div>
        <div class="card-body">
          <div class="card-body__container nh-row">
            <div class="nh-col-4">
              <form (ngSubmit)="showAlert()">
                <input type="text" placeholder="Press enter" />
              </form>
            </div>
            <div class="nh-col-8">
              <code>&lt;form (ngSubmit)=&quot;showAlert()&quot;&gt;</code><br />
              <code>&lt;input type=&quot;text&quot; placeholder=&quot;Press enter&quot; /&gt;</code><br />
              <code>&lt;/form&gt;</code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
