import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'nxh-dl-val',
  template: `<ng-content></ng-content>`,
  styles: [
    `
      :host {
        flex: 1;
        word-break: break-word;
      }

      :host.data-list--small-key {
        flex: 2;
      }
    `,
  ],
})
export class DataListValComponent {
  @HostBinding('class.data-list__value--style-as-key') @Input() styleAsKey = false;
  @HostBinding('class.data-list__value') clazz = true;
  @HostBinding('class.first-letter-capitalized') firstLetterCapitalized = true;
}
