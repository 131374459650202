import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { StackModule } from '@nexuzhealth/shared-ui-toolkit/stack';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { FaIconComponent, FaLayersComponent } from '@fortawesome/angular-fontawesome';
import { ToggleListOptionDescriptionDirective } from './toggle-list-option-description.directive';

@Component({
  selector: 'nxh-toggle-list-option',
  standalone: true,
  imports: [StackModule, NgIf, FaIconComponent, NgSwitch, NgSwitchCase, NgSwitchDefault, FaLayersComponent],
  templateUrl: './toggle-list-option.component.html',
  styleUrl: './toggle-list-option.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleListOptionComponent {
  @Input() showRadio = false;
  @Input() label?: string;
  @Input() description?: string;
  @Input() active = false;

  @ContentChild(ToggleListOptionDescriptionDirective)
  toggleListOptionDescription?: ToggleListOptionDescriptionDirective;
}
