import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@nexuzhealth/shared-ui-toolkit/forms';
import { of } from 'rxjs';

@Component({
  selector: 'nxh-radio-and-checkbox-demo',
  templateUrl: './radio-and-checkbox-demo.component.html',
  styleUrls: ['./radio-and-checkbox-demo.component.scss'],
})
export class RadioAndCheckboxDemoComponent implements OnInit {
  checkboxCode = `
    <nh-checkbox-group [label]="'mathematicians'" formGroupName="mathematicians" [direction]="'col'">
      <nh-checkbox [label]="'euler'" formControlName="euler"></nh-checkbox>
      <nh-checkbox [label]="'hardy'" formControlName="hardy"></nh-checkbox>
      <nh-checkbox [label]="'ramanujan'" formControlName="ramanujan"></nh-checkbox>
    </nh-checkbox-group>

    // or when you start from a list of options...

    <nh-checkbox [label]="option" [formControlName]="option" *ngFor="let option of mathematicianOptions"></nh-checkbox>

    new FormGroup({
      mathematicians: new FormGroup({
        euler: new FormControl(),
        hardy: new FormControl(),
        ramanujan: new FormControl(),
      }),
    })
  `;
  checkboxWithValidationCode = `
    <nh-checkbox-group [label]="'numbers'" formGroupName="numbers" [direction]="'row'">
      <nh-checkbox [label]="'pi'" formControlName="pi"></nh-checkbox>
      <nh-checkbox [label]="'perfect number'" formControlName="perfect"></nh-checkbox>
      <val-errors>
        <ng-template valError="minimum-one">select at least 1 number</ng-template>
      </val-errors>
    </nh-checkbox-group>
  `;

  checkboxWithOtherCode = `
    <nh-checkbox-group [label]="'domains'" formGroupName="domains">
      <div class="nh-row">
        <div class="nh-col">
          <nh-checkbox [label]="'number and quantity'" formControlName="numberAndQuantity"></nh-checkbox>
          <nh-checkbox [label]="'functions'" formControlName="functions"></nh-checkbox>
          <nh-checkbox [label]="'modeling'" formControlName="modeling"></nh-checkbox>
          <nh-checkbox [label]="'geometry'" formControlName="geometry"></nh-checkbox>
        </div>
        <div class="nh-col">
          <nh-checkbox [label]="'statistics and probability'" formControlName="statisticsAndProbability"></nh-checkbox>
          <div class="checkbox-other__container">
            <nh-checkbox [label]="'other'" formControlName="other"></nh-checkbox>
            <nh-control *ngIf="!!mathForm.get('domains.otherDomain')">
              <input type="text" formControlName="otherDomain" [placeholder]="'please specify'" nxhFocus />
              <val-errors [label]="'value' | i18next"></val-errors>
            </nh-control>
          </div>
        </div>
      </div>
    </nh-checkbox-group>

    new FormGroup({
      domains: new FormGroup({
        numberAndQuantity: new FormControl(),
        algebra: new FormControl(),
        functions: new FormControl(),
        modeling: new FormControl(),
        geometry: new FormControl(),
        statisticsAndProbability: new FormControl(),
        other: new FormControl(),
      }),
    })

    const domains = this.mathForm.get('domains') as FormGroup;
    domains.get('other').valueChanges.subscribe((other) => {
      if (other) {
        domains.addControl('otherDomain', new FormControl(null, Validators.required));
      } else {
        domains.removeControl('otherDomain');
      }
    });

  `;

  checkboxStandaloneCode = `
    <nh-checkbox [switch]="true" [label]="'Subscribe to our newsletter?'" formControlName="newsletter">
      <val-errors [label]="'value' | i18next"></val-errors>
    </nh-checkbox>
  `;

  radioButtonCode = `
    <nh-radio-group [label]="'object'" formControlName="object" [direction]="'row'">
      <nh-radio [label]="'cube'" value="cube"> </nh-radio>
      <nh-radio [label]="'sphere'" value="sphere"> </nh-radio>
      <val-errors></val-errors>
    </nh-radio-group>

    // or when starting from a list of options:

    <nh-radio-group [label]="'object'" formControlName="object" [direction]="'row'">
      <nh-radio *ngFor="let option of options" [label]="option" [value]="option"> </nh-radio>
      <val-errors></val-errors>
    </nh-radio-group>


    new FormGroup({
        object: new FormControl(null, Validators.required),
    })
  `;

  deprecatedCheckboxCode = `
  // HTML
  <div class="form-group">
    <label>favorite food</label>
    <div class="checkbox-container">
        <div class="custom-checkbox">
          <input
              type="checkbox"
              tabindex="-1"
              class="form-check-input"
              formControlName="someCheckbox1"
              id="someCheckbox1"
          />
          <label class="form-check-label" for="someCheckbox1">Custom checkbox here</label>
        </div>
        <div class="custom-checkbox">
          <input
              type="checkbox"
              tabindex="-1"
              class="form-check-input"
              formControlName="someCheckbox2"
              id="someCheckbox2"
          />
          <label class="form-check-label" for="someCheckbox2">Custom checkbox here</label>
        </div>
        <div class="custom-checkbox">
          <input
              type="checkbox"
              tabindex="-1"
              class="form-check-input"
              formControlName="someCheckbox3"
              id="someCheckbox3"
          />
          <label class="form-check-label" for="someCheckbox3">Custom checkbox here</label>
        </div>
        <div class="custom-checkbox">
          <input
              type="checkbox"
              tabindex="-1"
              class="form-check-input"
              formControlName="someCheckbox4"
              id="someCheckbox4"
          />
          <label class="form-check-label" for="someCheckbox4">Custom checkbox here</label>
        </div>
        <div class="custom-checkbox">
          <input
              type="checkbox"
              tabindex="-1"
              class="form-check-input"
              formControlName="someCheckbox5"
              id="someCheckbox5"
          />
          <label class="form-check-label" for="someCheckbox5">Custom checkbox here</label>
        </div>
    </div>
  </div>
  `;
  deprecatedRadioCode = `
  // HTML
  <div class="form-group">
    <label>favorite food</label>
    <div class="radio-container">
        <div class="form-check ">
          <input
            nxhFocus
            type="radio"
            class="form-check-input"
            formControlName="favFood"
            name="favFood"
            id="pizza"
            value="pizza"
          />
          <label class="form-check-label" for="pizza">pizza</label>
        </div>
        <div class="form-check ">
          <input
            type="radio"
            class="form-check-input"
            formControlName="favFood"
            name="favFood"
            id="pasta"
            value="pasta"
          />
          <label class="form-check-label" for="pasta">pasta</label>
        </div>
        <div class="form-check ">
          <input
            type="radio"
            class="form-check-input"
            formControlName="favFood"
            name="favFood"
            id="fries"
            value="fries"
          />
          <label class="form-check-label" for="fries">fries</label>
        </div>
        <div class="form-check ">
          <input
            type="radio"
            class="form-check-input"
            formControlName="favFood"
            name="favFood"
            id="hotdogs"
            value="hotdogs"
          />
          <label class="form-check-label" for="hotdogs">hotdogs</label>
        </div>
        <div class="form-check ">
          <input
            type="radio"
            class="form-check-input"
            formControlName="favFood"
            name="favFood"
            id="cake"
            value="cake"
          />
          <label class="form-check-label" for="travel">cake</label>
        </div>
        <div class="form-check ">
          <input
            type="radio"
            class="form-check-input"
            formControlName="favFood"
            name="favFood"
            id="gnocchi"
            value="gnocchi"
          />
          <label class="form-check-label" for="gnocchi">gnocchi</label>
        </div>
        <div class="form-check ">
          <input
            type="radio"
            class="form-check-input"
            formControlName="favFood"
            name="favFood"
            id="lasagna"
            value="lasagna"
          />
          <label class="form-check-label" for="lasagna">lasagna</label>
        </div>
        <div class="form-check ">
          <input
            type="radio"
            class="form-check-input"
            formControlName="favFood"
            name="favFood"
            id="magicbeans"
            value="magicbeans"
          />
          <label class="form-check-label" for="magicbeans">magic beans</label>
        </div>
    </div>
  </div>

  // TS
  form: FormGroup = new FormGroup({
    someNumber: new FormControl({ value: '' }),
    someDate: new FormControl({ value: new Date() }),
    someTime: new FormControl({ value: '' }),
    someInput: new FormControl({ value: '' }),
    someSelect: new FormControl({ value: '' }),
    favFood: new FormControl({ value: '' }),
    someCheckbox: new FormControl({ value: true }),
    someRadio: new FormControl({ value: 'jump' }),
  });
  `;

  toggleSwitchCode = `
        <nxh-toggle-switch
          iconLeft="calendar-alt"
          iconRight="list"
          [defaultChecked]="viewToggle$ | async"
          (checked)="viewToggle$.next($event)"
        ></nxh-toggle-switch>
  `;

  options = ['cube', 'sphere'];
  mathematicianOptions = ['euler', 'hardy', 'ramanujan'];

  mathForm = new UntypedFormGroup({
    newsletter: new UntypedFormControl(false, Validators.requiredTrue),
    numbers: new UntypedFormGroup(
      {
        pi: new UntypedFormControl(),
        perfect: new UntypedFormControl(),
      },
      minimumOne,
    ),
    mathematicians: new UntypedFormGroup({
      euler: new UntypedFormControl({ value: false, disabled: false }),
      hardy: new UntypedFormControl({ value: false, disabled: true }),
      ramanujan: new UntypedFormControl({ value: true, disabled: true }),
    }),
    domains: new UntypedFormGroup({
      numberAndQuantity: new UntypedFormControl(),
      algebra: new UntypedFormControl(),
      functions: new UntypedFormControl(),
      modeling: new UntypedFormControl(),
      geometry: new UntypedFormControl(),
      statisticsAndProbability: new UntypedFormControl(),
      other: new UntypedFormControl(),
    }),
    object: new UntypedFormControl(null, Validators.required),
    otherReason: new UntypedFormControl(null),
  });

  ngOnInit(): void {
    const domains = this.mathForm.get('domains') as UntypedFormGroup;
    domains.get('other').valueChanges.subscribe((other) => {
      if (other) {
        domains.addControl('otherDomain', new UntypedFormControl(null, Validators.required));
      } else {
        domains.removeControl('otherDomain');
      }
    });
  }

  submitMathForm(h: FormHelper) {
    h.submit(() => of(null), { resetOnSuccess: false }).subscribe();
  }
}

const minimumOne = (control: AbstractControl) => {
  return Object.values(control.value).find((val) => !!val) !== undefined ? null : { 'minimum-one': true };
};
