import { CivilDate, ClockTime, DateTime, DateTimePrecision } from '@nexuzhealth/shared-domain';
import {
  addDays,
  compareAsc,
  Duration,
  endOfDay,
  format,
  isAfter,
  isBefore,
  startOfDay,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { isWithinInterval } from 'date-fns/isWithinInterval';
import { I18NextPipe } from 'angular-i18next';
import { SortOrder } from './sort-utils';

export function mapDateToDateTime(date: Date, precision: DateTimePrecision): DateTime {
  return {
    value: jsDateToUtcDate(date).toISOString(),
    precision,
  };
}

export function jsDateToUtcDate(date: Date | string): Date {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return date ? new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())) : undefined;
}

export function mapDateToCivilDate(date?: Date | null): CivilDate | undefined {
  if (!date) {
    return undefined;
  }
  return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
}

export function mapCivilDateToDate(value?: CivilDate | null): Date | undefined {
  if (value && value.day > 0 && value.month > 0 && value.year > 0) {
    return new Date(value.year, value.month - 1, value.day);
  } else {
    return undefined;
  }
}

// Frontend is [startDate, endDate] (both inclusive), backend is [startDate, endDate[ (inclusive, exclusive)
// mapBackendCivilDateToFrontendDateEndOfDay converts the exclusive civilDate from BE to an inclusive Date for the FE
export function mapBackendCivilDateToFrontendDateEndOfDay(value?: CivilDate | null): Date | undefined {
  const date = mapCivilDateToDate(value);
  return date ? endOfDay(addDays(date, -1)) : undefined;
}

// Frontend is [startDate, endDate] (both inclusive), backend is [startDate, endDate[ (inclusive, exclusive)
// mapFrontendDateToBackendCivilDate converts the inclusive Date from FE to an exclusive civilDate for the BE
export function mapFrontendEndDateToBackendCivilDate(value?: Date | null): CivilDate | undefined {
  return value ? mapDateToCivilDate(addDays(value, 1)) : undefined;
}

/**
 * Sort given array of DateTimes. DateTimes with more precision are considered
 * more recent then DateTimes with less precision, e.g. with SortOrder.desc:
 *
 * 01/05/2019 > 04/2019 > 01/02/2017 > 2017
 */
export function sortDateTimes(dateTimes: Required<DateTime>[], sortOrder: SortOrder = SortOrder.asc) {
  dateTimes.sort(getDateTimeComparator());

  if (sortOrder === 'desc') {
    dateTimes.reverse();
  }
}

export function getDateTimeComparator() {
  const datePrecisions = Object.values(DateTimePrecision);

  return (left: DateTime, right: DateTime) => {
    if (!left && !right) {
      return 0;
    }
    if (!left) {
      return 1;
    }
    if (!right) {
      return -1;
    }

    const leftSortDate = mapDateTimeToDate(left);
    const rightSortDate = mapDateTimeToDate(right);

    const res = compareAsc(leftSortDate, rightSortDate);
    if (res !== 0) {
      return res;
    }

    return datePrecisions.indexOf(right.precision) - datePrecisions.indexOf(left.precision);
  };
}

export function mapDateTimeToDate(dateTime: DateTime): Date {
  const date = new Date(Date.parse(dateTime.value));

  if (dateTime.precision === DateTimePrecision.YEAR) {
    return startOfYear(new Date('' + date.getUTCFullYear()));
  }
  if (dateTime.precision === DateTimePrecision.MONTH) {
    return startOfMonth(new Date(date.getUTCFullYear(), date.getUTCMonth()));
  }

  return startOfDay(new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
}

export function isDateTime(date: Date | string | DateTime): date is DateTime {
  return date && (date as DateTime).precision !== undefined;
}

export type FormatDateTimeOptions =
  | {
      isExport: false;
      locale: string;
    }
  | {
      isExport: true;
      locale?: string; // ignored field but otherwise typescript cries
    };

export function formatDateTime(dateTime: DateTime, options: FormatDateTimeOptions) {
  const { isExport } = options;
  const _format = getPartialDateFormat(dateTime.precision, isExport, options.locale ?? 'en');
  return format(mapDateTimeToDate(dateTime), _format);
}

const partialDateFormats = {
  other: {
    DAY: 'dd/MM/yyyy',
    MONTH: 'MM/yyyy',
    YEAR: 'yyyy',
    default: 'dd/MM/yyyy',
  },
  export: {
    DAY: 'yyyy/MM/dd',
    MONTH: 'yyyy/MM',
    YEAR: 'yyyy',
    default: 'yyyy/MM/dd',
  },
};

function getPartialDateFormat(precision: DateTimePrecision, isExport: boolean, locale: string): string {
  if (isExport) {
    return partialDateFormats.export[precision] ?? partialDateFormats.export.default;
  }

  return partialDateFormats.other[precision] ?? partialDateFormats.other.default;
}

export function isPeriodActive(period: { start: Date; end?: Date }, dateToCompare = new Date()) {
  if (!period.end) {
    return !isBefore(dateToCompare, period.start);
  }
  return isWithinInterval(dateToCompare, { start: period.start, end: period.end });
}

export function isPartOfDay(period: { startTime: string | Date; endTime?: string | Date }): boolean {
  if (period) {
    if (period.startTime) {
      const startTime = new Date(period.startTime);
      if (startTime.getHours() !== 0 || startTime.getMinutes() !== 0) {
        return true;
      }
    }
    if (period.endTime) {
      const endTime = new Date(period.endTime);
      if (endTime.getHours() !== 23 || endTime.getMinutes() !== 59) {
        return true;
      }
    }
  }
  return false;
}

export function combineDateAndClockTime(date: Date, time: ClockTime): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.hours, time.minutes, time.seconds || 0);
}

export function parseClockTime(time: string): ClockTime | null {
  if (time === '') {
    //invalid or null time received
    return null;
  }
  const hoursAndMinutes: string[] = time.split(':');
  return <ClockTime>{
    hours: hoursAndMinutes[0] ? parseInt(hoursAndMinutes[0], 10) : 0,
    minutes: hoursAndMinutes[1] ? parseInt(hoursAndMinutes[1], 10) : 0,
  };
}

export function parseToFormTime(time: ClockTime): string {
  if (time) {
    return String(time.hours).padStart(2, '0') + ':' + String(time.minutes).padStart(2, '0');
  }
  return '';
}

export function durationToString(duration: Duration, i18: I18NextPipe): string {
  const durationList = [];
  if (duration.years) {
    durationList.push(duration.years + ' ' + i18.transform('years'));
  }
  if (duration.months) {
    durationList.push(duration.months + ' ' + i18.transform('months'));
  }
  if (duration.weeks) {
    durationList.push(duration.weeks + ' ' + i18.transform('weeks'));
  }
  if (duration.days) {
    durationList.push(duration.days + ' ' + i18.transform('days'));
  }
  if (duration.hours) {
    durationList.push(duration.hours + ' ' + i18.transform('hours'));
  }
  if (duration.minutes) {
    durationList.push(duration.minutes + ' ' + i18.transform('minutes'));
  }
  if (duration.seconds) {
    durationList.push(duration.seconds + ' ' + i18.transform('seconds'));
  }
  if (durationList.length === 0) {
    return '';
  }
  return durationList.join(', ');
}
