import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { guid } from '@datorama/akita';

@Injectable()
export class RadioGroupService {
  public parent?: UntypedFormControl;

  /**
   * Radio buttons within a group should share the same name.
   */
  public name = guid();
}
