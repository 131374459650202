<div ngbDropdown #dropdown="ngbDropdown" class="hide-default-down-icon" [placement]="placement">
  <div class="d-flex align-items-center confidentiality-select-gap">
    <fa-stack *ngIf="selectedItem; else noSelectedItem">
      <fa-icon [icon]="['fas', 'circle']" stackItemSize="2x" class="background" />
      <fa-icon [icon]="selectedItem.icon" stackItemSize="1x" class="foreground" />
    </fa-stack>
    <ng-template #noSelectedItem>
      <fa-stack>
        <fa-icon icon="circle" stackItemSize="2x" class="background" />
        <fa-icon icon="question" stackItemSize="1x" class="foreground" />
      </fa-stack>
    </ng-template>
    <button
      type="button"
      [attr.tabindex]="tabindex"
      nxh-button
      buttonType="subtle-button-small"
      ngbDropdownToggle
      #toggleButton
      [disabled]="isDisabled"
      nxhDataTest="confidentiality-btn"
    >
      <span class="selected-item_label" *ngIf="selectedItem; else noSelectedItem">
        {{ selectedItem.label | i18next }}
      </span>
      <ng-template #noSelectedItem>
        <span>{{ 'confidentiality' | i18next | titlecase }}</span>
      </ng-template>
      <ng-container *ngIf="!isDisabled">
        <ng-container [ngSwitch]="dropdown.isOpen()">
          <fa-icon *ngSwitchCase="true" icon="chevron-up" />
          <fa-icon *ngSwitchDefault icon="chevron-down" />
        </ng-container>
      </ng-container>
    </button>
  </div>
  <div ngbDropdownMenu>
    <button
      type="button"
      class="btn dropdown-item"
      (click)="selectItem(item)"
      ngbDropdownItem
      *ngFor="let item of items; let index = index"
      [nxhDataTest]="'confidentiality-item-' + index"
    >
      <fa-icon [icon]="item.icon" />
      <span>{{ item.label | i18next }}</span>
    </button>
  </div>
</div>
