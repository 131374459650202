import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output,
  QueryList,
} from '@angular/core';

import type { IconName } from '@fortawesome/fontawesome-common-types';
import { addDataTestAttributes, DataTestDirective } from '@nexuzhealth/shared-tech-feature-e2e';
import { AlertDescriptionComponent } from '../alert-description/alert-description.component';
import { AlertItemComponent } from '../alert-item/alert-item.component';
import { AlertTitleComponent } from '../alert-title/alert-title.component';

export type AlertType = 'primary' | 'success' | 'warning' | 'danger' | 'info';

const iconTypes: Partial<{ [key in AlertType]: IconName | undefined }> = {
  primary: 'info-circle',
  success: 'check-circle',
  warning: 'triangle-exclamation',
  danger: 'hexagon-exclamation',
  info: 'info-circle',
};

@Component({
  selector: 'nxh-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements AfterViewInit {
  @Input() type: AlertType = 'danger';
  @Input() alertTitle?: string | null;
  @Input() description?: string;
  @Input({ transform: booleanAttribute }) closeable = false;
  @HostBinding('class.no-bottom-margin') @Input({ transform: booleanAttribute }) noBottomMargin = false;

  @Output() closeAlert = new EventEmitter<void>();

  @ContentChildren(AlertTitleComponent) alertTitleComponent?: QueryList<AlertTitleComponent>;
  @ContentChildren(AlertDescriptionComponent) alertDescriptionComponent?: QueryList<AlertDescriptionComponent>;
  @ContentChildren(AlertItemComponent) alertItemComponent?: QueryList<AlertItemComponent>;

  constructor(
    private elementRef: ElementRef,
    @Optional() private dataTestDirective: DataTestDirective,
  ) {}

  /**
   * @ignore
   */
  @HostBinding('class.empty') get isEmpty() {
    return !this.alertItemVisible && !this.alertItemComponent?.first;
  }

  /**
   * @ignore
   */
  ngAfterViewInit(): void {
    addDataTestAttributes(this.dataTestDirective?.nxhDataTest || 'alert-component', {
      element: this.elementRef.nativeElement,
      suffix: '_alert',
    });
  }

  get alertClass() {
    return this.type === 'info' ? 'primary' : this.type;
  }

  get alertIcon() {
    return iconTypes[this.type];
  }

  get alertItemVisible() {
    return (
      this.alertTitle || this.description || this.alertTitleComponent?.first || this.alertDescriptionComponent?.first
    );
  }

  /**
   * Triggers a page scroll to this component.
   *
   * @param {ScrollIntoViewOptions} scrollIntoViewOptions - defines way of scrolling, cf. Check out https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
   * @param {ScrollIntoViewOptions} [scrollIntoViewOptions.block='end']
   * @param {ScrollIntoViewOptions} [scrollIntoViewOptions.inline='nearest']
   * @param {ScrollIntoViewOptions} [scrollIntoViewOptions.behaviour='smooth']
   */
  scrollTo(scrollIntoViewOptions: ScrollIntoViewOptions = { block: 'end', inline: 'nearest', behavior: 'smooth' }) {
    this.elementRef.nativeElement.scrollIntoView(scrollIntoViewOptions);
  }
}
