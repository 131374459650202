import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { StickerDirective } from '@nexuzhealth/shared-ui-toolkit/sticker';
import { PillComponent } from './pill/pill.component';

@NgModule({
  imports: [CommonModule, FontAwesomeModule, NgbTooltipModule, ButtonModule, StickerDirective],
  declarations: [PillComponent],
  exports: [PillComponent],
})
export class PillModule {}
