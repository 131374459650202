import { Component, Input } from '@angular/core';

@Component({
  selector: 'nxh-detail-bubble-demo-content',
  template: `
    <div class="card-body">
      <div class="card-body__container card-body__container--has-icon">
        <fa-icon class="bubble-content__icon" icon="pen"></fa-icon>
        <div class="card-body__container__content">geen contacten</div>
      </div>

      <div class="card-body__container card-body__container--has-icon">
        <fa-icon class="bubble-content__icon" icon="pen"></fa-icon>
        <div class="card-body__container__content">
          <div class="data-list">
            <div class="data-list__row">
              <div class="data-list__key">Status</div>
              <div class="data-list__value">Passief relevant</div>
            </div>

            <div class="data-list__row">
              <div class="data-list__key">Count</div>
              <div class="data-list__value">{{ count }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .card-body {
        max-height: 30rem;
        overflow-y: auto;
      }
    `,
  ],
})
export class DetailBubbleDemoContentComponent {
  @Input() count = 0;

  constructor() {}
}
