import { Component } from '@angular/core';

@Component({
  selector: 'nxh-back-button-demo',
  templateUrl: './back-button-demo.component.html',
  styleUrls: ['./back-button-demo.component.scss'],
})
export class BackButtonDemoComponent {
  default = `
    <nxh-back-button></nxh-back-button>
    <nxh-back-button fallback="/button"></nxh-back-button>
  `;

  fixed = `
    <nxh-back-button [routerLink]="['/button']" tooltip="custom tooltip"></nxh-back-button>
  `;

  custom = `<nxh-back-button (click)="doStuffThenGoToX()"></nxh-back-button>`;

  surprise() {
    alert("it's going to be nice");
    window.open('https://twitter.com/estressecurado/status/1429130254296174598?s=20', '_blank');
  }
}
