<div class="day-planner">
  <div class="day-planner__hour-container" *ngFor="let item of hours; let i = index">
    <div class="day-planner__hour-container__separator">
      <div class="day-planner__hour__title">{{ item.formattedHour }}</div>
      <div class="day-planner__hour-container__separator__divider"></div>
    </div>
    <div
      class="day-planner__hour-container__list"
      cdkDropList
      [cdkDropListData]="item.tasks"
      [cdkDropListConnectedTo]="LIST_IDS"
      (cdkDropListDropped)="drop($event)"
      id="{{ LIST_IDS[i] }}"
      [cdkDropListDisabled]="dragDropDisabled"
    >
      <div
        class="day-planner__hour-container__list__task"
        cdkDrag
        *ngFor="let task of item.tasks"
        (click)="withInfoBubble ? showBubble($event, task) : null"
      >
        <ng-container [ngSwitch]="!!template">
          <ng-container *ngSwitchDefault>
            <div class="day-planner__hour-container__list__task__container">
              <div class="day-planner__hour-container__list__task__container__start-hour">
                {{ task.dateInterval.from | nxhDate: 'shortTime' }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="true">
            <ng-container [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ task: task }"></ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="item.tasks.length === 0" class="day-planner__hour-container__list__task">
        <div class="day-planner__hour-container__list__task__container"></div>
      </div>
    </div>
  </div>
  <ng-container [ngSwitch]="!!emptyStateTemplate">
    <ng-container *ngSwitchDefault>
      <nxh-empty-state *ngIf="!generalTimeoutError && !generalRequestError && hours && hours.length === 0">
        <div class="empty__title">{{ emptyTitle }}</div>
        <div class="empty__description">
          {{ emptyDescription }}
        </div>
      </nxh-empty-state>
    </ng-container>
    <ng-container *ngSwitchCase="true">
      <ng-container
        [ngTemplateOutlet]="emptyStateTemplate"
        *ngIf="!generalTimeoutError && !generalRequestError && hours && hours.length === 0"
      ></ng-container>
    </ng-container>
  </ng-container>

  <nxh-timeout-state *ngIf="!generalRequestError && generalTimeoutError">
    <div class="timeout__title">{{ 'general-timeout-error-title' | i18next }}</div>
    <div class="timeout__description">{{ 'general-timeout-error-description' | i18next }}.</div>
  </nxh-timeout-state>
</div>
