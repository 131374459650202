import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'deprecated-submit-button-demo',
  templateUrl: './submit-button-demo.component.html',
  styleUrls: ['./submit-button-demo.component.scss'],
})
export class SubmitButtonDemoComponent {
  defaultCode = `
// use default label 'Save'
<deprecated-submit-button
  [showSpinner]="isLoading"
></deprecated-submit-button>
  `;
  colorTypeCode = `
  <deprecated-submit-button btnType="primary" (click)="clickAction()" [showSpinner]="isLoading"> </deprecated-submit-button>
  <deprecated-submit-button btnType="warning" (click)="clickAction()" [showSpinner]="isLoading"> </deprecated-submit-button>
  <deprecated-submit-button btnType="danger" (click)="clickAction()" [showSpinner]="isLoading"> </deprecated-submit-button>
  // 'success' is the default colorType
  <deprecated-submit-button btnType="success" (click)="clickAction()" [showSpinner]="isLoading"> </deprecated-submit-button>
  `;
  customLabelCode = `
// use custom label
<deprecated-submit-button
  [showSpinner]="isLoading"
  label="Custom label here">
</deprecated-submit-button>
  `;
  disabledCode = `
// use custom label
<deprecated-submit-button [showSpinner]="isLoading" [disabled]="true" label="I'm disabled"></deprecated-submit-button>
  `;

  noIconCode = `
<deprecated-submit-button [showIcon]="false"></deprecated-submit-button>
  `;

  isLoading = false;
  isLoading2 = false;

  constructor() {}

  clickAction() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }

  clickAction2() {
    this.isLoading2 = true;
    setTimeout(() => {
      this.isLoading2 = false;
    }, 2000);
  }
}
