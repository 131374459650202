import { AddressComponentModel } from '@nexuzhealth/shared-street-management-domain';

type ResourceName = string;

export interface KeyBox {
  name: ResourceName;
  sendVersion: string;
  code: string;
  extraInfo: string;
  entityName: ResourceName;
  startTime: string;
  endTime?: string;
  type: KeyBoxType;
  nickname: string;
  keyBoxAddress: Partial<KeyBoxAddress>;
  keyBoxContactInfo: Partial<KeyBoxContactInfo>;
  /**
   * @deprecated. Will be moved to PatientAddressKeyBox
   */
  emergencyOnly?: boolean;
}

export interface KeyBoxAddress {
  street: string;
  number: string;
  box: string;
  postalCode: string;
  city: string;
  country: string;
  comment: string;
  addressComponent?: AddressComponentModel;
}

export interface KeyBoxContactInfo {
  familyName: string;
  givenName: string;
  phone: string;
  mobile: string;
  email: string;
  address: Partial<KeyBoxAddress>;
}

export enum KeyBoxType {
  UNSPECIFIED = 'UNSPECIFIED',
  PRIVATE = 'PRIVATE',
  SHARED = 'SHARED',
}
