import { CalendarOptions } from '@fullcalendar/core';

export type LicensedCalendarOptions = CalendarOptions & { schedulerLicenseKey: string };

export function getCalendarOptions(
  fullCalendarLicenseKey: string,
  locale: string,
  options: CalendarOptions,
): LicensedCalendarOptions {
  return {
    eventBackgroundColor: 'var(--primaryColorWashed)',
    eventBorderColor: 'transparent',
    eventTextColor: 'var(--bodyTextColor)',
    firstDay: 1,
    headerToolbar: false,
    resourceOrder: 'title,id',
    schedulerLicenseKey: fullCalendarLicenseKey,
    locale: locale === 'en' ? 'en-GB' : locale, //https://github.com/fullcalendar/fullcalendar/issues/5303
    ...options,
  };
}
