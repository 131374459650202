<ng-container [ngSwitch]="longPlaying">
  <div class="loading-spinner" *ngSwitchCase="true">
    <fa-icon class="loading-spinner__icon animate-hourglass" [pulse]="true" icon="hourglass-start" />
    <span class="loading-spinner__content">{{ 'loading-search-results--long-playing' | i18next }}</span>
  </div>
  <div class="loading-spinner" *ngSwitchDefault>
    <fa-icon class="loading-spinner__icon" [spin]="true" icon="sync" />
    <span class="loading-spinner__content">{{ 'loading-search-results' | i18next }}</span>
  </div>
</ng-container>
