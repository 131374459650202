<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Page layout strategy</h1>
      <p>We have 3 types of page layouts: full width, limited width (55rem) and centered width.</p>
    </div>
  </div>
</div>
<div class="toolkit__body">
  <div class="toolkit__body__content" nxhScrollspyContainer>
    <div
      class="toolkit__body__content__block"
      id="block1"
      nxhScrollspyTarget="block1"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Basic full width page</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock1" (click)="showExampleBlock1 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock1" (click)="showExampleBlock1 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>

      <div *ngIf="showExampleBlock1">
        <div class="card">
          <div class="card-body card-body--compact">
            <img src="/assets/screenshots/page-layout/page-layout-steps-basic-full.jpg" alt="" />
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock1">
        <pre><code language="html" [highlight]="block1Code"></code></pre>
      </div>
    </div>
    <div
      class="toolkit__body__content__block"
      id="block2"
      nxhScrollspyTarget="block2"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Limited content</h2>
      <p>Content has a max-width of 55rem.</p>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock2" (click)="showExampleBlock2 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock2" (click)="showExampleBlock2 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showExampleBlock2">
        <div class="card">
          <div class="card-body card-body--compact">
            <img src="/assets/screenshots/page-layout/page-layout-steps-limited-content.jpg" alt="" />
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock2">
        <pre><code language="html" [highlight]="block2Code"></code></pre>
      </div>
    </div>
    <div
      class="toolkit__body__content__block"
      id="block3"
      nxhScrollspyTarget="block3"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Limited header</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock3" (click)="showExampleBlock3 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock3" (click)="showExampleBlock3 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showExampleBlock3">
        <div class="card">
          <div class="card-body card-body--compact">
            <img src="/assets/screenshots/page-layout/page-layout-steps-limited-title.jpg" alt="" />
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock3">
        <pre><code language="html" [highlight]="block3Code"></code></pre>
      </div>
    </div>
    <div
      class="toolkit__body__content__block"
      id="block4"
      nxhScrollspyTarget="block4"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Centered page</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock4" (click)="showExampleBlock4 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock4" (click)="showExampleBlock4 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showExampleBlock4">
        <div class="card">
          <div class="card-body card-body--compact">
            <img src="/assets/screenshots/page-layout/page-layout-steps-centered.jpg" alt="" />
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock4">
        <pre><code [highlight]="block4Code" language="html"></code></pre>
      </div>
    </div>
  </div>

  <div class="toolkit__body__scrollspy">
    <ul>
      <li (click)="scrollTo('block1')" [class.is-active]="(selected$ | async) === 'block1'">Basic full width page</li>
      <li (click)="scrollTo('block2')" [class.is-active]="(selected$ | async) === 'block2'">Limited content</li>
      <li (click)="scrollTo('block3')" [class.is-active]="(selected$ | async) === 'block3'">Limited header</li>
      <li (click)="scrollTo('block4')" [class.is-active]="(selected$ | async) === 'block4'">Centered page</li>
    </ul>
  </div>
</div>
