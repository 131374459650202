import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'nxh-listgroup-form',
  templateUrl: './listgroup-form.component.html',
  styleUrls: ['./listgroup-form.component.scss'],
})
export class ListgroupFormComponent {
  @Input() formGroup: UntypedFormGroup;
  constructor() {}
}
