<div class="spinner-container">
  <input (keyup)="valueChanged($event)" placeholder="Start searching" />
  <p *ngIf="loading">Continuesly spinning</p>
  <nxh-loading-spinner *ngIf="loading"></nxh-loading-spinner>
  <p *ngIf="loading">Triggered by hand</p>
  <nxh-loading-spinner *ngIf="loading" [longPlaying]="longPlaying"></nxh-loading-spinner>
  <div *ngIf="!loading && results">
    <ul>
      <li>Result 1</li>
      <li>Result 2</li>
      <li>Result 3</li>
    </ul>
  </div>
</div>

<div class="spinner-container">
  <input (keyup)="valueChanged2($event)" placeholder="Start searching" />
  <p *ngIf="loading2">Specified delay:</p>
  <nxh-loading-spinner *ngIf="loading2" longPlayingDelay="5000"></nxh-loading-spinner>
  <p *ngIf="loading2">Default delay:</p>
  <nxh-loading-spinner *ngIf="loading2" longPlayingDelay></nxh-loading-spinner>
  <div *ngIf="!loading2 && results2">
    <ul>
      <li>Result 1</li>
      <li>Result 2</li>
      <li>Result 3</li>
    </ul>
  </div>
</div>

<p>
  This demo demonstrates how to use the loading spinner and the longPlaying rxjs operator. The loading spinner is a
  component that can be added to any context to show a standardised loading spinner. The longPlaying operator allows for
  control of observables. This operator is intended for use when a specific call takes longer than expected. When this
  is the case, we can take action and notify the user we are still busy and it's still working. The operator takes care
  of subscribing and unsubscribing when needed.
</p>
