import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { UserContext } from '@nexuzhealth/shared-domain';

const idKey: keyof UserContext = 'name';

export type UserContextStateKey = UserContext[typeof idKey];
export type UserContextState = EntityState<UserContext, UserContextStateKey>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-context', idKey, resettable: true })
export class UserContextStore extends EntityStore<UserContextState> {
  constructor() {
    super();
  }
}
