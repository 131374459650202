import { Component } from '@angular/core';

@Component({
  selector: 'nxh-ellipsis-actions-demo',
  templateUrl: './ellipsis-actions-demo.component.html',
  styleUrls: ['./ellipsis-actions-demo.component.scss'],
})
export class EllipsisActionsDemoComponent {
  code = `
    <nxh-ellipsis-actions [loading]="loading" [deleteOption]="true" [editOption]="true" (onDelete)="deleteEventHandler($event)"
    (onEdit)="editEventHandler($event)" placeholder="locize-string"></nxh-ellipsis-actions>
  `;

  iconCode = `
  <div ngbDropdown class="icons hide-default-down-icon">
     <button nxh-button buttonType="icon" ngbTooltip="Print action">
    <fa-icon icon="print"></fa-icon>
  </button>
  <button nxh-button buttonType="icon" ngbTooltip="Edit action">
    <fa-icon icon="pen"></fa-icon>
  </button>
  <button nxh-button buttonType="icon" ngbTooltip="Remove action">
    <fa-icon icon="times"></fa-icon>
  </button>
  <button nxh-button buttonType="icon" ngbTooltip="More..." ngbDropdownToggle>
    <fa-icon icon="ellipsis-h"></fa-icon>
  </button>
    <div class="dropdownmenu" ngbDropdownMenu>
      <button class="btn" ngbDropdownItem><fa-icon icon="pills"></fa-icon> <span>Default Action</span></button>
      <button class="btn dropdown-item-primary" ngbDropdownItem>
        <fa-icon icon="ruler"></fa-icon> <span>Primary Action</span>
      </button>
      <button class="btn dropdown-item-success" ngbDropdownItem>
        <fa-icon icon="heart-rate"></fa-icon> <span>Success Action</span>
      </button>
      <button class="btn dropdown-item-warning" ngbDropdownItem>
        <fa-icon icon="heart-rate"></fa-icon> <span>Warning Action</span>
      </button>
      <button class="btn dropdown-item-danger" ngbDropdownItem>
        <fa-icon icon="trash-alt"></fa-icon> <span>Danger action</span>
      </button>
    </div>
  </div>
  `;

  tableCode = `
<ng-container cdkColumnDef="actions">
  <th cdk-header-cell *cdkHeaderCellDef></th>
  <td cdk-cell *cdkCellDef="let row">
    <div ngbDropdown container="body" display="static" placement="bottom-right" class="actions-container hide-default-down-icon">
      <button nxh-button buttonType="icon" ngbTooltip="Print action">
    <fa-icon icon="print"></fa-icon>
  </button>
  <button nxh-button buttonType="icon" ngbTooltip="Edit action">
    <fa-icon icon="pen"></fa-icon>
  </button>
  <button nxh-button buttonType="icon" ngbTooltip="Remove action">
    <fa-icon icon="times"></fa-icon>
  </button>
  <button nxh-button buttonType="icon" ngbTooltip="More..." ngbDropdownToggle>
    <fa-icon icon="ellipsis-h"></fa-icon>
  </button>
      <div class="dropdownmenu" ngbDropdownMenu>
        <button class="btn" ngbDropdownItem><fa-icon icon="pills"></fa-icon> <span>Default Action</span></button>
        <button class="btn dropdown-item-primary" ngbDropdownItem>
          <fa-icon icon="ruler"></fa-icon> <span>Primary Action</span>
        </button>
        <button class="btn dropdown-item-success" ngbDropdownItem>
          <fa-icon icon="heart-rate"></fa-icon> <span>Success Action</span>
        </button>
        <button class="btn dropdown-item-warning" ngbDropdownItem>
          <fa-icon icon="heart-rate"></fa-icon> <span>Warning Action</span>
        </button>
        <button class="btn dropdown-item-danger" ngbDropdownItem>
          <fa-icon icon="trash-alt"></fa-icon> <span>Danger action</span>
        </button>
      </div>
    </div>
  </td>
</ng-container>
  `;

  activeId: number;

  tableData = [
    {
      name: 'steve',
      lastName: 'jackson',
      reason: 'stomach ache',
    },
    {
      name: 'tom',
      lastName: 'toblerone',
      reason: 'diabetic',
    },
    {
      name: 'carl',
      lastName: 'carlson',
      reason: 'headache',
    },
  ];

  displayedColumns = ['name', 'lastName', 'reason', 'actions'];

  constructor() {}
}
