import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { NgSelectModule } from '@ng-select/ng-select';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { BoldPipeModule } from '@nexuzhealth/shared-ui-toolkit/pipes/bold';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { CustomizeNgSelectCheckboxesDirective } from './customize-ng-select-checkboxes.directive';
import { ConfidentialitySelectComponent } from './confidentiality-select/confidentiality-select.component';
import { CustomizeNgSelectAddTagDirective } from './customize-ng-select-add-tag.directive';
import { CustomizeNgSelectDefaultsDirective } from './customize-ng-select-defaults.directive';
import { NgSelectTemplatesComponent } from './ng-select-templates.component';
import { CheckboxOptionComponent } from './options/checkbox-option/checkbox-option.component';
import { LoadingOptionComponent } from './options/loading-option/loading-option.component';
import { NoResultOptionComponent } from './options/no-result-option/no-result-option.component';
import { NgOptionHighlightDirective } from './ng-option-highlight.directive';
import { NgSelectErrorDirective } from './ng-select-error.directive';

@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    NgbDropdownModule,
    SharedTechFeatureE2eModule,
    SharedUtilI18nModule,
    NgSelectModule,
    BoldPipeModule,
    ButtonModule,
    // TODO ADD THIS IMPORT BACK WHEN MERGED TO ANGULAR AND NX 16
    // NgOptionHighlightModule,
  ],
  exports: [
    CheckboxOptionComponent,
    LoadingOptionComponent,
    NoResultOptionComponent,
    ConfidentialitySelectComponent,
    CustomizeNgSelectAddTagDirective,
    CustomizeNgSelectCheckboxesDirective,
    CustomizeNgSelectDefaultsDirective,
    NgSelectTemplatesComponent,
    NgSelectModule,
    NgOptionHighlightDirective,
    NgSelectErrorDirective,
  ],
  declarations: [
    CheckboxOptionComponent,
    LoadingOptionComponent,
    NoResultOptionComponent,
    ConfidentialitySelectComponent,
    CustomizeNgSelectAddTagDirective,
    CustomizeNgSelectCheckboxesDirective,
    CustomizeNgSelectDefaultsDirective,
    NgSelectTemplatesComponent,
    NgOptionHighlightDirective,
    NgSelectErrorDirective,
  ],
})
export class SelectsModule {}
