<h1>Pipes</h1>

<h2>Date pipe</h2>

<p>
  The <a href="https://angular.io/api/common/DatePipe">Angular DatePipe</a> is very powerfull. You can pass a whole set
  of predefined formats and also pass your own custom formats. With great power comes great responsibility. As we want
  to standardize the date formats in the application, we have come up with the following formats.
</p>

<table>
  <tr>
    <th>Name</th>
    <th>Code</th>
    <th>Nl</th>
    <th>En</th>
    <th>Fr</th>
  </tr>
  <tr>
    <th>shortDate (default)</th>
    <td>
      <code [highlight]="getFormat('shortDate')" language="html"></code>
      <code [highlight]="getFormat()" language="html"></code>
    </td>
    <td>{{ now | nxhDate: 'shortDate' }}</td>
    <td class="en">{{ now | nxhDate: 'shortDate' : undefined : 'en' }}</td>
    <td>{{ now | nxhDate: 'shortDate' : undefined : 'fr' }}</td>
  </tr>
  <tr>
    <th>longDate</th>
    <td><code [highlight]="getFormat('longDate')" language="html"></code></td>
    <td>{{ now | nxhDate: 'longDate' }}</td>
    <td class="en">{{ now | nxhDate: 'longDate' : undefined : 'en' }}</td>
    <td>{{ now | nxhDate: 'longDate' : undefined : 'fr' }}</td>
  </tr>
  <tr>
    <th>monthDate</th>
    <td><code [highlight]="getFormat('monthDate')" language="html"></code></td>
    <td>{{ now | nxhDate: 'monthDate' }}</td>
    <td class="en">{{ now | nxhDate: 'monthDate' : undefined : 'en' }}</td>
    <td>{{ now | nxhDate: 'monthDate' : undefined : 'fr' }}</td>
  </tr>
  <tr>
    <th>shortTime</th>
    <td><code [highlight]="getFormat('shortTime')" language="html"></code></td>
    <td>{{ now | nxhDate: 'shortTime' }}</td>
    <td class="en">{{ now | nxhDate: 'shortTime' : undefined : 'en' }}</td>
    <td>{{ now | nxhDate: 'shortTime' : undefined : 'fr' }}</td>
  </tr>
  <tr>
    <th>mediumTime</th>
    <td><code [highlight]="getFormat('mediumTime')" language="html"></code></td>
    <td>{{ now | nxhDate: 'mediumTime' }}</td>
    <td>{{ now | nxhDate: 'mediumTime' : undefined : 'en' }}</td>
    <td>{{ now | nxhDate: 'mediumTime' : undefined : 'fr' }}</td>
  </tr>
  <tr>
    <th>longTime</th>
    <td><code [highlight]="getFormat('longTime')" language="html"></code></td>
    <td>{{ now | nxhDate: 'longTime' }}</td>
    <td>{{ now | nxhDate: 'longTime' : undefined : 'en' }}</td>
    <td>{{ now | nxhDate: 'longTime' : undefined : 'fr' }}</td>
  </tr>
  <tr>
    <th>shortDateTime</th>
    <td><code [highlight]="getFormat('shortDateTime')" language="html"></code></td>
    <td>{{ now | nxhDate: 'shortDateTime' }}</td>
    <td>{{ now | nxhDate: 'shortDateTime' : undefined : 'en' }}</td>
    <td>{{ now | nxhDate: 'shortDateTime' : undefined : 'fr' }}</td>
  </tr>
  <tr>
    <th>longDateTime</th>
    <td><code [highlight]="getFormat('longDateTime')" language="html"></code></td>
    <td>{{ now | nxhDate: 'longDateTime' }}</td>
    <td>{{ now | nxhDate: 'longDateTime' : undefined : 'en' }}</td>
    <td>{{ now | nxhDate: 'longDateTime' : undefined : 'fr' }}</td>
  </tr>
  <tr>
    <th>shortWeekdayDate</th>
    <td><code [highlight]="getFormat('shortWeekdayDate')" language="html"></code></td>
    <td>{{ now | nxhDate: 'shortWeekdayDate' }}</td>
    <td>{{ now | nxhDate: 'shortWeekdayDate' : undefined : 'en' }}</td>
    <td>{{ now | nxhDate: 'shortWeekdayDate' : undefined : 'fr' }}</td>
  </tr>
  <tr>
    <th>shortWeekdayDateTime</th>
    <td><code [highlight]="getFormat('shortWeekdayDateTime')" language="html"></code></td>
    <td>{{ now | nxhDate: 'shortWeekdayDateTime' }}</td>
    <td>{{ now | nxhDate: 'shortWeekdayDateTime' : undefined : 'en' }}</td>
    <td>{{ now | nxhDate: 'shortWeekdayDateTime' : undefined : 'fr' }}</td>
  </tr>
  <tr>
    <th>longWeekdayDate</th>
    <td><code [highlight]="getFormat('longWeekdayDate')" language="html"></code></td>
    <td>{{ now | nxhDate: 'longWeekdayDate' }}</td>
    <td>{{ now | nxhDate: 'longWeekdayDate' : undefined : 'en' }}</td>
    <td>{{ now | nxhDate: 'longWeekdayDate' : undefined : 'fr' }}</td>
  </tr>
  <tr>
    <th>longWeekdayDateTime</th>
    <td><code [highlight]="getFormat('longWeekdayDateTime')" language="html"></code></td>
    <td>{{ now | nxhDate: 'longWeekdayDateTime' }}</td>
    <td>{{ now | nxhDate: 'longWeekdayDateTime' : undefined : 'en' }}</td>
    <td>{{ now | nxhDate: 'longWeekdayDateTime' : undefined : 'fr' }}</td>
  </tr>
</table>

<h2>Currency pipe</h2>

<p>
  To format the currency, one can use Angular's <a href="https://angular.io/api/common/CurrencyPipe">CurrencyPipe</a>.
  For the moment it is still necessary to pass the currency along. The locale takes care of formatting the decimals and
  placing the symbol before or after the number.
</p>

eg: {{ 25 | currency: 'EUR' }}

<pre>
  <code [highlight]="currencyCode" language="html"></code>
</pre>

<h2>Number pipe</h2>

eg: {{ 25.0 | number }}

<pre>
  <code [highlight]="decimalsCode1" language="html"></code>
</pre>

<p>
  You can also specify the number of digits (but check
  <a href="https://angular.io/api/common/DecimalPipe">the documentation</a> for more details)
</p>

eg: {{ 25.0 | number: '3.4-5' }}

<pre>
  <code [highlight]="decimalsCode2" language="html"></code>
</pre>
