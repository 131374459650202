import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { AlertDescriptionComponent } from './alert-description/alert-description.component';
import { AlertItemComponent } from './alert-item/alert-item.component';
import { AlertTitleComponent } from './alert-title/alert-title.component';
import { AlertComponent } from './alert/alert.component';

@NgModule({
  imports: [CommonModule, IconsModule, ButtonModule],
  declarations: [AlertComponent, AlertItemComponent, AlertTitleComponent, AlertDescriptionComponent],
  exports: [AlertComponent, AlertItemComponent, AlertTitleComponent, AlertDescriptionComponent],
})
export class AlertModule {}
