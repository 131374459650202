import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'nxh-toast-demo',
  templateUrl: './toast-demo.component.html',
  styleUrls: ['./toast-demo.component.scss'],
})
export class ToastDemoComponent {
  codeTemplate = `
<button class="btn btn-danger" (click)="showToastError()">Show toast ERROR</button>
<button class="btn btn-warning" (click)="showToastWarning()">Show toast WARNING</button>
<button class="btn btn-info" (click)="showToastInfo()">Show toast INFO</button>
<button class="btn btn-success" (click)="showToastSuccess()">Show toast SUCCESS with progressbar and close button</button>
  `;

  codeTS = `
    constructor(private toastr: ToastrService, private i18n: I18NextPipe) { }
    
    showToastSuccess() {
      this.toastr.success(this.i18n.transform('hello-world'), this.i18n.transform('toastr-fun'), {
        closeButton: true,
        timeOut: 5000,
        progressBar: true,
        progressAnimation: 'increasing',
        positionClass: 'toast-top-right'
      });
    }
  `;

  codeToastrHelper = `
    constructor(private toastrHelper: ToastrHelperService) { }
    
    showToastSuccess() {
      this.toastrHelper.success('hello-world', 'toastr-fun');
    }
  `;

  constructor(private toastr: ToastrService) {}

  showToastSuccess() {
    this.toastr.success(`we dit it reddit <a href="https://google.com">wow </a>`, 'Action is optional.', {
      closeButton: true,
      timeOut: 5000,
      enableHtml: true,
      progressBar: true,
      tapToDismiss: false,
      progressAnimation: 'increasing',
      positionClass: 'toast-top-right',
    });
  }

  showToastWarning() {
    this.toastr.warning('Hello world!', 'Toastr fun!');
  }

  showToastInfo() {
    this.toastr.info('Hello world!', 'Toastr fun!');
  }

  showToastError() {
    this.toastr.error('Hello world!', 'Toastr fun!');
  }
}
