import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceName } from '@nexuzhealth/shared-domain';
import { FeatureFlag } from '../state/feature-flag.model';

@Injectable({ providedIn: 'root' })
export class FeatureFlagApiService {
  constructor(private http: HttpClient) {}

  load(flagName: ResourceName): Observable<FeatureFlag> {
    const url = 'api/dynamic/settings/v1/evaluateFeatureFlag';
    return this.http
      .post<Omit<FeatureFlag, 'name'>>(url, { flagName })
      .pipe(map((response) => ({ ...response, name: flagName })));
  }

  loadMany(flagNames: ResourceName[]): Observable<FeatureFlag[]> {
    const url = 'api/dynamic/settings/v1/evaluateFeatureFlags';
    type Response = { nameToEnabled: { [key: string]: boolean } };
    return this.http.post<Response>(url, { flagNames }).pipe(
      map((response) => {
        return Object.entries(response.nameToEnabled).map((entry) => {
          return { name: entry[0], enabled: entry[1] };
        });
      }),
    );
  }
}
