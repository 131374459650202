import { Component } from '@angular/core';

@Component({
  selector: 'nxh-dirty-check-demo',
  templateUrl: './dirty-check-demo.component.html',
  styleUrls: ['./dirty-check-demo.component.scss'],
})
export class DirtyCheckDemoComponent {
  routeCode = `
      {
        path: 'invoice',
        component: InvoicePageComponent,
        canDeactivate: [CanDeactivateGuard]
      }
  `;

  formCode = `
      this.form.valueChanges
        .pipe(
          map(() => this.form.dirty),
          distinctUntilChanged()
        )
        .subscribe(dirty => {
          this.formStore.setFormDirty('invoicePage', dirty);
        });
  `;

  modalCode = `

    ngOnInit() {
      // ...  
      this.form.valueChanges.subscribe(() => this.formStore.setModalDirty(this.form.dirty));
    }

    submit() {        
      this.formStore.setModalDirty(false); // <-- Take care of marking the modal pristine before closing it!
      this.activeModal.close();
    }
    
    cancel() {
      this.activeModal.dismiss(); // <-- Use dismiss() to cancel. The dirty check acts on dismiss only!
    }

  `;

  sidebarCode = `

    // routing config  
        {
        path: 'create',
        component: CreateTaskComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          canDeactivateOutlet: 'sidebar',
        },

    // additionaly for older forms you also have to do this 
      this.form.valueChanges
        .pipe(
          map(() => this.form.dirty),
          distinctUntilChanged()
        )
        .subscribe(dirty => {
          this.formStore.setFormDirty('createTask', dirty, 'sidebar');
        });
  `;

  constructor() {}
}
