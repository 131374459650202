import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'nxh-breadcrumb-demo',
  templateUrl: './breadcrumb-demo.component.html',
  styleUrls: ['./breadcrumb-demo.component.scss'],
})
export class BreadcrumbDemoComponent {
  selectedTab$ = this.route.paramMap.pipe(map((params) => params.get('tab')));

  markupCode = `
    <nxh-breadcrumb></nxh-breadcrumb>
  `;

  routerCode = `
    {
      path: 'scrollspy',
      component: ScrollspyComponent
    },
    {
      path: 'breadcrumb',
      component: BreadcrumbDemoComponent,
      children: [
        {
          path: ':tab',
          component: BreadcrumbDemoComponent
        }
      ],
      data: {
        breadcrumb: 'custom_tabs:custom_breadcrumb' //<-- This is optional. Provides custom breadcrumb label.
      }
    },
  `;

  constructor(private route: ActivatedRoute) {}
}
