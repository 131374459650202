import { ResourceName } from '@nexuzhealth/shared-domain';
import { COMMON_LANGUAGES } from './common-languages.enum';

const preferredLanguageToResourceNameMapping = {
  nl: COMMON_LANGUAGES.dutch,
  en: COMMON_LANGUAGES.english,
  fr: COMMON_LANGUAGES.french,
  de: COMMON_LANGUAGES.german,
};

export function preferredLanguageToResourceName(preferredLanguage: string) {
  return languageIdToResourceName(preferredLanguageToResourceNameMapping[preferredLanguage]);
}

export function languageIdToResourceName(languageId: string): ResourceName {
  return 'languages/' + languageId;
}

export function languageResourceNameToId(languageName: ResourceName): string {
  const prefix = 'languages/';
  if (languageName?.startsWith(prefix)) {
    return languageName.slice(prefix.length);
  }
  return languageName;
}
