import { Component, HostListener, Input, OnInit, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FormHelper } from '../../../shared/form-helper.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nh-cancel-button',
  templateUrl: './cancel-form-button.component.html',
  styleUrls: ['./cancel-form-button.component.scss'],
})
export class CancelFormButtonComponent implements OnInit {
  @Input() disabled = false;
  @Input() label = 'cancel';
  submitting$!: Observable<boolean>;

  constructor(
    @Optional() private formHelper: FormHelper, // @Optional() private connectFormHelperDirective: ConnectFormHelperDirective
  ) {}

  get helper() {
    return this.formHelper; // || this.connectFormHelperDirective?.formHelperDirective?.helper;
  }

  @HostListener('mousedown', ['$event'])
  preventLayoutShift(event: MouseEvent) {
    if (this.helper) {
      // do not focus the button to prevent unwanted onblur validation of an active input
      // a layout shift might occur which could push the button away from the cursor
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    this.submitting$ = this.helper?.submitting$ ?? of(false);
  }
}
