import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Range } from '@nexuzhealth/shared-domain';
import { MiniCalendarComponent } from '@nexuzhealth/shared-ui-toolkit/datepicker';
import { addYears } from 'date-fns';
import { endOfWeek } from 'date-fns/endOfWeek';
import { startOfWeek } from 'date-fns/startOfWeek';

@Component({
  selector: 'nxh-daterange-picker-demo',
  templateUrl: './date-picker-demo.component.html',
  styleUrls: ['./date-picker-demo.component.scss'],
})
export class DatePickerDemoComponent {
  range: Range;
  selectedDate;
  selectedRange;
  minDate = new Date();
  rangeControl = new UntypedFormControl();

  bullets = [
    {
      date: new Date(),
      tooltip: 'hejomama',
      status: 'active',
    },
    {
      date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay() + 8),
      tooltip: 'hejomama',
      status: 'warning',
    },
  ];

  calendarCode = `
    <nxh-mini-calendar
      [bullets]="bullets"
      [mode]="'single'"
      [range]="range"
      [noHighlightOnFocused]="true"
      (dateSelection)="onSelectDate($event)"
    ></nxh-mini-calendar>

    range: Range;

    bullets = [
      {
        date: new Date(),
        tooltip: 'hejomama',
        status: 'active',
      },
      {
        date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay() + 8),
        tooltip: 'hejomama',
        status: 'warning',
      },
    ];

    onSelectDate(dateEvent: { date: Date; range?: Range }) {
      this.range = this.getRange(dateEvent.date);
    }
  `;

  ngxDatePicker = `
    <nxh-date-picker
      formControlName="birthdate"
      [label]="'Age'"
      [maxDate]="maxDate"
      [required]="true"
      [errorMap]="{ 'invalid-date': '_informed-consent._errors' }"
    ></nxh-date-picker>

    form = new FormGroup({
      birthdate: new FormControl(addYears(new Date(), -10), [Validators.required])
    });
  `;

  multipleFieldsCode = `

    <form [formGroup]="appointmentsForm" nxhFocus>
      <div class="form-group">
        <label>appointment date</label>
        <div
          formArrayName="appointments"
          class="appointment"
          *ngFor="let appointment of appointments.controls; let i = index; let last = last"
        >
          <nxh-date-picker [formControlName]="i"></nxh-date-picker>
          <nxh-add-remove-buttons   // <-- ze buttons
            [showRemove]="hover.isActive()"
            [showAdd]="last"
            (add)="addAppointment()"
            (remove)="removeAppointment(i)"
          ></nxh-add-remove-buttons>
        </div>
      </div>
    </form>

    // below is standard FormArray stuff

    appointmentsForm = new FormGroup({
      appointments: new FormArray([this.createAppointmentFormControl()])
    });

    get appointments(): FormArray {
      return this.appointmentsForm.get('appointments') as FormArray;
    }

    removeAppointment(index) {
      this.appointments.removeAt(index);
      if (this.appointments.length === 0) {
        this.addAppointment();
      }
    }

    addAppointment() {
      this.appointments.push(this.createAppointmentFormControl());
    }

    private createAppointmentFormControl() {
      return new FormControl();
    }
  `;

  partialDateInputCode = `
    <nxh-date-input formControlName="partialDate"></nxh-date-input>
  `;

  faTimes = faTimes;
  faPlus = faPlus;
  mode: 'year' | 'day' | 'month' = 'day';
  dateFormat = 'DD/MM/YYYY';
  form = new UntypedFormGroup({
    birthdate: new UntypedFormControl(addYears(new Date(), -10), [Validators.required]),
  });

  partialForm = new UntypedFormGroup({
    partialDate: new UntypedFormControl(),
  });
  maxDate = new Date();

  fakeDates = [];
  filteredItems = [];
  appointmentsForm = new UntypedFormGroup({
    appointments: new UntypedFormArray([this.createAppointmentFormControl()]),
  });

  get appointments(): UntypedFormArray {
    return this.appointmentsForm.get('appointments') as UntypedFormArray;
  }

  randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }

  removeAppointment(index) {
    this.appointments.removeAt(index);
    if (this.appointments.length === 0) {
      this.addAppointment();
    }
  }

  addAppointment() {
    this.appointments.push(this.createAppointmentFormControl());
  }

  setMode(mode) {
    this.form.get('birthdate').setValue('');
    this.mode = mode;
    switch (mode) {
      case 'day':
        this.dateFormat = 'DD/MM/YYYY';
        break;
      case 'month':
        this.dateFormat = 'MM/YYYY';
        break;
      case 'year':
        this.dateFormat = 'YYYY';
        break;
      default:
        this.dateFormat = 'DD/MM/YYYY';
    }
  }

  onSelectDate(date: Date) {
    this.range = this.getRange(date);
  }

  select(c: MiniCalendarComponent) {
    c.selectDate(new Date(new Date().getFullYear(), new Date().getMonth() + 5, new Date().getDay() + 8));
  }

  private createAppointmentFormControl() {
    return new UntypedFormControl();
  }

  private getRange(date: Date) {
    return { fromDate: startOfWeek(date, { weekStartsOn: 1 }), toDate: endOfWeek(date, { weekStartsOn: 1 }) };
  }
}
