import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { Focusable } from '@nexuzhealth/shared-ui-toolkit/focus';
import { isValid } from 'date-fns';
import { addDataTestAttributes, DataTestDirective } from '@nexuzhealth/shared-tech-feature-e2e';
import { PartialDate } from './partial-date.model';

interface FieldDescription {
  type: 'day' | 'month' | 'year';
  placeholder: string;
  maxLength: number;
  maxValue: number;
}

@Component({
  selector: 'nxh-partial-date-input',
  templateUrl: './partial-date-input.component.html',
  styleUrls: ['./partial-date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PartialDateInputComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PartialDateInputComponent),
      multi: true,
    },
  ],
})
export class PartialDateInputComponent implements OnInit, ControlValueAccessor, Validator, Focusable, AfterViewInit {
  @Input() separator = '/';
  @Input() compact = true;
  @ViewChild('first', { static: true }) first!: ElementRef<HTMLInputElement>;
  @ViewChild('second', { static: true }) second!: ElementRef<HTMLInputElement>;
  @ViewChild('third', { static: true }) third!: ElementRef<HTMLInputElement>;

  form = new FormGroup({
    day: new FormControl(),
    month: new FormControl(),
    year: new FormControl(),
  });

  onTouched!: () => void;
  onChange!: (partialDate: PartialDate | null) => void;

  fields: { [type: string]: FieldDescription } = {
    day: {
      type: 'day',
      placeholder: '_partial-date-input._day.placeholder',
      maxLength: 2,
      maxValue: 31,
    },
    month: {
      type: 'month',
      placeholder: '_partial-date-input._month.placeholder',
      maxLength: 2,
      maxValue: 12,
    },
    year: {
      type: 'year',
      placeholder: '_partial-date-input._year.placeholder',
      maxLength: 4,
      maxValue: 9999,
    },
  };

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() private dataTestDirective: DataTestDirective,
  ) {}

  ngAfterViewInit(): void {
    addDataTestAttributes(
      this.dataTestDirective?.nxhDataTest || 'partial-date-input-component',
      { element: this.first.nativeElement, suffix: '_partialdate-1' },
      { element: this.second.nativeElement, suffix: '_partialdate-2' },
      { element: this.third.nativeElement, suffix: '_partialdate-3' },
    );
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() => {
      const partialDate = this.getPartialDateFromForm();
      this.onChange(partialDate);
    });
  }

  onKeydown(event: KeyboardEvent) {
    switch (event.key) {
      case 'Tab':
        this.onTab(event);
        break;
      case 'ArrowUp':
        this.onArrowUp(event);
        break;
      case 'ArrowDown':
        this.onArrowDown(event);
        break;
      case 'ArrowLeft':
        this.onArrowLeft(event);
        break;
      case 'ArrowRight':
        this.onArrowRight(event);
        break;
      case 'Backspace':
        this.onBackspace(event);
        break;
      case 'Delete':
        this.onDelete(event);
        break;
      default:
        // allow only numeric
        if (!new RegExp('^\\d$').test(event.key)) {
          this.preventDefault(event);
        }
    }
  }

  // added for testing purposes to be able to spy on it
  preventDefault(event: UIEvent) {
    event.preventDefault();
  }

  onInput(event: Event) {
    const target = event.target as HTMLInputElement;
    const next = target.nextElementSibling as HTMLInputElement;

    // focus next if cursor at end
    if (next && target.selectionStart === 2) {
      this.focus(next);
    }
  }

  // for testing purposes
  focus(input: HTMLInputElement) {
    input.focus();
  }

  onBlur(event: FocusEvent) {
    const target = event.target as HTMLInputElement;
    const relatedTarget = event.relatedTarget as HTMLInputElement;
    const newlyFocusedFieldIsDateField = relatedTarget && relatedTarget.parentElement === target.parentElement;
    if (!newlyFocusedFieldIsDateField) {
      this.onTouched();
    }
  }

  onFocus(event: FocusEvent) {
    const target = event.target as HTMLInputElement;
    const relatedTarget = event.relatedTarget as HTMLInputElement;
    target.setSelectionRange(0, target.value.length);
  }

  onClick(event: Event) {
    const target = event.target as HTMLInputElement;
    target.select();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
    this.cdr.markForCheck();
  }

  writeValue(partialDate: PartialDate): void {
    this.form.patchValue(
      {
        day: partialDate?.day ? this.withLeadingZero(`${partialDate?.day}`) : '',
        month: partialDate?.month ? this.withLeadingZero(`${partialDate?.month}`) : '',
        year: partialDate?.year ? `${partialDate?.year}` : '',
      },
      { emitEvent: false },
    );
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const partialDate: PartialDate = control.value;
    if (!partialDate) {
      return null;
    }

    if (!partialDate.year) {
      return { 'invalid-date': '_partial-date.year-missing' };
    }

    if (partialDate.year.length !== 4) {
      return { 'invalid-date': '_partial-date.year-incomplete' };
    }

    if (partialDate.day && !partialDate.month) {
      return { 'invalid-date': '_partial-date.month-missing' };
    }

    if (!this.isDateValid(partialDate)) {
      return { 'invalid-date': true };
    }

    return null;
  }

  setFocus() {
    this.first.nativeElement.focus();
  }

  // public for unit testing purposes
  isDateValid(partialDate: PartialDate) {
    const year = partialDate.year ? +partialDate.year : 0;
    const month = partialDate.month ? +partialDate.month - 1 : 0;
    const day = partialDate.day ? +partialDate.day : 1;

    const date = new Date(year, month, day);

    if (!isValid(date)) {
      return false;
    }

    // dates like 31/02 are automatically changed to 02/03 by JS, so here we do an additional check
    return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
  }

  private getPartialDateFromForm() {
    const partialDate: PartialDate = {
      day: this.form.value.day ? this.form.value.day : null,
      month: this.form.value.month || null,
      year: this.form.value.year || null,
    };

    const allEmptyFields = !Object.values(partialDate).some((value) => !!value);
    return allEmptyFields ? null : partialDate;
  }

  private withLeadingZero(value = '') {
    return value.length === 1 && value !== '0' ? '0' + value : value;
  }

  private onTab(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    this.prependZero(target);
  }

  private prependZero(input: HTMLInputElement) {
    const type = input.dataset['type'];
    if (type === 'day' || type === 'month') {
      if (input.value?.length === 1) {
        // but don't set '00' as value
        input.value = input.value !== '0' ? '0' + input.value : '';
        this.updateForm(type, input.value, { emitEvent: false });
      }
    }
  }

  private updateForm(type: PartialDateInputFormField, value: any, options = {}) {
    this.form.get(type)?.setValue(value, options);
  }

  private onArrowUp(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    const type = target.dataset['type'] as PartialDateInputFormField;
    const maxValue = this.fields[type].maxValue;
    let value = target.value ? +target.value : 0;

    if (value < maxValue) {
      value = value + 1;
      target.value = type === 'year' || value > 9 ? '' + value : '0' + value;
      target.setSelectionRange(target.value.length, target.value.length);
      this.updateForm(type, target.value);
    }

    event.preventDefault();
  }

  private onArrowDown(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;

    const type = target.dataset['type'] as PartialDateInputFormField;
    let value = target.value ? +target.value : 0;
    if (value > 1) {
      value = value - 1;
      target.value = type === 'year' || value > 9 ? '' + value : '0' + value;
      target.setSelectionRange(target.value.length, target.value.length);
      this.updateForm(type, target.value);
    } else if (value === 1) {
      value = value - 1;
      target.value = '';
      this.updateForm(type, target.value);
    }

    event.preventDefault();
  }

  private onArrowLeft(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const prev = target.previousElementSibling as HTMLInputElement;
    if (prev && target.selectionStart === 0) {
      this.prependZero(target);
      prev.focus();
      event.preventDefault();
    }
  }

  private onArrowRight(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const next = target.nextElementSibling as HTMLInputElement;
    if (
      next &&
      (!target.value ||
        (target.value?.length === 1 && target.selectionStart === 1) ||
        (target.value?.length === 2 && target.selectionStart === 2))
    ) {
      this.prependZero(target);
      this.focus(next);
      event.preventDefault();
    }
  }

  private onBackspace(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const prev = target.previousElementSibling as HTMLInputElement;
    if (prev && target.selectionStart === 0 && target.selectionEnd === 0) {
      this.prependZero(target);
      prev.focus();
      // remove this line to delete last char of previous
      event.preventDefault();
    }
  }

  private onDelete(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    const next = target.nextElementSibling as HTMLInputElement;
    if (
      next &&
      (!target.value ||
        (target.value?.length === 1 && target.selectionStart === 1 && target.selectionEnd === 1) ||
        (target.value?.length === 2 && target.selectionStart === 2 && target.selectionEnd === 2))
    ) {
      this.prependZero(target);
      next.focus();
      // remove this line to delete first char of next
      event.preventDefault();
    }
  }
}

type PartialDateInputFormField = keyof ReturnType<PartialDateInputComponent['form']['getRawValue']>;
