<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Hotkeys</h1>
    </div>
  </div>
</div>
<div class="toolkit__body">
  <div class="toolkit__body__content" nxhScrollspyContainer>
    <div
      class="toolkit__body__content__block"
      id="block1"
      nxhScrollspyTarget="block1"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Basic usage</h2>

      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock1" (click)="showExampleBlock1 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock1" (click)="showExampleBlock1 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>

      <div *ngIf="showExampleBlock1">
        <div class="card">
          <div class="card-body">
            <button *ngIf="color$ | async as color" class="btn" [class]="'btn-' + color" (click)="openHelpModal()">
              <fa-icon icon="question-circle"></fa-icon>
              <div>Open hotkeys overview</div>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock1">
        <pre><code language="html" [highlight]="block1Code"></code></pre>
      </div>
    </div>
  </div>

  <div class="toolkit__body__scrollspy">
    <ul>
      <li (click)="scrollTo('block1')" [class.is-active]="(selected$ | async) === 'block1'">Usage</li>
    </ul>
  </div>
</div>
