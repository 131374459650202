import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'nxh-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayViewComponent {
  @Input() set date(value: NgbDate) {
    this.date$$.next(value);
  }

  @Input() set currentMonth(value: number) {
    this.currentMonth$$.next(value);
  }

  @Input() set fromDate(value: NgbDate | null | undefined) {
    this.fromDate$$.next(value);
  }

  @Input() set toDate(value: NgbDate | null | undefined) {
    this.toDate$$.next(value);
  }

  @Input() set hoverDate(value: NgbDate | null) {
    this.hoverDate$$.next(value);
  }

  @Input() wontChangeOnNextDateSelection = false;
  @Input() noHighlightOnFocused = false;
  @Input() disabled = false;
  @Input() showToday = true;
  @Input() bullet?: { date: NgbDate; tooltip?: string; status: string };
  @Input() focused = false;

  date$$ = new BehaviorSubject<NgbDate | null>(null);
  currentMonth$$ = new BehaviorSubject<number | null>(null);
  fromDate$$ = new BehaviorSubject<NgbDate | null | undefined>(null);
  toDate$$ = new BehaviorSubject<NgbDate | null | undefined>(null);
  hoverDate$$ = new BehaviorSubject<NgbDate | null>(null);

  isOutside$ = combineLatest([this.date$$, this.currentMonth$$]).pipe(
    map(([date, currentMonth]) => date?.month !== currentMonth),
  );

  isToday$ = this.date$$.pipe(
    map((date) => {
      const now = new Date();
      return date?.equals({ year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() });
    }),
  );

  isHovered$ = combineLatest([this.date$$, this.hoverDate$$]).pipe(map(([date, hoverDate]) => date?.equals(hoverDate)));

  isRangeStart$ = combineLatest([this.date$$, this.fromDate$$]).pipe(map(([date, fromDate]) => date?.equals(fromDate)));
  isRangeEnd$ = combineLatest([this.date$$, this.toDate$$]).pipe(map(([date, toDate]) => date?.equals(toDate)));
  isBetween$ = combineLatest([this.date$$, this.fromDate$$, this.toDate$$, this.hoverDate$$]).pipe(
    map(([date, fromDate, toDate, hoverDate]) => {
      return date?.after(fromDate) && (toDate?.after(date) || (!toDate && hoverDate?.after(date)));
    }),
  );

  isBackgroundStart$ = combineLatest([this.date$$, this.fromDate$$, this.toDate$$, this.hoverDate$$]).pipe(
    map(([date, fromDate, toDate, hoverDate]) => {
      return (
        (date?.equals(fromDate) && toDate?.after(fromDate)) || (date?.equals(fromDate) && hoverDate?.after(fromDate))
      );
    }),
  );

  isBackgroundEnd$ = combineLatest([this.date$$, this.fromDate$$, this.toDate$$]).pipe(
    map(([date, fromDate, toDate]) => {
      return date?.equals(toDate) && fromDate?.before(date);
    }),
  );

  isBackgroundEndHovered$ = combineLatest([this.date$$, this.fromDate$$, this.toDate$$, this.hoverDate$$]).pipe(
    map(([date, fromDate, toDate, hoverDate]) => {
      return fromDate && !toDate && hoverDate?.equals(date) && fromDate.before(date);
    }),
  );

  showRangeBackground$ = combineLatest([
    this.isBetween$,
    this.isBackgroundStart$,
    this.isBackgroundEnd$,
    this.isBackgroundEndHovered$,
  ]).pipe(
    map(
      ([isBetween, isBackgroundStart, isBackgroundEnd, isBackgroundEndHovered]) =>
        isBetween || isBackgroundStart || isBackgroundEnd || isBackgroundEndHovered,
    ),
  );
}
