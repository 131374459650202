import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

// overloads to express that passing in null will result in null, passing in a Date will result in an NgbDate
export function jsDateToNgbDate(date: null | undefined): null;
export function jsDateToNgbDate(date: Date): NgbDate;
export function jsDateToNgbDate(date: Date | null | undefined): NgbDate | null {
  return date ? new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate()) : null;
}

export function ngbDateToJsDate(date: null | undefined): null;
export function ngbDateToJsDate<T extends NgbDate | NgbDateStruct>(date: T): Date;
export function ngbDateToJsDate<T extends NgbDate | NgbDateStruct>(date: T | null | undefined): Date | null {
  return date ? new Date(date.year, date.month - 1, date.day) : null;
}

export function getNgbDateRange(selectedDate: NgbDate, currentDateRange?: NgbDateRange | null): NgbDateRange {
  const currentFromDate = currentDateRange?.fromDate;
  const currentToDate = currentDateRange?.toDate;
  if (!currentFromDate && !currentToDate) {
    return { fromDate: selectedDate, toDate: currentToDate || null };
  } else if (currentFromDate && !currentToDate && selectedDate.after(currentFromDate)) {
    return { fromDate: currentFromDate, toDate: selectedDate };
  } else {
    return { fromDate: selectedDate, toDate: null };
  }
}

export type NgbDateRange = { fromDate: NgbDate | null; toDate: NgbDate | null };
