import { Pipe, PipeTransform } from '@angular/core';
import { AssignedId, AssignedIdTypenameEnum } from '@nexuzhealth/shared-domain';
import { findAssignedId } from '@nexuzhealth/shared-util';

/**
 * @see {findAssignedId}
 */
@Pipe({
  name: 'findAssignedIdType',
  pure: false, // Required because id's can be mutable -> change detection not run
})
export class FindAssignedIdTypePipe implements PipeTransform {
  transform(ids?: AssignedId[], type?: AssignedIdTypenameEnum): AssignedId | undefined {
    return findAssignedId(ids, type);
  }
}
