// Suppressed - deprecated component
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  Directive,
  ElementRef,
  Input,
  isDevMode,
  OnChanges,
  OnInit,
  Optional,
  Renderer2,
  Self,
  SimpleChanges,
} from '@angular/core';
import { NgControl } from '@angular/forms';

// temporary workarounds until these components have been rewritten
const noFormControlEls = 'NXH-(SELECT-|NUMBER-FIELD|EMAIL-ADDRESS|TIME-PICKER|DATE-PICKER|TOGGLE-LIST|PHONE-NUMBER)';

/**
 * @deprecated Use <nh-control>
 */
@Directive({
  selector: '[nxhLabel]',
  exportAs: 'labelDirective',
})
export class LabelDirective implements OnInit, OnChanges {
  @Input('nxhLabel') label;
  @Input() id?;
  @Input() required = false;
  @Input() containerClass: string | string[];

  private requiredSpan: HTMLSpanElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    @Self() @Optional() private control?: NgControl,
  ) {}

  ngOnInit(): void {
    const nativeElement: HTMLElement = this.el.nativeElement;

    if (!this.control && isDevMode()) {
      console.warn(
        'Label directive not on element with [FormControl] or [formControlName]. Programming Error?',
        nativeElement,
      );
    }

    const regex = new RegExp(noFormControlEls);

    if (this.control && !regex.test(nativeElement.nodeName)) {
      this.renderer.addClass(nativeElement, 'form-control');
    }

    const nextSiblings = this.getNextSiblings(nativeElement);

    const id = this.id || `label:${Math.random()}`;
    this.renderer.setAttribute(nativeElement, 'id', id);

    const formGroup = this.renderer.createElement('div');
    this.getFormGroupClassnames().forEach((className) => {
      this.renderer.addClass(formGroup, className);
    });

    const label = this.renderer.createElement('label');
    this.renderer.setAttribute(label, 'for', id);
    this.renderer.setProperty(label, 'innerText', this.label);
    this.renderer.appendChild(formGroup, label);

    const isRequired = nativeElement.hasAttribute('required');
    this.requiredSpan = this.renderer.createElement('span');
    this.renderer.appendChild(label, this.requiredSpan);
    if (isRequired || this.required) {
      this.renderer.addClass(this.requiredSpan, 'required');
    }

    const parent: HTMLElement = nativeElement.parentElement;
    this.renderer.insertBefore(parent, formGroup, nativeElement);
    this.renderer.appendChild(formGroup, nativeElement);
    nextSiblings.forEach((nextSibling) => {
      this.renderer.appendChild(formGroup, nextSibling);
    });

    this.enableDisableLabel(label);
  }

  private enableDisableLabel(label) {
    if (!this.control || !this.control.control) {
      return;
    }

    if (this.control.control.disabled) {
      this.renderer.addClass(label, 'disabled');
    }

    // eslint-disable-next-line
    const that = this;

    const originalDisable = this.control.control.disable;
    this.control.control.disable = function (opts?) {
      that.renderer.addClass(label, 'disabled');
      // eslint-disable-next-line
      originalDisable.apply(this, arguments);
    };

    const originalEnable = this.control.control.enable;
    this.control.control.enable = function (opts?) {
      that.renderer.removeClass(label, 'disabled');
      // eslint-disable-next-line
      originalEnable.apply(this, arguments);
    };
  }

  private getNextSiblings(element) {
    const siblings = [];
    while (this.renderer.nextSibling(element)) {
      element = this.renderer.nextSibling(element);
      siblings.push(element);
    }
    return siblings;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.label && !changes.label.isFirstChange()) {
      const el = this.el.nativeElement?.previousSibling;
      if (el) {
        el.innerText = changes.label.currentValue;
        const isRequired = this.el.nativeElement.hasAttribute('required');
        this.requiredSpan = this.renderer.createElement('span');
        this.renderer.appendChild(el, this.requiredSpan);
        if (isRequired || this.required) {
          this.renderer.addClass(this.requiredSpan, 'required');
        }
      }
    }
    const requiredChange = changes['required'];

    if (!requiredChange || requiredChange.isFirstChange()) {
      return;
    }

    if (requiredChange.currentValue && this.requiredSpan) {
      this.renderer.addClass(this.requiredSpan, 'required');
    } else {
      this.renderer.removeClass(this.requiredSpan, 'required');
    }
  }

  private getFormGroupClassnames(): string[] {
    if (this.containerClass && this.containerClass instanceof Array) {
      return ['form-group', ...this.containerClass];
    } else if (this.containerClass) {
      return ['form-group', this.containerClass as string];
    } else {
      return ['form-group'];
    }
  }
}
