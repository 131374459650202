<h1>Grid</h1>

<p>
  Our 12-column grid system ("reflex") can be applied using nh-row and nh-col-n classes, n being a digit from 1 - 12.
</p>

<div class="nh-row">
  <div class="nh-col-2"><div class="inner">nh-col-2</div></div>
  <div class="nh-col-2"><div class="inner">nh-col-2</div></div>
  <div class="nh-col-2"><div class="inner">nh-col-2</div></div>
  <div class="nh-col-6"><div class="inner">nh-col-6</div></div>
</div>
<div class="nh-row">
  <div class="nh-col-6"><div class="inner">nh-col-6</div></div>
  <div class="nh-col-4"><div class="inner">nh-col-4</div></div>
  <div class="nh-col-1"><div class="inner">nh-col-1</div></div>
  <div class="nh-col-1"><div class="inner">nh-col-1</div></div>
</div>

<p>
  The numbers can add up to a maximum of 12, but do not need to always be 12, anything less will just not fill the
  entire width.
</p>

<div class="nh-row">
  <div class="nh-col-4"><div class="inner">nh-col-4</div></div>
  <div class="nh-col-2"><div class="inner">nh-col-2</div></div>
</div>

<p>When using nh-col (so without the number) the column takes up as much space as possible.</p>

<div class="nh-row">
  <div class="nh-col-2"><div class="inner">nh-col-2</div></div>
  <div class="nh-col"><div class="inner">nh-col</div></div>
</div>

<p>A column with nh-col-s takes up as little space as needed.</p>

<div class="nh-row">
  <div class="nh-col-s"><div class="inner">nh-col-s</div></div>
  <div class="nh-col-4"><div class="inner">nh-col-4</div></div>
</div>

<p>Setting nh-col on all columns makes them of equal size.</p>

<div class="nh-row">
  <div class="nh-col"><div class="inner">nh-col</div></div>
  <div class="nh-col"><div class="inner">nh-col</div></div>
  <div class="nh-col"><div class="inner">nh-col</div></div>
</div>

<p>There's a gutter between each column. To remove this, additionally set the class nh-col--nopad.</p>

<div class="nh-row">
  <div class="nh-col nh-col--nopad"><div class="inner">nh-col nh-col--nopad</div></div>
  <div class="nh-col nh-col--nopad"><div class="inner">nh-col nh-col--nopad</div></div>
  <div class="nh-col nh-col--nopad"><div class="inner">nh-col nh-col--nopad</div></div>
</div>
