<div [formGroup]="formGroup" class="player-form">
  <input type="text" formControlName="firstName" [nxhCompactFormControl]="'first name'" />
  <input type="text" [required]="true" formControlName="lastName" [nxhCompactFormControl]="'last name'" />
  <div class="nh-row">
    <nxh-date-picker class="nh-col" formControlName="birthDate" [label]="'birth date'"></nxh-date-picker>
    <div class="form-group col">
      <label for="nextBirhtDay">next birth day <fa-icon [icon]="faCrown"></fa-icon></label>
      <nxh-date-picker class="" formControlName="nextBirthDay"></nxh-date-picker>
    </div>
  </div>
  <nxh-address-form [parentGroup]="formGroup"></nxh-address-form>
</div>
