import { Component } from '@angular/core';

@Component({
  selector: 'nxh-disable-submit-on-enter-demo',
  templateUrl: './disable-submit-on-enter-demo.component.html',
  styleUrls: ['./disable-submit-on-enter-demo.component.scss'],
})
export class DisableSubmitOnEnterDemoComponent {
  constructor() {}

  showAlert() {
    window.alert('Form submitted');
  }
}
