import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TabnavComponent } from '../tabnav.component';

@Component({
  selector: 'nxh-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  @Input() link!: RouterLink['routerLink'];
  @Input() skipLocationChange = false;
  @Input() queryParams?: any;

  /**
   * @deprecated
   */
  @Input() customClass: any;

  @ViewChild('rla', { read: RouterLinkActive, static: true }) routerLinkActive!: RouterLinkActive;

  constructor(public tabnav: TabnavComponent) {}

  get routerLinkActiveOptions(): RouterLinkActive['routerLinkActiveOptions'] {
    return { exact: false };
  }

  isActive() {
    return this.routerLinkActive.isActive;
  }
}
