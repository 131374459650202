import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { BackButtonComponent } from './back-button.component';

@NgModule({
  imports: [CommonModule, SharedTechFeatureE2eModule, NgbTooltipModule, SharedUtilI18nModule, IconsModule],
  exports: [BackButtonComponent],
  declarations: [BackButtonComponent],
})
export class BackButtonModule {}
