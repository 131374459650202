import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { ErrorMessageComponent } from './components/error-message/error-message.component';
import { AutoValidateDirective } from './directives/auto-validate.directive';
import { BypassAutoValidateDirective } from './directives/bypass-auto-validate.directive';
import { ControlErrorDirective } from './directives/control-error.directive';
import { ControlError2Directive } from './directives/control-error-2.directive';
import { DeprecatedAutoValidateDirective } from './directives/deprecated-auto-validate.directive';

@NgModule({
  imports: [CommonModule, SharedUtilI18nModule],
  exports: [
    ErrorMessageComponent,
    AutoValidateDirective,
    BypassAutoValidateDirective,
    ControlErrorDirective,
    ControlError2Directive,
    DeprecatedAutoValidateDirective,
  ],
  declarations: [
    ErrorMessageComponent,
    AutoValidateDirective,
    BypassAutoValidateDirective,
    ControlErrorDirective,
    ControlError2Directive,
    DeprecatedAutoValidateDirective,
  ],
})
export class ValidationModule {}
