import { addDays, addMinutes, addMonths, addSeconds, addWeeks, addYears } from 'date-fns';
import { ClockTime } from './common';
import { DAY } from './recurrence';

export enum UnitOfTime {
  UNIT_OF_TIME_UNDEFINED = 'UNIT_OF_TIME_UNDEFINED',
  SECONDS = 'SECOND',
  MINUTES = 'MINUTE',
  HOURS = 'HOUR',
  DAYS = 'DAY',
  WEEKS = 'WEEK',
  MONTHS = 'MONTH',
  YEARS = 'YEAR',
}

export interface Timing {
  type: TimingType;
  dateTime?: string;
  endDateTime?: string;
  eventTypeName?: string;
  timeZone?: string;
  dayPart?: string;
  time?: string;
}

export enum TimingType {
  UNSPECIFIED = 'UNSPECIFIED',
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  DATE = 'DATE',
  RANGE = 'RANGE',
  EVENT_BASED = 'EVENT_BASED',
  DAY_PART = 'DAY_PART',
}

export interface FhirTiming {
  event?: string[];
  repeat?: FhirRepeat;
}

export interface FhirRepeat {
  bounds?: FhirBounds;
  count?: number;
  countMax?: number;
  duration?: number;
  durationMax?: number;
  durationUnit?: UnitOfTime;
  frequency?: number;
  frequencyMax?: number;
  period?: number;
  periodMax?: number;
  periodUnit?: UnitOfTime;
  dayOfWeek?: FhirDayOfWeek[];
  timeOfDay?: ClockTime[];
  when?: string[]; // http://hl7.org/fhir/valueset-event-timing.html
  offset?: number;
}

export interface FhirBounds {
  duration?: number;
  durationUnit?: UnitOfTime;
  startTime?: string;
  endTime?: string;
}

export enum FhirDayOfWeek {
  FHIR_DAY_OF_WEEK_UNDEFINED = 'FHIR_DAY_OF_WEEK_UNDEFINED',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export function convertDayToFhirDayOfWeek(day: DAY): FhirDayOfWeek {
  switch (day) {
    case DAY.MON:
      return FhirDayOfWeek.MON;
    case DAY.TUE:
      return FhirDayOfWeek.TUE;
    case DAY.WED:
      return FhirDayOfWeek.WED;
    case DAY.THU:
      return FhirDayOfWeek.THU;
    case DAY.FRI:
      return FhirDayOfWeek.FRI;
    case DAY.SAT:
      return FhirDayOfWeek.SAT;
    case DAY.SUN:
      return FhirDayOfWeek.SUN;
  }
  return FhirDayOfWeek.FHIR_DAY_OF_WEEK_UNDEFINED;
}

export function convertFhirDayOfWeekToDay(day: FhirDayOfWeek): DAY | undefined {
  switch (day) {
    case FhirDayOfWeek.MON:
      return DAY.MON;
    case FhirDayOfWeek.TUE:
      return DAY.TUE;
    case FhirDayOfWeek.WED:
      return DAY.WED;
    case FhirDayOfWeek.THU:
      return DAY.THU;
    case FhirDayOfWeek.FRI:
      return DAY.FRI;
    case FhirDayOfWeek.SAT:
      return DAY.SAT;
    case FhirDayOfWeek.SUN:
      return DAY.SUN;
    default:
      return undefined;
  }
}

export function addDuration(refDate: Date, duration: number, durationUnit: UnitOfTime) {
  if (!duration || !durationUnit) {
    return refDate;
  }
  switch (durationUnit) {
    case UnitOfTime.SECONDS:
      return addSeconds(refDate, duration);
    case UnitOfTime.MINUTES:
      return addMinutes(refDate, duration);
    case UnitOfTime.DAYS:
      return addDays(refDate, duration);
    case UnitOfTime.WEEKS:
      return addWeeks(refDate, duration);
    case UnitOfTime.MONTHS:
      return addMonths(refDate, duration);
    case UnitOfTime.YEARS:
      return addYears(refDate, duration);
  }
  return refDate;
}

export function convertUnitOfTime(from: number, fromUnit: UnitOfTime, toUnit: UnitOfTime): number | undefined {
  // Table to convert each unit to seconds.
  const conversionTable: Partial<Record<UnitOfTime, number>> = {
    [UnitOfTime.SECONDS]: 1,
    [UnitOfTime.MINUTES]: 60,
    [UnitOfTime.HOURS]: 60 * 60,
    [UnitOfTime.DAYS]: 60 * 60 * 24,
    [UnitOfTime.WEEKS]: 60 * 60 * 24 * 7,
  };

  const convertedFromUnit = conversionTable[fromUnit];
  const convertedToUnit = conversionTable[toUnit];

  if (convertedFromUnit && convertedToUnit) {
    return (convertedFromUnit * from) / convertedToUnit;
  }

  return undefined;
}
