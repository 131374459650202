import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { IconLoaderModule } from '@nexuzhealth/shared-ui-toolkit/icon-loader';
import { TimeoutStateModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/timeout-state';
import { EmptyStateModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/empty-state';
import { TableLoaderComponent } from './table-loader.component';

@NgModule({
  imports: [CommonModule, SharedUtilI18nModule, IconLoaderModule, TimeoutStateModule, EmptyStateModule],
  exports: [TableLoaderComponent],
  declarations: [TableLoaderComponent],
})
export class TableLoaderModule {}
