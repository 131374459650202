import { ElementRef, Injectable, TemplateRef } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BehaviorSubject } from 'rxjs';
import {
  PopoverConfig,
  PopoverContent,
  PopoverPosition,
  PopoverRef,
  PopoverService,
} from '@nexuzhealth/shared-ui-toolkit/popover';
import { markRowAsActive } from '@nexuzhealth/shared-ui-toolkit/table';
import { ButtonStatus } from '@nexuzhealth/shared-ui-toolkit/button';
import { DetailBubbleComponent } from './detail-bubble/detail-bubble.component';

@Injectable({
  providedIn: 'any',
})
export class DetailBubbleService {
  constructor(private popper: PopoverService) {}

  show(
    content: PopoverContent,
    origin: ElementRef<any> | HTMLElement,
    bubbleConfig: BubbleConfig,
    popoverConfig?: PopoverConfig,
  ): BubbleRef {
    // typically the DetailBubble is used on a table row, if so we highlight that row
    const removeActiveRow =
      bubbleConfig.highlightRow === undefined || bubbleConfig.highlightRow ? markRowAsActive(origin) : undefined;

    const config: PopoverConfig = {
      container: DetailBubbleComponent,
      content,
      origin,
      hasBackdrop: popoverConfig?.hasBackdrop ?? true,
      backdropClass: 'transparant',
      data: { ...bubbleConfig, origin } as BubbleData,
      positions: popoverConfig?.positions ?? ['top', 'bottom', 'left', 'right'],
    };

    const ref = this.popper.open(config);

    if (removeActiveRow) {
      ref.result$.subscribe({
        complete: () => {
          removeActiveRow();
        },
      });
    }

    return ref;
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BubbleRef extends PopoverRef {}

export interface BubbleConfig {
  title: string | TemplateRef<any>;
  subTitleTemplate?: TemplateRef<any>;
  actions?: BubbleAction[];
  size?: 'sm' | 'md' | 'lg' | 'xxl';
  containerClass?: string | string[];
  data?: any;
  icon?: IconProp;
  confirmListener?: () => void;
  hasBackdrop?: boolean;
  positions?: PopoverPosition[];
  highlightRow?: boolean;
}

export interface BubbleData extends BubbleConfig {
  origin: HTMLElement;
}

export interface BaseBubbleAction {
  icon: IconProp;
  status?: ButtonStatus;
}

export interface BubbleButton extends BaseBubbleAction {
  actionName: string;
  confirm?: boolean;
  confirmText?: string;
  confirmType?: 'danger' | 'warning' | 'primary' | 'success';
  confirmIcon?: IconProp;
  toolTip?: string;
  disabled?: BehaviorSubject<boolean>; //Try to not use this, as this is not the ideal solution. refactoring will happen in: https://jira.uz.kuleuven.ac.be/browse/FEG-824
  callback?: any;
}

export interface BubbleLink extends BaseBubbleAction {
  routerLink: any[] | string;
}

export type BubbleAction = BubbleButton | BubbleLink;
