import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormStore } from '@nexuzhealth/shared-ui-toolkit/dirty-check/data-access';
import { FormHelperService } from '@nexuzhealth/shared-ui-toolkit/validation';
import { ToastrService } from 'ngx-toastr';
import { throwError, timer } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'nxh-nested-forms-demo',
  templateUrl: './nested-forms-demo.component.html',
  styleUrls: ['./nested-forms-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormHelperService],
})
export class NestedFormsDemoComponent implements OnInit {
  selectedPlayer = 0;
  form: UntypedFormGroup;
  testError = false;

  constructor(
    private router: Router,
    private formStore: FormStore,
    private toastr: ToastrService,
    private formHelper: FormHelperService,
  ) {}

  get professionalFormGroup() {
    return this.form.get('professional') as UntypedFormGroup;
  }

  get amateurFormGroup() {
    return this.form.get('amateur') as UntypedFormGroup;
  }

  get players() {
    const fa = this.form.get('players') as UntypedFormArray;
    return fa.controls;
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      teamName: new UntypedFormControl(null, Validators.required),
      players: new UntypedFormArray([this.createPlayerFormControl()]),
      teamType: new UntypedFormControl('professional', Validators.required),
    });

    this.setTeamTypeControls('professional');
    this.form.get('teamType').valueChanges.subscribe((teamType) => this.setTeamTypeControls(teamType));

    this.form.valueChanges
      .pipe(
        map(() => this.form.dirty),
        distinctUntilChanged(),
      )
      .subscribe((dirty) => {
        this.formStore.setIsDirty('nestedForm', dirty);
      });
  }

  setTeamTypeControls(teamType) {
    if (teamType === 'professional') {
      this.form.addControl('professional', new UntypedFormGroup({}));
      this.form.removeControl('amateur');
    } else {
      this.form.addControl('amateur', new UntypedFormGroup({}));
      this.form.removeControl('professional');
    }
  }

  addPlayer() {
    const fa = this.form.get('players') as UntypedFormArray;
    fa.push(this.createPlayerFormControl());
    this.selectedPlayer = fa.controls.length - 1;
  }

  removePlayer(index) {
    const fa = this.form.get('players') as UntypedFormArray;
    fa.removeAt(index);
    this.selectedPlayer = 0;
  }

  submit() {
    const request = () => {
      console.log('this.form.value', this.form.value);
      return !this.testError
        ? timer(1000).pipe(take(1))
        : timer(1000).pipe(
            take(1),
            switchMap(() => throwError('error')),
          );
    };

    this.formHelper.submit(this.form, request).subscribe({
      next: () => {
        this.formStore.setIsDirty('nestedForm', false);
        this.router.navigate(['takemeoutofhere']);
      },
      error: (err) => {
        this.toastr.error('ERROR');
      },
    });
  }

  testEdit() {
    const value = {
      teamName: 'Anderlecht',
      players: [
        {
          firstName: 'Joske',
          lastName: 'De Jos',
          birthDate: '2010-01-17',
          nextBirthDay: '2020-01-17',
          address: { street: null, nbr: null, city: null, postalCode: null },
        },
        {
          firstName: 'Balleke',
          lastName: 'Bolleke',
          birthDate: null,
          nextBirthDay: null,
          address: { street: null, nbr: null, city: null, postalCode: null },
        },
      ],
      teamType: 'amateur',
      amateur: { category: 'work' },
    };

    const fa = this.form.get('players') as UntypedFormArray;
    fa.clear();
    for (const player of value.players) {
      fa.push(this.createPlayerFormControl());
    }

    this.form.patchValue(value);
  }

  cancel() {
    this.router.navigate(['takemeoutofhere']);
  }

  private createPlayerFormControl() {
    return new UntypedFormGroup({
      firstName: new UntypedFormControl(null),
      lastName: new UntypedFormControl(null, Validators.required),
      birthDate: new UntypedFormControl(),
      nextBirthDay: new UntypedFormControl(),
    });
  }
}
