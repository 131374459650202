import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function greaterThanValidator(greaterThan: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return value && greaterThan >= +value ? { greaterThan: true } : null;
  };
}

export function numberRangeValidator(startNumberFieldName: string, endNumberFieldName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const startNumber = control.get(startNumberFieldName)?.value;
    const endNumber = control.get(endNumberFieldName)?.value;

    return startNumber && endNumber && endNumber < startNumber ? { endNumberIsBeforeStartNumber: true } : null;
  };
}

/**
 * Returns an invalid-number error if the number is not a whole number.
 * Because it expects control.value to be a number, inputs with values like `10.0` or `1e1`
 * will not fail.
 * Negative numbers will also pass when they are whole.
 */
export const wholeNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value = control.value;
  if (value === undefined || value === null) {
    return null;
  }
  if (!Number.isInteger(value)) {
    return { 'invalid-number': true };
  }
  return null;
};
