import { NgModule } from '@angular/core';

import { LabelDirective } from './label.directive';

/**
 * @deprecated Use <nh-control>
 */
@NgModule({
  exports: [LabelDirective],
  declarations: [LabelDirective],
})
export class LabelDirectiveModule {}
