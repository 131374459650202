import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'nxh-amateur-form',
  templateUrl: './amateur-form.component.html',
  styleUrls: ['./amateur-form.component.scss'],
})
export class AmateurFormComponent implements OnInit {
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() category: string;

  constructor() {}

  get categoryControl() {
    return this.parentFormGroup.get('category');
  }

  ngOnInit() {
    this.parentFormGroup.addControl('category', new UntypedFormControl(this.category, Validators.required));
  }
}
