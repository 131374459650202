import { Component } from '@angular/core';

@Component({
  selector: 'nxh-pipes-demo',
  templateUrl: './pipes-demo.component.html',
  styleUrls: ['./pipes-demo.component.scss'],
})
export class PipesDemoComponent {
  now = new Date(2022, 0, 29, 14, 12, 25);

  currencyCode = `
    {{ 25 | currency: 'EUR' }}
  `;

  decimalsCode1 = `
    {{ 25.00 | number }}
  `;

  decimalsCode2 = `
    {{ 25 | number:'3.4-5' }}
  `;

  getFormat(format?) {
    return format ? `{{ now | nxhDate: '${format}' }}` : '{{ now | nxhDate }}';
  }
}
