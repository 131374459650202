<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Loading states</h1>
      <p>possible loading states when waiting for data</p>
    </div>
  </div>
</div>

<nxh-page-loader #pageLoader></nxh-page-loader>

<div [hidden]="pageLoader.active$ | async">
  <div class="toolkit__body">
    <div class="toolkit__body__content" nxhScrollspyContainer>
      <div
        class="toolkit__body__content__block"
        id="tableLoader"
        nxhScrollspyTarget="tableLoader"
        (scrollInViewPort)="scrolledInView($event)"
      >
        <h2 class="page-title">Table loader</h2>
        <button nxh-button [outline]="true" (click)="loadBooks()">loading</button>
        <button
          nxh-button
          buttonType="subtle-button"
          buttonStatus="neutral"
          (click)="tableLoader.setLoaded(0); dataSource.next([])"
        >
          empty
        </button>
        <button class="btn btn-danger" (click)="setTableLoaderError('shit happened')">error</button>
        <button nxh-button [outline]="true" (click)="smallText = !smallText">
          {{ smallText ? 'disable' : 'enable' }} small text
        </button>
        <div class="card">
          <div class="card-body">
            <div class="card-body__container">
              <table
                cdk-table
                nxhSortTable
                [sortModel]="sortModel"
                [dataSource]="dataSource"
                [trackBy]="trackByTitle"
                class="table"
              >
                <ng-container cdkColumnDef="title">
                  <th cdk-header-cell *cdkHeaderCellDef>
                    <nxh-sort-header>title</nxh-sort-header>
                  </th>
                  <td cdk-cell *cdkCellDef="let book">{{ book.title }}</td>
                </ng-container>

                <ng-container cdkColumnDef="author">
                  <th cdk-header-cell *cdkHeaderCellDef>author</th>
                  <td cdk-cell *cdkCellDef="let book">{{ book.author }}</td>
                </ng-container>

                <thead>
                  <tr cdk-header-row *cdkHeaderRowDef="['title', 'author']"></tr>
                </thead>
                <tbody>
                  <tr cdk-row *cdkRowDef="let book; columns: ['title', 'author']"></tr>
                </tbody>
              </table>
              <nxh-table-loader #tableLoader [smallText]="smallText"></nxh-table-loader>
            </div>
          </div>
        </div>

        <p>
          Use the <code>TableLoaderComponent</code> to manage the <i>table loading states</i>. you can use the
          <b>loadingMessage</b> property to add a custom loading message
        </p>

        <p><b>NEW</b>: you can now use the input smallText (boolean) to make the error + loading state smaller</p>

        <pre><code language="typescript" [highlight]="tableLoaderCode"></code></pre>

        <nxh-alert
          type="info"
          alertTitle="Check the ListDocumentsComponent for an elaborate example with paging!"
        ></nxh-alert>
      </div>

      <div
        class="toolkit__body__content__block"
        id="pageLoader"
        nxhScrollspyTarget="pageLoader"
        (scrollInViewPort)="scrolledInView($event)"
      >
        <h2 class="page-title">PageLoader</h2>
        <p>Use the <code>PageLoaderComponent</code> to manage the <i>page loading states</i>.</p>

        <h2 class="sub-title">Inputs api</h2>
        <pre><code language="html" [highlight]="pageLoaderInputApiCode"></code> </pre>

        <p>
          Loading has the most importance. If loading is true, error and data are simply ignored. When data is an empty
          array or undefined, we show an empty state. Alternatively, you can also pass the number of loaded items, via
          the [loaded] param.
        </p>

        <h2 class="sub-title">Manual api</h2>

        <pre><code language="typescript" [highlight]="pageLoaderManualCode"></code></pre>

        <p>
          When using the manual api and <code>ChangeDetection.OnPush</code> on the host component (or on one of their
          parents), you <b>might</b>b> have to manually run <code>cdr.detectChanges()</code> after calling a method on
          PageLoader.
        </p>

        <p>
          Note that before you can call <code>startLoading()</code> you could check whether PageLoaderComponent's
          <code>canLoading()</code> returns true. This is true if data hasn't been loaded yet, or the previous load
          returned nothing or an error. This can be useful when you are reloading the data after creating a new item.
        </p>

        <p>
          Another interesting use case is when the user removes the last entity in the list. In case the list was
          filtered, you should show the table's empty state, otherwise you should show the page's empty state. On way to
          do this is to check wheter the filtered is enabled before calling the PageLoader's <code>setLoaded</code>.
          Here's an example:
        </p>
        <pre><code language="typescript" [highlight]="filterEnabledCode"></code></pre>

        <h2 class="sub-title">Custom templates</h2>

        <p>It's possible to pass custom templates to the PageLoader (or to the TableLoader). Here's an example:</p>

        <pre><code language="html" [highlight]="withCustomTemplate"></code></pre>
      </div>

      <div
        class="toolkit__body__content__block"
        id="tinyLoader"
        nxhScrollspyTarget="tinyLoader"
        (scrollInViewPort)="scrolledInView($event)"
      >
        <h2 class="page-title">set the tiny loader state</h2>
        <p>
          a small version of a full page loader, you can use the <b>loadingMessage</b> property to add a custom loading
          message
        </p>
        <button nxh-button [outline]="true" (click)="tinyLoader.startLoading()">loading</button>
        <button nxh-button buttonType="subtle-button" buttonStatus="neutral" (click)="tinyLoader.setLoaded(0)">
          empty
        </button>
        <button class="btn btn-danger" (click)="setTinyLoaderError('shit happened')">error</button>
        <button class="btn btn-warning" (click)="tinyLoader.setState(TinyLoaderStatus.warning)">warning</button>
        <button class="btn btn-success" (click)="tinyLoader.setState(TinyLoaderStatus.success)">success</button>
        <pre><code
          language="typescript"
          highlight="@ViewChild('tinyLoader', { static: true, read: TinyLoaderComponent }) tinyLoader: TinyLoaderComponent;"></code></pre>
        <pre><code language="typescript" [highlight]="tinyCode"></code></pre>
        <nxh-tiny-loader #tinyLoader [errorStateTemplate]="errorState"></nxh-tiny-loader>

        <ng-template #errorState>
          this is <a href="https://www.google.com">google</a>, you can use this to search for this error
        </ng-template>
      </div>
    </div>

    <div class="toolkit__body__scrollspy">
      <ul>
        <li (click)="scrollTo('tableLoader')" [class.is-active]="(selected$ | async) === 'tableLoader'">
          Table Loader
        </li>
        <li (click)="scrollTo('pageLoader')" [class.is-active]="(selected$ | async) === 'pageLoader'">Page Loader</li>
        <li (click)="scrollTo('tinyLoader')" [class.is-active]="(selected$ | async) === 'tinyLoader'">Tiny Loader</li>
      </ul>
    </div>
  </div>
</div>
