import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ScrollspyService } from '@nexuzhealth/shared-ui-toolkit/scrollspy';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'nxh-task-check-demo',
  templateUrl: './task-check-demo.component.html',
  styleUrls: ['./task-check-demo.component.scss'],
})
export class TaskCheckDemoComponent {
  showExampleBlock1 = true;
  block1Code = `
//html
<form [formGroup]="form">
  <div formArrayName="items" *ngFor="let item of items.controls; let i = index" class="nh-row">
    <p class="nh-col-10">{{ tasks[i] }}</p>
    <nxh-task-check
      [id]="i"
      [value]="item"
      formControlName="{{ i }}"
      tooltip="this is a tooltip"
      class="nh-col-2"
    ></nxh-task-check>
  </div>
</form>


//ts
tasks = ["get groceries", "clean house","overthrow the government"];
selected$ = new BehaviorSubject('block1');
form = new FormGroup({
  items: new FormArray([
    new FormControl(true),
    new FormControl(false),
    new FormControl(true)
  ])
});
  `;

  tasks = ['get groceries', 'clean house', 'overthrow the government'];
  selected$ = new BehaviorSubject('block1');
  form = new UntypedFormGroup({
    items: new UntypedFormArray([
      new UntypedFormControl(true),
      new UntypedFormControl(false),
      new UntypedFormControl(true),
    ]),
  });

  get items() {
    return this.form.get('items') as UntypedFormArray;
  }
  constructor(private scrollspyService: ScrollspyService) {}

  scrollTo(id) {
    this.selected$.next(id);
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id) {
    this.selected$.next(id);
  }
}
