<div class="form-group" [formGroup]="parent">
  <label class="required" [class.disabled]="parent.disabled" *ngIf="label">{{ label }}</label>
  <div
    formArrayName="timings"
    class="period__row"
    *ngFor="let period of periods.controls; let i = index; let last = last"
  >
    <nxh-time-period [parent]="$any(period)" *ngIf="showEndDate()" />
    <nxh-time-clock [parent]="$any(period)" *ngIf="!showEndDate()" />
    <nxh-add-remove-buttons
      [showAdd]="last"
      (add)="addPeriod()"
      (remove)="removePeriod(i)"
      [addIconToolTip]="addIconTooltip"
      [removeIconToolTip]="removeIconTooltip"
    />
  </div>
  <val-errors controlName="timings">
    <ng-template valError="minlength" let-error="error">{{
      '_errors.minlength' | i18next: { error: error }
    }}</ng-template>
  </val-errors>
</div>
