import { ChangeDetectionStrategy, Component } from '@angular/core';
import { of } from 'rxjs';

@Component({
  selector: 'nxh-bullet-demo',
  templateUrl: './alert-message-demo.component.html',
  styleUrls: ['./alert-message-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertMessageDemoComponent {
  akitaCode = `
    <nxh-alert
      type="primary"
      alertTitle="akita"
      description=" Akita is a state management pattern, built on top of RxJS."
    ></nxh-alert>
  `;

  ngrx = `
    <nxh-alert [type]="'warning'">
      <nxh-alert-title>Ngrx</nxh-alert-title>
      <nxh-alert-description>
        <ul>
          <li>NgRx Store provides reactive state management for Angular apps inspired by Redux.</li>
          <li>
            NgRx Effects gives you a framework for isolating side effects from your components by connecting observables of
            actions to your store.
          </li>
          <li>NgRx Schematics helps you avoid writing common boilerplate and instead focus on building your application</li>
        </ul>
      </nxh-alert-description>
    </nxh-alert>
  `;

  other = `
    <nxh-alert type="success">
      <nxh-alert-item
        alertTitle="NGXS"
        description="NGXS is a state management pattern + library for Angular. It acts as a single source of truth for your application's state, providing simple rules for predictable state mutations."
      ></nxh-alert-item>
    
      <nxh-alert-item>
        <nxh-alert-title>elf</nxh-alert-title>
        <nxh-alert-description>A Reactive Store with Magical Powers</nxh-alert-description>
      </nxh-alert-item>
    
      <!--  of met ngFor -->
    
      <nxh-alert-item *ngFor="let alert of alerts$ | async">
        <nxh-alert-title>{{ alert.title }}</nxh-alert-title>
        <nxh-alert-description>{{ alert.description }}</nxh-alert-description>
      </nxh-alert-item>
    </nxh-alert>
  `;

  scroll = `
    <button class="btn btn-primary" (click)="akita.scrollTo()">scroll to alert</button>
  `;

  alerts$ = of([
    { title: 'MobX', description: 'Simple, scalable state management.' },
    { title: 'Redux', description: 'Redux is a predictable state container for JavaScript apps.' },
  ]);
}
