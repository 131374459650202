// Suppressed - component needs refactoring (HN-12077)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { MonthRecurrence, SelectionType } from '@nexuzhealth/shared-domain';
import { MonthRecurrenceFormValue } from '../recurrences-form.domain';

export function mapToMonthRecurrence(
  monthRecurrenceFormValue: MonthRecurrenceFormValue,
  useDayPartAllDay: boolean,
): MonthRecurrence {
  // for now only 1 day
  const monthDay = monthRecurrenceFormValue.month.days[0];

  let result: MonthRecurrence;

  if (monthDay._selectNthDayOrWeekday === 'nthDay') {
    result = {
      period: monthDay.nthDay?._period,
      month: {
        selectionType: SelectionType.DAYS,
        days: [{ nthDay: monthDay.nthDay?.nthDay, timings: monthDay.timings }],
      },
      time: monthRecurrenceFormValue.month.dayparts?.time,
    };
  } else if (monthDay._selectNthDayOrWeekday === 'weekday') {
    result = {
      period: monthDay.weekday?._period,
      month: {
        selectionType: SelectionType.WEEKDAY,
        days: [
          {
            weekday: {
              nth: monthDay.weekday?.nth,
              weekday: monthDay.weekday?.weekday,
            },
            timings: monthDay.timings,
          },
        ],
      },
      time: monthRecurrenceFormValue.month.dayparts?.time,
    };
  } else {
    const days = monthDay.dayparts?.days ?? [];
    result = {
      period: 1,
      month: {
        selectionType: SelectionType.SELECTED_DAYS,
        days: days.map((day) => ({ nthDay: +day })),
      },
      time: monthRecurrenceFormValue.month.dayparts?.time,
    };
  }

  if (monthRecurrenceFormValue.month.dayparts) {
    result.timings = [];
    // Is allDay recurrence or Time = no recurrence
    if (monthRecurrenceFormValue.month.dayparts.allDayOrTime) {
      if (useDayPartAllDay) {
        result.timings.push({ allDay: true });
      }
    } else {
      // Is day part
      monthRecurrenceFormValue.month.dayparts.parts?.forEach((part) => {
        result.timings.push({ dayPartName: part });
      });
    }
  }

  return result;
}
