// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { guid } from '@datorama/akita';
import { FullCalendarComponent } from '@fullcalendar/angular';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { Range } from '@nexuzhealth/shared-domain';
import { I18NextPipe } from 'angular-i18next';

import { CalendarOptions, EventClickArg, EventContentArg } from '@fullcalendar/core';
import { ResourceLabelContentArg } from '@fullcalendar/resource';
import { VerboseFormattingArg } from '@fullcalendar/core/internal';
import { DateService } from '@nexuzhealth/shared-util';
import { FULLCALENDAR_LICENSE_KEY_TOKEN } from '../fullcalendar-license-key-token';
import { getCalendarOptions, LicensedCalendarOptions } from '../calendar-utils';
import { CalendarConfig, CalendarResource, ResourceTimelineCalendarEvent } from '../calendar.model';
import { TemplateHelperService } from '../template-helper.service';

@Component({
  selector: 'nxh-calendar-resource-timeline',
  templateUrl: './calendar-resource-timeline.component.html',
  styleUrls: ['./calendar-resource-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TemplateHelperService],
})
export class CalendarResourceTimelineComponent implements OnChanges {
  @Input() range: Range;
  @Input() scrollToDate: Date;
  @Input() events: ResourceTimelineCalendarEvent[];
  @Input() showEndDate = true;
  @Input() resources: CalendarResource[];
  @Input() resourceHeader: string;
  @Input() config: CalendarConfig;
  @Input() resourceLabelContent: TemplateRef<{ $implicit: CalendarResource }>;
  @Input() customEventTemplate: TemplateRef<{ $implicit: ResourceTimelineCalendarEvent }>;
  @Output() selectEvent = new EventEmitter<EventClickArg>();
  @ViewChild(FullCalendarComponent, { static: true, read: FullCalendarComponent })
  fullCalendarComponent!: FullCalendarComponent;
  @ViewChild('resourceLabelTemplate', { static: true }) resourceLabelTemplate!: TemplateRef<{
    $implicit: CalendarResource;
  }>;
  @ViewChild('defaultResourceLabelContent', { static: true }) defaultResourceLabelContent!: TemplateRef<{
    $implicit: CalendarResource;
  }>;
  @ViewChild('eventTemplate', { static: true }) eventTemplate!: TemplateRef<{
    $implicit: ResourceTimelineCalendarEvent;
  }>;
  private am = this.i18NextPipe.transform('_calendar.am').toUpperCase();
  private pm = this.i18NextPipe.transform('_calendar.pm').toUpperCase();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(FULLCALENDAR_LICENSE_KEY_TOKEN) private fullCalendarLicenseKey: string,
    private dateService: DateService,
    private i18NextPipe: I18NextPipe,
    private templateHelperService: TemplateHelperService,
  ) {}

  get options(): CalendarOptions {
    return {
      ...this.getResourceTimelineOptions(),
      ...this.config,
      plugins: [resourceTimelinePlugin],
      resourceAreaHeaderContent: this.resourceHeader,
      resources: this.resources || [],
      initialDate: this.range.fromDate,
      scrollTime: this.initialScrollTime,
      events: this.events || [],
      eventDataTransform: this.eventDataTransform,
    };
  }

  get initialScrollTime(): string {
    const initialScrollTime =
      this.scrollToDate && this.range ? dateToScrollTime(this.scrollToDate, this.range) : '00:00';
    this.scrollToDate = null;
    return initialScrollTime;
  }

  get resourceLabelContentTemplate(): TemplateRef<{ $implicit: CalendarResource }> {
    return this.resourceLabelContent ?? this.defaultResourceLabelContent;
  }

  eventDataTransform(event): ResourceTimelineCalendarEvent {
    if (!event.id) {
      event.id = guid();
    }
    const start = new Date(event.start);
    const end = event.end ? new Date(event.end) : null;
    const eventStart = new Date(start);
    const eventEnd = new Date(end ? end : start);
    if (eventStart.getHours() < 12) {
      eventStart.setHours(0, 0, 0, 0);
      eventEnd.setHours(12, 0, 0, 0);
    } else {
      eventStart.setHours(12, 0, 0, 0);
      eventEnd.setHours(24, 0, 0, 0);
    }
    const extendedProps = event.extendedProps || {};
    return { ...event, start: eventStart, end: eventEnd, extendedProps: { ...extendedProps, start, end } };
  }

  get calendar() {
    return this.fullCalendarComponent.getApi();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const rangeChange = changes['range'];
    if (rangeChange) {
      if (!rangeChange.isFirstChange()) {
        this.calendar?.gotoDate(this.range.fromDate);
        this.calendar?.scrollToTime(dateToScrollTime(this.range.fromDate, this.range));
      }
    }

    const scrollToDateChange = changes['scrollToDate'];
    if (scrollToDateChange && this.scrollToDate && this.range) {
      this.calendar?.scrollToTime(dateToScrollTime(this.scrollToDate, this.range));
    }

    const resourcesChange = changes['resources'];
    const eventsChange = changes['events'];
    if (eventsChange || resourcesChange) {
      this.templateHelperService.destroyViews();
    }
  }

  private getResourceTimelineOptions(): LicensedCalendarOptions {
    return getCalendarOptions(this.fullCalendarLicenseKey, this.locale, {
      eventClick: (eventClickArg: EventClickArg) => this.selectEvent.next(eventClickArg),
      eventContent: (eventContentArg: EventContentArg) => ({
        domNodes: this.templateHelperService.getTemplateRootNodes(
          this.customEventTemplate ?? this.eventTemplate,
          eventContentArg.event.id,
          eventContentArg.event,
          (v1, v2) => v1.id === v2.id,
        ),
      }),
      eventMinWidth: this.showEndDate ? 80 : 40,
      height: '100%',
      initialView: 'resourceTimelineWeek',
      resourceAreaWidth: '16.75rem',
      resourceLabelContent: (resourceLabelContentArg: ResourceLabelContentArg) => ({
        domNodes: this.templateHelperService.getTemplateRootNodes(
          this.resourceLabelTemplate,
          resourceLabelContentArg.resource.id,
          resourceLabelContentArg.resource,
          (v1, v2) => v1.id === v2.id,
        ),
      }),
      resourceOrder: 'title',
      scrollTime: '00:00',
      slotDuration: '12:00',
      slotLabelFormat: [
        (verboseFormattingArg: VerboseFormattingArg) => {
          const date = new Date(
            verboseFormattingArg.date.year,
            verboseFormattingArg.date.month,
            verboseFormattingArg.date.day,
          );
          return this.dateService.formatDate(date, 'shortDate') ?? '';
        },
        (verboseFormattingArg: VerboseFormattingArg) => {
          const date = new Date(
            verboseFormattingArg.date.year,
            verboseFormattingArg.date.month,
            verboseFormattingArg.date.day,
          );
          return this.dateService.formatDate(date, 'EEEE')?.toUpperCase() ?? '';
        },
        (verboseFormattingArg: VerboseFormattingArg) => (verboseFormattingArg.date.hour < 12 ? this.am : this.pm),
      ],
      slotMinWidth: this.showEndDate ? 80 : 40,
    });
  }
}

function dateToScrollTime(date: Date, range: Range): string {
  const hoursToScroll =
    Math.floor(
      ((date.getTime() - range.fromDate.getTime()) / (range.toDate.getTime() - range.fromDate.getTime())) * 7,
    ) * 24;
  return `${hoursToScroll}:00`;
}
