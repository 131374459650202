import { isEqual } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { SortOptions } from '../sort-utils';

export class SortModel<T = void> {
  private sortOptions!: BehaviorSubject<SortOptions<T | null>>;

  constructor(sortOptions: SortOptions<T> | null = null) {
    this.sortOptions = new BehaviorSubject<SortOptions<T>>(sortOptions);
  }

  get value() {
    return this.sortOptions.getValue();
  }

  setValue(sortOptions) {
    this.sortOptions.next(sortOptions);
  }

  valueChanges() {
    return this.sortOptions.asObservable().pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)));
  }
}
