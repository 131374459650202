import { Component } from '@angular/core';

@Component({
  selector: 'nxh-grid-demo',
  templateUrl: './grid-demo.component.html',
  styleUrls: ['./grid-demo.component.scss'],
})
export class GridDemoComponent {
  constructor() {}
}
