<nxh-recurrence-period [parent]="parent" type="weekly" />

<ng-container [formGroup]="parent">
  <div class="days-optional-group" *ngIf="daysOptional">
    <nh-radio-group class="day-group" [formControl]="radioSelect">
      <div class="nh-row">
        <nh-radio value="frequency" [label]="'&zwj;'" />
        <div class="inline-radio-form">
          <div class="nh-row">
            <nxh-number-field [formControl]="frequency" [min]="1" [max]="7" [roundingPrecision]="0" />
            <div class="no-capitalization">
              {{ '_frequency.times-per-week' | i18next }}
            </div>
          </div>
          <div>
            <val-errors [control]="frequency" [label]="'frequentie' | i18next" />
          </div>
        </div>
      </div>

      <div class="nh-row">
        <nh-radio
          class="days-radio"
          value="weekday"
          [label]="'_frequency.on-the-following-days' | i18next"
          [nxhDataTest]="'radio-weekday-rec-following-days'"
        />
        <div class="nh-row row__days">
          <div class="nh-col">
            <nxh-days-selector
              [selected]="days?.value | keys"
              [disabled]="days?.disabled"
              (addDay)="onAddDay($event)"
              (removeDay)="onRemoveDay($event)"
            />
            <val-errors [control]="weekFormGroup">
              <ng-template valError="minOneDay">
                {{ '_resource-planning._recurrence._errors.min-one-day' | i18next }}
              </ng-template>
            </val-errors>
          </div>
        </div>
      </div>
    </nh-radio-group>
  </div>

  <div class="nh-row row__days" *ngIf="!daysOptional">
    <div class="nh-col">
      <nxh-days-selector
        [selected]="days?.value | keys"
        (addDay)="onAddDay($event)"
        (removeDay)="onRemoveDay($event)"
      />
      <val-errors [control]="weekFormGroup">
        <ng-template valError="minOneDay">
          {{ '_resource-planning._recurrence._errors.min-one-day' | i18next }}
        </ng-template>
      </val-errors>
    </div>
  </div>

  <div *ngIf="useTimeslots">
    <ng-container *ngIf="!useSameTimingsForAllDays">
      <ng-container *ngFor="let dayGroup of days?.controls">
        <div [formGroup]="$any(dayGroup)" class="nh-row row__day-periods">
          <div class="nh-col-2">
            {{ dayGroup.get('weekday')?.value | i18next }}
          </div>
          <div class="nh-col">
            <nxh-time-periods
              [parent]="$any(dayGroup)"
              [addIconTooltip]="'_resource-planning._recurrence.add' | i18next"
              [removeIconTooltip]="'_resource-planning._recurrence.remove' | i18next"
            />
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="nh-row" *ngIf="useSameTimingsForAllDays && days?.controls && days.controls.length > 0">
      <nxh-time-periods
        [parent]="weekFormGroup"
        [addIconTooltip]="'_resource-planning._recurrence.add' | i18next"
        [removeIconTooltip]="'_resource-planning._recurrence.remove' | i18next"
      />
    </div>
  </div>

  <div *ngIf="useDayparts">
    <nh-checkbox
      [switch]="true"
      [formControl]="sameTimingsForAllDaysFormControl"
      [label]="'_resource-planning._recurrence.same-dayparts-for-all-days' | i18next"
      [nxhDataTest]="'select-sameTimingsForAllDaysFormControl'"
      *ngIf="!useSameTimingsForAllDays"
    />

    <nxh-dayparts
      [parent]="$any(daypartsFormGroup)"
      [partOptions]="dayparts"
      [useDayPartAllDay]="useDayPartAllDay"
      [validateDayPartOrTime]="validateDayPartOrTime"
      *ngIf="weekFormGroup.get('dayparts') as daypartsFormGroup"
    />

    <ng-container *ngFor="let dayGroup of days?.controls; let i = index">
      <nxh-dayparts
        [title]="dayGroup.get('weekday')?.value"
        [parent]="$any(daypartsFormGroup)"
        [partOptions]="dayparts"
        [useDayPartAllDay]="useDayPartAllDay"
        [validateDayPartOrTime]="validateDayPartOrTime"
        [nxhDataTest]="'dayparts-' + i"
        *ngIf="!sameTimingsForAllDaysFormControl.value && dayGroup.get('dayparts') as daypartsFormGroup"
      />
    </ng-container>
  </div>
</ng-container>
