import { Injectable } from '@angular/core';
import { AgendaPlannerQuery } from '../../state/agenda-planner-query.service';
import { AgendaPlannerStore } from '../../state/agenda-planner-store.service';
import { DateInterval } from '../models/date.model';

@Injectable()
export class AgendaPlannerService {
  constructor(private agendaPlannerStore: AgendaPlannerStore) {}

  updateSelectedDateInterval(selectedDateInterval: DateInterval) {
    this.agendaPlannerStore.update({ selectedDateInterval });
  }

  setAgendaPlannerLoadingState(isLoading: boolean) {
    this.agendaPlannerStore.setLoading(isLoading);
  }

  setDirty(dirty: boolean) {
    this.agendaPlannerStore.update((state) => ({ ...state, dirty }));
  }
}

export const AGENDA_PLANNER_PROVIDERS = [AgendaPlannerService, AgendaPlannerStore, AgendaPlannerQuery];
