<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Collapse</h1>
      <p>Reveal or hide content.</p>
    </div>
  </div>
</div>
<div class="toolkit__body">
  <div class="toolkit__body__content" style="height: calc(100vh - 18.5rem)" nxhScrollspyContainer>
    <div
      class="toolkit__body__content__block"
      id="basic"
      nxhScrollspyTarget="card"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Card</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showCard" (click)="showCard = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showCard" (click)="showCard = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showCard">
        <nxh-collapse>
          <div class="card">
            <div class="card-header card-header--large">
              <div class="card-header__title">This is a basic card header!</div>
              <div class="card-header__controls">
                <nxh-collapse-chevron-button></nxh-collapse-chevron-button>
              </div>
            </div>
            <nxh-collapse-panel>
              <div class="card-body">
                <div class="card-body__container">
                  Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
                  quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit
                  erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper
                  hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor ex.
                </div>
                <div class="card-body__container">
                  Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
                  quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit
                  erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper
                  hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor ex.
                </div>
              </div>
            </nxh-collapse-panel>
          </div>
        </nxh-collapse>
      </div>
      <div *ngIf="!showCard">
        <pre><code language="html" [highlight]="cardCode"></code></pre>
      </div>
    </div>

    <div
      class="toolkit__body__content__block"
      id="basic"
      nxhScrollspyTarget="card-full"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Card header fully clickable</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showCardFull" (click)="showCardFull = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showCardFull" (click)="showCardFull = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showCardFull">
        <nxh-collapse>
          <div class="card">
            <div class="card-header card-header--large">
              <div class="card-header__title" nxhCollapseToggle>
                This is a basic card header!
                <nxh-collapse-chevron></nxh-collapse-chevron>
              </div>
            </div>
            <nxh-collapse-panel>
              <div class="card-body">
                <div class="card-body__container">
                  Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
                  quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit
                  erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper
                  hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor ex.
                </div>
              </div>
            </nxh-collapse-panel>
          </div>
        </nxh-collapse>
      </div>
      <div *ngIf="!showCardFull">
        <pre><code [highlight]="cardFullCode" language="html"></code></pre>
      </div>
    </div>

    <div
      class="toolkit__body__content__block"
      id="basic"
      nxhScrollspyTarget="basic"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Highlight block</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showHighlightBlock" (click)="showHighlightBlock = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showHighlightBlock" (click)="showHighlightBlock = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showHighlightBlock">
        <div class="card">
          <div class="card-body">
            <div class="card-body__container">
              <nxh-collapse>
                <div class="highlight-block">
                  <div class="highlight-block__title highlight-block__title--collapse" nxhCollapseToggle>
                    Show hidden content
                    <nxh-collapse-chevron></nxh-collapse-chevron>
                  </div>
                  <nxh-collapse-panel>
                    <p>
                      Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
                      quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum
                      hendrerit erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse
                      ullamcorper hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor
                      ex.
                    </p>
                  </nxh-collapse-panel>
                </div>
              </nxh-collapse>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showHighlightBlock">
        <pre><code [highlight]="highlightBlockCode" language="html"></code></pre>
      </div>
    </div>

    <div
      class="toolkit__body__content__block"
      id="default-open"
      nxhScrollspyTarget="default-open"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Default open</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showDefaultOpen" (click)="showDefaultOpen = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showDefaultOpen" (click)="showDefaultOpen = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showDefaultOpen">
        <div class="card">
          <div class="card-body">
            <div class="card-body__container">
              <nxh-collapse [defaultOpen]="true">
                <div class="highlight-block">
                  <div class="highlight-block__title highlight-block__title--collapse" nxhCollapseToggle>
                    Show hidden content
                    <nxh-collapse-chevron></nxh-collapse-chevron>
                  </div>
                  <nxh-collapse-panel>
                    <p>
                      Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
                      quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum
                      hendrerit erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse
                      ullamcorper hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor
                      ex.
                    </p>
                  </nxh-collapse-panel>
                </div>
              </nxh-collapse>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showDefaultOpen">
        <pre><code [highlight]="defaultOpenCode" language="html"></code></pre>
      </div>
    </div>

    <div
      class="toolkit__body__content__block"
      id="link-button"
      nxhScrollspyTarget="link-button"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Link button</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showLinkButton" (click)="showLinkButton = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showLinkButton" (click)="showLinkButton = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showLinkButton">
        <div class="card">
          <div class="card-body">
            <div class="card-body__container">
              <nxh-collapse>
                <nxh-collapse-link-button>Show hidden content</nxh-collapse-link-button>
                <nxh-collapse-panel>
                  Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
                  quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit
                  erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper
                  hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor ex.
                </nxh-collapse-panel>
              </nxh-collapse>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showLinkButton">
        <pre><code [highlight]="linkButtonCode" language="html"></code></pre>
      </div>
    </div>

    <div
      class="toolkit__body__content__block"
      id="chevron-button"
      nxhScrollspyTarget="chevron-button"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Widget header</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showWidgetHeader" (click)="showWidgetHeader = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showWidgetHeader" (click)="showWidgetHeader = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showWidgetHeader">
        <div class="card">
          <div class="card-body">
            <div class="card-body__container">
              <nxh-collapse>
                <div class="sidebar-widget__header" style="cursor: inherit">
                  <div class="sidebar-widget__title">
                    <fa-icon [icon]="['fas', 'bookmark']"></fa-icon>
                    <button class="btn btn-subtle-primary">
                      {{ '_health-elements._summary.title' | i18next }}
                    </button>
                  </div>
                  <div class="sidebar-widget__header__controls">
                    <nxh-collapse-chevron-button></nxh-collapse-chevron-button>
                  </div>
                </div>
                <nxh-collapse-panel>
                  Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
                  quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit
                  erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper
                  hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor ex.
                </nxh-collapse-panel>
              </nxh-collapse>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showWidgetHeader">
        <pre><code [highlight]="widgetHeaderCode" language="html"></code></pre>
      </div>
    </div>

    <div
      class="toolkit__body__content__block"
      id="custom-collapse"
      nxhScrollspyTarget="custom-collapse"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Controlled open / closed state</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button
          nxh-button
          buttonType="icon"
          [class.active]="showControlledCollapse"
          (click)="showControlledCollapse = true"
        >
          <fa-icon icon="image"></fa-icon>
        </button>
        <button
          nxh-button
          buttonType="icon"
          [class.active]="!showControlledCollapse"
          (click)="showControlledCollapse = false"
        >
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showControlledCollapse">
        <div class="card">
          <div class="card-body">
            <div class="card-body__container">
              <button class="btn btn-warning" (click)="controlledToggle()">Toggle after 1 second</button>
              <nxh-collapse [isOpen]="controlledIsOpen">
                <nxh-collapse-panel>
                  Cras tincidunt, enim non imperdiet imperdiet, magna nisl pharetra est, sed tincidunt libero turpis
                  quis felis. Morbi quis justo metus. Aliquam orci turpis, ornare in ante quis, condimentum hendrerit
                  erat. Nulla lectus augue, posuere at egestas eget, semper imperdiet ex. Suspendisse ullamcorper
                  hendrerit ipsum eu tristique. Nam a elit interdum erat finibus viverra. Sed ac dolor ex.
                </nxh-collapse-panel>
              </nxh-collapse>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showControlledCollapse">
        <pre><code [highlight]="controlledCollapseCode" language="typescript"></code></pre>
      </div>
    </div>

    <div
      class="toolkit__body__content__block"
      id="props"
      nxhScrollspyTarget="props"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h3 class="page-title">&lt;nxh-collapse/&gt;</h3>
      <table class="bg-white">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Default</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="no-capitalization">defaultOpen</td>
            <td>Show content on load</td>
            <td class="no-capitalization">false</td>
          </tr>
          <tr>
            <td class="no-capitalization">isOpen</td>
            <td>Controle open / closed state</td>
            <td class="no-capitalization">null</td>
          </tr>
          <tr>
            <td class="no-capitalization">disabled</td>
            <td>Disable open/close behavior</td>
            <td class="no-capitalization">false</td>
          </tr>
          <tr>
            <td class="no-capitalization">change</td>
            <td>Emit when collapsed state changes</td>
            <td>EventEmitter&lt;boolean&gt;</td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  </div>
</div>
