import { Injectable } from '@angular/core';
import { ResourceName } from '@nexuzhealth/shared-domain';

@Injectable({ providedIn: 'root' })
export class PersonBlockComponentDataLookup {
  public datasource!: PersonBlockComponentDataSource;

  getGender(genderName?: ResourceName) {
    return genderName ? this.datasource.getGender(genderName) : undefined;
  }

  getCountry(countryName?: ResourceName) {
    return countryName ? this.datasource.getCountry(countryName) : undefined;
  }
}

export interface PersonBlockComponentDataSource {
  getGender(genderName: ResourceName | string | undefined): string | undefined;
  getCountry(countryName: ResourceName | string | undefined): string | undefined;
}
