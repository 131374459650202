import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18NextModule } from 'angular-i18next';
import { LineChartComponent } from './line-chart/line-chart.component';
import { TimeLineChartComponent } from './time-line-chart/time-line-chart.component';

@NgModule({
  imports: [CommonModule, I18NextModule],
  declarations: [LineChartComponent, TimeLineChartComponent],
  exports: [LineChartComponent, TimeLineChartComponent],
})
export class ChartsModule {}
