<h1>Date controls</h1>

<h2>DatePicker</h2>

<h3 class="card-title">config:</h3>
<pre>
    <span class="small-title">label: string;</span>
    <span class="small-title">placeholder: string</span> (default value <span class="label-on-form">'dd/mm/YYYY'</span>) <b>optional</b>
    <span class="small-title">required: boolean</span> (default value <span class="label-on-form">false</span>) <b>optional</b>
    <span class="small-title">dateFormat: string</span> (default value <span class="label-on-form">'dd/mm/YYYY'</span>) <b>optional</b>
    <span class="small-title">errorMap: {{ '{' }} [errorName: string]: string {{ '}' }}</span> (default value <span
  class="label-on-form">{{ '{}' }}</span>) <b>optional</b>
    <span class="small-title">catchAllErrorMessage: string</span> <b>optional</b>
    <span class="small-title">disableValidation: boolean</span> (default value <span class="label-on-form">false</span>) <b>optional</b>
    <span class="small-title">minDate: Date</span> <b>optional</b>
    <span class="small-title">maxDate: Date</span> <b>optional</b>
    <span class="small-title">mode: string</span> day | month | year <b>optional</b></pre>

<p>if you want to be able to only choose month or year, change mode and dateFormat</p>

<pre>for month selection mode="<b>month</b>" dateFormat="<b>MM/YYYY</b>"</pre>
<pre>for year selection mode="<b>year</b>" dateFormat="<b>YYYY</b>"</pre>

<div>
  <p>demo will change after you picked a new date, this will always be a static choice in forms!</p>
  <button nxh-button [outline]="true" (click)="setMode('day')">full date</button>
  <button nxh-button [outline]="true" (click)="setMode('month')">year & month</button>
  <button nxh-button [outline]="true" (click)="setMode('year')">year</button>
</div>

<form [formGroup]="form" class="form-group" deprecatedAutoValidate>
  <nxh-date-picker
    formControlName="birthdate"
    [label]="'Age'"
    [maxDate]="maxDate"
    [mode]="mode"
    [placeholder]="dateFormat"
    [required]="true"
    [errorMap]="{ 'invalid-date': '_informed-consent._errors' }"
  ></nxh-date-picker>

  <button class="btn btn-primary">submit</button>
</form>

<p>
  Passing minDate or maxDate will disallow picking dates below or above the given dates. It is still possible to
  manually enter dates (or "garbage") in the input field. Therefore the component will also perform validation. Default
  error keys are:
</p>
<ul>
  <li>_errors.required</li>
  <li>_errors.min-date</li>
  <li>_errors.max-date</li>
  <li>_errors.invalid-date</li>
</ul>
<p>
  Should one want to disable this validation, set <code>disableValidation = true</code>. Should one want to overwrite
  the default error keys, one can pass a errorMap. Should one want to show the same error message for all possible
  validation errors, one can pass the <code>catchAllErrorMessage</code> parameter.
</p>

<pre><code language="html" [highlight]="ngxDatePicker"></code></pre>

<h2>Multiple dates</h2>
<p>
  If you need to specify multiple dates, we keep it simple and simply provide the possibility to add a new field. Here
  is an example:
</p>

<form class="nh-row" [formGroup]="appointmentsForm" [nxhFocus]="true">
  <div class="form-group nh-col">
    <label>appointment date</label>
    <div
      formArrayName="appointments"
      class="appointment"
      *ngFor="let appointment of appointments.controls; let i = index; let last = last"
    >
      <nxh-date-picker [formControlName]="i"></nxh-date-picker>
      <nxh-add-remove-buttons
        [showAdd]="last"
        (add)="addAppointment()"
        (remove)="removeAppointment(i)"
      ></nxh-add-remove-buttons>
    </div>
  </div>
  <div class="col">
    <div class="form-group">
      <label>just another field</label>
      <input type="text" class="form-control" />
    </div>
  </div>
</form>

<p>
  To implement this, you use the standard <a href="https://angular.io/api/forms/FormArray">FormArray</a> mechanism of
  Angular (<a href="https://alligator.io/angular/reactive-forms-formarray-dynamic-fields/">here</a> for a concise
  explanation). For the add/remove buttons you can make use of the <code>AddRemoveButtons</code> component.
</p>

<pre>
  <code language="html" [highlight]="multipleFieldsCode"></code>
</pre>

<br />
{{ appointmentsForm.value | json }}

<br />
<h2>Partial Date input</h2>

<p>
  This component allows entering parts of a date. When setting its value it expects a value in the form of
  <code class="partial-date__code" language="typescript" highlight="{ year: string, month: string, day: string }"></code
  >. If you want to convert this value to a <code>DateObject</code>, you can use the utility method
  <code>mapToDateObject</code> (cf. partial-date-input-utils.ts)
</p>
<p>
  Note that the year part is always required. Other parts can be skipped, but illegal dates like 31/02/2001 are not
  allowed.
</p>
<pre><code language="html" [highlight]=partialDateInputCode></code></pre>
<form [formGroup]="partialForm" nxhAutoValidate>
  <nxh-partial-date-input formControlName="partialDate"></nxh-partial-date-input>
</form>

<br />
<h2>Mini calendar</h2>

<p>Calendar developed specifically developed for appointments team. Can be extended later on.</p>

<br />
<b>'Appointments' mode</b>

<p>
  For appointments we don't need to be able to select a range, but when selecting a date, a range covering the selected
  date's week should be shown. This can be accomplished by:
</p>
<ul>
  <li>setting [mode]="'single'" (the default)</li>
  <li>setting [noHighlightOnFocused]="true"</li>
  <li>calculate the range on receiving the (dateSelection) event</li>
  <li>setting the calculated [range]</li>
</ul>

<p>By passing [bullets] you can draw bullet points underneath a date.</p>

<nxh-mini-calendar
  [bullets]="bullets"
  [mode]="'single'"
  [range]="range"
  [noHighlightOnFocused]="true"
  (dateSelection)="onSelectDate($event)"
></nxh-mini-calendar>

<pre>
<code language="html" [highlight]="calendarCode"></code>
</pre>

<br />
<b>Some methods</b>
<ul>
  <li>navigateToMonth() to navigate to certain month</li>
  <li>selectDate() to select a date (and navigate towards that date)</li>
  <li>selectToday() to select today a date (and navigate towards that date)</li>
</ul>

<br />
<b>'Single' mode</b>

<p>
  Allows for selecting a date. Note that currently we don't support using this inside a form - it's not a
  ControlValueAccessor (yet?)
</p>

<nxh-mini-calendar (dateSelection)="selectedDate = $event"></nxh-mini-calendar>

<div>selected date = {{ selectedDate | json }}</div>

<br />
<b>'Range' mode</b>

<p>
  Allows for selecting a range. Note that currently we don't support using this inside a form - it's not a
  ControlValueAccessor (yet?)
</p>

<nxh-mini-calendar [mode]="'range'" (rangeSelection)="selectedRange = $event"></nxh-mini-calendar>

<div>selected date = {{ selectedRange | json }}</div>

<br />
<b>As a button</b>

<nxh-calendar-button
  [mode]="'single'"
  [range]="range"
  [noHighlightOnFocused]="true"
  (dateSelection)="onSelectDate($event)"
></nxh-calendar-button>

<nh-control [label]="'range'" [required]="true">
  <nxh-range-picker
    [formControl]="rangeControl"
    [appendTo]="'body'"
    [showToday]="false"
    [minDate]="minDate"
    [showLabels]="true"
    nxhDataTest="range"
  ></nxh-range-picker>
  <val-errors [control]="rangeControl"></val-errors>
</nh-control>
