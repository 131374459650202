<p>
  You can use the <code>[nxhTypeahead]</code> directive to turn an input in a typeahead. Most of the time though, you
  will use the <code>ng-select</code> component. We're not deprectating this directive (contrary to
  <code>nxh-typeahead-cva</code>) because it is not unthinkable we'll need it for supporting more specialistic use cases
  like a TwitterInput or so.
</p>

<h1>default</h1>

<p>
  To turn an input element in a typeahead, you put a [nxhTypeahead] directive on it. In its simplest case, you add the
  directive, along with:
</p>
<ul>
  <li>
    [searchMethod]: a function that takes a term and returns an Observable of <code>TypeaheadOption</code>s. This is
    tuple containing a value and a display attribute.
  </li>
  <li>[scrollable]: denotes whether the typeahead should be scrollable. Default is false</li>
  <li>(selectOption): callback function that is called wheneber an option has been selected</li>
</ul>

<input
  class="form-control"
  type="text"
  nxhTypeahead
  [placeholder]="'start typing'"
  [scrollable]="true"
  [searchMethod]="searchMethod"
  (selectOption)="select($event)"
/>

<pre>
    <div><code language="html" [highlight]="defaultCode"></code></div>
  </pre>

<h1>with a custom option template</h1>

<p>
  To change the way a TypeaheadOption is rendered, you can pass a <code>[optionTemplate]</code>. The option tempalte can
  use the following <a href="https://angular.io/guide/structural-directives#microsyntax">'let-attributes'</a>:
</p>
<ul>
  <li>let-item: the <code>TypeaheadOption</code></li>
  <li>let-term: the search term that was specified</li>
  <li>let-active: index of the active option</li>
</ul>

<input
  class="form-control"
  type="text"
  nxhTypeahead
  [searchMethod]="searchMethod"
  [optionTemplate]="optionTemplate"
  (selectOption)="select($event)"
/>

<ng-template #optionTemplate let-item let-term="term">
  {{ term }} => <span class="bg-success text-danger" [innerHTML]="item.display | bold: term"></span> ({{ item.extra }})
</ng-template>

<pre>
    <div><code language="html" [highlight]="withOptionTemplateCode"></code></div>
  </pre>

<h1>with a custom results template</h1>

<p>
  It is possible to have full control over the resultsTemplate, by specifying the <code>resultTemplate</code>. Again,
  there are some 'let-attributes' available:
</p>
<ul>
  <li>let-options: the <code>TypeaheadOptions</code></li>
  <li>let-term: the search term that was specified</li>
  <li>let-active: index of the active option</li>
  <li>let-select: function to call when selecting an option</li>
  <li>let-createListItemId: when implmenting a scrollable typeahead, call this function giving the index of option</li>
</ul>

<input
  class="form-control"
  type="text"
  nxhTypeahead
  [scrollable]="true"
  [searchMethod]="searchMethod"
  [resultTemplate]="resultTemplate"
  (selectOption)="select($event)"
/>

<ng-template
  #resultTemplate
  let-options
  let-term="term"
  let-active="active"
  let-select="select"
  let-createListItemId="createListItemId"
>
  <div>
    <h5>countries</h5>
    <div
      class="card bg-success text-white"
      [attr.id]="createListItemId(index)"
      [class.bg-warning]="active === index"
      *ngFor="let option of options; let index = index"
      (click)="select(option)"
    >
      <div class="card-body">{{ option.display }}</div>
    </div>
  </div>
</ng-template>

<pre>
  <div><code language="html" [highlight]="withResultTemplateCode"></code></div>
</pre>
