import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Metadata } from '@nexuzhealth/shared-domain';
import { getHttpParams, retryBackoff, RetryBackoffConfig, timeoutHeaders } from '@nexuzhealth/shared-util';
import { Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import {
  addCoercedContentTypeToHeaders,
  getCoercedContentTypeIfNeeded,
} from '../shared/FileWithCoercedContentType.model';
import { SignedUrlResponse } from '../model/blob.model';

@Injectable({
  providedIn: 'root',
})
export class BlobApiService {
  private readonly baseUrl = 'api/tech/blob/v1';

  constructor(private http: HttpClient) {}

  getBlobMetaData(blobName: string): Observable<Metadata> {
    const url = `${this.baseUrl}/${blobName}`;
    return this.http.get<Metadata>(url, {
      params: { metadata: true },
    });
  }

  getBlob(blobName: string, download = true, filename?: string): Observable<HttpResponse<Blob>> {
    const url = `${this.baseUrl}/${blobName}`;
    const params = getHttpParams({ filename });
    return this.http
      .get<Blob>(url, {
        observe: 'response',
        responseType: 'blob' as 'json',
        headers: timeoutHeaders({ errorMillis: -1 }),
        params,
      })
      .pipe(
        tap((data) => {
          if (download) this.downLoadFile(data, data.headers.get('content-type'));
        }),
      );
  }

  getBlobWithRetry(
    blobName: string,
    retryConfig: RetryBackoffConfig,
    download = true,
    filename?: string,
  ): Observable<HttpResponse<Blob>> {
    const url = `${this.baseUrl}/${blobName}`;
    const params = getHttpParams({ filename });
    return this.http
      .get<Blob>(url, {
        observe: 'response',
        responseType: 'blob' as 'json',
        headers: timeoutHeaders({ errorMillis: -1 }),
        params,
      })
      .pipe(
        retryBackoff(retryConfig),
        tap((data) => {
          if (download) this.downLoadFile(data, data.headers.get('content-type'));
        }),
      );
  }

  createSignedUrl(file: File) {
    const url = `${this.baseUrl}/signedUrl`;

    // workaround for mimetype pkcs12
    const fileType = getCoercedContentTypeIfNeeded(file) ?? file.type;

    return this.http.post<SignedUrlResponse>(url, {
      contentType: fileType,
      fileName: file.name,
    });
  }

  createBlob(file: File) {
    return this.createSignedUrl(file).pipe(
      mergeMap(
        (response) => {
          return this.http.put(response.url, file, {
            headers: addCoercedContentTypeToHeaders(file, new HttpHeaders(response.headers)),
          });
        },
        (response) => response.blobName,
      ),
    );
  }

  createBlobWithUploadProgress(file: File) {
    return this.createSignedUrl(file).pipe(
      mergeMap(
        (response) => {
          return this.http.put(response.url, file, {
            headers: addCoercedContentTypeToHeaders(file, new HttpHeaders(response.headers)),
            reportProgress: true,
            observe: 'events',
          });
        },
        (response, event) => ({
          blobName: response.blobName,
          event: event,
        }),
      ),
    );
  }

  private downLoadFile(data: any, type: string) {
    const contentDisposition = data.headers.get('content-disposition');

    let name = '';
    if (contentDisposition !== null) {
      name = contentDisposition.split('=').length >= 2 ? data.headers.get('content-disposition').split('=')[1] : '';
      name = trimByChar(name, '"');
    }

    const url = window.URL.createObjectURL(data.body);
    const link = document.createElement('a');
    link.href = url;
    link.download = name;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }
}

function trimByChar(fileName, character) {
  const first = [...fileName].findIndex((char) => char !== character);
  const last = [...fileName].reverse().findIndex((char) => char !== character);
  return fileName.substring(first, fileName.length - last);
}
