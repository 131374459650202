import { CdkColumnDef } from '@angular/cdk/table';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filterNullOrUndefined, SortOrder } from '@nexuzhealth/shared-util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SortTableDirective } from '../sort-table.directive';

@Component({
  selector: 'nxh-sort-header',
  templateUrl: './sort-header.component.html',
  styleUrls: ['./sort-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSwitch, FontAwesomeModule, NgSwitchCase, NgSwitchDefault],
})
export class SortHeaderComponent<T> implements OnInit, OnDestroy {
  @Input() defaultSortOrder = SortOrder.asc;
  initialSortOrder!: SortOrder;
  sortByOrder?: SortOrder;
  private fieldName!: keyof T;
  private destroy = new Subject<void>();

  constructor(
    private cdkColumnDef: CdkColumnDef,
    private sortTableDirective: SortTableDirective<T>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.initialSortOrder = this.defaultSortOrder;
    this.fieldName = this.cdkColumnDef.name as keyof T;
    this.sortTableDirective.sortChanges
      .pipe(filterNullOrUndefined, takeUntil(this.destroy))
      .subscribe((sortOptions) => {
        this.sortByOrder = sortOptions.sortBy === this.fieldName ? sortOptions.sortByOrder : undefined;
        this.cdr.markForCheck();
      });
  }

  sort() {
    const sortOrder = this.sortByOrder ? toggle(this.sortByOrder) : this.defaultSortOrder;
    this.sortTableDirective.setSortOrder(this.fieldName, sortOrder);
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}

function toggle(sortOrder: SortOrder): SortOrder {
  return sortOrder === SortOrder.asc ? SortOrder.desc : SortOrder.asc;
}
