import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
} from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DetailBubbleService } from '@nexuzhealth/shared-ui-toolkit/detail';
import { formatPersonName } from '@nexuzhealth/shared-util';
import { AgendaPlannerTaskViewModel, HourViewModel } from '../../shared/models/date.model';
import { AgendaPlannerService } from '../../shared/services/agenda-planner.service';
import { AgendaPlannerQuery } from '../../state/agenda-planner-query.service';
import { DayPlannerBubbleComponent } from '../day-planner-bubble/day-planner-bubble.component';

@Component({
  selector: 'nxh-day-planner',
  templateUrl: './day-planner.component.html',
  styleUrls: ['./day-planner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayPlannerComponent implements OnDestroy {
  destroy$ = new Subject<void>();
  hours: HourViewModel[];
  LIST_IDS = [];
  @Output() dayListChangedEmitter = new EventEmitter();
  @Input() dragDropDisabled = true;
  @Input() emptyTitle;
  @Input() emptyDescription;
  @Input() generalRequestError;
  @Input() generalTimeoutError;
  @Input() withInfoBubble = true;

  @Input()
  set allHoursToDisplayInPlanner(value: Observable<HourViewModel[]>) {
    if (value) {
      value.pipe(takeUntil(this.destroy$)).subscribe(
        (res) => {
          this.hours = res;
          this.generateIds();
          this.agendaPlannerService.setAgendaPlannerLoadingState(false);
          this.cdr.detectChanges();
        },
        (err) => {
          this.toastr.error(this.i18next.transform('_dayplanner.list-error'), err);
        },
      );
    }
  }

  @Input() template: TemplateRef<any>;
  @Input() emptyStateTemplate: TemplateRef<any>;

  constructor(
    private toastr: ToastrService,
    private i18next: I18NextPipe,
    private agendaPlannerService: AgendaPlannerService,
    private agendaPlannerQuery: AgendaPlannerQuery,
    private cdr: ChangeDetectorRef,
    private detailBubbleSvc: DetailBubbleService,
  ) {}

  generateIds() {
    this.LIST_IDS = [];
    for (let index = 0; index < this.hours.length; index++) {
      this.LIST_IDS.push('cdk-drop-list-' + index);
    }
  }

  drop(event: CdkDragDrop<AgendaPlannerTaskViewModel[], any>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.dayListChangedEmitter.emit(this.hours);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showBubble(event: MouseEvent, task: AgendaPlannerTaskViewModel): void {
    this.detailBubbleSvc.show(DayPlannerBubbleComponent, event.target as HTMLElement, {
      title: formatPersonName(task.data.fullPatientName),
      data: task,
    });
  }
}
