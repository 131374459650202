import { Pipe, PipeTransform } from '@angular/core';
import { ResourceApi } from '@fullcalendar/resource';

@Pipe({
  name: 'minResourceLabelHeight',
})
export class MinResourceLabelHeightPipe implements PipeTransform {
  transform(resource: ResourceApi): number {
    return (
      Math.max(
        ...Object.values(
          resource
            .getEvents()
            .map((event) => event.startStr)
            .reduce(function (duplicatesOfString: Record<string, number>, string: string) {
              duplicatesOfString[string] =
                duplicatesOfString[string] === undefined ? 1 : duplicatesOfString[string] + 1;
              return duplicatesOfString;
            }, {}),
        ),
      ) * 20
    );
  }
}
