<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Password input</h1>
    </div>
  </div>
</div>

<div class="toolkit__body">
  <div class="toolkit__body__content" nxhScrollspyContainer>
    <div
      class="toolkit__body__content__block"
      id="block1"
      nxhScrollspyTarget="block1"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Password input</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock1" (click)="showExampleBlock1 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock1" (click)="showExampleBlock1 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showExampleBlock1">
        <div class="card">
          <form [formGroup]="form">
            <div class="card-body">
              <div class="card-body__container">
                <div class="nh-row">
                  <div class="form-group nh-col-4">
                    <label>Paswoord</label>
                    <nxh-password-input
                      formControlName="password"
                      [placeholder]="'Jouw paswoord ...'"
                    ></nxh-password-input>
                  </div>
                </div>
                <button type="button" class="btn btn-primary" (click)="form.enabled ? form.disable() : form.enable()">
                  Enable/Disable
                </button>
                <div>
                  <pre>{{ form.value | json }}</pre>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div *ngIf="!showExampleBlock1">
        <pre><code language="html" [highlight]="block1Code"></code></pre>
      </div>
    </div>
  </div>
</div>
