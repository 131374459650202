import { Component, Input } from '@angular/core';

import { ThemeColor } from '../theme.component';

@Component({
  selector: 'nxh-color-detail',
  templateUrl: './color-detail.component.html',
  styleUrls: ['./color-detail.component.scss'],
})
export class ColorDetailComponent {
  @Input() selectedColor: ThemeColor;

  constructor() {}
}
