import { InjectionToken } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { Facet } from '@nexuzhealth/shared-finance-data-access-insurability';
import { DeepPartial } from 'utility-types';
import { EFactStatus } from '@nexuzhealth/shared-finance-domain';

export interface SystemSettings {
  production: boolean;
  application: string;
  loginEndpoint: string;
  logOutEndpoint: string;
  logOutEhealthEndpoint: string;
  userPortalEndpoint: string;
  organizationPortalEndpoint: string;
  nexuzhealthStaticUrl: string;
  bcfi: string;
  config: Config;
  wasm: Pick<Config, 'endpoint'>;
  auth0: {
    domain: string;
    clientId: string;
    audience: string;
  };
  i18next: {
    backend: {
      loadPath: string;
    };
  };
  myCarenetDashboard: string;
  zetesEidEndpoint: string;
  zetesClientId: string;
  idleTimeout: {
    enabled: boolean;
    idleAfter: number;
    timeoutAfter: number;
  };
  ehealth: {
    certificates: {
      generalInformation: {
        nl: string;
        fr: string;
      };
      downloadManager: string;
    };
  };
  integrations: {
    healthconnect: {
      integratorId: string;
      eFormsApiHost: string;
    };
    doccle: {
      hostUrl: string;
    };
  };
  custom?: any;
  featureToggles: {
    loadDynamicEnabled?: boolean;
  };
  pushNotifs?: {
    port?: number;
    pathname: string;
  };
  fullcalendarLicenseKey: string;
  formviewer: {
    fontawesomeUrl: string;
    stylesheetUrl: string;
    jsBundleUrl: string;
  };
  theming: {
    path: string;
  };
  barometer: {
    consent: {
      path: string;
    };
  };
  nexuzhealth: {
    FAQ: Record<LanguageType, string>;
    privacy: Record<LanguageType, string>;
  };
  thirdparties: Record<'CMEAL' | 'COACHBOX' | 'MINDBYTES' | 'MOVEUP', Record<LanguageType, string>>;
  mapboxAccessToken: string;
}

export interface Config {
  clientId: 'moapr';
  clientVersion: string;
  publicRoutes: string[];
  endpoint: string;
  port?: string;
}

export type ComponentSettings = DeepPartial<{
  components: {
    'nav-item-external-links': {
      'sso-links': [
        {
          ssoType: string;
          displayKey: string;
        },
      ];
    };
    'care-plan-form': {
      partsSelectedByDefault: boolean;
    };
    'patient-page': {
      loadOpenContact: boolean;
      smedVisible?: boolean;
      path?: string;
    };
    'patient-records-page': {
      displayedFields: string[];
      validators: [[string, ValidatorFn]];
      validatePersonDataVisible: boolean;
      'create-contact-button-visible': boolean;
    };
    'patient-entourage': {
      showExtraIdentifiers: boolean;
    };
    'patient-search-result-table-INSS': {
      displayedColumns: string[];
      createAddress: boolean;
    };
    'patient-search-result-table-PHONETIC': {
      displayedColumns: string[];
      createAddress: boolean;
    };
    'health-element-page': {
      showValidationStatus: boolean;
      showValidatedBy: boolean;
      motivationsRequired: boolean;
    };
    'insurability-page': {
      multiplePeriods: boolean;
    };
    'administrative-dossier': {
      insurability: {
        facets: Facet[];
        multiplePeriods: boolean;
        titleType: 'short' | 'long';
        paymentVisible: boolean;
        thirdPayerVisible: boolean;
      };
      ehealth: {
        informedConsents: boolean;
        therapeuticRelations: boolean;
      };
      hideBisButton: boolean;
    };
    'therapy-history-page': {
      showValidationStatus: true;
    };
    'eid-modal-component': {
      routeOnSuccess: string[];
      doVerifyId: boolean;
    };
    patient: {
      'search-full': {
        alert: {
          body: string;
        };
        displayedColumns: string[];
        simpleSearchEnabled: boolean;
        advancedSearchEnabled: boolean;
        onlyGivenAllowed: boolean;
        allPatientsSearchEnabled: boolean;
      };
      defaultPatientPageForClinical: string[];
      link: string;
    };
    'medication-page': {
      attributes: {
        frequency: {
          required: boolean;
        };
      };
    };
    'medication-widget': {
      includeProposals: boolean;
    };
    sumehr: {
      canExport: boolean;
    };
    katz: {
      showNewToiletRequestButton: boolean;
    };
    sidebar: {
      summary: {
        displayedWidgets: string[];
      };
    };
    finance: {
      efact: {
        allowNavigationToMessage: boolean;
        allowMarkAsRefused: boolean;
        allowChangeAndRebill: boolean;
        allowCreditCertificate: boolean;
        allowCreditAndRebill: boolean;
        allowResubmitCredit: boolean;
        showAttestButton: boolean;
        showErrorsOnlyDefaultValue: boolean;
        showInvoiceMonth: boolean;
        processingEFactStatuses: EFactStatus[];
        usesCertificate: boolean;
        usesDryRunOptions: boolean;
        pollForReleaseInvoiceUpdates: boolean;
      };
      labelOverrides: {
        releaseInvoice: {
          tooltip: string;
          success: string;
          failed: string;
        };
        efactEmptyTable: string;
      };
    };
    tricNavBarLimitedByMemberdataDeceased: boolean;
    parameters: {
      showObservationFunctionality: boolean;
    };
    messages: {
      hideInboxFolders: string[];
      defaultOutlets: {
        [key: string]: string[];
      };
    };
  };
  services: {
    healthElementQuery: {
      onlyShowValidatedHealthElements: boolean;
    };
  };
  routeconfig: {
    documents: {
      routes: string[];
    };
  };
  helpCenterRecentErrorModules: string[];
}>;

export const SystemSettingsToken = new InjectionToken<SystemSettings>('SystemSettings');
export const ComponentSettingsToken = new InjectionToken<ComponentSettings>('ComponentSettings');

export type LanguageType = 'nl' | 'fr' | 'en';
