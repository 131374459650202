import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Renderer2,
  Self,
} from '@angular/core';
import { DomUtilService } from '@nexuzhealth/shared-util';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Directive({
  selector: '[nxhEllipsisWithTooltip]',
})
export class EllipsisWithTooltipDirective implements AfterViewInit, OnDestroy {
  @Input() lines = 1;
  /**
   * Set this to false for the rare cases where the check fails when taking into account height
   */
  @Input() checkByHeight = true;
  private destroy$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private dom: DomUtilService,
    @Self() @Optional() private tooltip?: NgbTooltip,
  ) {}

  ngAfterViewInit(): void {
    this.setStyling();
    this.detectTooltip();
  }

  @HostListener('window:resize')
  onResize() {
    this.detectTooltip();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setStyling() {
    const el = this.elementRef.nativeElement;
    this.renderer.addClass(el, 'maxlines');
    this.renderer.addClass(el, 'maxlines-' + this.lines);
  }

  /**
   * Call this if you want to re-evaluate setting the tooltip. Note that in a later
   * phase the application will call this when opening/closing the MUD.
   */
  detectTooltip() {
    if (!this.tooltip) {
      return;
    }

    const el = this.elementRef.nativeElement;
    const dimensions = this.dom.getDimensions(el);

    // in tests the mock DomUtilService is not setup in ngAfterInit
    if (!dimensions) {
      return;
    }

    const disableTooltip = this.checkByHeight
      ? dimensions.offsetHeight >= dimensions.scrollHeight
      : dimensions.offsetWidth >= dimensions.scrollWidth;

    if (disableTooltip !== this.tooltip.disableTooltip) {
      this.tooltip.disableTooltip = disableTooltip;
      this.cdr.markForCheck();
    }
  }
}
