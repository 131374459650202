import { NgModule } from '@angular/core';

import { LookforwardFocusDirective } from './lookforward-focus.directive';
import { LookforwardFocusSourceDirective } from './lookforward-focus-source.directive';

@NgModule({
  exports: [LookforwardFocusDirective, LookforwardFocusSourceDirective],
  declarations: [LookforwardFocusDirective, LookforwardFocusSourceDirective],
})
export class LookforwardFocusModule {}
