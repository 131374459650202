import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'nxh-timepicker-demo',
  templateUrl: './timepicker-demo.component.html',
  styleUrls: ['./timepicker-demo.component.scss'],
})
export class TimepickerDemoComponent {
  timePickerCode = `
    <nxh-time-picker-input
        max="14:00"
        min="00:00"
        step="900"
        label="Tijdstip"
        required="true"
        autofocus="true"
        autocomplete="off"
        [formControl]="timePickerControl"
    ></nxh-time-picker-input>
  `;

  timeValue = '00:00';
  timePickerControl = new UntypedFormControl({ value: '00:15', disabled: false });

  constructor() {}
}
