<div class="planners__header">
  <h2>Calendars & Planners</h2>
  <p>This section of the uitoolkit lists moapr built calendars and planners along with a tutorial.</p>
  <hr />
</div>
<h3>Day planner</h3>
<p>In order to make use of the day planner some setup is required.</p>
<h6>Part 1</h6>

<nxh-alert
  type="info"
  description="When using the AgendaPlanner you have to provide AGENDA_PLANNER_PROVIDERS (an alias for AgendaService, AgendaQuery and AgendaStore)."
></nxh-alert>

<p>
  Before we can start making use of the day planner it is required to pass it a list of hourviewmodels. Luckily the
  <em><b>agenda-planner-query.service.ts</b></em> contains a method named <b>groupAllTasksByHour</b>. When a list of
  plannable objects is retrieved using an API service it is required to first convert the objects to a list of
  <b>AgendaPlannerTaskViewModels</b> before passing it to the <b>groupAllTasksByHour</b> method. In the mock api the
  listAllObjects method returns a list of <b>HourViewModels</b>. Be aware that the mock api already contains a list of
  <b>AgendaPlannerTaskViewModels</b>, in most cases a mapping will be required.
</p>
<p>
  If you want the skeleton loader to be visible be sure to set the planner loading state to true before performing your
  request (the dayplanner will take care of the loadingstate from there on). In the example below every time a
  navigation action is triggered and the <b>selectedDateInterval</b> is updated a new list of HourViewModels is
  retrieved and passed to the dayplanner.
</p>
<div class="nh-row">
  <div class="nh-col-6">
    <pre><code [highlight]="plannablesObservableExample" language="typescript"></code></pre>
  </div>
  <div class="nh-col-6">
    <p>PSUEDO API CODE</p>
    <pre><code [highlight]="apiExample" language="typescript"></code></pre>
  </div>
</div>
<h6>Part 2</h6>
<p>
  it is possible to pass a template to the dayplanner, the template will be used to display <b>plannable objects</b> in
  the dayplanner. The dayplanner also contains a default implentation for each row which is very basic (only displays
  start hour). It is also required to pass the dayplanner a translated title & description for the emptystate. In the
  example below a template is passed to the day planner. The HourViewModel contains a list of
  AgendaPlannerTaskViewModels, the viewmodel contains a property named data which can be used to display any data
  specific to your needs.
</p>
<div class="nh-row">
  <div class="nh-col-6">
    <pre><code [highlight]="dayplannerExample" language="html"></code></pre>
  </div>
  <div class="nh-col-6">
    <pre><code [highlight]="templateExample" language="html"></code></pre>
  </div>
</div>
<h3>Example #1 - Drag & Drop</h3>
<nxh-agenda-navigator
  (selectedDateIntervalEmitter)="updateDateInterval($event)"
  [selectedDisplayOption]="day"
  [intervalOptionsVisible]="false"
></nxh-agenda-navigator>
<div class="day-planner card">
  <div class="day-planner__header">
    <h1 *ngIf="(loading$ | async) === false">Wendy Welingelichte</h1>
    <nxh-skeleton-loader [view]="'dayplanner-header'" *ngIf="loading$ | async"></nxh-skeleton-loader>
  </div>
  <nxh-day-planner
    [allHoursToDisplayInPlanner]="plannables$"
    [template]="taskTemplate"
    emptyTitle="no routes planned for selected date."
    emptyDescription="select another date or contact your planner."
    [dragDropDisabled]="false"
  ></nxh-day-planner>
  <ng-template #taskTemplate let-task="task">
    <div class="day-planner__example-obj">
      <div class="day-planner__example-obj__start-hour">{{ task.dateInterval.from | nxhDate: 'shortTime' }}</div>
      <div class="day-planner__example-obj__patient-container">
        <div class="day-planner__example-obj__patient-container__profile-img">
          <nxh-avatar [fullName]="task.data.name | formatPersonName"></nxh-avatar>
        </div>
        <div class="day-planner__example-obj__patient-container__name">{{ task.data.name }}</div>
      </div>
      <div class="day-planner__example-obj__type">{{ task.content }}</div>
      <div class="day-planner__example-obj__address">{{ task.data.address }}</div>
      <div class="day-planner__example-obj__actions">
        <fa-icon icon="exclamation-circle" class="text-danger icon"></fa-icon>
        <fa-icon icon="home" class="text-warning icon"></fa-icon>
      </div>
      <button nxh-button [outline]="true" (click)="sayHello()">Bezoek openen</button>
    </div>
  </ng-template>
</div>
<h3 class="mt-5">Example #2 - Without Drag & Drop</h3>
<nxh-agenda-navigator
  (selectedDateIntervalEmitter)="updateDateInterval($event)"
  [selectedDisplayOption]="day"
  [intervalOptionsVisible]="false"
></nxh-agenda-navigator>
<div class="day-planner card">
  <div class="day-planner__header">
    <h1 *ngIf="(loading$ | async) === false">Wendy Welingelichte</h1>
    <nxh-skeleton-loader [view]="'dayplanner-header'" *ngIf="loading$ | async"></nxh-skeleton-loader>
  </div>
  <nxh-day-planner
    [allHoursToDisplayInPlanner]="plannables$"
    [template]="taskTemplate"
    emptyTitle="no routes planned for selected date."
    emptyDescription="select another date or contact your planner."
  ></nxh-day-planner>
  <ng-template #taskTemplate let-task="task">
    <div class="day-planner__example-obj">
      <div class="day-planner__example-obj__start-hour">{{ task.dateInterval.from | nxhDate: 'shortTime' }}</div>
      <div class="day-planner__example-obj__patient-container">
        <div class="day-planner__example-obj__patient-container__profile-img">
          <nxh-avatar [fullName]="task.data.name | formatPersonName"></nxh-avatar>
        </div>
        <div class="day-planner__example-obj__patient-container__name">{{ task.data.name }}</div>
      </div>
      <div class="day-planner__example-obj__type">{{ task.content }}</div>
      <div class="day-planner__example-obj__address">{{ task.data.address }}</div>
      <div class="day-planner__example-obj__actions">
        <fa-icon icon="exclamation-circle" class="text-danger icon"></fa-icon>
        <fa-icon icon="home" class="text-warning icon"></fa-icon>
      </div>
      <button nxh-button [outline]="true" (click)="sayHello()">Bezoek openen</button>
    </div>
  </ng-template>
</div>
