import { Component } from '@angular/core';
import { ScrollspyService } from '@nexuzhealth/shared-ui-toolkit/scrollspy';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'nxh-scrollspy-demo',
  templateUrl: './scrollspy-demo.component.html',
  styleUrls: ['./scrollspy-demo.component.scss'],
})
export class ScrollspyDemoComponent {
  selected$ = new BehaviorSubject('info');

  code = `
    <li (click)="scrollTo('secondary')"
      [class.text-primary]="(selected$ | async) === 'secondary'">
      secondary
    </li>

    <div class="d-flex flex-column w-50 top-page" nxhScrollspyContainer>
    <div class="card" id="info" nxhScrollspyTarget="info"
      (scrollInViewPort)="scrolledInView($event)">

    ...

    scrollTo(id) {
      this.selected$.next(id);
      this.scrollspyService.scrollTo(id, { emitEvent: false });
    }
  `;

  constructor(private scrollspyService: ScrollspyService) {}

  scrollTo(id) {
    this.selected$.next(id);
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id) {
    this.selected$.next(id);
  }
}
