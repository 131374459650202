import { AfterViewInit, Component, ElementRef, forwardRef, Input, Optional, Renderer2, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ClockTime, isClockTime } from '@nexuzhealth/shared-domain';
import { Focusable, FOCUSSABLE } from '@nexuzhealth/shared-ui-toolkit/focus'; // TODO change this
import { padStart } from 'lodash-es';
import { addDataTestAttributes, DataTestDirective } from '@nexuzhealth/shared-tech-feature-e2e';

@Component({
  selector: 'nxh-time-picker-input',
  templateUrl: './time-picker-input.component.html',
  styleUrls: ['./time-picker-input.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimePickerInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => TimePickerInputComponent), multi: true },
    { provide: FOCUSSABLE, useExisting: forwardRef(() => TimePickerInputComponent) },
  ],
})
export class TimePickerInputComponent implements ControlValueAccessor, Validator, Focusable, AfterViewInit {
  @Input() max = '24:00';
  @Input() min = '00:00';
  @Input() compact = true;
  @Input() step = 900; // 900 seconds = 15mins
  @Input() label?: string;
  @Input() errorMessage?: string;
  @Input() required = false;
  @Input() autofocus = false;
  @Input() autocomplete = 'off';
  @Input() id = 'timePickerInput';
  @Input() useClockTime = false;
  @ViewChild('ref', { static: true }) elementRef!: ElementRef<HTMLInputElement>;
  onChange!: (_: any) => void;
  onTouch!: () => void;

  constructor(
    private hostElement: ElementRef,
    private renderer: Renderer2,
    @Optional() private dataTestDirective: DataTestDirective,
  ) {}

  ngAfterViewInit(): void {
    addDataTestAttributes(this.dataTestDirective?.nxhDataTest || 'time-picker-component', {
      element: this.elementRef.nativeElement,
      suffix: '_timepicker',
    });
  }

  valueChange(event: any) {
    if (!this.useClockTime || !event.target.value) {
      this.onChange(event.target.value);
    } else {
      const split = event.target.value.split(':');
      const clockTime: ClockTime = { hours: +split[0], minutes: +split[1], seconds: 0 };
      this.onChange(clockTime);
    }
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  writeValue(value: ClockTime | string) {
    if (isClockTime(value)) {
      value = padStart(value.hours + '', 2, '0') + ':' + padStart(value.minutes + '', 2, '0');
    }
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
  }

  blur() {
    this.onTouch();
    // trigger validation with invalid input
    if (this.elementRef.nativeElement.validity.badInput) {
      this.onChange(null);
    }
  }

  private toTime(value: ClockTime | string) {
    if (isClockTime(value)) {
      return new Date(1970, 0, 1, value.hours, value.minutes || 0);
    } else {
      const timeTokens = value.split(':') as unknown as number[];
      return new Date(1970, 0, 1, timeTokens[0], timeTokens[1]);
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    let valid = this.elementRef.nativeElement.validity.badInput ? { 'invalid-time': true } : null;

    if (!control.value) {
      // empty is valid time
      return valid;
    }

    if (!valid) {
      const date = this.toTime(control.value);
      const maxTime = this.toTime(this.max);
      const minTime = this.toTime(this.min);

      if (date && !(minTime <= date && date <= maxTime)) {
        valid = { 'invalid-time': true };
      }
    }

    return valid;
  }

  setFocus() {
    this.elementRef.nativeElement.focus();
  }
}
