import { isNil } from 'lodash-es';
import { ClockTime, ResourceName } from './common';
import { Timing } from './time';

export interface TimeSchedule {
  name?: ResourceName;
  recurrences: Recurrence[];
  timings: Timing[];
}

// This actually corresponds to the backend commontime.Range  type, while the Range interface
// does not...
export interface Period {
  startTime: Date;
  endTime?: Date;
}

export type Recurrence = DayRecurrence | WeekRecurrence | MonthRecurrence;

// day

export interface DayRecurrence {
  period: number;
  day: { timings: RecurrenceTiming[] };
  time?: string;
}

export function isDayRecurrence(obj?: Recurrence): obj is DayRecurrence {
  return !isNil((obj as DayRecurrence)?.day);
}

export type RecurrenceTiming = RecurrenceTimingTimeslot | RecurrenceTimingAllDay | RecurrenceTimingDayParts;

export type RecurrenceTimingTimeslot = { timeslot: Timeslot };

export function isRecurrenceTimingTimeslot(obj?: any): obj is RecurrenceTimingTimeslot {
  return (obj as RecurrenceTimingTimeslot)?.timeslot !== undefined;
}

export function isRecurrenceTimingTimeslotArray(obj?: any): obj is RecurrenceTimingTimeslot[] {
  return obj && Array.isArray(obj) && obj.length > 0 && isRecurrenceTimingTimeslot(obj[0]);
}

export type RecurrenceTimingAllDay = { allDay: boolean };

export function isRecurrenceTimingAllDay(obj?: any): obj is RecurrenceTimingAllDay {
  return (obj as RecurrenceTimingAllDay)?.allDay !== undefined;
}

export type RecurrenceTimingDayParts = { dayPartName: string; timing?: Timeslot };

export function isRecurrenceTimingParts(obj?: any): obj is RecurrenceTimingDayParts {
  return (obj as RecurrenceTimingDayParts)?.dayPartName !== undefined;
}

export function isRecurrenceTimingPartsArray(obj?: any): obj is RecurrenceTimingDayParts[] {
  return obj && Array.isArray(obj) && obj.length > 0 && isRecurrenceTimingParts(obj[0]);
}

// week

export interface WeekRecurrence {
  period: number;
  week: { days?: WeekDayRecurrence[]; frequency?: number };
  timings?: RecurrenceTiming[];
  time?: string;
}

export function isWeekRecurrence(obj?: Recurrence): obj is WeekRecurrence {
  return !isNil((obj as WeekRecurrence)?.week);
}

export interface WeekDayRecurrence {
  weekday: DAY;
  timings: RecurrenceTiming[];
  time?: string;
}

// month

export interface MonthRecurrence {
  period: number;
  month: { selectionType: SelectionType; days: MonthDayRecurrence[]; timings?: RecurrenceTiming[] };
  timings?: RecurrenceTiming[];
  time?: string;
}

export function isMonthRecurrence(obj?: Recurrence): obj is MonthRecurrence {
  return !isNil((obj as MonthRecurrence)?.month);
}

export interface MonthDayRecurrence {
  nthDay?: number; // nhtDay *or* weekday
  weekday?: MonthWeekdayRecurrence;
  timings?: RecurrenceTiming[];
}

export interface MonthWeekdayRecurrence {
  weekday: DAY;
  nth: number;
}

export interface Timeslot {
  start: {
    hours: number;
    minutes: number;
    seconds?: number;
  };
  end: {
    hours: number;
    minutes: number;
    seconds?: number;
  };
}

export enum TimeslotType {
  RANGE = 'RANGE',
  SINGLE = 'SINGLE',
}

export enum DAY {
  MON = 'monday',
  TUE = 'tuesday',
  WED = 'wednesday',
  THU = 'thursday',
  FRI = 'friday',
  SAT = 'saturday',
  SUN = 'sunday',
}

export const recurrence_days = Object.values(DAY); //[DAY.MON, DAY.TUE, DAY.WED, DAY.THU, DAY.FRI, DAY.SAT, DAY.SUN] as const;
export type RecurrenceDay = (typeof recurrence_days)[number];

export enum SelectionType {
  DAYS = 'DAY',
  WEEKDAY = 'WEEKDAY',
  SELECTED_DAYS = 'SELECTED_DAYS',
}

export interface DayPartOption {
  value: string;
  label: string;
  range: {
    from: ClockTime;
    to: ClockTime;
  };
}

export interface DayPart {
  name: string;
  label: string;
  code: string;
  startTime: ClockTime;
  endTime: ClockTime;
  sendVersion?: Date;
}
