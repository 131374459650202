import { inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { ClockTime } from '@nexuzhealth/shared-domain';
import { formatClockTime } from '@nexuzhealth/shared-util';

@Pipe({
  name: 'formatClockTime',
})
export class FormatClockTimePipe implements PipeTransform {
  private locale = inject(LOCALE_ID);

  transform(clockTime: ClockTime, locale?: string): string {
    return formatClockTime(clockTime, locale || this.locale);
  }
}
