import { ElementRef } from '@angular/core';

/**
 * Calculates colspan spanning from fromColName till tilColName.
 */
export function calcColspan(displayedCols: string[], fromColName: string, tilColName: string | null = null) {
  const fromIndex = fromColName ? displayedCols.indexOf(fromColName) : 0;
  const tilIndex = tilColName ? displayedCols.indexOf(tilColName) : displayedCols.length;
  return tilIndex - fromIndex;
}

/**
 * Marks a row as active that contains the given origin, i.e. typically the element that was clicked upon
 */
export function markRowAsActive(origin: HTMLElement | ElementRef, activeClass = 'active'): (() => void) | null {
  let nativeElement;
  if (origin instanceof MouseEvent) {
    nativeElement = origin.target;
  } else if (isElementRef(origin)) {
    nativeElement = origin.nativeElement;
  } else {
    nativeElement = origin;
  }

  const tr = findEl(nativeElement, 'TR');
  if (!tr) {
    return null;
  }

  const table = findEl(tr.parentElement, 'TABLE');
  if (!table) {
    return null;
  }

  const existingActive = table.querySelector(`tr.${activeClass}`);
  if (existingActive) {
    existingActive.classList.remove(activeClass);
  }

  tr.classList.add(activeClass);
  return () => tr.classList.remove(activeClass);
}

function findEl(el: HTMLElement | null, nodeName: string) {
  if (!el) {
    return null;
  }

  if (el.nodeName === nodeName) {
    return el;
  }

  return findEl(el.parentElement, nodeName);
}

function isElementRef(el: HTMLElement | ElementRef<any>): el is ElementRef<any> {
  return !!(el as ElementRef)?.nativeElement;
}
