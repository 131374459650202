import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export interface ThemeColor {
  title: string;
  color: string;
  description: string;
  usage: string;
  hex: string;
}

@Component({
  selector: 'nxh-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss'],
})
export class ThemeComponent {
  selectedColor: ThemeColor;

  themeColors: ThemeColor[] = [
    {
      title: 'Primary Dark color',
      color: 'var(--primaryColorDark)',
      description: 'This is the main color of the Moapr App.',
      usage: 'background-color: var(--primaryColorDark);',
      hex: '#11658a',
    },
    {
      title: 'Success Dark color',
      color: 'var(--successColorDark)',
      description: 'This is the success color of the Moapr App.',
      usage: 'background-color: var(--successColorDark);',
      hex: '#016c49',
    },
    {
      title: 'Warning Dark color',
      color: 'var(--warningColorDark)',
      description: 'This is the warning color of the Moapr App.',
      usage: 'background-color: var(--warningColorDark);',
      hex: '#da993f',
    },
    {
      title: 'Danger Dark color',
      color: 'var(--dangerColorDark)',
      description: 'This is the danger color of the Moapr App.',
      usage: 'background-color: var(--dangerColorDark);',
      hex: '#a72526',
    },
    {
      title: 'Primary Pure color',
      color: 'var(--primaryColor)',
      description: 'This is the main color of the Moapr App.',
      usage: 'background-color: var(--primaryColor);',
      hex: '#167FAC',
    },
    {
      title: 'Success Pure color',
      color: 'var(--successColor)',
      description: 'This is the success color of the Moapr App.',
      usage: 'background-color: var(--successColor);',
      hex: '#02875b',
    },
    {
      title: 'Warning Pure color',
      color: 'var(--warningColor)',
      description: 'This is the warning color of the Moapr App.',
      usage: 'background-color: var(--warningColor);',
      hex: '#f2aa46',
    },
    {
      title: 'Danger Pure color',
      color: 'var(--dangerColor)',
      description: 'This is the danger color of the Moapr App.',
      usage: 'background-color: var(--dangerColor);',
      hex: '#d12f30',
    },
    {
      title: 'Primary Light color',
      color: 'var(--primaryColorLight)',
      description: 'This is the main color of the Moapr App.',
      usage: 'background-color: var(--primaryColorLight);',
      hex: '#4499bd',
    },
    {
      title: 'Success Light color',
      color: 'var(--successColorLight)',
      description: 'This is the success color of the Moapr App.',
      usage: 'background-color: var(--successColorLight);',
      hex: '#349f7c',
    },
    {
      title: 'Warning Light color',
      color: 'var(--warningColorLight)',
      description: 'This is the warning color of the Moapr App.',
      usage: 'background-color: var(--warningColorLight);',
      hex: '#f5bb6b',
    },
    {
      title: 'Danger Light color',
      color: 'var(--dangerColorLight)',
      description: 'This is the danger color of the Moapr App.',
      usage: 'background-color: var(--dangerColorLight);',
      hex: '#db5859',
    },
    {
      title: 'Primary Washed color',
      color: 'var(--primaryColorWashed)',
      description: 'This is the main color of the Moapr App.',
      usage: 'background-color: var(--primaryColorWashed);',
      hex: '#e8f3f7',
    },
    {
      title: 'Success Washed color',
      color: 'var(--successColorWashed)',
      description: 'This is the success color of the Moapr App.',
      usage: 'background-color: var(--successColorWashed);',
      hex: '#e6f3ef',
    },
    {
      title: 'Warning Washed color',
      color: 'var(--warningColorWashed)',
      description: 'This is the warning color of the Moapr App.',
      usage: 'background-color: var(--warningColorWashed);',
      hex: '#fef7ed',
    },
    {
      title: 'Danger Washed color',
      color: 'var(--dangerColorWashed)',
      description: 'This is the danger color of the Moapr App.',
      usage: 'background-color: var(--dangerColorWashed);',
      hex: '#fbebeb',
    },
  ];

  constructor(private modalService: NgbModal) {}

  selectColor(color: ThemeColor) {
    this.selectedColor = color;
  }

  open(content) {
    this.modalService.open(content);
  }
}
