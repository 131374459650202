import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';
import { sortBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  AdministrativeGenderReference,
  CityReference,
  CountryReference,
  HealthCareWorkerProfessionReference,
  LANGUAGE_ISO_CODE_SOURCE,
  LanguageReference,
  MaritalStatusReference,
  MediumReference,
  NationalityReference,
  ProfessionReference,
  ReferenceType,
} from '../shared/reference-types';

@Injectable({
  providedIn: 'root',
})
export class ReferenceApiService {
  private regexes = {
    professions: /professions\/(.*)/,
    healthcareworkerprofessions: /healthcareworkerprofessions\/(.*)/,
    countries: /countries\/(.*)/,
    maritalstatusses: /(.*)/,
    nationalities: /nationalities\/(.*)/,
    languages: /languages\/(.*)/,
    gender: /genders\/(.*)/,
  };

  constructor(
    private http: HttpClient,
    private i18nPipe: I18NextPipe,
  ) {}

  getProfessionValues(): Observable<ProfessionReference[]> {
    return this.getValues('professions');
  }

  getHealthCareWorkerProfessionValues(): Observable<HealthCareWorkerProfessionReference[]> {
    return this.getValues('healthcareworkerprofessions');
  }

  getCountryValues(): Observable<CountryReference[]> {
    return this.getValues('countries');
  }

  getCityValues(): Observable<CityReference[]> {
    return this.getValues('cities');
  }

  getMediumValues(): Observable<MediumReference[]> {
    return this.getValues('mediums');
  }

  getMaritalStatusValues(): Observable<MaritalStatusReference[]> {
    return this.getValues('maritalstatusses');
  }

  getNationalityValues(): Observable<NationalityReference[]> {
    return this.getValues('nationalities');
  }

  getLanguageValues(): Observable<LanguageReference[]> {
    const httpParams = new HttpParams().set('sources', LANGUAGE_ISO_CODE_SOURCE);
    return this.getValues('languages', httpParams);
  }

  getAdministrativeGenderValues(source?: string): Observable<AdministrativeGenderReference[]> {
    if (source) {
      return this.getValues('genders?source=' + source).pipe(
        map((vals: AdministrativeGenderReference[]) => sortBy(vals, 'value')),
      );
    }

    return this.getValues('genders');
  }

  private getValues<T extends ReferenceType>(
    specificPath: string,
    httpParams: HttpParams | null = null,
  ): Observable<T[]> {
    const url = `api/bas/reference/v1/${specificPath}`;
    return this.http.get<{ data: T[] }>(url, { params: httpParams }).pipe(
      map(({ data }) => data),
      map((values) =>
        values.map((v) => ({
          ...v,
          name: this.parseId(v.name, specificPath),
          value: this.i18nPipe.transform(v.translationKey),
        })),
      ),
    );
  }

  private parseId(name: string, prefix: string): string {
    const regex = this.regexes[prefix];

    return name.replace(regex, '$1');
  }
}
