export interface Mutuality {
  type: MutualityType;
  translationKey: string;
}

export enum MutualityType {
  Unknown = 'UNKNOWN',
  LCM = 'LCM',
  LNZ = 'LNZ',
  NVSM = 'NVSM',
  LM = 'LM',
  OZ = 'OZ',
  HVIZ = 'HVIZ',
  HRRAIL = 'HR_RAIL',
}

export const MutualityOrder = new Map([
  [MutualityType.LCM, 0],
  [MutualityType.LNZ, 1],
  [MutualityType.NVSM, 2],
  [MutualityType.LM, 3],
  [MutualityType.OZ, 4],
  [MutualityType.HVIZ, 5],
  [MutualityType.HRRAIL, 6],
  [MutualityType.Unknown, 7],
]);

/**
 * Array containing all the mutualities
 */
export const mutualities: readonly Mutuality[] = Object.values(MutualityType).map((mutuality) => ({
  type: mutuality,
  translationKey: mutualityToTranslationKey(mutuality),
}));

function mutualityToTranslationKey(mutuality: MutualityType) {
  return `_mutuality.${mutuality}`;
}
