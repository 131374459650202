import { Pipe, PipeTransform } from '@angular/core';
import { I18nextArrayByKeyPipe } from '@nexuzhealth/shared-util';
import { Role } from '@nexuzhealth/shared-domain';

@Pipe({
  name: 'formatRoles',
})
export class FormatRolesPipe implements PipeTransform {
  constructor(private i18nextArrayByKeyPipe: I18nextArrayByKeyPipe) {}

  transform(roles: Role[], applyFilter: 'ONLY_ASSIGNABLE' | 'ALL' = 'ALL', separator = ', '): string {
    if (!roles || roles.length === 0) {
      return '';
    }
    if (applyFilter === 'ONLY_ASSIGNABLE') {
      roles = roles.filter((role) => role.assignable);
    }
    return this.i18nextArrayByKeyPipe.transform(roles, 'translationKey', separator) as string;
  }
}
