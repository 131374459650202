<h2>Tabnav</h2>
<p>
  The <em>tabnav component</em> contains a list of <em><b>navItems</b> (Array of strings)</em>, for the
  <em>tabnav</em> to function properly it is important to setup the child routes accordingly (see examples below). In
  addition to a list op tabs it is also possible to display a button by passing a value to <b><em>btnName</em></b
  >. You can bind a callback to the button's emitter named <b><em>btnActionEmitter</em></b
  >. The examples below cover everything you need to make use of te <em>tabnav component</em> properly. <br /><br />
  <b>Styling note:</b> The <em>tabnav component</em> takes the width of its parent, this means that you get to size the
  tabnav to match your own requirements by making use of a container div.
</p>
<hr />
<p>
  Notice that in the example below there is a container named tabnav-contianer wrapping the
  <em>nxh-tabnav component</em>
</p>
<pre><code [highlight]="tabNavComponentCodeSnippet" language="html"></code>></pre>
<hr />
<h4>Router setup</h4>
<p>
  Underneath the <em>tabnav-container</em> in the code snippet above you can see that there is also a
  <b>router outlet</b>. The router outlet will display the selected child path. You can wrap the router outlet in a
  container to configure its size. For the <b>router outlet</b> to know what component should be displayed it is
  required to specify the child paths in the router module, see snippet below. If you want the first child component to
  be selected make sure to route to <em>'tab-nav/tab1'</em> otherwise no tab will be selected.
</p>
<pre><code [highlight]="routerModuleConfig" language="typescript"></code>></pre>
