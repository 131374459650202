import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'nxh-phone-number-demo',
  templateUrl: './phone-number-demo.component.html',
  styleUrls: ['./phone-number-demo.component.scss'],
})
export class PhoneNumberDemoComponent {
  typeForm = new UntypedFormGroup({
    phoneType: new UntypedFormControl('fixed'),
  });

  phoneForm = new UntypedFormGroup({
    phone: new UntypedFormControl(),
  });

  constructor() {}

  submit(fh) {
    const request = () => of(true);
    fh.submit(this.phoneForm, request)
      .pipe(delay(1000))
      .subscribe({
        next: () => {},
      });
  }

  setPhoneNumber() {
    this.phoneForm.get('phone').setValue('+32473737373');
  }
}
