<div class="card theme">
  <div class="top">Click a color to see usage and details.</div>
  <div class="middle">
    <div
      [ngStyle]="{ background: color.color }"
      class="color"
      (click)="selectColor(color); open(content)"
      *ngFor="let color of themeColors"
    >
      <p>{{ color.title }}<br />{{ color.hex }}</p>
    </div>
  </div>
</div>
<div class="card theme">
  <div class="top">All variables</div>
  <div class="middle">
    <pre><code>
          //colorVariants
          <div class="cb"><div class="colorblock" style="background-color: var(--lightOrangeWashedColor);"></div> <p>--lightOrangeWashedColor: #ffe9c5;</p></div>
          <div class="cb"><div class="colorblock" style="background-color: var(--lightGreenWashedColor);"></div> <p>--lightGreenWashedColor: #cffbc8;</p></div>
          <div class="cb"><div class="colorblock" style="background-color: var(--darkBlueWashedColor);"></div> <p>--darkBlueWashedColor: #c7d9fb;</p></div>
          <div class="cb"><div class="colorblock" style="background-color: var(--lightBlueWashedColor);"></div> <p>--lightBlueWashedColor: #c7f3fb;</p></div>
          <div class="cb"><div class="colorblock" style="background-color: var(--lightRedWashedColor);"></div> <p>--lightRedWashedColor: #fbc7d8;</p></div>
          <div class="cb"><div class="colorblock" style="background-color: var(--redWashedColor);"></div> <p>--redWashedColor: #fbabab;</p></div>
          <div class="cb"><div class="colorblock" style="background-color: var(--purpleWashedColor);"></div> <p>--purpleWashedColor: #dbc7fb;</p></div>
          <div class="cb"><div class="colorblock" style="background-color: var(--yellowWashedColor);"></div> <p>--yellowWashedColor: #fff5b2;</p></div>
          <div class="cb"><div class="colorblock" style="background-color: var(--greyWashedColor);"></div> <p>--greyWashedColor: var(--gray-200);</p></div>

          // gray
          --white: #fff;
          --gray-100: #FAFCFE;
          --gray-200: #F5F8FB;
          --gray-300: #F2F6F9;
          --gray-400: #E5EBEF;
          --gray-500: #A6B2C4;
          --gray-600: #68798E;
          --gray-700: #4E5F70;
          --gray-800: #0C1827;

          // body
          --bodyBGColor: #f3f7fa;
          --bodyTextColor: #0C1827;

          // header
          --headerBGColor: white;
          --headerBorderColor: #E5EBEF;
          --headerNavItemColor: #4E5F70;
          --headerBoxShadowColor: #F2F6F9;

          // navbar
          --navbarHoverColor: #F5F8FB;

          // expandable sidebar navigation
          --sidebarNavActiveColor: #e2edf1;
          --sidebarBGColor: transparent;

          // patient context bar
          --patientbarBGColor: #FAFCFE;
          --patientbarTextColor: #68748E;

          // cards
          --cardBGColor: #fafcfd;

          // forms
          --formLabelColor: #9096AA;

          // buttons
          --buttonDisabledColor: #A6AFC4;
      </code></pre>
  </div>
</div>
<ng-template #content let-modal>
  <nxh-color-detail [selectedColor]="selectedColor"></nxh-color-detail>
</ng-template>
