import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { CompactFormControlDirectiveModule } from '@nexuzhealth/shared-ui-toolkit/compact-form-control';
import { ValidationModule } from '@nexuzhealth/shared-ui-toolkit/validation';
import { TimePickerInputComponent } from './time-picker-input.component';

@NgModule({
  imports: [
    CommonModule,
    SharedTechFeatureE2eModule,
    CompactFormControlDirectiveModule,
    ValidationModule,
    SharedUtilI18nModule,
  ],
  exports: [TimePickerInputComponent],
  declarations: [TimePickerInputComponent],
})
export class TimePickerInputModule {}
