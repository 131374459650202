import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedReferenceDataDataAccessModule } from '@nexuzhealth/shared-reference-data-data-access';
import { SelectsModule } from '@nexuzhealth/shared-ui-toolkit/selects';
import { I18NextOptionPipeModule } from '@nexuzhealth/shared-ui-toolkit/pipes/i18next-option';
import {
  SelectAdministrativeGenderComponent,
  SelectCityComponent,
  SelectCountryComponent,
  SelectHealthCareWorkerProfessionComponent,
  SelectLanguageComponent,
  SelectMaritalStatusComponent,
  SelectMediumComponent,
  SelectProfessionComponent,
} from './components';
import { SelectNationalityComponent } from './components/select-nationality/select-nationality.component';

const components = [
  SelectAdministrativeGenderComponent,
  SelectCityComponent,
  SelectCountryComponent,
  SelectHealthCareWorkerProfessionComponent,
  SelectLanguageComponent,
  SelectMaritalStatusComponent,
  SelectMediumComponent,
  SelectNationalityComponent,
  SelectProfessionComponent,
];

@NgModule({
  imports: [CommonModule, SelectsModule, SharedReferenceDataDataAccessModule, I18NextOptionPipeModule],
  declarations: [...components],
  exports: [...components],
})
export class SharedReferenceDataFeatureReferenceDataModule {}
