import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { HotkeysComponent } from './hotkeys.component';

export type Options = {
  element: any;
  description: string | undefined;
  keys: string;
};

@Injectable({
  providedIn: 'root',
})
export class HotkeysService {
  hotkeys = new Map();
  defaults: Partial<Options> = {
    element: this.document,
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private eventManager: EventManager,
    private modalService: NgbModal,
  ) {
    this.addShortcut({ keys: 'control.shift.?' }).subscribe(() => {
      this.openHelpModal();
    });
  }

  addShortcut(options: Partial<Options>) {
    const merged = { ...this.defaults, ...options };
    const event = `keydown.${merged.keys}`;

    if (merged.description) {
      this.hotkeys.set(merged.keys, merged.description);
    }

    return new Observable((observer) => {
      const handler = (e: Event) => {
        e.preventDefault();
        observer.next(e);
      };

      const dispose = this.eventManager.addEventListener(merged.element, event, handler);

      return () => {
        dispose();
        this.hotkeys.delete(merged.keys);
      };
    });
  }

  openHelpModal() {
    setTimeout(() => {
      const modalRef = this.modalService.open(HotkeysComponent);
      modalRef.componentInstance.hotkeys = this.hotkeys;
    });
  }
}
