<h2>ellipsis button</h2>

<h2>Icon buttons with ellipsis dropdown for more actions</h2>
<p></p>
<div ngbDropdown class="icons hide-default-down-icon">
  <button nxh-button buttonType="icon" ngbTooltip="Print action">
    <fa-icon icon="print"></fa-icon>
  </button>
  <button nxh-button buttonType="icon" ngbTooltip="Edit action">
    <fa-icon icon="pen"></fa-icon>
  </button>
  <button nxh-button buttonType="icon" ngbTooltip="Remove action">
    <fa-icon icon="times"></fa-icon>
  </button>
  <button nxh-button buttonType="icon" ngbTooltip="More..." ngbDropdownToggle>
    <fa-icon icon="ellipsis-h"></fa-icon>
  </button>
  <div ngbDropdownMenu>
    <button class="btn" ngbDropdownItem>
      <fa-icon icon="pills"></fa-icon>
      <span>Default Action</span>
    </button>
    <button class="btn dropdown-item-primary" ngbDropdownItem>
      <fa-icon icon="ruler"></fa-icon>
      <span>Primary Action</span>
    </button>
    <button class="btn dropdown-item-success" ngbDropdownItem>
      <fa-icon icon="heart-rate"></fa-icon>
      <span>Success Action</span>
    </button>
    <button class="btn dropdown-item-warning" ngbDropdownItem>
      <fa-icon icon="heart-rate"></fa-icon>
      <span>Warning Action</span>
    </button>
    <button class="btn dropdown-item-danger" ngbDropdownItem>
      <fa-icon icon="trash-alt"></fa-icon>
      <span>Danger action</span>
    </button>
  </div>
</div>
<pre><code [highlight]="iconCode" language="html"></code></pre>

<h2>Icon buttons with ellipsis dropdown for more actions in a table</h2>
<p></p>
<div class="card">
  <div class="card-body card-body--no-padding">
    <table class="table" cdk-table [dataSource]="tableData">
      <!-- User name Definition -->
      <ng-container cdkColumnDef="name">
        <th cdk-header-cell *cdkHeaderCellDef>name</th>
        <td cdk-cell *cdkCellDef="let row">{{ row.name }}</td>
      </ng-container>

      <ng-container cdkColumnDef="lastName">
        <th cdk-header-cell *cdkHeaderCellDef>last name</th>
        <td cdk-cell *cdkCellDef="let row">{{ row.lastName }}</td>
      </ng-container>

      <ng-container cdkColumnDef="reason">
        <th cdk-header-cell *cdkHeaderCellDef>reason</th>
        <td cdk-cell *cdkCellDef="let row">{{ row.reason }}</td>
      </ng-container>

      <ng-container cdkColumnDef="actions">
        <th cdk-header-cell *cdkHeaderCellDef></th>
        <td cdk-cell *cdkCellDef="let row">
          <div ngbDropdown class="actions-container hide-default-down-icon">
            <button nxh-button buttonType="icon" ngbTooltip="Print action">
              <fa-icon icon="print"></fa-icon>
            </button>
            <button nxh-button buttonType="icon" ngbTooltip="Edit action">
              <fa-icon icon="pen"></fa-icon>
            </button>
            <button nxh-button buttonType="icon" ngbTooltip="Remove action">
              <fa-icon icon="times"></fa-icon>
            </button>
            <button nxh-button buttonType="icon" ngbTooltip="More..." ngbDropdownToggle>
              <fa-icon icon="ellipsis-h"></fa-icon>
            </button>
            <div ngbDropdownMenu>
              <button class="btn" ngbDropdownItem>
                <fa-icon icon="pills"></fa-icon>
                <span>Default Action</span>
              </button>
              <button class="btn dropdown-item-primary" ngbDropdownItem>
                <fa-icon icon="ruler"></fa-icon>
                <span>Primary Action</span>
              </button>
              <button class="btn dropdown-item-success" ngbDropdownItem>
                <fa-icon icon="heart-rate"></fa-icon>
                <span>Success Action</span>
              </button>
              <button class="btn dropdown-item-warning" ngbDropdownItem>
                <fa-icon icon="heart-rate"></fa-icon>
                <span>Warning Action</span>
              </button>
              <button class="btn dropdown-item-danger" ngbDropdownItem>
                <fa-icon icon="trash-alt"></fa-icon>
                <span>Danger action</span>
              </button>
            </div>
          </div>
        </td>
      </ng-container>
      <!-- Header and Row Declarations -->
      <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
      <tr
        [ngClass]="{ active: activeId === i }"
        (click)="activeId = i"
        cdk-row
        *cdkRowDef="let row; columns: displayedColumns; let i = index"
      ></tr>
    </table>
  </div>
</div>
<pre><code [highlight]="tableCode" language="html"></code></pre>
