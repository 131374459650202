import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { NotFoundModule } from '@nexuzhealth/shared-ui-toolkit/not-found';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { PageNotAuthorizedComponent } from './components/page-not-authorized/page-not-authorized.component';
import { FilterUnauthorizedPipe } from './components/filter-unauthorized.pipe';
import { IsAuthorizedDirective } from './components/is-authorized.directive';

@NgModule({
  imports: [CommonModule, SharedUtilI18nModule, RouterModule, NotFoundModule, ButtonModule],
  declarations: [PageNotAuthorizedComponent, FilterUnauthorizedPipe, IsAuthorizedDirective],
  exports: [PageNotAuthorizedComponent, FilterUnauthorizedPipe, IsAuthorizedDirective],
})
export class SharedAuthenticationFeatureAuthorizationModule {}
