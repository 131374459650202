<nxh-alert type="warning" alertTitle="For last documentation check Storybook">
  <nxh-alert-description>
    <a href="https://storybook.nexuzhealth.io/?path=/docs/forms-radio-buttons-and-checkboxes--default" target="_blanc"
      >Storybook</a
    >
  </nxh-alert-description>
</nxh-alert>

<h1>radio and checkboxes</h1>

<h2>checkboxes</h2>

<form [formGroup]="mathForm" nhForm #f="nhForm" (ngSubmit)="submitMathForm(f.helper)">
  <nh-fieldset>
    <p>
      Use a <code>nh-checkbox</code>s and group them with an <code>nh-checkbox-group</code>. Change the [direction] from
      'col' to 'row' for a different layout. Default is 'col'
    </p>

    <nh-checkbox-group [label]="'mathematicians'" formGroupName="mathematicians" [direction]="'col'">
      <nh-checkbox [label]="'euler'" formControlName="euler"></nh-checkbox>
      <nh-checkbox [label]="'hardy'" formControlName="hardy"></nh-checkbox>
      <nh-checkbox [label]="'ramanujan'" formControlName="ramanujan"></nh-checkbox>
    </nh-checkbox-group>

    <pre>
    <code language="html" [highlight]="checkboxCode"></code>
  </pre>

    <hr />

    <p>When there is validation on the group, embed a <code>val-errors</code> component:</p>

    <nh-checkbox-group [label]="'numbers'" formGroupName="numbers" [direction]="'row'">
      <nh-checkbox [label]="'pi'" formControlName="pi"></nh-checkbox>
      <nh-checkbox [label]="'perfect number'" formControlName="perfect"></nh-checkbox>
      <val-errors>
        <ng-template valError="minimum-one">select at least 1 number</ng-template>
      </val-errors>
    </nh-checkbox-group>

    <pre>
    <code language="html" [highlight]="checkboxWithValidationCode"></code>
  </pre>

    <hr />

    <p>
      For a more complex layout, you can use <code>nh-row</code> and <code>nh-col</code>s within the checkbox-group:
    </p>

    <nh-checkbox-group [label]="'domains'" formGroupName="domains">
      <div class="nh-row">
        <div class="nh-col">
          <nh-checkbox [label]="'number and quantity'" formControlName="numberAndQuantity"></nh-checkbox>
          <nh-checkbox [label]="'functions'" formControlName="functions"></nh-checkbox>
          <nh-checkbox [label]="'modeling'" formControlName="modeling"></nh-checkbox>
          <nh-checkbox [label]="'geometry'" formControlName="geometry"></nh-checkbox>
        </div>
        <div class="nh-col">
          <nh-checkbox [label]="'statistics and probability'" formControlName="statisticsAndProbability"></nh-checkbox>
          <div class="checkbox-other__container">
            <nh-checkbox [label]="'other'" formControlName="other"></nh-checkbox>
            <nh-control *ngIf="!!mathForm.get('domains.otherDomain')">
              <input type="text" formControlName="otherDomain" [placeholder]="'please specify'" [nxhFocus]="true" />
              <val-errors [label]="'value' | i18next"></val-errors>
            </nh-control>
          </div>
        </div>
      </div>
    </nh-checkbox-group>

    <p>
      Also note how we provide the possibility to add more specific information when you tick off the 'other' option.
    </p>

    <pre>
    <code language="html" [highlight]="checkboxWithOtherCode"></code>
  </pre>

    <hr />

    <p>
      It is also possible to use an <code>nh-checkbox</code> as standalone component. Set [switch]="true" to attain the
      "switch" look.
    </p>

    <nh-checkbox [switch]="true" [label]="'Subscribe to our newsletter?'" formControlName="newsletter">
      <val-errors [label]="'value' | i18next"></val-errors>
    </nh-checkbox>

    <pre>
      <code language="html" [highlight]="checkboxStandaloneCode"></code>
    </pre>

    <nxh-alert type="info">
      <nxh-alert-description>
        As an aside, ToggleSwitchComponent is another fancy checkbox. It is not yet a ControlValueAccessor, so it is
        (currently?) not meant to use it in a form. It is a great way to toggle certain elements on your page though.
      </nxh-alert-description>
    </nxh-alert>

    <nxh-toggle-switch iconLeft="calendar-alt" iconRight="list"></nxh-toggle-switch>

    <pre>
      <code language="html" [highlight]="toggleSwitchCode"></code>
    </pre>

    <hr />

    <h2>radio buttons</h2>

    <p>
      Use a <code>nh-radio</code>s and group them with an <code>nh-radio-group</code>. Change the [direction] from 'col'
      to 'row' for a different layout. Default is 'col'
    </p>

    <nh-radio-group [label]="'object'" formControlName="object" [direction]="'row'">
      <nh-radio *ngFor="let option of options" [label]="option" [value]="option"></nh-radio>
      <val-errors></val-errors>
    </nh-radio-group>

    <pre>
    <code language="html" [highlight]="radioButtonCode"></code>
  </pre>

    <hr />
  </nh-fieldset>
  <nh-submit-button></nh-submit-button>
</form>

<pre
  >{{ mathForm.value | json }}
</pre>

<h2>bare bones</h2>

<p>
  In case you need to build this without the new form framework, for example to do a quick fix in old code, you can fall
  back on the bootstrap classes:
</p>

<pre>
  <code language="html" [highlight]="deprecatedCheckboxCode"></code>
</pre>

<pre>
  <code language="html" [highlight]="deprecatedRadioCode"></code>
</pre>
