import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { IconName } from '@fortawesome/fontawesome-common-types';

export type StatusMessageType = 'warning' | 'error' | 'success' | 'loading' | 'information';

const iconLookup: Record<StatusMessageType, IconName> = {
  warning: 'triangle-exclamation',
  error: 'hexagon-exclamation',
  success: 'check-circle',
  loading: 'spinner',
  information: 'info-circle',
};

@Component({
  selector: 'nxh-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusMessageComponent {
  @Input() type!: StatusMessageType;
  @Input() small = false;

  get iconName(): IconName {
    return iconLookup[this.type];
  }

  get iconSpin(): boolean {
    const loadingType: StatusMessageType = 'loading';
    return this.type === loadingType;
  }
}
