import { Component } from '@angular/core';

@Component({
  selector: 'nxh-counter-demo',
  templateUrl: './counter-demo.component.html',
  styleUrls: ['./counter-demo.component.scss'],
})
export class CounterDemoComponent {
  code1 = `
    <nxh-counter [value]="6">
      <div>hallloooo</div>
    </nxh-counter>
  `;

  code2 = `
    <nxh-counter [value]="6"></nxh-counter>
  `;

  code3 = `
    <nxh-counter [value]="3456545" [maxDigits]="3">
      <div>hallloooo</div>
    </nxh-counter>
    `;

  code4 = `
    <nxh-counter [value]="6" [statusMap]="{ success: 5, danger: 8 }">
      <div>hallloooo</div>
    </nxh-counter>
    `;

  code5 = `
    <nxh-counter [value]="10" [statusMap]="{ success: 5, danger: 8 }">
      <div>hallloooo</div>
    </nxh-counter>
  `;

  code6 = `
    <nxh-counter [value]="254" [position]="'right'">
      <div>hallloooo</div>
    </nxh-counter>
    `;

  code7 = `
    <nxh-counter [value]="254" [position]="'left'">
      <div>hallloooo</div>
    </nxh-counter>
      `;

  code8 = `
    <nxh-tabnav [topLevel]="true">
      <nxh-tab [link]="'tab1'">tab 1</nxh-tab>
      <nxh-tab [link]="'tab2'">
        <nxh-counter [value]="324">tab 2</nxh-counter>
      </nxh-tab>
    </nxh-tabnav>
  `;
}
