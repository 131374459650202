import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { AssignedId, AssignedIdTypenameEnum, Practitioner, ResourceName } from '@nexuzhealth/shared-domain';
import { PractitionerService } from '@nexuzhealth/shared/practitioner/data-access';
import { FOCUSSABLE } from '@nexuzhealth/shared-ui-toolkit/focus';
import { BaseSearchComponent, SearchOption, SearchOptionMapper } from '@nexuzhealth/shared-ui-toolkit/selects';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { I18NextPipe } from 'angular-i18next';
import { formatPersonName } from '@nexuzhealth/shared-util';
import { PractitionerSearchModalOptions } from '../practitioner-search-modal/practitioner-search-modal-options';
import { PractitionerSearchModalComponent } from '../practitioner-search-modal/practitioner-search-modal.component';

@Component({
  selector: 'nxh-practitioner-search',
  templateUrl: './practitioner-search.component.html',
  styleUrls: ['./practitioner-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PractitionerSearchComponent),
      multi: true,
    },
    { provide: FOCUSSABLE, useExisting: forwardRef(() => PractitionerSearchComponent) },
  ],
})
export class PractitionerSearchComponent
  extends BaseSearchComponent<Practitioner>
  implements SearchOptionMapper<Practitioner>, OnInit
{
  @Input() placeholder = '_practitioner-search.placeholder';
  /**
   * Use this to show a loading spinner. Typically used when showing an initial value, and the
   * value's description hasn't been loaded yet.
   */
  @Input() set loading(loading: boolean) {
    this.loading$.next(loading);
  }
  @HostBinding('class.multiple') @Input() multiple = false;
  @Input() search = false;
  @Input() professions: ResourceName[];
  @Input() profession: ResourceName;
  @Input() names: string[] = [];
  addTagFn;
  RIZIV = AssignedIdTypenameEnum.BE_RIZIV;
  faInfoCircle = faInfoCircle;

  constructor(
    private practitionerService: PractitionerService,
    private i18next: I18NextPipe,
    private cdRef: ChangeDetectorRef,
    private injector: Injector,
    private modalService: NgbModal,
  ) {
    super(cdRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.addTagFn = (name) => {
      this.openModal();
    };
  }

  openModal() {
    const injector = Injector.create({
      providers: [
        {
          provide: PractitionerSearchModalOptions,
          useValue: <PractitionerSearchModalOptions>{
            professions: this.profession ? [this.profession] : this.professions,
          },
        },
      ],
      parent: this.injector,
    });

    from(
      this.modalService.open(PractitionerSearchModalComponent, {
        injector,
        size: 'xl',
      }).result,
    ).subscribe((res) => {
      if (res) {
        const option = this.createOption(res);
        this.selectComponent.writeValue(option);
        this.onChange(option);
      }
    });
  }

  createOption(practitioner: Practitioner): PractitionerOption {
    return {
      formattedPersonName: formatPersonName(practitioner.personName),
      assignedIds: practitioner.assignedIds,
      practice: practitioner.professionCode,
      value: practitioner,
    };
  }

  trackOption(option: PractitionerOption): string {
    return option?.value?.name;
  }

  getCompareOptions() {
    return (left: PractitionerOption, right: PractitionerOption) => {
      return left?.value?.name === right?.value?.name;
    };
  }

  protected autocomplete(term): Observable<Practitioner[]> {
    if (this.profession) {
      this.professions = [this.profession];
    }
    return this.practitionerService.searchPractitioners(term, this.professions, 20, this.names).pipe(
      map(({ data }) => {
        return data.map((item) => {
          const professions = item?.professions ?? [];
          return {
            ...item,
            professionCode: professions[0]?.professionCode.toLowerCase().replace('references/', ''),
          };
        });
      }),
    );
  }
}

interface PractitionerOption extends SearchOption<Practitioner> {
  specialty?: string;
  formattedPersonName: string;
  assignedIds: AssignedId[];
  value: Practitioner;
}
