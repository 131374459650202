// this is a workaround for https://nexuzhealth.atlassian.net/browse/GP-9108
// the underlying issue is this bug in chromeOS https://issuetracker.google.com/issues/323433766
// in short: in chrome, when drag-dropping a file with extension .p12, its mimetype is not correctly recognized as pkcs12
//
// when the chrome bug is fixed:
// 1. delete this file
// 2. remove any depending code and revert to using file.type where needed
// 3. please test drag and drop again to make sure it really works without this hack
// 4. drink coffee and rejoice in having made the codebase smaller

import { HttpHeaders } from '@angular/common/http';

export interface FileWithCoercedContentType {
  CoerceExtensions?: Record<string, string>;
}

export function getCoercedContentTypeIfNeeded(file: File): string | undefined {
  const ff = file as FileWithCoercedContentType;
  if (ff.CoerceExtensions) {
    return ff.CoerceExtensions[file.name?.split('.').pop()];
  }
  return null;
}

export function addCoercedContentTypeToHeaders(file: File, headers: HttpHeaders) {
  let result = headers;
  const coerce = getCoercedContentTypeIfNeeded(file);
  if (coerce) {
    result = result.set('content-type', coerce);
  }
  return result;
}

export function addCoercedContentTypeToFile(file: File, coerce: FileWithCoercedContentType) {
  if (coerce.CoerceExtensions) {
    file['CoerceExtensions'] = coerce.CoerceExtensions;
  }
}
