import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { ComponentRef, ElementRef, InjectionToken, TemplateRef, Type } from '@angular/core';
import { filterNullOrUndefined } from '@nexuzhealth/shared-util';
import { Subject } from 'rxjs';

// eslint-disable-next-line
export interface PopoverComponent {}

export type PopoverContent = TemplateRef<any> | Type<any> | string | ComponentRef<any>;

export interface PopoverActions {
  [methodName: string]: (...args: any[]) => void;
}

// eslint-disable-next-line
export type PopoverConfig = {
  /**
   * Width of the PopoverComponent.
   */
  width?: string | number;

  /**
   * Height of the PopoverComponent
   */
  height?: string | number;

  /**
   * Specifies the origin of the modal, so the PopoverComponent can be positioned near by this origin component. Eg.
   * for an autocomplete or data picker. When not given, the PopoverComponent will be displayed in the middle of the
   * screen
   */
  origin?: ElementRef<any> | HTMLElement;

  /**
   * Specifies the content to be embedded in the default SimplePopoverComponent or the given containerComponent
   */
  content?: PopoverContent;

  /**
   * Used in combination with the content attribute. Defaults to SimplePopoverComponent
   */
  container?: ComponentType<PopoverComponent>;

  /**
   * Specifies the PopoverComponent, overrides the default PopoverComponent. Note that PopoverComponents are responsible
   * for their own content, so it doesn't make sense to use this in combination with the content attribute!
   */
  component?: ComponentType<PopoverComponent>;

  /**
   * The tokens to be injected in the PopoverComponent. Typically only used when you specify your own PopoverCompoent
   */
  customTokens?: WeakMap<any, any>;

  /**
   * Data that can is injected in the PopoverComponent
   */
  data?: any;

  /**
   * When hasBackdrop is false, this property denotes whether clicks outside the popup result in the popup being closed or not
   */
  allowClicksOutside?: boolean;

  /**
   * Distance the popup will be positioned from the bottom.
   */
  bottom?: string;

  /**
   * Distance the popup will be positioned from the bottom.
   */
  top?: string;

  hasBackdrop?: boolean;

  /**
   * Css class for the backdrop. Defaults to 'popover-backdrop'
   */
  backdropClass?: string;

  /**
   * Css class for the popup. Defaults to ''
   */
  panelClass?: string | string[];

  scrollStrategy?: 'block' | 'reposition' | 'noop' | 'close';

  positions?: PopoverPosition[];

  viewportMargin?: number;
};

export const POPOVER_CONTENT = new InjectionToken<PopoverContent>('PopoverContent');
export const POPOVER_DATA = new InjectionToken<any>('PopoverData');
export const POPOVER_ACTIONS = new InjectionToken<any>('PopoverActions');

export type PopoverPosition =
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'start-bottom-start-top'
  | 'start-top-start-top'
  | 'start-top-start-bottom';

export class PopoverRef<T = any> {
  private closedSubj = new Subject<T | undefined>();
  /**
   * Can contain a result. Subscribe to this observable in case you don't care whether there is a result or not
   */
  closed$ = this.closedSubj.asObservable();
  /**
   * Only called when there is a result.
   */
  result$ = this.closed$.pipe(filterNullOrUndefined);
  private mouseEnterSubj = new Subject<HTMLElement>();
  /**
   * Mouse has entered the DetailBubble and started hovering the bubble.
   */
  mouseEnter$ = this.mouseEnterSubj.asObservable();
  private mouseLeaveSubj = new Subject<void>();
  /**
   * Mouse has left the DetailBubble and stopped hovering the bubble.
   */
  mouseLeave$ = this.mouseLeaveSubj.asObservable();

  constructor(public overlay: OverlayRef) {}

  get closed() {
    return this.closedSubj.isStopped;
  }

  close(result?: T) {
    this.overlay.dispose();
    this.closedSubj.next(result);
    this.closedSubj.complete();
  }

  sendAction(name: T) {
    this.closedSubj.next(name);
  }

  onMouseEnter(origin: HTMLElement) {
    this.mouseEnterSubj.next(origin);
  }

  onMouseLeave() {
    this.mouseLeaveSubj.next();
  }
}

export interface PopoverAction<T> {
  result: T;
  onSuccess?: () => void;
  onError?: (err: any) => void;
}
