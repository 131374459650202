import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BulletComponent } from './bullet.component';
import { BulletDescriptionDirective } from './bullet-description.directive';

@NgModule({
  imports: [CommonModule, SharedUtilI18nModule, NgbTooltipModule],
  exports: [BulletComponent, BulletDescriptionDirective],
  declarations: [BulletComponent, BulletDescriptionDirective],
})
export class BulletModule {}
