<nxh-line-chart [labels]="labels" [datasets]="values" [colors]="colors"></nxh-line-chart>
<br />

<div class="card">
  <table class="table">
    <thead>
      <tr>
        <th>Date</th>
        <th>Blood values</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of tableData">
        <td>{{ item.date }}</td>
        <td>{{ item.bloodValue1 }} / {{ item.bloodValue2 }}</td>
      </tr>
    </tbody>
  </table>
</div>
