<div class="card card--modal">
  <div class="card-header">
    <div class="card-header__title">{{ selectedColor.title }}</div>
  </div>
  <div class="card-body">
    <div class="placeholder" [ngStyle]="{ background: selectedColor.color }"></div>
    <h3>{{ selectedColor.hex }} | {{ selectedColor.title }}</h3>
    <p>{{ selectedColor.description }}<br /><br /><small>Usage:</small></p>
    <pre><code language="scss" [highlight]="selectedColor.usage"></code>></pre>
  </div>
</div>
