import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const contactPointCMRegex = /^\d{3}$/;
export const cbeMask = '0000.000.000';

export function cbeModuloValidator(ignoreLength = true): ValidatorFn {
  return (control) => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const cbe = control.value.replace(/\D/g, '');
    if (cbe.length !== 10 && ignoreLength) {
      return null;
    }

    const modulo = 97 - (parseInt(cbe.substring(0, 8), 10) % 97);

    if (modulo === parseInt(cbe.substring(8), 10)) {
      return null;
    }

    return { 'cbe-modulo': { message: 'invalid-modulo' } };
  };
}

export function rizivNumberValidator(control: AbstractControl): ValidationErrors | null {
  const val = control.value;
  if (!val) {
    return null;
  }

  if (!val.match(/^([0-9]{8})$|^([0-9]{11})$/)) {
    return { 'invalid-riziv': { message: 'invalid-riziv' } };
  }

  const baseNumber = parseInt(val.substring(0, 6), 10);
  const ctrlNumber = parseInt(val.substring(6, 8), 10);

  const isMod97 = 97 - (baseNumber % 97) === ctrlNumber;
  const isMod89 = 89 - (baseNumber % 89) === ctrlNumber;
  const isMod83 = 83 - (baseNumber % 83) === ctrlNumber;
  const isMod79 = 79 - (baseNumber % 79) === ctrlNumber;

  if (isMod97 || isMod89 || isMod83 || isMod79) {
    return null;
  }

  return { 'invalid-riziv': { message: 'invalid-riziv' } };
}
