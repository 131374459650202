import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { AfterViewInit, Directive, ElementRef, EventEmitter, NgZone, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ScrollspyService } from './scrollspy.service';

@Directive({
  selector: '[nxhScrollspyContainer]',
})
export class ScrollspyContainerDirective implements AfterViewInit, OnDestroy {
  @Output() scrolled = new EventEmitter<string>();
  private scrollDispatcher!: ScrollDispatcher;
  private scrollable!: CdkScrollable;

  private emitEvent = true;
  private destroy$ = new Subject<void>();

  constructor(
    private host: ElementRef,
    private zone: NgZone,
    private platform: Platform,
    private scrollspyService: ScrollspyService,
  ) {}

  ngAfterViewInit(): void {
    this.scrollDispatcher = new ScrollDispatcher(this.zone, this.platform, document);
    this.scrollable = new CdkScrollable(this.host, this.scrollDispatcher, this.zone);
    this.scrollDispatcher.register(this.scrollable);
    this.scrollDispatcher.scrolled(500).subscribe(() => {
      // typically when you click a link, we don't need to emit the event, as we already set the link 'active'
      if (!this.emitEvent || this.scrollspyService.scrollTargets.length === 0) {
        return;
      }

      const offset = this.scrollable.measureScrollOffset('top');

      const sts = this.scrollspyService.scrollTargets.filter((st: any) => st.offsetTop > offset);
      if (sts.length > 0) {
        sts.sort((left: any, right: any) => left.offsetTop - right.offsetTop);
        this.zone.run(() => {
          sts[0].scrolledInViewPort();
        });
      }
    });

    this.scrollspyService.scrollEvents$.pipe(takeUntil(this.destroy$)).subscribe(({ id, options }) => {
      this.scrollTo(id, options);
    });
  }

  scrollTo(id: string, { emitEvent = true }: { emitEvent: boolean }) {
    this.emitEvent = emitEvent;

    const scrollspyTarget = this.scrollspyService.find(id);
    if (scrollspyTarget) {
      const top = scrollspyTarget.offsetTop - this.host.nativeElement.offsetTop;
      this.scrollable.scrollTo({ top, behavior: 'smooth' });
    }

    if (!emitEvent) {
      setTimeout(() => (this.emitEvent = true), 1000);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
