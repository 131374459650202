import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { HealthCareProfession, ListType } from '@nexuzhealth/shared-domain';

export interface HealthcareworkerProfessionsState {
  professionsPerListType: {
    [key: string]: HealthCareProfession[];
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'healthcare-professions', resettable: true })
export class HealthcareworkerProfessionsStore extends Store<HealthcareworkerProfessionsState> {
  constructor() {
    super({
      professionsPerListType: {},
    });
  }

  storeProfessionsForListType(listType: ListType, professions: HealthCareProfession[]): void {
    this.update((state) => {
      return {
        professionsPerListType: {
          ...state.professionsPerListType,
          [listType]: professions,
        },
      };
    });
  }
}
