import { AbstractControl, ValidatorFn } from '@angular/forms';

const fromDateRequired: ValidatorFn = (control: AbstractControl) => {
  const range = control.value;
  if (!range) {
    return { 'date-range-picker.from-required': { label: 'start-date' } };
  }
  return !range.fromDate ? { 'date-range-picker.from-required': { label: 'start-date' } } : null;
};

const required: ValidatorFn = (control: AbstractControl) => {
  const range = control.value;
  if (!range) {
    return { 'date-range-picker.required': true };
  }
  return !range.fromDate ? { 'date-range-picker.required': true } : null;
};

export const DateRangeValidators = {
  fromDateRequired,
  required,
};
