<div [class.disabled]="disabled$$ | async" class="group" [class.group--row]="direction === 'row'">
  <label [class.required]="required" [class.disabled]="disabled$$ | async" *ngIf="label">{{ label }}</label>
  <div class="group__control-group">
    <div class="group__control-group__controls" [class.group__control-group__controls--no-gap]="noGap">
      <ng-content />
    </div>
    <div class="group__control-group__errors">
      <ng-content select="val-errors" />
      <val-errors [control]="formGroup" *ngIf="errorsComponent.length === 0" />
    </div>
  </div>
</div>
