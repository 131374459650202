import { inject, Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { CollapseService } from './collapse.service';

@Injectable()
export class ChevronService {
  icon$: Observable<IconName> = inject(CollapseService).isOpen$.pipe(
    map((isOpen) => (isOpen ? ('chevron-up' as const) : ('chevron-down' as const))),
  );
}
