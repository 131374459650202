<div class="info">
  <h1 class="hero-title">Progress Bar</h1>
  <p>
    Usage:
    <a href="https://ng-bootstrap.github.io/#/components/progressbar/examples"
      >https://ng-bootstrap.github.io/#/components/progressbar/examples</a
    ><br />
    Inputs:
  </p>
  <ul>
    <li>value: value from 0 - 100</li>
    <li>type: dark, primary, info, warning, danger, succes, secondary</li>
    <li>striped: true/false</li>
    <li>animated: true/false</li>
  </ul>
  <pre><code [highlight]="pbCode" language="html"></code></pre>
</div>
<ngb-progressbar [striped]="striped" [animated]="animated" [value]="value" [type]="type"></ngb-progressbar>
<button [disabled]="disabled" class="btn btn-primary" (click)="run()">Start</button>

<div class="info">
  <h1 class="hero-title">Stateless Progress Bar</h1>
  <pre><code [highlight]="pbCodeStateless" language="html"></code></pre>
</div>
<ngb-progressbar [striped]="true" [animated]="true" [value]="100" type="nxh"></ngb-progressbar>
