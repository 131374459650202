<h1>Line chart</h1>
<p>
  Small wrapper around <a href="https://www.chartjs.org/">chart.js</a>' line chart. These are its most important inputs
  and output:
</p>
<ul>
  <li><b>labels:</b> this is an array of labels you want to give to the x axis</li>
  <li><b>datasets:</b> an array of values you want to show.</li>
  <li><b>tooltips:</b> an array of custom tooltips you want to show.</li>
  <li><b>data:</b> alternatively you can combine labels, datasets and tooltips in a "ChartData" object.</li>
  <li>
    <b>selectPoint:</b> a callback function that gives you an object when you click a point in chart "event, active"
  </li>
</ul>
<pre><code language="html" [highlight]="code"> </code></pre>

<p>You can also configure the colors, these need to be set for <b>every</b> dataset you add</p>
<pre><code language="typescript" [highlight]="changeColorCodeTS"></code></pre>
<pre><code language="html" [highlight]="changeColorCode"></code></pre>

<p>Here are some examples.</p>

<div class="tabnav-container">
  <nxh-tabnav [navItems]="navItems" [topLevel]="true"></nxh-tabnav>
</div>
<router-outlet></router-outlet>
