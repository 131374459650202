<div class="app__container">
  <nxh-navbar [title]="'UI Toolkit'">
    <ng-container class="left">
      <nxh-nav-item-simple *ngFor="let navItem of navItems" [navItem]="navItem"></nxh-nav-item-simple>
    </ng-container>
    >
  </nxh-navbar>
  <button
    nxh-button
    buttonType="icon"
    class="dummy-page__button"
    [routerLink]="'dummy-page'"
    tooltip="Dummy page template"
  >
    <fa-icon icon="plus"></fa-icon>
  </button>
  <div class="page-container" cdk-scrollable>
    <div class="d-flex">
      <div ngbAccordion class="bg-white sidebar">
        <div ngbAccordionItem [collapsed]="false">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Components</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ul class="sidebar-menu">
                <li><a [routerLink]="'alert-message'" routerLinkActive="is-active">Alert messages</a></li>
                <li><a [routerLink]="'back-button'" routerLinkActive="is-active">Back button</a></li>
                <li><a [routerLink]="'breadcrumb'" routerLinkActive="is-active">Breadcrumbs</a></li>
                <li><a [routerLink]="'charts/pijnschaal'" routerLinkActive="is-active">Charts</a></li>
                <li><a [routerLink]="'collapse'" routerLinkActive="is-active">Collapse</a></li>
                <li><a [routerLink]="'confirm'" routerLinkActive="is-active">Confirm</a></li>
                <li><a [routerLink]="'counter-bar-card'" routerLinkActive="is-active">Counter bar card</a></li>
                <li><a [routerLink]="'counter'" routerLinkActive="is-active">Counter</a></li>
                <li><a [routerLink]="'divider'" routerLinkActive="is-active">Divider</a></li>
                <li><a [routerLink]="'dropdown'" routerLinkActive="is-active">Dropdown menu</a></li>
                <li><a [routerLink]="'icon-loader'" routerLinkActive="is-active">Icon Loader</a></li>
                <li><a [routerLink]="'progress-bar'" routerLinkActive="is-active">Progress bar</a></li>
                <li><a [routerLink]="'task-check'" routerLinkActive="is-active">Tasklist Check</a></li>
                <li><a [routerLink]="'toast'" routerLinkActive="is-active">Toast</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div ngbAccordionItem>
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Patterns</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ul class="sidebar-menu">
                <li><a [routerLink]="'ellipsis-button'" routerLinkActive="is-active">Ellipsis button</a></li>
                <li><a [routerLink]="'empty-state'" routerLinkActive="is-active">Empty state</a></li>
                <li><a [routerLink]="'hotkeys'" routerLinkActive="is-active">Hotkeys</a></li>
                <li><a [routerLink]="'loading-states'" routerLinkActive="is-active">Loading states</a></li>
                <li><a [routerLink]="'page-layout'" routerLinkActive="is-active">Page layout</a></li>
                <li>
                  <a [routerLink]="'patterns/ellipsis-with-tooltip'" routerLinkActive="is-active">Ellipsis w/tooltip</a>
                </li>
                <li><a [routerLink]="'scrollspy'" routerLinkActive="is-active">Scrollspy</a></li>
                <li><a [routerLink]="'tab-nav/tab1'" routerLinkActive="is-active">Tabnav</a></li>
                <li><a [routerLink]="'tabs'" routerLinkActive="is-active">Tabs</a></li>
                <li><a [routerLink]="'timeout-state'" routerLinkActive="is-active">Timeout state</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div ngbAccordionItem>
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Forms</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ul class="sidebar-menu">
                <li><a [routerLink]="'autosuggest'" routerLinkActive="is-active">Autosuggest</a></li>
                <li><a [routerLink]="'date-picker'" routerLinkActive="is-active">Date controls</a></li>
                <li><a [routerLink]="'dirty-check'" routerLinkActive="is-active">Dirty check</a></li>
                <li>
                  <a [routerLink]="'disable-submit-on-enter'" routerLinkActive="is-active">Disable Submit on Enter</a>
                </li>
                <li><a [routerLink]="'focus'" routerLinkActive="is-active">Focus</a></li>
                <li><a [routerLink]="'input-list'" routerLinkActive="is-active">Input list</a></li>
                <li><a [routerLink]="'lookforward-focus'" routerLinkActive="is-active">Lookforward focus</a></li>
                <li><a [routerLink]="'nested-forms'" routerLinkActive="is-active">nested forms</a></li>
                <li><a [routerLink]="'password-input'" routerLinkActive="is-active">Password input</a></li>
                <li><a [routerLink]="'phonenumber'" routerLinkActive="is-active">Phonenumber</a></li>
                <li>
                  <a [routerLink]="'radio-checkbox'" routerLinkActive="is-active">Radio and checkboxes (new)</a>
                </li>
                <li><a [routerLink]="'recurrence'" routerLinkActive="is-active">Recurrence (new)</a></li>
                <li><a [routerLink]="'selects'" routerLinkActive="is-active">Selects</a></li>
                <li><a [routerLink]="'submit-button'" routerLinkActive="is-active">Submit button</a></li>
                <li><a [routerLink]="'timepicker'" routerLinkActive="is-active">Timepicker</a></li>
                <li><a [routerLink]="'upload'" routerLinkActive="is-active">Upload</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div ngbAccordionItem>
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Data Visualisation</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ul class="sidebar-menu">
                <li><a [routerLink]="'detail-bubble'" routerLinkActive="is-active">Detail Bubble</a></li>
                <li><a [routerLink]="'calendar'" routerLinkActive="is-active">Calendars</a></li>
                <li><a [routerLink]="'planners'" routerLinkActive="is-active">Planners</a></li>
                <li><a [routerLink]="'pipes'" routerLinkActive="is-active">Pipes</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div ngbAccordionItem>
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Design Kit</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ul>
                <li><a [routerLink]="'/helper-classes'" routerLinkActive="is-active">Helper Classes</a></li>
                <li><a [routerLink]="'grid'" routerLinkActive="is-active">Grid</a></li>
                <li><a [routerLink]="'theme'" routerLinkActive="is-active">Theme colors</a></li>
                <li><a [routerLink]="'typography'" routerLinkActive="is-active">Typography</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div ngbAccordionItem>
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>Guidelines</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ul>
                <li><a [routerLink]="'page-layout'" routerLinkActive="is-active">Page layout</a></li>
                <li><a [routerLink]="'settings'" routerLinkActive="is-active">Settings</a></li>
                <li><a [routerLink]="'timeouts'" routerLinkActive="is-active">Timeouts</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-grow-1 contentbar">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<nxh-default-errors></nxh-default-errors>
