<div class="detail-bubble" [ngClass]="classnames" #origin>
  <div class="arrow-container--horizontal" (click)="close()">
    <div class="arrow arrow--up" (click)="$event.stopPropagation()" #arrowUp></div>
  </div>
  <div class="card-container">
    <div class="arrow-container--vertical" (click)="close()">
      <div class="arrow arrow--left" (click)="$event.stopPropagation()" #arrowLeft></div>
    </div>
    <div class="card" #card>
      <div class="card-header" #header>
        <div class="detail-bubble-header-container">
          <div class="detail-bubble-header-container--main-title-and-actions">
            <div class="card-header__title">
              <fa-icon class="title-icon" *ngIf="bubbleData.icon" [icon]="bubbleData.icon" />
              <div class="detail-bubble__title">
                <ng-container *ngIf="titleRef; else title">
                  <ng-container *ngTemplateOutlet="titleRef" />
                </ng-container>
                <ng-template #title>
                  {{ bubbleData.title }}
                </ng-template>
              </div>
            </div>
            <div class="card-header__controls detail-bubble__controls">
              <ng-container *ngFor="let action of bubbleActions">
                <ng-container [ngSwitch]="action.isLink">
                  <ng-container
                    *ngSwitchCase="true"
                    [ngTemplateOutlet]="bubbleLink"
                    [ngTemplateOutletContext]="{ $implicit: action }"
                  />
                  <ng-container
                    *ngSwitchDefault
                    [ngTemplateOutlet]="bubbleButton"
                    [ngTemplateOutletContext]="{ $implicit: action }"
                  />
                </ng-container>
              </ng-container>
              <button
                nxh-button
                buttonType="icon"
                buttonStatus="neutral"
                [ngbTooltip]="'close' | i18next"
                (click)="close()"
                [nxhDataTest]="bubbleData.title + '-close'"
              >
                <fa-icon icon="times" />
              </button>
            </div>
          </div>
          <div>
            <ng-container *ngIf="bubbleData.subTitleTemplate">
              <ng-container *ngTemplateOutlet="$any(bubbleData.subTitleTemplate)" />
            </ng-container>
          </div>
        </div>
      </div>
      <div class="card-body card-body--no-padding" #body>
        <ng-container [ngSwitch]="renderMethod">
          <div *ngSwitchCase="'text'" [innerHTML]="content"></div>
          <ng-container *ngSwitchCase="'template'">
            <!-- context always present with renderMethod 'template'-->
            <ng-container *ngTemplateOutlet="$any(content); context: context!" />
          </ng-container>
          <ng-container *ngSwitchCase="'component'">
            <ng-container *ngComponentOutlet="$any(content); injector: injector" />
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="arrow-container--vertical" (click)="close()">
      <div class="arrow arrow--right" (click)="$event.stopPropagation()" #arrowRight></div>
    </div>
  </div>
  <div class="arrow-container--horizontal" (click)="close()">
    <div class="arrow arrow--down" (click)="$event.stopPropagation()" #arrowDown></div>
  </div>
</div>

<ng-template #bubbleLink let-action>
  <button
    nxh-button
    buttonType="icon"
    [buttonStatus]="action.status || 'primary'"
    [routerLink]="action.routerLink"
    (click)="close()"
  >
    <fa-icon [icon]="action.icon" />
  </button>
</ng-template>

<ng-template #bubbleButton let-action>
  <ng-container [ngSwitch]="action.confirm">
    <button
      *ngSwitchCase="true"
      nxh-button
      buttonType="icon"
      [buttonStatus]="action.status || 'primary'"
      nxhConfirm
      [showConfirm]="action.confirm"
      [confirmText]="action.confirmText"
      [confirmType]="action.confirmType || 'danger'"
      [busy]="false"
      (confirm)="doActionAndWait(action)"
      [ngbTooltip]="getToolTip(action)"
      [disableTooltip]="(action.disabled | async) === false"
      [disableConfirm]="(action.disabled | async) === true"
      [class]="action.actionName + '-icon'"
      [nxhDataTest]="bubbleData.title + '-' + action.actionName"
    >
      <fa-icon [icon]="action.icon" />
    </button>
    <button
      *ngSwitchDefault
      nxh-button
      buttonType="icon"
      [buttonStatus]="action.status || 'primary'"
      (click)="doAction(action)"
      [ngbTooltip]="getToolTip(action)"
      [disableTooltip]="(action.disabled | async) === false"
      [disabled]="action.disabled | async"
      [class]="action.actionName + '-icon'"
      [nxhDataTest]="bubbleData.title + '-' + action.actionName"
    >
      <fa-icon [icon]="action.icon" />
    </button>
  </ng-container>
</ng-template>
