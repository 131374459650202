import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { LoadingButtonComponent } from './loading-button.component';

@NgModule({
  imports: [CommonModule, SharedUtilI18nModule, IconsModule],
  exports: [LoadingButtonComponent],
  declarations: [LoadingButtonComponent],
})
export class LoadingButtonModule {}
