import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Range } from '@nexuzhealth/shared-domain';
import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { getNgbDateRange, jsDateToNgbDate, NgbDateRange, ngbDateToJsDate } from '../ngb-date-utils';

@Component({
  selector: 'nxh-calendar-button',
  templateUrl: './calendar-button.component.html',
  styleUrls: ['./calendar-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarButtonComponent {
  @Input()
  mode: 'single' | 'range' = 'single';

  /**
   * Prevents highlighting cells that are in range when starting a range by clicking a startDate and hovering to an
   * toDate, as this *might* impact performance (all cells need to be rerendered at each hover). This property is
   * only relevant for mode 'range'
   */
  @Input() noHighlightInRangeOnHover = false;

  /**
   * Prevents highlighting the focused cell. This could be handy in the rare case when you want to highlight a
   * 'range' (e.g. of a whole week) on clicking a particular date. Only relevant for mode 'single'.
   */
  @Input() noHighlightOnFocused = false;

  @Output() dateSelection = new EventEmitter<Date | null>();
  @Output() rangeSelection = new EventEmitter<Partial<Range>>();

  hoveredDate: NgbDate | null = null;
  ngbDateRange?: NgbDateRange | null;
  @Input() date?: Date;

  @Input() set range(range: Partial<Range>) {
    this.ngbDateRange = range
      ? {
          fromDate: range.fromDate ? jsDateToNgbDate(range.fromDate) : null,
          toDate: range.toDate ? jsDateToNgbDate(range.toDate) : null,
        }
      : null;
  }

  get startDate(): NgbDate {
    return this.date ? jsDateToNgbDate(this.date) : this.ngbDateRange?.fromDate ?? jsDateToNgbDate(new Date());
  }

  onDateSelection(date: NgbDate, datepicker: NgbInputDatepicker) {
    if (this.mode === 'range') {
      this.ngbDateRange = getNgbDateRange(date, this.ngbDateRange);
      this.rangeSelection.emit({
        fromDate: this.ngbDateRange.fromDate ? ngbDateToJsDate(this.ngbDateRange.fromDate) : undefined,
        toDate: this.ngbDateRange.toDate ? ngbDateToJsDate(this.ngbDateRange.toDate) : undefined,
      });
    } else {
      this.dateSelection.emit(ngbDateToJsDate(date));
    }
    datepicker.close();
  }

  onMouseEnter(date: NgbDate) {
    if (this.mode === 'range' && !this.noHighlightInRangeOnHover) this.hoveredDate = date;
  }

  onMouseLeave() {
    if (this.noHighlightInRangeOnHover && !this.noHighlightInRangeOnHover) this.hoveredDate = null;
  }

  toggleDatepicker(datepicker: NgbInputDatepicker) {
    datepicker.toggle();
  }
}
