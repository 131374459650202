import { Directive, TemplateRef } from '@angular/core';

export interface ToggleListOption<K = string | boolean | number, T = any> {
  label?: string;
  value: K;
  disabled?: boolean;
  payload?: T;
}

@Directive({
  selector: '[nxhToggleListOption]',
})
export class ToggleListOptionDirective {
  constructor(public template: TemplateRef<any>) {}
}
