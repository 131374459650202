import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable()
export class MockPractitionerApiService {
  searchPractitioners(searchString: string, limit: number = 20) {
    return of({
      data: tobies.filter((toby) =>
        toby.personName.given.toLocaleLowerCase().startsWith(searchString.toLocaleLowerCase()),
      ),
    });
  }
}

const tobies = [
  {
    name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783',
    personName: { prefix: '', nick: '', given: 'Toby 1', otherGivens: [], family: 'Alderweireld', suffix: '' },
    source: 'COBRHA',
    lastModifier: 'UZLEUVEN\\dverst1',
    assignedIds: [
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/identifiers/1b267c48-63a0-43f3-9b25-811a127a1b88',
        typeName: 'enums/common-identifier-type/values/be-inss',
        value: '12345678909',
        sourceType: 'ADMINISTRATOR',
        lastModifier: 'UZLEUVEN\\dverst1',
        source: 'COBRHA',
        sendVersion: '2019-11-05T13:15:33.144210Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/identifiers/95c7581b-e72c-498c-a186-735a4df8aeb2',
        typeName: 'enums/common-identifier-type/values/be-nihii',
        value: '43563193668',
        sourceType: 'ADMINISTRATOR',
        lastModifier: 'UZLEUVEN\\dverst1',
        source: 'COBRHA',
        sendVersion: '2019-11-05T13:15:33.183463Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/identifiers/c052b726-a6e2-4ee9-b697-cd2e074d516b',
        typeName: 'enums/common-identifier-type/values/be-nihii',
        value: '10335943770',
        sourceType: 'ADMINISTRATOR',
        lastModifier: 'UZLEUVEN\\dverst1',
        source: 'COBRHA',
        sendVersion: '2019-11-05T13:15:33.228533Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
    ],
    contexts: [
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/29a3abe9-d817-4bd8-8889-8400949cc900',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/29a3abe9-d817-4bd8-8889-8400949cc900/addresses/0ac1dd27-bb60-4625-84c5-da69b272c09f',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:16:18.171509Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/29a3abe9-d817-4bd8-8889-8400949cc900/contactpoints/46fcbb0b-bf9f-4de9-a89e-8727dc9a79a1',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:16:18.215092Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/29a3abe9-d817-4bd8-8889-8400949cc900/contactpoints/75e29a65-bb8c-4ec8-8c3a-fd7590a0294f',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:16:18.254138Z',
          },
        ],
        sendVersion: '2019-11-05T13:16:18.127408Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/2cbb8afa-9e64-4d3c-bb6b-290e905e1318',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/2cbb8afa-9e64-4d3c-bb6b-290e905e1318/addresses/7eea1905-bfea-4c42-8478-cc14ae8de681',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:23:16.271644Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/2cbb8afa-9e64-4d3c-bb6b-290e905e1318/contactpoints/5d17dd9d-ba6a-440c-a465-bff230ef386c',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:23:16.357807Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/2cbb8afa-9e64-4d3c-bb6b-290e905e1318/contactpoints/81bb263f-a499-4396-b2c0-8542bcfb333b',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:23:16.320354Z',
          },
        ],
        sendVersion: '2019-11-05T15:23:16.220659Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/4cd6f45e-9603-4f71-a670-bda2f3c1e950',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/4cd6f45e-9603-4f71-a670-bda2f3c1e950/addresses/e2264af6-1370-4a20-bc4f-01ea5593ec86',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:44:26.408199Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/4cd6f45e-9603-4f71-a670-bda2f3c1e950/contactpoints/099bd19a-f440-406d-af31-9d61cad949c2',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:44:26.450138Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/4cd6f45e-9603-4f71-a670-bda2f3c1e950/contactpoints/ca283a2a-9a96-4250-b38a-0346c69439f2',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:44:26.484905Z',
          },
        ],
        sendVersion: '2019-11-05T13:44:26.366139Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/6da1800e-587b-49a0-a5d7-7b462ba9e1ba',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/6da1800e-587b-49a0-a5d7-7b462ba9e1ba/addresses/8cf2d805-a7bc-4ce6-b82c-034f96da54ca',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:20:39.085670Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/6da1800e-587b-49a0-a5d7-7b462ba9e1ba/contactpoints/25cc906d-fbe5-4e77-8d22-8577dfc8767b',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:20:39.137132Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/6da1800e-587b-49a0-a5d7-7b462ba9e1ba/contactpoints/87c61c13-af31-48d8-95d3-84cfa85ab93e',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:20:39.183072Z',
          },
        ],
        sendVersion: '2019-11-05T15:20:39.028115Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/71c11e5b-4b2f-4294-b968-031b6d478f31',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/71c11e5b-4b2f-4294-b968-031b6d478f31/addresses/6ef8e62e-7d10-4a50-b752-12deee2856b6',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:25:24.427521Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/71c11e5b-4b2f-4294-b968-031b6d478f31/contactpoints/09679388-a5a6-4752-bf4b-def4452ddd73',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:25:24.515933Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/71c11e5b-4b2f-4294-b968-031b6d478f31/contactpoints/9ff88095-9bea-4dc0-863e-11bc1a72d394',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:25:24.478887Z',
          },
        ],
        sendVersion: '2019-11-05T15:25:24.373162Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/79d4bcab-e087-4cbb-a3f1-0be895ec81d4',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/79d4bcab-e087-4cbb-a3f1-0be895ec81d4/addresses/2de611aa-dd28-45f9-8f6d-c677b880706d',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:15:33.006880Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/79d4bcab-e087-4cbb-a3f1-0be895ec81d4/contactpoints/b9442c06-06e7-4bdc-b6f8-191dfb9a6339',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:15:33.061217Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/79d4bcab-e087-4cbb-a3f1-0be895ec81d4/contactpoints/f402796a-e3f5-48d6-b349-b3fab02fd2b8',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:15:33.097990Z',
          },
        ],
        sendVersion: '2019-11-05T13:15:32.953687Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/7b0d3cee-e496-4127-b4b6-3570a84dd029',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [],
        contactPoints: [],
        sendVersion: '2019-11-05T13:59:45.418993Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/85b602dc-183d-4dc4-89a7-c50bfdbf1e88',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/85b602dc-183d-4dc4-89a7-c50bfdbf1e88/addresses/64609f83-97ad-4fa0-94d8-d89bf5819288',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T14:03:04.702524Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/85b602dc-183d-4dc4-89a7-c50bfdbf1e88/contactpoints/20c39ee7-f420-4af0-bc57-94ec44875725',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T14:03:04.761789Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/85b602dc-183d-4dc4-89a7-c50bfdbf1e88/contactpoints/af88e613-e989-47d1-9b07-083446ffd318',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T14:03:04.807940Z',
          },
        ],
        sendVersion: '2019-11-05T14:03:04.635415Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/8a128a41-3979-4ead-9183-1e05ab3946c9',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/8a128a41-3979-4ead-9183-1e05ab3946c9/addresses/5534b40a-29da-4370-98a7-7281701db5e9',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:41:18.157937Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/8a128a41-3979-4ead-9183-1e05ab3946c9/contactpoints/8bbc9c9c-a019-41aa-be7b-2252b3ccfd2e',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:41:18.200232Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/8a128a41-3979-4ead-9183-1e05ab3946c9/contactpoints/b58cd915-63d3-4097-b265-874ada1cd81a',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:41:18.236776Z',
          },
        ],
        sendVersion: '2019-11-05T13:41:18.113940Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/a522ef29-e66d-435d-be97-8f7a0181c9ad',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/a522ef29-e66d-435d-be97-8f7a0181c9ad/addresses/21c6d459-467c-4b60-b1fa-160dc0ed5963',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:06:37.033365Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/a522ef29-e66d-435d-be97-8f7a0181c9ad/contactpoints/84195a89-5945-45f1-9f42-0766ab247f72',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:06:37.082652Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/a522ef29-e66d-435d-be97-8f7a0181c9ad/contactpoints/a9e8badc-c5d3-4f54-92b6-a18e5334dd53',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:06:37.123426Z',
          },
        ],
        sendVersion: '2019-11-05T15:06:36.984256Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/a7c345c0-f188-4218-bd39-c0b15603d462',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/a7c345c0-f188-4218-bd39-c0b15603d462/addresses/9e9d1796-ce59-4948-ae05-1e1f7d05b044',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:12:05.437112Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/a7c345c0-f188-4218-bd39-c0b15603d462/contactpoints/1fcb3e9c-8b06-49d2-9ee9-c44eedb941ca',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:12:05.526913Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/a7c345c0-f188-4218-bd39-c0b15603d462/contactpoints/3ecb8cbc-8194-4b87-8e90-6177c0b1f767',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:12:05.490642Z',
          },
        ],
        sendVersion: '2019-11-05T15:12:05.384927Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/bca13fa8-1ce3-4bd8-8963-85c0253611ff',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [],
        contactPoints: [],
        sendVersion: '2019-11-05T13:56:37.214774Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/c9a13e25-1893-40fd-91b2-d3bdb7310f43',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/c9a13e25-1893-40fd-91b2-d3bdb7310f43/addresses/e4eec70f-6533-49be-866c-00e11b0655fd',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:30:51.317336Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/c9a13e25-1893-40fd-91b2-d3bdb7310f43/contactpoints/918017b5-5c6d-458e-bca2-f0ab5cdd9f42',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:30:51.370108Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/c9a13e25-1893-40fd-91b2-d3bdb7310f43/contactpoints/a5160f79-ce5e-4bf7-bbe7-f02ec86c3827',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:30:51.405523Z',
          },
        ],
        sendVersion: '2019-11-05T13:30:51.270789Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/ccc5eae8-4cea-47db-8e0c-229ab7c57b04',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/ccc5eae8-4cea-47db-8e0c-229ab7c57b04/addresses/8b9a2f85-c3c8-48bf-a073-5c09179b82de',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-07T08:18:54.828230Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/ccc5eae8-4cea-47db-8e0c-229ab7c57b04/contactpoints/8146b3cb-a287-45f6-9afc-7231b531c4b5',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-07T08:18:54.888467Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/ccc5eae8-4cea-47db-8e0c-229ab7c57b04/contactpoints/d2944f00-4915-43ca-8da6-d24829a984d5',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-07T08:18:54.927711Z',
          },
        ],
        sendVersion: '2019-11-07T08:18:54.776682Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/d168e859-3434-4528-86cb-1e6cdbcc2aa8',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/d168e859-3434-4528-86cb-1e6cdbcc2aa8/addresses/36c41714-3eef-4d5e-bbf3-bdb879f3e21b',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:43:11.648259Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/d168e859-3434-4528-86cb-1e6cdbcc2aa8/contactpoints/2cb6236a-aafb-446b-9712-63b1b9af7544',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:43:11.697212Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/d168e859-3434-4528-86cb-1e6cdbcc2aa8/contactpoints/61b5a5e4-f4dd-43d9-8145-cc0d9facdea4',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:43:11.735999Z',
          },
        ],
        sendVersion: '2019-11-05T15:43:11.587422Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/d7c5ae17-32d2-42c5-8272-d8bcdfc741ab',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/d7c5ae17-32d2-42c5-8272-d8bcdfc741ab/addresses/353da79f-4138-4440-8c90-61d2e26e9899',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:08:29.173095Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/d7c5ae17-32d2-42c5-8272-d8bcdfc741ab/contactpoints/0d0d36a9-31a5-4cde-a14e-33935224c51a',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:08:29.267487Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/d7c5ae17-32d2-42c5-8272-d8bcdfc741ab/contactpoints/a84ca858-ecd8-477c-932f-7e4bb283fd77',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T15:08:29.226820Z',
          },
        ],
        sendVersion: '2019-11-05T15:08:29.120090Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/db092be6-aba0-46a0-b3da-4d486bf3979d',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/db092be6-aba0-46a0-b3da-4d486bf3979d/addresses/46859a3f-b64b-442b-8137-ff37bb723d10',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:46:22.557816Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/db092be6-aba0-46a0-b3da-4d486bf3979d/contactpoints/62f3723e-4756-443b-8011-3202ccade6ad',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:46:22.694344Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/db092be6-aba0-46a0-b3da-4d486bf3979d/contactpoints/b1d64f86-b717-40ec-bfc7-c0fd88e42d5f',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:46:22.655630Z',
          },
        ],
        sendVersion: '2019-11-05T13:46:22.513991Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/dd3ea06b-39a1-4936-a7ae-685f8e15fb44',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/dd3ea06b-39a1-4936-a7ae-685f8e15fb44/addresses/8636f6c5-5d31-4840-912e-4081d6bb01a2',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:23:01.702137Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/dd3ea06b-39a1-4936-a7ae-685f8e15fb44/contactpoints/83ad1cc7-da68-42ef-ac55-19fcf4263443',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:23:01.791750Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/dd3ea06b-39a1-4936-a7ae-685f8e15fb44/contactpoints/e3eded48-53a2-4643-8bbc-43b38d3853dc',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:23:01.752098Z',
          },
        ],
        sendVersion: '2019-11-05T13:23:01.647278Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
      {
        name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/e7028723-0e72-49f3-a0a4-b6700f0a896f',
        organisationName: 'organisations/d35f7bab-e27a-4470-afdd-700c994bd5d2',
        source: 'COBRHA',
        lastModifier: 'UZLEUVEN\\dverst1',
        addresses: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/e7028723-0e72-49f3-a0a4-b6700f0a896f/addresses/573e4f96-407c-44e7-8f5e-5fe9ed4513ca',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: 'Voetbalstraat',
            number: '6',
            box: '',
            postalCode: '1000',
            city: 'Brussel',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:29:31.207445Z',
          },
        ],
        contactPoints: [
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/e7028723-0e72-49f3-a0a4-b6700f0a896f/contactpoints/67682ef6-c290-4837-a598-d551d21afdc2',
            system: 'System_PHONE',
            value: '02/014 14 14',
            use: 'Use_UNSPECIFIED',
            comment: 'Only during working hour',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:29:31.289400Z',
          },
          {
            name: 'practitioners/d1fea123-ca14-4069-8436-b857f6c72783/practitionerContexts/e7028723-0e72-49f3-a0a4-b6700f0a896f/contactpoints/93fbc87b-a240-498b-8db6-f22f0ce8d526',
            system: 'System_EMAIL',
            value: 'toby.alderweireld@outlook.com',
            use: 'Use_UNSPECIFIED',
            comment: '',
            lastModifier: 'UZLEUVEN\\dverst1',
            source: 'COBRHA',
            sendVersion: '2019-11-05T13:29:31.251967Z',
          },
        ],
        sendVersion: '2019-11-05T13:29:31.164198Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
    ],
    sendVersion: '2019-11-05T13:15:32.911158Z',
  },
  {
    name: 'practitioners/2383b7d0-b004-46c9-8fb2-f455e85fff1b',
    personName: { prefix: '', nick: '', given: 'Toby 2', otherGivens: [], family: 'Alderweireld', suffix: '' },
    source: 'COBRHA',
    lastModifier: 'UZLEUVEN\\dverst1',
    assignedIds: [],
    contexts: [],
    sendVersion: '2019-11-05T13:13:49.482965Z',
  },
  {
    name: 'practitioners/576388fa-0034-47cb-9a6d-84287388cbbe',
    personName: { prefix: '', nick: '', given: 'TOBY 3', otherGivens: [], family: 'THOMAS', suffix: '' },
    source: 'RIZIVDOWNLOAD',
    lastModifier: 's9_batch',
    assignedIds: [
      {
        name: 'practitioners/576388fa-0034-47cb-9a6d-84287388cbbe/identifiers/59109155-8822-4bb9-aaf6-9ca0fec5bb5f',
        typeName: 'enums/common-identifier-type/values/be-nihii',
        value: '41853631',
        sourceType: 'ADMINISTRATOR',
        lastModifier: 's9_batch',
        source: 'RIZIVDOWNLOAD',
        sendVersion: '2020-04-25T02:32:57.953131Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
    ],
    contexts: [
      {
        name: 'practitioners/576388fa-0034-47cb-9a6d-84287388cbbe/practitionerContexts/441fba14-3d43-4b3d-9b58-d6ad93d3aca2',
        organisationName: '',
        source: 'RIZIVDOWNLOAD',
        lastModifier: 's9_batch',
        addresses: [
          {
            name: 'practitioners/576388fa-0034-47cb-9a6d-84287388cbbe/practitionerContexts/441fba14-3d43-4b3d-9b58-d6ad93d3aca2/addresses/fa6b8ff4-2eeb-4260-8404-d27c55ed81c6',
            use: 'Use_OFFICIAL',
            extraLines: '',
            street: '',
            number: '',
            box: '',
            postalCode: '',
            city: '',
            country: '699d90e5-7498-4152-b8e6-5c877a809d83',
            comment: '',
            lastModifier: 's9_batch',
            source: 'RIZIVDOWNLOAD',
            sendVersion: '2020-04-25T02:32:57.953131Z',
          },
        ],
        contactPoints: [],
        sendVersion: '2020-04-25T02:32:57.953131Z',
        endTime: '9999-12-31T23:59:59.999999999Z',
      },
    ],
    sendVersion: '2020-04-25T02:32:57.953131Z',
  },
];
