import { Component } from '@angular/core';
import { ScrollspyService } from '@nexuzhealth/shared-ui-toolkit/scrollspy';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'nxh-dummy-page',
  templateUrl: './dummy-page.component.html',
})
export class DummyPageComponent {
  showExampleBlock1 = true;
  block1Code = `
  // code comes here
  `;

  showExampleBlock2 = true;
  block2Code = `
  // code comes here
  `;

  showExampleBlock3 = true;
  block3Code = `
  // code comes here
  `;

  selected$ = new BehaviorSubject('block1');

  constructor(private scrollspyService: ScrollspyService) {}

  scrollTo(id) {
    this.selected$.next(id);
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id) {
    this.selected$.next(id);
  }
}
