<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Helper Classes</h1>
    </div>
  </div>
</div>
<div class="toolkit__body">
  <div class="toolkit__body__content" nxhScrollspyContainer>
    <div
      class="toolkit__body__content__block"
      id="block1"
      nxhScrollspyTarget="block1"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Max line class</h2>

      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock1" (click)="showExampleBlock1 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock1" (click)="showExampleBlock1 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>

      <div *ngIf="showExampleBlock1">
        <div class="slidecontainer">
          <label>the amount of lines you want to show works between 1 and 5</label>
          <div>
            <input type="range" min="1" max="5" [(ngModel)]="amount" />
          </div>
          we are now showing {{ amount }} lines
          <hr />
        </div>
        <div class="card">
          <div class="card-body">
            <div class="card-body__container">
              <div class="test maxlines maxlines-{{ amount }}">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porttitor venenatis vulputate. Vestibulum
                ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nulla lorem nisi,
                ullamcorper a pharetra vitae, tincidunt at eros. Donec varius vitae lacus ut sollicitudin. Etiam ut
                vulputate enim. Pellentesque in lectus quam. Curabitur vel lacinia tellus. Curabitur quis efficitur
                velit. Integer et feugiat purus. Proin bibendum luctus turpis, quis efficitur lacus cursus in. Quisque
                cursus tellus ipsum, id pulvinar tellus laoreet nec. Integer luctus justo orci, a tristique sapien
                euismod vel. Vestibulum placerat rutrum lobortis. Quisque vitae sodales mauris, lacinia tristique mi.
                Donec gravida nibh non ante hendrerit interdum. Phasellus ac hendrerit mauris. Donec at volutpat eros,
                id dignissim ligula. Maecenas efficitur orci nec magna vulputate luctus. In sed ex quis urna feugiat
                aliquam. Aliquam consectetur facilisis ligula, non condimentum ligula ornare et. Fusce pellentesque
                aliquam lacinia. Proin posuere convallis magna a suscipit. Phasellus in tincidunt lacus. In hac
                habitasse platea dictumst. Quisque eget auctor felis, id suscipit nibh. Aliquam sagittis felis sit amet
                nulla semper, eu euismod enim porta. Quisque aliquam condimentum quam. Mauris sit amet lorem augue.
                Proin imperdiet leo dui, a ultrices arcu euismod ut. Suspendisse potenti. Duis dignissim justo quis
                hendrerit blandit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus
                mus. Nulla a rhoncus ante, at faucibus metus. Maecenas lobortis faucibus ullamcorper. Nulla nec tortor
                eget nibh interdum placerat. Orci varius natoque penatibus et magnis dis parturient montes, nascetur
                ridiculus mus. Fusce lacinia nisl ut sapien accumsan, at semper augue fringilla. Donec maximus at dolor
                et tincidunt. Duis tristique sem a tortor pellentesque, eu eleifend justo maximus. Quisque ut est nisi.
                Nunc at viverra nisl. Ut eu faucibus magna, eu elementum ligula. Mauris ac urna convallis, molestie elit
                et, malesuada ex. Maecenas molestie viverra ante, in tempor libero maximus at. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Nam rhoncus, ex nec varius elementum, enim nulla sagittis ipsum, eget
                posuere mi risus sed augue. Aliquam suscipit congue neque, sit amet fringilla velit luctus eu. Nullam
                eleifend libero enim, nec facilisis diam laoreet sed. Nullam ac imperdiet leo. Sed commodo diam eu
                fringilla sagittis. Sed congue finibus odio quis aliquam. Phasellus ultrices ullamcorper quam a viverra.
                Proin eu odio id orci dictum suscipit a id felis.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock1">
        <pre><code [highlight]="block1Code" language="html"></code></pre>
      </div>
    </div>
  </div>

  <div class="toolkit__body__scrollspy">
    <ul>
      <li (click)="scrollTo('block1')" [class.is-active]="(selected$ | async) === 'block1'">Max Lines</li>
    </ul>
  </div>
</div>
