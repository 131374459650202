import { AssignedId, AssignedIdTypenameEnum } from '@nexuzhealth/shared-domain';

export function findAssignedId(ids?: AssignedId[], type?: AssignedIdTypenameEnum): AssignedId | undefined {
  if (!ids) {
    return undefined;
  }
  if (!type) {
    return ids[0];
  }
  return ids.find((id) => id.typeName === type);
}
