import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NxhFormsModule } from '@nexuzhealth/shared-ui-toolkit/forms';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { PillModule } from '@nexuzhealth/shared-ui-toolkit/pill';
import { I18NextModule } from 'angular-i18next';
import { Nl2brPipeModule } from '@nexuzhealth/shared-ui-toolkit/pipes/nl2br';
import {
  AddressCommentDirective,
  AddressLabelDirective,
  AddressLineDirective,
  AddressSelectorComponent,
} from './components/address-selector';
import { PillTypePipe } from './components/address-selector/pill-type.pipe';
import { AddressComponent } from './components/address/address.component';
import { FormatAddressPipe } from './pipes/format-address.pipe';

@NgModule({
  imports: [CommonModule, I18NextModule, NxhFormsModule, PillModule, IconsModule, Nl2brPipeModule, Nl2brPipeModule],
  declarations: [
    AddressComponent,
    FormatAddressPipe,
    AddressSelectorComponent,
    AddressCommentDirective,
    AddressLabelDirective,
    AddressLineDirective,
    PillTypePipe,
  ],
  exports: [
    AddressComponent,
    FormatAddressPipe,
    AddressSelectorComponent,
    AddressCommentDirective,
    AddressLabelDirective,
    AddressLineDirective,
  ],
  providers: [FormatAddressPipe],
})
export class SharedReferenceDataFeatureAddressModule {}
