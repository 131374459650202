import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PageLoaderComponent } from '@nexuzhealth/shared-ui-toolkit/loading-states/page-loader';
import { FormHelperService } from '@nexuzhealth/shared-ui-toolkit/validation';
import { timeoutHeaders } from '@nexuzhealth/shared-util';
import { of, Subject, throwError, TimeoutError } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

@Component({
  selector: 'nxh-timeouts-demo',
  templateUrl: './timeouts-demo.component.html',
  styleUrls: ['./timeouts-demo.component.scss'],
  providers: [FormHelperService],
})
export class TimeoutsDemoComponent {
  dogs$ = new Subject<unknown>();
  dogsForm = new UntypedFormGroup({ name: new UntypedFormControl('Droopy') });

  constructor(
    private fhs: FormHelperService,
    private http: HttpClient,
  ) {}

  warningPageLoader = `
    <nxh-page-loader [warningTimeoutTime]="1000" #pageLoader></nxh-page-loader>
  `;

  errorPageLoader = `
    import { timeoutHeaders } from '@nexuzhealth/shared-util';

    this.http
      .get<any[]>('/assets/data/dogs.json', { headers: timeoutHeaders({ errorMillis: 500 }) })
  `;

  customWarningMillis = `
    <nxh-page-loader [warningTimeoutTime]="1000"></nxh-page-loader>

    ...
    this.fhs.submit(this.dogsForm, request, { timeouts: { warningMillis: 1000, intervalMillis: 1000 } });
  `;

  settingRequestHeader = `
    import { timeoutHeaders } from '@nexuzhealth/shared-util';

    const headers = timeoutHeaders({ errorMillis: 10000 });
    this.http
      .get<any[]>('/assets/data/dogs.json', { headers })

    // or if you already have a Headers object
    let headers = new HttpHeaders();
    headers = timeoutHeaders({ errorMillis: 10000 }, headers);
  `;

  setTimeoutsServiceCode = `

    constructor(
      ...
      private timeouts: SetTimeoutsService
    ) {}

    fetchTariffs() {

      this.invoicePageService
        .loadETarStatus(this.tenantQuery.getTenantName(), this.createInvoice())
        .pipe(this.timeouts.at({ warningMillis: 500, errorMillis: 1000 }, this.destroy$), takeUntil(this.destroy$))
        .subscribe({
    ...
  `;

  loadPage(pageLoader: PageLoaderComponent, error = false) {
    pageLoader.startLoading();
    const headers = error ? timeoutHeaders({ errorMillis: 500 }) : new HttpHeaders();
    this.http.get<any[]>('/assets/data/dogs.json', { headers }).subscribe({
      next: (dogs) => {
        pageLoader.setLoaded(dogs.length);
        this.dogs$.next(dogs);
      },
      error: (err) => pageLoader.setError(err),
    });
  }

  submitPersonForm() {
    const req = () => {
      // fake call, typically the TimeoutError is thrown by a HttpTimeoutInterceptor
      return of(null).pipe(
        delay(3000),
        switchMap(() => throwError(new TimeoutError())),
      );
    };
    this.fhs.submit(this.dogsForm, req, { timeouts: { warningMillis: 1000, intervalMillis: 1000 } });
  }
}
