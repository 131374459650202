import { Component } from '@angular/core';

@Component({
  selector: 'nxh-settings-demo',
  templateUrl: './settings-demo.component.html',
  styleUrls: ['./settings-demo.component.scss'],
})
export class SettingsDemoComponent {
  componentsConfigFile = `
    export const componentsConfig = {
      components: {
        'patient-list-page': {
          displayedColumns: ['patient', 'inss', 'phoneNumber', 'birthDay', 'home-address']
        },
        'medication-page': {
          attributes: {
            frequency: {
              required: true
            }
          }
        }
      }
    };
  `;

  componentsConfigCode = `
    constructor(
      private settings: SettingsService
    ) {}

    const defaultColumns = ['patient', 'inss', 'phoneNumber', 'birthDay', 'home-address'];
    this.displayedColumns = this.settings.getComponentSettingValue(
      'patient-list-page.displayedColumns',
      defaultColumns
    );

    <nxh-patient-list [displayedColumns]="displayedColumns" [patients]="data$ | async"></nxh-patient-list>
  `;

  settingsFile = `
     {
      "featureToggles": {
        "loadDynamicEnabled": false
      },
      "production": false,
      "application": "gp",
      "firebase": {
        "apiKey": "AIzaSyAsK49cIg1MPeouzB7ZCJLlyumHeGVB2YQ",
        "authDomain": "moapr-dev.firebaseapp.com",
        "projectId": "moapr-dev"
      },
      "loginEndpoint": "http://login.test.moapr.be/login;tenantName=",
      "logOutEndpoint": "http://login.test.moapr.be/logout"
    }
  `;

  initializeSettingsCode = `
    providers: [
      {
        provide: APP_INITIALIZER,
        multi: true,
        useFactory: preloadAll,
        deps: [SettingsService, I18nextConfigService, UserPreferencesService]
      },
    ]
  `;

  settingsCode = `
    constructor(
      private settings: SettingsService
    ) {}

    logout() {
      this.authService.logout().then(() => {
        window.location.href = this.settings.logOutEndpoint;
      });
    }
  `;

  constructor() {}
}
