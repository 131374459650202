<div class="mini-calendar__container">
  <input
    class="mini-calendar"
    ngbDatepicker
    #datepicker="ngbDatepicker"
    [autoClose]="'outside'"
    (dateSelect)="onDateSelection($event, datepicker)"
    [displayMonths]="1"
    [navigation]="'arrows'"
    [dayTemplate]="t"
    [startDate]="startDate"
    [datepickerClass]="'mini-calendar__button'"
    outsideDays="visible"
    tabindex="-1"
  />
  <button nxh-button buttonType="subtle-button" buttonStatus="neutral" #btn (click)="toggleDatepicker(datepicker)">
    <fa-icon icon="calendar-alt" />
  </button>
</div>

<ng-template #t let-date let-focused="focused" let-currentMonth="currentMonth">
  <nxh-day-view
    [date]="date"
    [currentMonth]="currentMonth"
    (mouseenter)="onMouseEnter(date)"
    (mouseleave)="onMouseLeave()"
    [noHighlightOnFocused]="mode === 'range' || noHighlightOnFocused"
    [focused]="focused"
    [fromDate]="ngbDateRange?.fromDate"
    [toDate]="ngbDateRange?.toDate"
  />
</ng-template>
