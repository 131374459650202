import { Injectable } from '@angular/core';
import { action, Store, StoreConfig } from '@datorama/akita';
import { UserPreferences } from '@nexuzhealth/shared-domain';
import { CookieService, moaprFunctionalCookieKeys } from '@nexuzhealth/shared-settings-data-access-cookies';

export const key_user_preferences = moaprFunctionalCookieKeys.nh_user_preferences;

export interface UserPreferencesState {
  preferredLanguage: string;
}

export function createInitialUserPreferencesState(): UserPreferencesState {
  return {
    preferredLanguage: '',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-preferences' })
export class UserPreferencesStore extends Store<UserPreferencesState> {
  constructor(private cookieService: CookieService) {
    super(createInitialUserPreferencesState());
  }

  @action('setPreferredLanguage')
  setPreferredLanguage(preferredLanguage: string): void {
    this.update({ preferredLanguage });
    this.storeLocal(preferredLanguage);
  }

  private storeLocal(value: string) {
    const userPreferences = { preferredLanguage: value } as UserPreferences;
    this.cookieService.setFunctionalCookie(key_user_preferences, JSON.stringify(userPreferences));
  }
}
