import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { I18NextModule, I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { CookieService } from '@nexuzhealth/shared-settings-data-access-cookies';
import { I18NextConfig } from './config';
import { appInit } from './i18next';

export const i18next_config = new InjectionToken<I18NextConfig>('I18NEXTCONFIG');

export function i18nextFactory(
  i18next: ITranslationService,
  customConfig: I18NextConfig,
  cookieService: CookieService,
) {
  return () => appInit(i18next, customConfig, cookieService);
}

@NgModule({
  declarations: [],
  imports: [CommonModule, I18NextModule.forRoot()],
  exports: [I18NextModule],
})
export class SharedUtilI18nModule {
  static forRoot(config: I18NextConfig): ModuleWithProviders<SharedUtilI18nModule> {
    return {
      ngModule: SharedUtilI18nModule,
      providers: [
        { provide: i18next_config, useValue: config },
        {
          provide: APP_INITIALIZER,
          useFactory: i18nextFactory,
          deps: [I18NEXT_SERVICE, i18next_config],
          multi: true,
        },
      ],
    };
  }
}
