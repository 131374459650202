import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
} from '@angular/core';
import { filterNullOrUndefined } from '@nexuzhealth/shared-util';
import { NgSelectComponent } from '@ng-select/ng-select';
import { filter, switchMap, takeUntil, BehaviorSubject, Subject } from 'rxjs';
import { FocusService } from '../shared/focus.service';
import { Focusable, FOCUSSABLE } from '../shared/focusable';

@Directive({
  selector: '[nxhFocus]',
  standalone: true,
})
export class FocusDirective implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private _auto = true;
  @Input() set auto(auto) {
    this._auto = auto ?? true;
  }

  get auto() {
    return this._auto;
  }

  @Input() handle?: string;

  @Input('nxhFocus') setFocus!: boolean;
  @Input() debounceFocus = 0;

  private destroy$ = new Subject<void>();
  private handleSubj = new BehaviorSubject<string | null>(null);

  constructor(
    private el: ElementRef,
    private focusService: FocusService,
    @Self() @Optional() @Inject(FOCUSSABLE) private focusable?: Focusable,
    @Self() @Optional() private ngSelect?: NgSelectComponent,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // note we won't do anything when you change the auto flag, this one is for initial focus only

    // if the focus name is changed we have to reevaluate if this control should receive focus
    let change = changes['handle'];
    if (change && !change.isFirstChange()) {
      this.handleSubj.next(change.currentValue);
    }

    change = changes['setFocus'];
    if (change && !change.isFirstChange() && change.currentValue === true) {
      setTimeout(() => this.focus(), this.debounceFocus);
    }
  }

  ngOnInit(): void {
    // note after all we won't consider the formControlName, it is confusing e.g. with radio buttons that have the same
    // formControlName
    this.handle = this.handle ?? `focus_${Math.random()}`;
    this.handleSubj.next(this.handle);
  }

  ngAfterViewInit(): void {
    // dynamic focus
    this.handleSubj
      .asObservable()
      .pipe(
        filterNullOrUndefined,
        switchMap((handle) => this.focusService.focus$.pipe(filter((name) => name === handle))),
        takeUntil(this.destroy$),
      )
      .subscribe(() => this.focus());

    // initial focus
    if (this.auto && this.setFocus && this.handle) {
      this.focusService.focus(this.handle);
    }
  }

  private focus() {
    if (this.focusable) {
      this.focusable.setFocus();
    } else if (this.ngSelect) {
      this.ngSelect.focus();
    } else if (this.el.nativeElement && this.el.nativeElement.focus) {
      this.el.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
