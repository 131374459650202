import { Component } from '@angular/core';

@Component({
  selector: 'nxh-progress-bar-demo',
  templateUrl: './progress-bar-demo.component.html',
  styleUrls: ['./progress-bar-demo.component.scss'],
})
export class ProgressBarDemoComponent {
  value = 0;
  type = 'nexuzhealth';
  striped = true;
  animated = true;
  disabled = false;

  pbCode = `
  <ngb-progressbar
      [striped]="striped" // boolean
      [animated]="animated" // boolean
      [value]="value" // 0 - 100
      [type]="type"> // color "nxh"
  </ngb-progressbar>
  `;

  pbCodeStateless = `
  <ngb-progressbar
      [striped]="true"
      [animated]="true"
      [value]="100"
      type="nxh">
  </ngb-progressbar>
  `;

  constructor() {}

  run() {
    this.type = 'nexuzhealth';
    this.striped = true;
    this.animated = true;
    this.value = 0;
    this.disabled = true;
    setTimeout(() => {
      this.value = 0;
    }, 400);
    setTimeout(() => {
      this.value = 15;
    }, 800);
    setTimeout(() => {
      this.value = 45;
    }, 1600);
    setTimeout(() => {
      this.value = 85;
    }, 2000);
    setTimeout(() => {
      this.value = 100;
      this.type = 'success';
      this.striped = false;
      this.animated = false;
      this.disabled = false;
    }, 2400);
  }
}
