import { Directive, HostListener } from '@angular/core';

import { CollapseService } from './collapse.service';

@Directive({
  selector: '[nxhCollapseToggle]',
  exportAs: 'nxhCollapseToggle',
  // TODO fix this --> https://angular.io/guide/styleguide#style-06-03
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'collapse__toggle',
    role: 'button',
    tabindex: '0',
  },
})
export class CollapseToggleDirective {
  constructor(private collapseService: CollapseService) {}

  @HostListener('click', ['$event'])
  @HostListener('keydown.enter', ['$event'])
  @HostListener('keydown.space', ['$event'])
  toggle(event: Event) {
    this.collapseService.toggle();
  }
}
