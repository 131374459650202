import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BulletModule } from '@nexuzhealth/shared-ui-toolkit/bullet';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { NxhFormsModule } from '@nexuzhealth/shared-ui-toolkit/forms';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { CalendarButtonComponent } from './calendar-button/calendar-button.component';
import { DayViewComponent } from './day-view/day-view.component';
import { MiniCalendarComponent } from './mini-calendar/mini-calendar.component';
import { RangePickerComponent } from './range-picker/range-picker.component';
import { RangeBoundaryWontChangePipe } from './range-picker/range-boundary-wont-change.pipe';

@NgModule({
  imports: [
    CommonModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    BulletModule,
    IconsModule,
    ButtonModule,
    NxhFormsModule,
    SharedUtilI18nModule,
  ],
  declarations: [
    MiniCalendarComponent,
    DayViewComponent,
    CalendarButtonComponent,
    RangePickerComponent,
    RangeBoundaryWontChangePipe,
  ],
  exports: [MiniCalendarComponent, CalendarButtonComponent, RangePickerComponent],
})
export class DatepickerModule {}
