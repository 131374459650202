import { Pipe, PipeTransform } from '@angular/core';
import { AssignedId, AssignedIdTypenameEnum, OrganisationIdentifierTypenameEnum } from '@nexuzhealth/shared-domain';
import { formatAssignedId } from '@nexuzhealth/shared-util';

@Pipe({
  name: 'formatAssignedId',
})
export class FormatAssignedIdPipe implements PipeTransform {
  transform(
    assignedId?: AssignedId | string,
    type?: AssignedIdTypenameEnum | OrganisationIdentifierTypenameEnum,
  ): string {
    return formatAssignedId(assignedId, type);
  }
}
