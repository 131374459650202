import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { ICONS } from './icons';

export class IconRegistryService {
  constructor(
    private library: FaIconLibrary,
    private faConfig: FaConfig,
  ) {
    this.faConfig.defaultPrefix = 'far';
  }

  loadAll() {
    this.library.addIcons(...ICONS);
  }

  /**
   * Lazy loaded modules that need their own (fontawesome) icons can use this method to load the icons in the library
   */
  loadMore(...icons: any[]) {
    this.library.addIcons(...icons);
  }
}
