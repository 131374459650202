import { Injectable } from '@angular/core';
import { UserPreferencesQuery } from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { I18NextPipe } from 'angular-i18next';
import {
  addDays,
  addMonths,
  differenceInMinutes,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
} from 'date-fns';
import { de } from 'date-fns/locale/de';
import { enGB } from 'date-fns/locale/en-GB';
import { fr } from 'date-fns/locale/fr';
import { nlBE } from 'date-fns/locale/nl-BE';

import { DateInterval } from '../models/date.model';

@Injectable({
  providedIn: 'root',
})
export class DateUtilService {
  constructor(
    private i18next: I18NextPipe,
    private userPref: UserPreferencesQuery,
  ) {}

  userLanguage = this.userPref.getPreferredLanguage();
  supportedLanguages = {
    nl: nlBE,
    fr: fr,
    de: de,
    en: enGB,
  };
  dayNumber_formatString = 'd';
  monthName_formatString = 'MMMM';
  yearNumber_formatString = 'yyyy';
  day_formatString = 'EEEE dd/MM/yyyy';

  //#region MONTH
  getCurrentMonthToString(dateInterval: DateInterval): string {
    const startOfMonthDate = startOfMonth(dateInterval?.from ?? new Date());
    return this.getFormattedMonthString(startOfMonthDate);
  }

  getCurrentMonthDateInterval(dateInterval: DateInterval): DateInterval {
    const startOfMonthDate = startOfMonth(dateInterval?.from ?? new Date());
    const endOfMonthDate = endOfMonth(startOfMonthDate);
    return this.dateIntervalFactoryBuilder(startOfMonthDate, endOfMonthDate);
  }

  getNextMonthToString(selectedDateInterval: DateInterval): string {
    const startOfMonthDate = addMonths(startOfMonth(selectedDateInterval.from), 1);
    return this.getFormattedMonthString(startOfMonthDate);
  }

  getNextMonthDateInterval(selectedDateInterval: DateInterval): DateInterval {
    const startOfMonthDate = addMonths(startOfMonth(selectedDateInterval.from), 1);
    const endOfMonthDate = endOfMonth(startOfMonthDate);
    return this.dateIntervalFactoryBuilder(startOfMonthDate, endOfMonthDate);
  }

  getPreviousMonthToString(selectedDateInterval: DateInterval): string {
    const startOfMonthDate = subMonths(startOfMonth(selectedDateInterval.from), 1);
    return this.getFormattedMonthString(startOfMonthDate);
  }

  getPreviousMonthDateInterval(selectedDateInterval: DateInterval): DateInterval {
    const startOfMonthDate = subMonths(startOfMonth(selectedDateInterval.from), 1);
    const endOfMonthDate = endOfMonth(startOfMonthDate);
    return this.dateIntervalFactoryBuilder(startOfMonthDate, endOfMonthDate);
  }

  getFormattedMonthString(startOfMonthDate: Date): string {
    const month = this.i18next.transform(format(startOfMonthDate, this.monthName_formatString).toLowerCase());
    const year = format(startOfMonthDate, this.yearNumber_formatString);
    return `${month} ${year}`;
  }

  //#endregion

  //#region WEEK
  getCurrentWeekDateInterval(dateInterval?: DateInterval): DateInterval {
    const startOfWeekDate = startOfWeek(dateInterval?.from ?? new Date(), { weekStartsOn: 1 });
    const endOfWeekDate = endOfWeek(startOfWeekDate, { weekStartsOn: 1 });
    return this.dateIntervalFactoryBuilder(startOfWeekDate, endOfWeekDate);
  }

  getCurrentWeekToString(dateInterval?: DateInterval): string {
    const startOfWeekDate = startOfWeek(dateInterval?.from ?? new Date(), { weekStartsOn: 1 });
    const endOfWeekDate = endOfWeek(startOfWeekDate, { weekStartsOn: 1 });
    return this.getFormattedWeekString(startOfWeekDate, endOfWeekDate);
  }

  getNextWeekDateInterval(selectedDateInterval) {
    const startOfWeekDate = startOfDay(addDays(selectedDateInterval.to, 1));
    const endOfWeekDate = endOfWeek(startOfWeekDate, { weekStartsOn: 1 });
    return this.dateIntervalFactoryBuilder(startOfWeekDate, endOfWeekDate);
  }

  getNextWeekToString(selectedDateInterval): string {
    const startOfWeekDate = addDays(endOfWeek(selectedDateInterval.from, { weekStartsOn: 1 }), 1);
    const endOfWeekDate = endOfWeek(startOfWeekDate, { weekStartsOn: 1 });
    return this.getFormattedWeekString(startOfWeekDate, endOfWeekDate);
  }

  getPreviousWeekDateInterval(selectedDateInterval) {
    const endOfWeekDate = endOfDay(subDays(selectedDateInterval.from, 1));
    const startOfWeekDate = startOfWeek(endOfWeekDate, { weekStartsOn: 1 });
    return this.dateIntervalFactoryBuilder(startOfWeekDate, endOfWeekDate);
  }

  getPreviousWeekToString(selectedDateInterval): string {
    const endOfWeekDate = subDays(selectedDateInterval.from, 1);
    const startOfWeekDate = startOfWeek(endOfWeekDate, { weekStartsOn: 1 });
    return this.getFormattedWeekString(startOfWeekDate, endOfWeekDate);
  }

  getFormattedWeekString(startOfWeekDate: Date, endOfWeekDate: Date): string {
    const isMonthEqual = startOfWeekDate.getMonth() === endOfWeekDate.getMonth();
    const isYearEqual = startOfWeekDate.getFullYear() === endOfWeekDate.getFullYear();
    const startDay = format(startOfWeekDate, this.dayNumber_formatString);
    const startMonth = this.i18next.transform(format(startOfWeekDate, this.monthName_formatString).toLowerCase());
    const startYear = format(startOfWeekDate, this.yearNumber_formatString);
    const endDay = format(endOfWeekDate, this.dayNumber_formatString);
    const endMonth = this.i18next.transform(format(endOfWeekDate, this.monthName_formatString).toLowerCase());
    const endYear = format(endOfWeekDate, this.yearNumber_formatString);

    if (!isYearEqual) {
      return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
    }
    if (isMonthEqual) {
      return `${startDay} - ${endDay} ${endMonth} ${endYear}`;
    }
    return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${endYear}`;
  }

  //#endregion

  //#region DAY
  getCurrentDayDateInterval(dateInterval: DateInterval): DateInterval {
    const startOfDayDate = startOfDay(dateInterval?.from ?? new Date());
    const endOfDayDate = endOfDay(startOfDayDate);
    return this.dateIntervalFactoryBuilder(startOfDayDate, endOfDayDate);
  }

  getNextDayDateInterval(selectedDateInterval) {
    const nextDayDate = addDays(selectedDateInterval.from, 1);
    const endOfDayDate = endOfDay(nextDayDate);
    return this.dateIntervalFactoryBuilder(nextDayDate, endOfDayDate);
  }

  getPreviousDayDateInterval(selectedDateInterval) {
    const previousDayDate = startOfDay(subDays(selectedDateInterval.to, 1));
    const endOfDayDate = endOfDay(previousDayDate);
    return this.dateIntervalFactoryBuilder(previousDayDate, endOfDayDate);
  }

  getDayToString(selectedDateInterval): string {
    const dayWithDate = format(selectedDateInterval.to, this.day_formatString, {
      locale: this.supportedLanguages[this.userLanguage],
    });
    return dayWithDate;
  }

  //#endregion

  //#region  SUPPORTING FUNCTIONS
  dateIntervalFactoryBuilder(fromDate: Date, toDate: Date): DateInterval {
    return { from: fromDate, to: toDate };
  }

  getAllDatesBetweenInterval(selectedDateInterval: DateInterval) {
    return eachDayOfInterval({ start: selectedDateInterval.from, end: selectedDateInterval.to });
  }

  //#endregion
}

export function convertDurationToHeight(dateFrom, dateTo): number {
  const minutesDifference = Math.abs(differenceInMinutes(dateFrom, dateTo));
  return minutesDifference / 7;
}

export function stdTimezoneOffset(date) {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

export function isDstObserved(date: Date) {
  return date.getTimezoneOffset() < stdTimezoneOffset(date);
}
