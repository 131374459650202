import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { faCrown } from '@fortawesome/pro-regular-svg-icons';
import { setYear } from 'date-fns/setYear';

@Component({
  selector: 'nxh-player-form',
  templateUrl: './player-form.component.html',
  styleUrls: ['./player-form.component.scss'],
})
export class PlayerFormComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;

  faCrown = faCrown;

  constructor() {}

  ngOnInit() {
    // just to check wheter it is disabled after form submit
    this.formGroup.get('firstName').disable({ emitEvent: false });
    this.formGroup.get('nextBirthDay').disable({ emitEvent: false });

    this.formGroup.get('birthDate').valueChanges.subscribe((birthDate) => {
      if (birthDate) {
        const nextBirthDay = setYear(birthDate, new Date().getFullYear());
        this.formGroup.get('nextBirthDay').setValue(nextBirthDay);
      } else {
        this.formGroup.get('nextBirthDay').setValue(null);
      }
    });
  }

  get middleNameVisible() {
    return !!this.formGroup.get('middleName');
  }
}
