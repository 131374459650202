import { Pipe, PipeTransform } from '@angular/core';
import { DAY } from '@nexuzhealth/shared-domain';

@Pipe({
  name: 'keys',
})
export class KeysPipe implements PipeTransform {
  transform(value: { weekday: DAY }[]): DAY[] {
    return value?.map(({ weekday }) => weekday) ?? [];
  }
}
