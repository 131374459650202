import { EmbeddedViewRef, Injectable, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

// https://github.com/fullcalendar/fullcalendar-angular/issues/204#issuecomment-652505774
@Injectable()
export class TemplateHelperService implements OnDestroy {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly views = new Map<string, EmbeddedViewRef<any>>();

  public viewManagement: 'auto' | 'manual' = 'auto';

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnDestroy(): void {
    this.destroyViews();
  }

  public destroyViews(): void {
    for (const view of this.views.values()) {
      view.destroy();
    }
    this.views.clear();
  }

  public getTemplateRootNodes<T>(
    template: TemplateRef<{ $implicit: T }>,
    id: string,
    context: T,
    comparator?: (v1: T, v2: T) => boolean,
  ) {
    const view = this.getView(template, id, context, comparator);
    this.views.set(id, view);
    return view.rootNodes;
  }

  private getNewView<T>(template: TemplateRef<{ $implicit: T }>, context: T): EmbeddedViewRef<{ $implicit: T }> {
    const view = this.viewContainerRef.createEmbeddedView(template, { $implicit: context });
    view.detectChanges();
    return view;
  }

  private getView<T>(
    template: TemplateRef<{ $implicit: T }>,
    id: string,
    context: T,
    comparator?: (v1: T, v2: T) => boolean,
  ): EmbeddedViewRef<{ $implicit: T }> {
    const view = this.views.get(id);
    if (view) {
      if (comparator && comparator(view.context.$implicit, context)) {
        view.markForCheck();
        return view;
      } else if (this.viewManagement === 'auto') {
        this.destroyView(id);
      }
    }
    return this.getNewView(template, context);
  }

  private destroyView(id: string): void {
    const view = this.views.get(id);
    if (view) {
      const index = this.viewContainerRef.indexOf(view);
      if (index !== -1) {
        this.viewContainerRef.remove(index);
      }
      view.destroy();
      this.views.delete(id);
    }
  }
}
