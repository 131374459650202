<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Dummy page template</h1>
      <p>
        This is an example of the toolkit page layout. Start from here! Search for 'dummy-page.component' in your
        codebase to use it.
      </p>
    </div>
  </div>
</div>
<div class="toolkit__body">
  <div class="toolkit__body__content" nxhScrollspyContainer>
    <div
      class="toolkit__body__content__block"
      id="block1"
      nxhScrollspyTarget="block1"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Block1</h2>
      <p>
        If you have a screencast (mp4), place it in the assets-folder in the map screencasts. Then link it in the
        video-tag below.
      </p>

      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock1" (click)="showExampleBlock1 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock1" (click)="showExampleBlock1 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>

      <div *ngIf="showExampleBlock1">
        <div class="card">
          <div class="card-body">
            <div class="toolkit__screencast">
              <iframe
                src="https://www.youtube.com/embed/rUxt5j99ZrU"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock1">
        <pre><code language="html" [highlight]="block1Code"></code></pre>
      </div>
    </div>
    <div
      class="toolkit__body__content__block"
      id="block2"
      nxhScrollspyTarget="block2"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Block2</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock2" (click)="showExampleBlock2 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock2" (click)="showExampleBlock2 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showExampleBlock2">
        <div class="card">
          <div class="card-body">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant. Sed id
              semper risus in hendrerit gravida. Vel facilisis volutpat est velit egestas dui id. Et molestie ac feugiat
              sed lectus vestibulum mattis. Egestas diam in arcu cursus euismod quis viverra nibh cras. Justo laoreet
              sit amet cursus. Adipiscing commodo elit at imperdiet. Proin fermentum leo vel orci.
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock2">
        <pre><code language="html" [highlight]="block2Code"></code></pre>
      </div>
    </div>
    <div
      class="toolkit__body__content__block"
      id="block3"
      nxhScrollspyTarget="block3"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Block3</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock3" (click)="showExampleBlock3 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock3" (click)="showExampleBlock3 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>
      <div *ngIf="showExampleBlock3">
        <div class="card">
          <div class="card-body">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant. Sed id
              semper risus in hendrerit gravida. Vel facilisis volutpat est velit egestas dui id. Et molestie ac feugiat
              sed lectus vestibulum mattis. Egestas diam in arcu cursus euismod quis viverra nibh cras. Justo laoreet
              sit amet cursus. Adipiscing commodo elit at imperdiet. Proin fermentum leo vel orci.
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock3">
        <pre><code language="html" [highlight]="block3Code"></code></pre>
      </div>
    </div>
  </div>

  <div class="toolkit__body__scrollspy">
    <ul>
      <li (click)="scrollTo('block1')" [class.is-active]="(selected$ | async) === 'block1'">Block1</li>
      <li (click)="scrollTo('block2')" [class.is-active]="(selected$ | async) === 'block2'">Block2</li>
      <li (click)="scrollTo('block3')" [class.is-active]="(selected$ | async) === 'block3'">Block3</li>
    </ul>
  </div>
</div>
