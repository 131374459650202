import {
  AfterContentInit,
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  QueryList,
  Self,
} from '@angular/core';
import { FormGroupDirective, FormGroupName } from '@angular/forms';
import { ValidationErrorsComponent } from 'ngx-valdemort';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { INVALIDABLE_CONTROL } from '../../../shared/form-helper.domain';
import { disabledChanges } from '../../../shared/form-helper.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nh-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
})
export class CheckboxGroupComponent implements AfterContentInit, OnDestroy {
  @Input() required = false;
  @Input() label?: string;
  @Input() direction?: 'row' | 'col' = 'col';
  @Input() compact = true;
  @Input() controlName!: string;

  @ContentChildren(ValidationErrorsComponent, { descendants: false })
  errorsComponent!: QueryList<ValidationErrorsComponent>;
  @HostBinding('class')
  classes = INVALIDABLE_CONTROL;
  disabledSubj = new BehaviorSubject(false);
  private destroyedSubj = new Subject<void>();

  constructor(
    @Self() @Optional() private fgn: FormGroupName,
    @Self() @Optional() private fgd: FormGroupDirective,
  ) {}

  ngAfterContentInit(): void {
    const fg = this.fgn ?? this.fgd;
    if (!fg) {
      return;
    }

    disabledChanges(fg.control)
      .pipe(takeUntil(this.destroyedSubj))
      .subscribe((disabled) => this.disabledSubj.next(disabled));

    if (!(this.errorsComponent?.length > 0)) {
      return;
    }

    const errorsComponent = this.errorsComponent.get(0);

    if (errorsComponent) {
      if (!errorsComponent.label) {
        errorsComponent.label = this.label || null;
      }
      errorsComponent.control = fg.control;
    }
  }

  ngOnDestroy(): void {
    this.destroyedSubj.next();
    this.destroyedSubj.complete();
  }
}
