<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Dropdown menu</h1>
    </div>
  </div>
</div>
<div class="toolkit__body">
  <div class="toolkit__body__content" nxhScrollspyContainer>
    <div
      class="toolkit__body__content__block"
      id="block1"
      nxhScrollspyTarget="block1"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Dropdown menu</h2>
      <p>
        More info:
        <a target="_blank" href="https://ng-bootstrap.github.io/#/components/dropdown/examples"
          >https://ng-bootstrap.github.io/#/components/dropdown/examples</a
        >
      </p>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock1" (click)="showExampleBlock1 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock1" (click)="showExampleBlock1 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>

      <div *ngIf="showExampleBlock1">
        <div class="card">
          <div class="card-body">
            <div ngbDropdown class="hide-default-down-icon">
              <button nxh-button [outline]="true" ngbDropdownToggle>
                Dropdown
                <fa-icon icon="chevron-down" class="chevron"></fa-icon>
              </button>
              <div ngbDropdownMenu>
                <button class="btn" ngbDropdownItem>
                  <fa-icon icon="pills"></fa-icon>
                  <span>Default Action</span>
                </button>
                <button class="btn dropdown-item-primary" ngbDropdownItem>
                  <fa-icon icon="ruler"></fa-icon>
                  <span>Primary Action</span>
                </button>
                <button class="btn dropdown-item-success" ngbDropdownItem>
                  <fa-icon icon="heart-rate"></fa-icon>
                  <span>Success Action</span>
                </button>
                <button class="btn dropdown-item-warning" ngbDropdownItem>
                  <fa-icon icon="heart-rate"></fa-icon>
                  <span>Warning Action</span>
                </button>
                <button class="btn dropdown-item-danger" ngbDropdownItem>
                  <fa-icon icon="trash-alt"></fa-icon>
                  <span>Danger action</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock1">
        <pre><code language="html" [highlight]="block1Code"></code></pre>
      </div>
    </div>

    <div
      class="toolkit__body__content__block"
      id="block3"
      nxhScrollspyTarget="block3"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h3 class="page-title">Dropdown with headers</h3>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock3" (click)="showExampleBlock3 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock3" (click)="showExampleBlock3 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>

      <div *ngIf="showExampleBlock3">
        <div class="card">
          <div class="card-body">
            <div ngbDropdown class="hide-default-down-icon">
              <button nxh-button [outline]="true" ngbDropdownToggle>
                Dropdown with headers
                <fa-icon icon="chevron-down" class="chevron"></fa-icon>
              </button>
              <div ngbDropdownMenu>
                <label class="dropdown-header">Test label hier</label>
                <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
                  <fa-icon icon="plus"></fa-icon>
                  <span>Default Action</span>
                </button>
                <label class="dropdown-header">Dit is een ander label</label>
                <button class="btn dropdown-item-success" ngbDropdownItem (click)="doSomething()">
                  <fa-icon icon="ruler"></fa-icon>
                  <span>Success Action</span>
                </button>
                <button class="btn dropdown-item-danger" ngbDropdownItem (click)="doSomething()">
                  <fa-icon icon="trash-alt"></fa-icon>
                  <span>Danger action</span>
                </button>
                <label class="dropdown-header">Dit is nog een label</label>
                <button class="btn" ngbDropdownItem (click)="doSomething()">
                  <nxh-bullet status="active">Hoge bloeddruk</nxh-bullet>
                </button>
                <button class="btn" ngbDropdownItem (click)="doSomething()">
                  <nxh-bullet status="danger">Hoge bloeddruk</nxh-bullet>
                </button>
                <hr />
                <button class="btn" ngbDropdownItem (click)="doSomething()">
                  <span class="text-primary">Nieuw zorgelement toevoegen</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock3">
        <pre><code [highlight]="block3Code" language="html"></code></pre>
      </div>
    </div>
    <div
      class="toolkit__body__content__block"
      id="block4"
      nxhScrollspyTarget="block4"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h4 class="page-title">Dropdown item inner scroll</h4>
      <div class="toolkit__body__content__block__tabbox">
        <button
          nxh-button
          buttonType="icon"
          [class.active]="showExampleBlock4"
          icon="image"
          (click)="showExampleBlock4 = true"
        >
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock4" (click)="showExampleBlock4 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>

      <div *ngIf="showExampleBlock4">
        <div class="card">
          <div class="card-body">
            <div ngbDropdown class="hide-default-down-icon">
              <button nxh-button [outline]="true" ngbDropdownToggle>
                Dropdown item inner scroll
                <fa-icon icon="chevron-down" class="chevron"></fa-icon>
              </button>
              <div ngbDropdownMenu>
                <div class="dropdown-container--limited">
                  <label class="dropdown-header">Test label hier</label>
                  <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
                    <fa-icon icon="plus"></fa-icon>
                    <span>Default Action</span>
                  </button>
                  <label class="dropdown-header">Dit is een ander label</label>
                  <button class="btn dropdown-item-success" ngbDropdownItem (click)="doSomething()">
                    <fa-icon icon="ruler"></fa-icon>
                    <span>Success Action</span>
                  </button>
                  <button class="btn dropdown-item-danger" ngbDropdownItem (click)="doSomething()">
                    <fa-icon icon="trash-alt"></fa-icon>
                    <span>Danger action</span>
                  </button>
                  <label class="dropdown-header">Dit is nog een label</label>
                  <button class="btn" ngbDropdownItem (click)="doSomething()">
                    <nxh-bullet status="active">Hoge bloeddruk</nxh-bullet>
                  </button>
                  <button class="btn" ngbDropdownItem (click)="doSomething()">
                    <nxh-bullet status="danger">Hoge bloeddruk</nxh-bullet>
                  </button>
                  <label class="dropdown-header">Test label hier</label>
                  <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
                    <fa-icon icon="plus"></fa-icon>
                    <span>Default Action</span>
                  </button>
                  <label class="dropdown-header">Dit is een ander label</label>
                  <button class="btn dropdown-item-success" ngbDropdownItem (click)="doSomething()">
                    <fa-icon icon="ruler"></fa-icon>
                    <span>Success Action</span>
                  </button>
                  <button class="btn dropdown-item-danger" ngbDropdownItem (click)="doSomething()">
                    <fa-icon icon="trash-alt"></fa-icon>
                    <span>Danger action</span>
                  </button>
                  <label class="dropdown-header">Dit is nog een label</label>
                  <button class="btn" ngbDropdownItem (click)="doSomething()">
                    <nxh-bullet status="active">Hoge bloeddruk</nxh-bullet>
                  </button>
                  <button class="btn" ngbDropdownItem (click)="doSomething()">
                    <nxh-bullet status="danger">Hoge bloeddruk</nxh-bullet>
                  </button>
                  <label class="dropdown-header">Test label hier</label>
                  <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
                    <fa-icon icon="plus"></fa-icon>
                    <span>Default Action</span>
                  </button>
                  <label class="dropdown-header">Dit is een ander label</label>
                  <button class="btn dropdown-item-success" ngbDropdownItem (click)="doSomething()">
                    <fa-icon icon="ruler"></fa-icon>
                    <span>Success Action</span>
                  </button>
                  <button class="btn dropdown-item-danger" ngbDropdownItem (click)="doSomething()">
                    <fa-icon icon="trash-alt"></fa-icon>
                    <span>Danger action</span>
                  </button>
                  <label class="dropdown-header">Dit is nog een label</label>
                  <button class="btn" ngbDropdownItem (click)="doSomething()">
                    <nxh-bullet status="active">Hoge bloeddruk</nxh-bullet>
                  </button>
                  <button class="btn" ngbDropdownItem (click)="doSomething()">
                    <nxh-bullet status="danger">Hoge bloeddruk</nxh-bullet>
                  </button>
                </div>
                <hr />
                <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
                  <fa-icon icon="plus"></fa-icon>
                  <span>Voeg hier iets toe</span>
                </button>
                <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
                  <fa-icon icon="ruler"></fa-icon>
                  <span>Klik op deze button</span>
                </button>
                <button class="btn dropdown-item-primary" ngbDropdownItem (click)="doSomething()">
                  <fa-icon icon="plus"></fa-icon>
                  <span>Doe nog iets anders</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock4">
        <pre><code [highlight]="block4Code" language="html"></code></pre>
      </div>
    </div>
  </div>

  <div class="toolkit__body__scrollspy">
    <ul>
      <li (click)="scrollTo('block1')" [class.is-active]="(selected$ | async) === 'block1'">Dropdown menu</li>
      <li (click)="scrollTo('block3')" [class.is-active]="(selected$ | async) === 'block3'">Custom dropdown menu</li>
      <li (click)="scrollTo('block4')" [class.is-active]="(selected$ | async) === 'block4'">
        Dropdown item inner scroll
      </li>
    </ul>
  </div>
</div>
