import {
  AfterViewInit,
  Directive,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FormStore } from '@nexuzhealth/shared-ui-toolkit/dirty-check/data-access';
import { merge, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { FormHelper, reset_config, setFocus } from '../../shared/form-helper.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[nhForm]',
  exportAs: 'nhForm',
  providers: [FormHelper],
})
export class FormHelperDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() config: {
    formName?: string;
    noFocus?: boolean;
    focusId?: string;
    setFocusWhenStable?: boolean;
    isModal?: boolean;
    noDirtyCheck?: boolean;
  } = {};

  private customSubmitButtonCLicked$ = new Subject<void>();
  private formGroupDirective = inject(FormGroupDirective, { self: true });
  submitButtonClicked$ = merge(this.formGroupDirective.ngSubmit, this.customSubmitButtonCLicked$);

  private reset$ = new Subject<void>();
  private destroy$ = new Subject<void>();
  public formName!: string;
  private outlet!: string;

  constructor(
    @Self() private elementRef: ElementRef,
    public helper: FormHelper,
    private formStore: FormStore,
    private renderer: Renderer2,
    @Optional() private route: ActivatedRoute,
  ) {
    helper.setFormHelperDirective(this);
  }

  get nativeElement() {
    return this.elementRef.nativeElement;
  }

  get form() {
    return this.formGroupDirective.form;
  }

  get submitted() {
    return this.formGroupDirective.submitted;
  }

  ngOnInit(): void {
    this.formName = this.config.formName || 'formName-' + Math.random();
    this.outlet = this.route?.snapshot?.data?.['canDeactivateOutlet'] ?? 'primary';

    this.renderer.setAttribute(this.nativeElement, 'id', this.formName);

    merge(this.form.valueChanges, this.reset$)
      .pipe(
        map(() => this.form.dirty),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe((dirty) => {
        this.setDirty(dirty);
      });

    this.helper.submitting$.pipe(takeUntil(this.destroy$)).subscribe((submitting) => {
      if (submitting) {
        this.renderer.addClass(this.nativeElement, 'form--submitting');
      } else {
        this.renderer.removeClass(this.nativeElement, 'form--submitting');
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.config?.noFocus) {
      return;
    }

    if (this.config?.setFocusWhenStable) {
      // setting focus in next tick - layout may not have been fully stabilized
      setTimeout(() => this.setFocusOnInitialField());
    } else {
      this.setFocusOnInitialField();
    }
  }

  ngOnDestroy(): void {
    this.cleanupFormStore();
    this.destroy$.next();
    this.destroy$.complete();
  }

  setDirty(dirty: boolean) {
    if (this.config?.noDirtyCheck) {
      return;
    }
    if (this.config?.isModal) {
      this.formStore.setModalDirty(dirty);
    } else {
      this.formStore.setFormDirty(this.formName, dirty, this.outlet);
    }
  }

  reset(resetOnSuccess: boolean | undefined | reset_config) {
    if (resetOnSuccess === true || resetOnSuccess === 'reset-on-config') {
      // todo: note emitEvent is true (the default) for backwards compatibility, cf. https://jira.uz.kuleuven.ac.be/browse/FEG-704
      this.formGroupDirective.form.reset({});
    } else if (resetOnSuccess === 'reset-initial-value-on-success') {
      // todo check FormStore!!!
      this.formGroupDirective.resetForm();
    } else {
      // todo: kept for backwards compatibility, cf. https://jira.uz.kuleuven.ac.be/browse/FEG-704
      this.formGroupDirective.resetForm(this.form.getRawValue());
    }

    this.markPristine();
  }

  markPristine() {
    this.formGroupDirective.form.markAsPristine();
    this.reset$.next();
  }

  customSubmitButtonClicked() {
    this.customSubmitButtonCLicked$.next();
  }

  private setFocusOnInitialField() {
    const el = this.config.focusId
      ? this.nativeElement.querySelector(
          `#${this.config.focusId} input, #${this.config.focusId} textarea, #${this.config.focusId} select, input#${this.config.focusId}, textarea#${this.config.focusId}, select#${this.config.focusId}`,
        )
      : this.nativeElement.querySelector('input,textarea,select');

    setFocus(el);
  }

  private cleanupFormStore() {
    if (this.config?.noDirtyCheck) {
      return;
    }
    if (this.config?.isModal) {
      this.formStore.markModalPristine();
    } else {
      this.formStore.destroyForm(this.outlet, this.formName);
    }
  }
}
