import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'nxh-confirm-demo',
  templateUrl: './confirm-demo.component.html',
  styleUrls: ['./confirm-demo.component.scss'],
})
export class ConfirmDemoComponent {
  simpleCase = `
    <button class="btn btn-danger"
            nxhConfirm
            [confirmText]="'are you sure??'"
            (confirm)="remove(tr)">
      remove
    </button>
  `;

  withBusyIndicator = `
    <button class="btn btn-danger"
            nxhConfirm
            [confirmText]="'are you sure??'"
            [busy]="busy$ | async"
            (confirm)="removeWithBusyIndicator(tr)">
      {{ '_therapeutic-relation.remove' | i18next }}
    </button>

    private busySubj = new BehaviorSubject(false);

    removeWithBusyIndicator() {
      this.busySubj.next(true);
      setTimeout(() => {
        alert('gone');
        this.busySubj.next(false);
      }, 5000);
    }

    get busy$() {
      return this.busySubj.asObservable();
    }

  `;

  warningConfirm = `
    <button class="btn btn-warning"
            nxhConfirmWarning
            (confirm)="send(tr)">
      send
    </button>
  `;

  infoConfirm = `
    <button class="btn btn-info"
            nxhConfirmInfo
            (confirm)="execute(tr)">
      execute
    </button>-
  `;

  private busySubj = new BehaviorSubject(false);

  constructor() {}

  remove() {
    console.log('removed');
  }

  removeWithBusyIndicator() {
    console.log('start removing');
    this.busySubj.next(true);
    setTimeout(() => {
      console.log('removed');
      this.busySubj.next(false);
    }, 5000);
  }

  send() {
    console.log('sent');
  }

  execute() {
    console.log('executed');
  }

  get busy$() {
    return this.busySubj.asObservable();
  }
}
