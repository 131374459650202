<h3>Timepicker component</h3>

<nxh-time-picker-input
  max="14:00"
  min="00:00"
  [step]="900"
  label="Tijdstip"
  [required]="true"
  [autofocus]="true"
  autocomplete="off"
  [nxhFocus]="true"
  [formControl]="timePickerControl"
></nxh-time-picker-input>
<pre>Value: {{ timePickerControl.value }}</pre>
<br />
<hr />
<br />
<h3>Timepicker code</h3>
<pre><code language="html" [highlight]="timePickerCode"></code></pre>

<p>Want to work with <code>ClockTime</code>? Simply set [useClockTime]="true".</p>
