import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { CollapseChevronButtonComponent } from './collapse-chevron-button/collapse-chevron-button.component';
import { CollapseChevronComponent } from './collapse-chevron/collapse-chevron.component';
import { CollapseLinkButtonComponent } from './collapse-link-button/collapse-link-button.component';
import { CollapsePanelComponent } from './collapse-panel/collapse-panel.component';
import { CollapseToggleDirective } from './collapse-toggle.directive';
import { CollapseComponent } from './collapse/collapse.component';

@NgModule({
  imports: [CommonModule, IconsModule, ButtonModule],
  declarations: [
    CollapseComponent,
    CollapseChevronComponent,
    CollapseChevronButtonComponent,
    CollapseLinkButtonComponent,
    CollapsePanelComponent,
    CollapseToggleDirective,
  ],
  exports: [
    CollapseComponent,
    CollapseChevronComponent,
    CollapseChevronButtonComponent,
    CollapseLinkButtonComponent,
    CollapsePanelComponent,
    CollapseToggleDirective,
  ],
})
export class CollapseModule {}
