<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Input lists</h1>
      <p>Basic use of an input list and input group list nested form with the nxh-add-remove-buttons</p>
    </div>
  </div>
</div>

<div class="toolkit__body">
  <div class="toolkit__body__content" nxhScrollspyContainer>
    <form [formGroup]="form">
      <div
        class="toolkit__body__content__block"
        id="inputList"
        nxhScrollspyTarget="inputList"
        (scrollInViewPort)="scrolledInView($event)"
      >
        <h2 class="page-title">Input List</h2>
        <div class="card">
          <div class="card-body">
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
              <li [ngbNavItem]="1">
                <a ngbNavLink>Template</a>
                <ng-template ngbNavContent>
                  <pre><code language="html" [highlight]="template"></code></pre>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink>Code</a>
                <ng-template ngbNavContent>
                  <pre><code language="typescript" [highlight]="code"></code></pre>
                </ng-template>
              </li>
            </ul>

            <div [ngbNavOutlet]="nav"></div>

            <div
              class="input-list"
              formArrayName="inputList"
              *ngFor="let input of inputList.controls; let i = index; let last = last"
            >
              <nh-control>
                <input [formControlName]="i" />
              </nh-control>
              <nxh-add-remove-buttons
                [showAdd]="last"
                (add)="addInput()"
                (remove)="removeInput(i)"
              ></nxh-add-remove-buttons>
            </div>
          </div>
        </div>
      </div>
      <div
        class="toolkit__body__content__block"
        id="inputGroupList"
        nxhScrollspyTarget="inputGroupList"
        (scrollInViewPort)="scrolledInView($event)"
      >
        <h2 class="page-title">Input Group List</h2>
        <div class="card">
          <div class="card-body">
            <ul ngbNav #navGroup="ngbNav" class="nav-tabs">
              <li [ngbNavItem]="1">
                <a ngbNavLink>Template</a>
                <ng-template ngbNavContent>
                  <pre><code language="html" [highlight]="templateGroup"></code></pre>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink>Code</a>
                <ng-template ngbNavContent>
                  <pre><code language="typescript" [highlight]="codeGroup"></code></pre>
                </ng-template>
              </li>
            </ul>

            <div [ngbNavOutlet]="navGroup"></div>

            <div
              class="input-list input-list--group"
              formArrayName="inputGroupList"
              *ngFor="let input of inputGroupList.controls; let i = index; let last = last"
            >
              <nxh-listgroup-form [formGroup]="$any(input)" class="list-item"></nxh-listgroup-form>
              <nxh-add-remove-buttons
                [showAdd]="last"
                (add)="addInputGroup()"
                (remove)="removeInputGroup(i)"
              ></nxh-add-remove-buttons>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="toolkit__body__scrollspy">
    <ul>
      <li (click)="scrollTo('inputList')" [class.is-active]="(selected$ | async) === 'inputList'">Input List</li>
      <li (click)="scrollTo('inputGroupList')" [class.is-active]="(selected$ | async) === 'inputGroupList'">
        Input Group List
      </li>
    </ul>
  </div>
</div>
