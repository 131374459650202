import { Injectable } from '@angular/core';
import { filterNilValue, Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { filterNullOrUndefined, hexToRGB, manipulateHex } from '@nexuzhealth/shared-util';
import { Theme } from './theme';
import { ThemeStore } from './theme.store';

@Injectable({ providedIn: 'root' })
export class ThemeQuery extends Query<Theme & { isDefault: boolean }> {
  primaryColor$: Observable<string>;
  logoUrl$: Observable<string>;
  backgroundImageUrl$: Observable<string>;
  supportPhone$: Observable<string>;
  supportEmail$: Observable<string>;
  manualUrl$: Observable<string>;
  client$: Observable<string>;

  constructor(protected override store: ThemeStore) {
    super(store);
    this.primaryColor$ = this.select('mainColor').pipe(filterNullOrUndefined);
    this.logoUrl$ = this.select('logo').pipe(filterNullOrUndefined);
    this.backgroundImageUrl$ = this.select('backgroundImage').pipe(filterNilValue());
    this.supportPhone$ = this.select('supportPhone').pipe(filterNullOrUndefined);
    this.supportEmail$ = this.select('supportEmail').pipe(filterNullOrUndefined);
    this.manualUrl$ = this.select('manualUrl').pipe(filterNullOrUndefined);
    this.client$ = this.select('shortCode').pipe(filterNullOrUndefined);

    this.primaryColor$.subscribe((primaryColor) => {
      document.documentElement.style.setProperty(`--primaryColor`, primaryColor);
      document.documentElement.style.setProperty(`--primaryColorRgb`, hexToRGB(primaryColor));
      document.documentElement.style.setProperty(`--primaryColorLight`, manipulateHex(0.2, primaryColor));
      document.documentElement.style.setProperty(`--primaryColorDark`, manipulateHex(-0.2, primaryColor));
      document.documentElement.style.setProperty(`--primaryColorWashed`, manipulateHex(0.92, primaryColor));
      document.documentElement.style.setProperty(`--primaryColorAlt`, manipulateHex(0.77, primaryColor));
    });
  }
}
