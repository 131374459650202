import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'nxh-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {
  @Input() parentGroup: UntypedFormGroup;
  private prefix = Math.random();

  constructor() {}

  ngOnInit() {
    // we can also add a control in a subform
    this.parentGroup.addControl(
      'address',
      new UntypedFormGroup(
        {
          address1: new UntypedFormControl(null, Validators.required),
          address2: new UntypedFormControl(),
          country: new UntypedFormControl(),
          city: new UntypedFormControl(),
          zip: new UntypedFormControl(),
        },
        { validators: validateAddress(), updateOn: 'blur' },
      ),
    );
  }

  get address() {
    return this.parentGroup.get('address') as UntypedFormGroup;
  }

  prefixId(fieldName) {
    return this.prefix + '-' + fieldName;
  }
}

function validateAddress(): ValidatorFn {
  return (formGroup: UntypedFormGroup) => {
    const country = formGroup.get('country').value;
    const city = formGroup.get('city').value;
    const cityNotInCountry = country && city && (country !== 'belgium' || city !== 'louvain');
    return cityNotInCountry ? { 'city-not-in-country': true } : null;
  };
}
