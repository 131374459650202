import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

export interface TypeaheadConfig {
  dueTime: number;
  startSearchingAtLength: number;
}

export function typeahead<T>(
  searchFunction: (term) => Observable<T[]>,
  loadingSubj: Subject<boolean>,
  overrideConfig?: Partial<TypeaheadConfig>,
) {
  return function (source: Observable<string>): Observable<T[]> {
    const config: TypeaheadConfig = {
      dueTime: overrideConfig?.dueTime || 300,
      startSearchingAtLength: overrideConfig?.startSearchingAtLength || 2,
    };

    return source.pipe(
      debounceTime(config.dueTime),
      distinctUntilChanged(),
      tap((_term) => {
        if (loadingSubj) {
          loadingSubj.next(_term?.length >= config.startSearchingAtLength);
        }
      }),
      switchMap((_term) => {
        return _term && _term.length >= config.startSearchingAtLength
          ? searchFunction(_term).pipe(catchError(() => of([])))
          : of([]);
      }),
      tap(() => {
        if (loadingSubj) {
          loadingSubj.next(false);
        }
      }),
    );
  };
}
