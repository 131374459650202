import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { ChevronService } from '../chevron.service';

@Component({
  selector: 'nxh-collapse-chevron-button',
  templateUrl: './collapse-chevron-button.component.html',
  styleUrls: ['./collapse-chevron-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapseChevronButtonComponent {
  icon$ = inject(ChevronService).icon$;
}
