import { Injectable } from '@angular/core';
import {
  AgendaPlannerQuery,
  createAgendaPlannerTaskViewModel,
  DateInterval,
} from '@nexuzhealth/shared-util-agenda-planner';
import { of } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';

@Injectable()
export class PlannerMockApi {
  constructor(private agendaPlannerQuery: AgendaPlannerQuery) {}

  //Date interval will alwasy be day from 00:00 to 23:59:59
  listAllObjects(dateInterval: DateInterval) {
    const mockData1 = { name: 'Marianne Daemen', address: 'Waterlelistraat 25, 3600 Genk', gender: 'female' };
    const mockData2 = { name: 'Evelien Schouteden', address: 'Bosstraat 12, 3600 Genk', gender: 'female' };
    const mockData3 = { name: 'Kris Aerts', address: 'Willekesmolenlaan 1, 3500 Hasselt', gender: 'male' };
    const mockData4 = { name: 'Stephanie Paesen', address: 'Marcelhabetslaan 4, 3600 Genk', gender: 'female' };
    const tasks = [
      createAgendaPlannerTaskViewModel(
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 9, 0),
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 9, 45),
        ['HYZ', 'WND', 'MED'],
        mockData1,
      ),
      createAgendaPlannerTaskViewModel(
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 9, 15),
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 9, 45),
        ['HYZ'],
        mockData2,
      ),
      createAgendaPlannerTaskViewModel(
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 9, 20),
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 9, 45),
        ['MED'],
        mockData3,
      ),
      createAgendaPlannerTaskViewModel(
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 9, 30),
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 9, 45),
        ['LKE'],
        mockData4,
      ),
      createAgendaPlannerTaskViewModel(
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 11, 40),
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 12, 0),
        ['HYZ, ZLF'],
        mockData2,
      ),
      createAgendaPlannerTaskViewModel(
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 13, 30),
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 13, 40),
        ['MED', 'ZLF'],
        mockData3,
      ),
      createAgendaPlannerTaskViewModel(
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 8, 0),
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 10, 30),
        ['LKE'],
        mockData2,
      ),
      createAgendaPlannerTaskViewModel(
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 10, 45),
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 10, 55),
        ['HYZ', 'INSP'],
        mockData1,
      ),
      createAgendaPlannerTaskViewModel(
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 16, 15),
        new Date(dateInterval.from.getFullYear(), dateInterval.from.getMonth(), dateInterval.from.getDate(), 17, 15),
        ['INSP,MED'],
        mockData3,
      ),
    ];
    return of(tasks).pipe(
      delay(500),
      mergeMap((v) => {
        return this.agendaPlannerQuery.groupAllTasksByHour(v);
      }),
    );
  }
}
