import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WeatherService {
  constructor(private _http: HttpClient) {}

  dailyForecast() {
    return this._http
      .get('//api.openweathermap.org/data/2.5/forecast?q=Hasselt,be&APPID=0e249307ce6b65bee27ae00b39cb26d3')
      .pipe(map((result) => result));
  }
}
