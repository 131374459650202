import { Injectable } from '@angular/core';
import { LogService, Preloadable } from '@nexuzhealth/shared-util';
import { firstValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { UserPreferencesApiService } from '../api/user-preferences-api.service';
import { UserPreferencesStore } from '../state/preferences-store.service';

@Injectable({ providedIn: 'root' })
export class UserPreferencesService implements Preloadable {
  constructor(
    private userPreferencesStore: UserPreferencesStore,
    private userPreferencesApiService: UserPreferencesApiService,
    private logger: LogService,
  ) {}

  async preload(): Promise<{ preferredLanguage: string }> {
    const preferred: string = await firstValueFrom(this.loadPreferredLanguage());
    return {
      preferredLanguage: preferred,
    };
  }

  setPreferredLanguage(preferredLanguage: string) {
    this.userPreferencesStore.setPreferredLanguage(preferredLanguage);
    window.location.href =
      'https://' + window.location.host + window.location.pathname + '?language=' + preferredLanguage;
  }

  loadPreferredLanguage(): Observable<string> {
    this.logger.debug(`[UserPreferencesService] loading preferred language`);
    return this.userPreferencesApiService.getDefaultUserLanguage().pipe(
      tap((data) => {
        this.userPreferencesStore.setPreferredLanguage(data);
      }),
    );
  }
}
