import { Component } from '@angular/core';

@Component({
  selector: 'nxh-lookforward-focus-demo',
  templateUrl: './lookforward-focus-demo.component.html',
  styleUrls: ['./lookforward-focus-demo.component.scss'],
})
export class LookforwardFocusDemoComponent {
  code = `
<div class="m-3">

  <input class="form-control" placeholder="just an input">

  <div class="mb-4 mt-4">
    <div nxhLookforwardFocus="unique-id-1" focusClass="text-primary">
      I want focus when one of the radio buttons has focus
    </div>
    <div class="form-check">
      <input nxhLookforwardFocusSource="unique-id-1" class="form-check-input" name="whatever" type="radio" id="1" [value]="1">
      <label class="form-check-label" for="1">1</label>
    </div>
    <div class="form-check">
      <input nxhLookforwardFocusSource="unique-id-1" class="form-check-input" name="whatever" type="radio" id="2" [value]="2">
      <label class="form-check-label" for="2">2</label>
    </div>
  </div>

  <input class="form-control" placeholder="another input">

  <div class="mb-4 mt-4">
    <div nxhLookforwardFocus="unique-id-2" [focusClasses]="['text-primary', 'bg-success']">
      I want focus when one of the radio buttons has focus
    </div>
    <div class="form-check">
      <input nxhLookforwardFocusSource="unique-id-2" class="form-check-input" name="nevermind" type="radio" id="3" [value]="3">
      <label class="form-check-label" for="3">3</label>
    </div>
    <div class="form-check">
      <input nxhLookforwardFocusSource="unique-id-2" class="form-check-input" name="nevermind" type="radio" id="3" [value]="4">
      <label class="form-check-label" for="4">4</label>
    </div>
  </div>

</div>
  `;

  constructor() {}
}
