import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { endOfDay, startOfDay } from 'date-fns';
import { DateInterval } from '../shared/models/date.model';

export interface AgendaPlannerState {
  selectedDateInterval: DateInterval;
  dirty: boolean;
}

export function createInitialAgendaPlannerState(): AgendaPlannerState {
  return {
    dirty: false,
    selectedDateInterval: { from: startOfDay(new Date()), to: endOfDay(new Date()) },
  };
}

@Injectable()
@StoreConfig({ name: 'agenda-planner' })
export class AgendaPlannerStore extends Store<AgendaPlannerState> {
  constructor() {
    super(createInitialAgendaPlannerState());
  }
}
