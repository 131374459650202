import {
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  Self,
  SimpleChange,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgSelectTemplatesComponent } from './ng-select-templates.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-select[addTag]',
})
export class CustomizeNgSelectAddTagDirective implements OnChanges, AfterViewInit {
  private static componentRef: ComponentRef<NgSelectTemplatesComponent>;

  @Input() addTagToItemsList = true;
  @Input() addTag!: (term: string) => any | Promise<any>;

  constructor(
    @Self() private ngSelect: NgSelectComponent,
    private resolver: ComponentFactoryResolver,
    private _vcr: ViewContainerRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const addTagChange: SimpleChange = changes['addTagChange'];
    const addTagToItemsList: SimpleChange = changes['addTagToItemsList'];
    if ((addTagChange && !addTagChange.isFirstChange()) || (addTagToItemsList && !addTagToItemsList.isFirstChange())) {
      this.setup();
    }
  }

  ngAfterViewInit(): void {
    this.setup();
  }

  private setup() {
    const defaultAddTag = (tag: string) => {
      return { [this.ngSelect.bindLabel]: tag, addTag: true };
    };

    let addTag = this.addTag || defaultAddTag;

    if (!this.addTagToItemsList) {
      addTag = this.withAddTagToItemsList(addTag);
    }

    // FEG-1523 - review whether this is still necessary
    // make sure NgSelectTemplatesComponent has been initialized
    this.loadNgSelectTemplates();

    this.ngSelect.addTag = addTag;
  }

  private withAddTagToItemsList(addTag: (term: string) => any | Promise<any>) {
    const _addTag = addTag;
    return (tag: string) => {
      const newTag = _addTag(tag);

      setTimeout(() => {
        const index = this.ngSelect.itemsList.items.indexOf(newTag);
        this.ngSelect.itemsList.items.splice(index);
      });

      return newTag;
    };
  }

  private loadNgSelectTemplates() {
    if (!CustomizeNgSelectAddTagDirective.componentRef) {
      const factory = this.resolver.resolveComponentFactory(NgSelectTemplatesComponent);
      CustomizeNgSelectAddTagDirective.componentRef = this._vcr.createComponent(factory);
    }

    return CustomizeNgSelectAddTagDirective.componentRef;
  }
}
