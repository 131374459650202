import { Directive, Input } from '@angular/core';
import { FormHelperDirective } from './form-helper.directive';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[connectNxhForm]' })
export class ConnectFormHelperDirective {
  public formHelperDirective?: FormHelperDirective | null;

  @Input() set connectNxhForm(formHelperDirective: FormHelperDirective) {
    this.formHelperDirective = formHelperDirective;
  }
}
