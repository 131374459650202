<div
  class="partial-date input-group"
  [formGroup]="form"
  [class.partial-date--disabled]="form.disabled"
  [class.compact]="compact"
  nxhBypassAutoValidate
>
  <input
    #first
    class="form-control"
    type="text"
    [attr.data-type]="fields['day'].type"
    [attr.maxlength]="fields['day'].maxLength"
    [placeholder]="fields['day'].placeholder | i18next"
    [formControlName]="fields['day'].type"
    (keydown)="onKeydown($event)"
    (input)="onInput($event)"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    (click)="onClick($event)"
  />{{ separator }}
  <input
    #second
    class="form-control"
    type="text"
    [attr.data-type]="fields['month'].type"
    [attr.maxlength]="fields['month'].maxLength"
    [placeholder]="fields['month'].placeholder | i18next"
    [formControlName]="fields['month'].type"
    (keydown)="onKeydown($event)"
    (input)="onInput($event)"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    (click)="onClick($event)"
  />{{ separator }}
  <input
    #third
    class="form-control"
    type="text"
    [attr.data-type]="fields['year'].type"
    [attr.maxlength]="fields['year'].maxLength"
    [placeholder]="fields['year'].placeholder | i18next"
    [formControlName]="fields['year'].type"
    (keydown)="onKeydown($event)"
    (input)="onInput($event)"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    (click)="onClick($event)"
  />
</div>
