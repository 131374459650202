import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstChars',
})
export class FirstCharsPipe implements PipeTransform {
  transform(value = '', nbrOfChars = 1): unknown {
    return value?.length > nbrOfChars ? value.substr(0, nbrOfChars) : value;
  }
}
