import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatterService extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      try {
        return {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parseInt(date[2], 10),
        };
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? `${date.day}`.padStart(2, '0') +
          this.DELIMITER +
          `${date.month}`.padStart(2, '0') +
          this.DELIMITER +
          `${date.year}`.padStart(2, '0')
      : '';
  }
}
