import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  templateUrl: './counter-bar-card-demo.component.html',
  styleUrls: ['./counter-bar-card-demo.component.scss'],
})
export class CounterBarCardDemoComponent implements OnInit {
  form: UntypedFormGroup;

  codeExample = `
    <div class="counter-bar-card">
      <div class="counter-bar-card__side-bar">1</div>
      <div>My card content goes here</div>
    </div>
  `;

  ngOnInit() {
    this.form = new UntypedFormGroup({
      firstname: new UntypedFormControl(),
      lastname: new UntypedFormControl(),
    });
  }
}
