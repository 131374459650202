<nxh-alert
  type="info"
  alertTitle="When using the new nhForm directive you don't have to worry about setting the focus on the first field yourself!"
></nxh-alert>

<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Focus API</h1>
      <p>How (Auto)Focus Works.</p>
    </div>
  </div>
</div>

<p>
  Typically you can use HTML's autofocus attribute to automatically set the focus on a control. However, this only works
  once (on page load), thus making it unfit for single page webapplications. To autofocus a control, you can then put
  the <code>nxhFocus</code> directive on that control.
</p>
<input type="text" class="form-control" [nxhFocus]="true" />
<pre>
    <code language="html" [highlight]="code1"></code>
</pre>

<p>
  To conditionally set autofocus, you can use the <code>[auto]</code> attribute. This can be handy when you have 2
  focussable controls on the page.
</p>

<pre>
    <code language="html" [highlight]="code2"></code>
</pre>

<p>
  It is also possible to programmatically set the focus on a control. To accomplish this, you have to specify a
  <code>handle</code> and call <code>FocusService.focus('handle')</code>
</p>

<pre>
                <code language="html" [highlight]="code3"></code>
            </pre>

<p>
  Click the button, this should move the focus to the input field below. Afterwards, type "moapr" in the field. This
  should again move the focus to the next field.
</p>
<button class="btn btn-primary" (click)="setFocus('field-1')">set focus</button>
<br />
<br />
<input
  type="text"
  class="form-control"
  placeholder="type: moapr"
  [formControl]="fc"
  [nxhFocus]="true"
  [auto]="false"
  handle="field-1"
/>
<br />
<input type="text" class="form-control" [nxhFocus]="true" [auto]="false" handle="field-2" />
<br />

<h1>Making a component "Focussable"</h1>

<p>
  Some components are not focussable by default. For example, when writing a ControlValueAccessor (CVA), often not the
  CVA itself but a control inside the CVA should be focussable. To be able to make the CVA focussable as well, you have
  to implement the Focussable interface, and add it as an FOCUSSABLE to its list of providers. Here's an example:
</p>

<pre>
  <code language="typescript" [highlight]="cvaCode"></code>
</pre>

<h1>When a control is hidden because the page is still loading</h1>

<p>
  Putting the FocusDirective on a control while it is hidden (for example because the PageLoader is still active) will
  not work - hidden controls can't receive focus. For this reason, PageLoader has an attribute,
  <code>setFocusAfterLoad</code>. When you pass the handle name of the control, that control will receive focus after
  the page has been loaded.
</p>
