<ng-container [formGroup]="parentFormGroup">
  <div class="nh-row mt-2">
    <div class="nh-col-6">
      <ng-select [required]="true" formControlName="category" [placeholder]="'category'" [nxhLabel]="'category'">
        <ng-option [value]="'work'">work</ng-option>
        <ng-option [value]="'pub'">pub</ng-option>
      </ng-select>
    </div>
  </div>
</ng-container>
