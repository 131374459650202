import { defer, iif, Observable, throwError, timer } from 'rxjs';
import { concatMap, retryWhen, tap } from 'rxjs/operators';

// Taken from https://github.com/alex-okrushko/backoff-rxjs @ 5702674645b041aa9260d0e7957406c36a0aced5 and modified slightly

export interface RetryBackoffConfig {
  // Initial backoff in ms. Defaults to 100.
  initialBackoffMs?: number;
  // Maximum backoff in ms between retries. Defaults to Infinity.
  maxBackoffMs?: number;
  // Maximum number of retry attempts. Defaults to Infinity.
  maxRetries?: number;
  // When set to `true` every successful emission will reset the delay and the error count. Defaults to `true`.
  resetOnSuccess?: boolean;
  // Conditional retry check based on returned error. Defaults to noOp that always returns `true`.
  shouldRetry?: (error: any) => boolean;
  // Custom backoff calculation instead of default exponential backoff.
  calculateBackoff?: (iteration: number, initialBackoff: number) => number;
}

/**
 * Returns an Observable that mirrors the source Observable except with an error.
 * If the source Observable calls error, rather than propagating
 * the error call this method will resubscribe to the source Observable with
 * exponentially increasing interval and up to a maximum of count
 * re-subscriptions (if provided). Retrying can be cancelled at any point if
 * shouldRetry returns false.
 */
export function retryBackoff(config: RetryBackoffConfig): <T>(source: Observable<T>) => Observable<T> {
  const {
    initialBackoffMs = 100,
    maxBackoffMs = Infinity,
    maxRetries = Infinity,
    resetOnSuccess = true,
    shouldRetry = () => true,
    calculateBackoff = exponentialBackoff,
  } = config;
  return <T>(source: Observable<T>) =>
    defer(() => {
      let index = 0;
      return source.pipe(
        retryWhen<T>((errors) =>
          errors.pipe(
            concatMap((error) => {
              const attempt = index++;
              return iif(
                () => attempt < maxRetries && shouldRetry(error),
                timer(Math.min(calculateBackoff(attempt, initialBackoffMs), maxBackoffMs)),
                throwError(error),
              );
            }),
          ),
        ),
        tap(() => {
          if (resetOnSuccess) {
            index = 0;
          }
        }),
      );
    });
}

/** Exponential backoff calculation */
export function exponentialBackoff(iteration: number, initialBackoff: number) {
  return Math.pow(2, iteration) * initialBackoff;
}
