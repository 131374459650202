// Suppressed - component needs refactoring (HN-12077)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DAY } from '@nexuzhealth/shared-domain';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RecurrencesFormService } from '../recurrences-form.service';

@Component({
  selector: 'nxh-recurrence-tab-week',
  templateUrl: './recurrence-tab-week.component.html',
  styleUrls: ['./recurrence-tab-week.component.scss'],
})
export class RecurrenceTabWeekComponent implements OnInit, OnDestroy {
  @Input() parent!: UntypedFormGroup;

  private readonly destroy$ = new Subject<void>();

  get validateDayPartOrTime() {
    return this.recurrencesFormService.getValidateDayPartOrTime();
  }

  get dayparts() {
    return this.recurrencesFormService.getDayParts();
  }

  get useTimeslots() {
    return this.recurrencesFormService.getUseTimeslots();
  }

  get useDayparts() {
    return this.recurrencesFormService.getUseDayparts();
  }

  get daysOptional() {
    return this.recurrencesFormService.getWeekdaysOptional();
  }

  get useSameTimingsForAllDays() {
    return this.recurrencesFormService.getUseSameTimingsForAllDays();
  }

  get useDayPartAllDay() {
    return this.recurrencesFormService.getUseDayPartAllDay();
  }

  get weekFormGroup(): UntypedFormGroup {
    return this.parent.get('week') as UntypedFormGroup;
  }

  get radioSelect() {
    return this.weekFormGroup?.get('_selectFrequencyOrWeekday') as UntypedFormControl;
  }

  get frequency() {
    return this.weekFormGroup?.get('frequency') as UntypedFormControl;
  }

  get days(): UntypedFormArray {
    return this.weekFormGroup?.get('days') as UntypedFormArray;
  }

  get sameTimingsForAllDaysFormControl() {
    return this.weekFormGroup.get('sameTimingsForAllDays') as UntypedFormControl;
  }

  constructor(private recurrencesFormService: RecurrencesFormService) {}

  ngOnInit(): void {
    this.radioSelect?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value === 'frequency') {
        this.frequency.enable({ emitEvent: false });
        this.days.disable({ emitEvent: false });
      } else {
        this.frequency.disable({ emitEvent: false });
        this.days.enable({ emitEvent: false });
      }
    });

    this.sameTimingsForAllDaysFormControl?.valueChanges.subscribe((sameTimingsForAllDays) => {
      if (sameTimingsForAllDays) {
        this.days.controls.forEach((dayGroup) => {
          (<UntypedFormGroup>dayGroup).removeControl('dayparts');
        });
        if (!this.weekFormGroup.get('dayparts')) {
          this.recurrencesFormService.addDayparts(this.weekFormGroup);
        }
      } else {
        this.days.controls.forEach((dayGroup: UntypedFormGroup) => {
          if (!dayGroup.get('dayparts')) {
            this.recurrencesFormService.addDayparts(dayGroup);
          }
        });
        this.weekFormGroup.removeControl('dayparts');
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAddDay(day: DAY) {
    const addDayparts = this.useDayparts && this.sameTimingsForAllDaysFormControl?.value !== true;
    this.recurrencesFormService.addDayToWeek(this.weekFormGroup, day, {
      addTimeslot: this.useTimeslots,
      addDayparts: addDayparts,
      sameTimings: this.useSameTimingsForAllDays,
    });
  }

  onRemoveDay(day: DAY) {
    const index = this.days.controls.findIndex((control) => control.get('weekday')?.value === day);
    this.days.removeAt(index);
  }
}
