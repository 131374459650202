import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest } from 'rxjs';
import { delay, distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { CollapseService } from '../collapse.service';

const openingAnimationTime = 300;
const closingAnimationTime = 200;

@Component({
  selector: 'nxh-collapse-panel',
  templateUrl: './collapse-panel.component.html',
  styleUrls: ['./collapse-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state('open', style({ height: '*', opacity: 1, overflow: 'visible' })),
      state('closed', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
      transition(
        'closed => open',
        group([
          animate(`${openingAnimationTime}ms ease-out`, style({ height: '*', opacity: 1 })),
          animate(`${openingAnimationTime}ms steps(1,start)`, style({ overflow: 'hidden' })),
        ]),
      ),
      transition(
        'open => closed',
        group([
          animate(`${closingAnimationTime}ms ease-in`, style({ height: '0px', opacity: 0 })),
          animate(`${closingAnimationTime}ms steps(1,start)`, style({ overflow: 'hidden' })),
        ]),
      ),
    ]),
  ],
})
export class CollapsePanelComponent {
  state$ = this.collapseService.state$;

  mountContent$ = combineLatest([
    this.collapseService.isOpen$,
    // wait for closing animation to finish; startWith(false) to immediately show body on init
    this.collapseService.isOpen$.pipe(delay(closingAnimationTime), startWith(false)),
  ]).pipe(
    map(([isOpen, isClosingAnimationRunning]) => isOpen || isClosingAnimationRunning),
    distinctUntilChanged(),
  );

  constructor(private collapseService: CollapseService) {}
}
