import { Attribute, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'nxh-recurrence-period',
  templateUrl: './recurrence-period.component.html',
  styleUrls: ['./recurrence-period.component.scss'],
})
export class RecurrencePeriodComponent {
  @Input() parent!: UntypedFormGroup;

  constructor(@Attribute('type') public type: 'daily' | 'weekly' | 'monthly') {}
}
