import { Component } from '@angular/core';

@Component({
  selector: 'nxh-tab2',
  templateUrl: './tab2.component.html',
  styleUrls: ['./tab2.component.scss'],
})
export class Tab2Component {
  constructor() {}
}
