import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationStart, Event, NavigationEnd, Router } from '@angular/router';
import { I18NextPipe } from 'angular-i18next';

@Injectable({
  providedIn: 'root',
})
export class AppTitleService {
  private breadcrumb: string;

  constructor(
    private router: Router,
    private i18next: I18NextPipe,
    private title: Title,
  ) {}
  setup() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof ActivationStart) {
        const backup = event.snapshot.data?.breadcrumb;
        this.breadcrumb = this.i18next.transform(backup);
      }
      if (event instanceof NavigationEnd) {
        try {
          const urlArray = event.url.split('/');
          let _title = this.i18next.transform(urlArray[1], { defaultValue: this.breadcrumb });
          _title = _title.charAt(0).toUpperCase() + _title.slice(1);
          if (urlArray[1] === 'patients' && urlArray[2]) {
            this.title.setTitle('nexuzhealth.pro');
          } else {
            this.title.setTitle(_title + ' - nexuzhealth.pro');
          }
        } catch (err) {
          // no biggie if this one fails
          console.log('error while setting title', err);
        }
      }
    });
  }
}
