import { Component } from '@angular/core';

@Component({
  selector: 'nxh-upload-demo',
  templateUrl: './upload-demo.component.html',
  styleUrls: ['./upload-demo.component.scss'],
})
export class UploadDemoComponent {
  constructor() {}
}
