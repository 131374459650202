import { CookieConfig } from './cookie.service';

export enum moaprEssentialCookieKeys {
  nh_auth_redirectto = 'nh.auth.redirectTo',
  nh_samv2_outdated_notification = 'nh.samv2.outdated-notification',
  nh_user_theme = 'nh.user.theme',
  nh_auth_user_context_name = 'nh.auth.user-context-name',
  nh_auth_login_target_app = 'nh.auth.login_target-app',
  nh_auth_login_last_message = 'nh.auth.login_last-message',
  nh_multimedia_mimetypes = 'nh.multimedia.mimetypes',
}

export enum moaprFunctionalCookieKeys {
  nh_labinformationpreferred = 'nh.labinformationpreferred',
  nh_ehealth_remind_me_after = 'nh.ehealth.remind-me-after',
  nh_ui_sidebar = 'nh.ui.sidebar',
  side_menu_open = 'side-menu-open',
  nh_medication_product_search_type = 'nh.medication.product-search-type',
  nh_locize_i18nextlang = 'nh.locize.i18nextlang',
  nh_user_preferences = 'nh.user.preferences',
}

export const moaprCookieConfig: CookieConfig = {
  essentialCookies: {
    [moaprEssentialCookieKeys.nh_auth_redirectto]: {
      storage: 'sessionStorage',
      description: 'This ensures the user is sent back to the URL where the login procedure was started',
    },
    [moaprEssentialCookieKeys.nh_samv2_outdated_notification]: {
      storage: 'sessionStorage',
      description:
        'This ensures that in nexuzhealth pro nurse and doctor, the samV2 out-of-date message is shown only once per session.',
    },
    [moaprEssentialCookieKeys.nh_user_theme]: {
      storage: 'sessionStorage',
      description:
        'This helps create the theme at login based on the organisation being logged into (logo, background image, etc.)',
    },
    [moaprEssentialCookieKeys.nh_auth_user_context_name]: {
      storage: 'localStorage',
      description: 'This is required as part of the authentication process',
    },
    [moaprEssentialCookieKeys.nh_auth_login_target_app]: {
      storage: 'sessionStorage',
      description: 'This ensures the user is sent back to the app where the login procedure was started.',
    },
    [moaprEssentialCookieKeys.nh_auth_login_last_message]: {
      storage: 'sessionStorage',
      description: 'This allows us to display an error message or info message on the login screen.',
    },
    [moaprEssentialCookieKeys.nh_multimedia_mimetypes]: {
      storage: 'localStorage',
      description:
        'This is required to optimise application performance. It filters which types of documents we allow to upload',
    },
  },
  functionalCookies: {
    [moaprFunctionalCookieKeys.nh_locize_i18nextlang]: {
      storage: 'localStorage',
      description: 'Remembers the user’s preferred language.',
    },
    [moaprFunctionalCookieKeys.nh_user_preferences]: {
      storage: 'localStorage',
      description: 'Remembers the user’s preferred language.',
    },
    [moaprFunctionalCookieKeys.nh_labinformationpreferred]: {
      storage: 'localStorage',
      description:
        'This ensures that for lab requests in nexuzhealth pro doctor, the lab of the previous request can be selected by default when making a new request.',
    },
    [moaprFunctionalCookieKeys.nh_ehealth_remind_me_after]: {
      storage: 'localStorage',
      description: 'This ensures the user can be reminded later about eHealth certificate notifications.',
    },
    [moaprFunctionalCookieKeys.nh_ui_sidebar]: {
      storage: 'localStorage',
      description: 'Remembers if the right sidebar was open or closed on last use',
    },
    [moaprFunctionalCookieKeys.side_menu_open]: {
      storage: 'localStorage',
      description: 'Remembers if the side menu (left) was open or closed on last use',
    },
    [moaprFunctionalCookieKeys.nh_medication_product_search_type]: {
      storage: 'localStorage',
      description:
        'This allows nexuzhealth pro nurse and doctor to remember the last used filter for a medication search.',
    },
  },
  cookieDomain: '.nexuzhealth.pro',
};
