import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * @deprecated Use <nh-control>
 */
@Directive({
  selector: '[nxhCompactFormControl]',
})
export class CompactFormControlDirective implements OnInit, OnDestroy {
  @Input() id?: string;
  @Input() label?: string;
  @Input('nxhCompactFormControl') _label?: string;
  @Input() placeholder?: string;
  @Input() containerClass?: string | string[];
  @Input() labelClass?: string | string[];
  @Input() required = false;

  private formGroup!: HTMLDivElement;

  constructor(
    private el: ElementRef,
    private control: NgControl,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    const input: HTMLInputElement = this.el.nativeElement;

    this.renderer.addClass(input, 'form-control');

    if (this.placeholder) {
      this.renderer.setAttribute(input, 'placeholder', this.placeholder);
    }

    const labelText = this.getLabelText();
    if (!labelText) {
      return;
    }

    this.formGroup = this.renderer.createElement('div');
    this.addClasses(this.formGroup, this.getFormGroupClassnames());

    const id = this.getInputId();
    const label = this.renderer.createElement('label');

    this.renderer.setAttribute(label, 'for', id);
    this.addClasses(label, this.getLabelClassnames());
    this.renderer.setProperty(label, 'innerText', labelText);
    if (input.required || this.required) {
      const span = this.renderer.createElement('span');
      this.renderer.addClass(span, 'required');
      this.renderer.appendChild(label, span);
    }
    this.renderer.setAttribute(input, 'id', id);

    const parent: HTMLElement = this.el.nativeElement.parentElement;

    const parentIsInputGroup = parent.classList.contains('input-group');
    if (parentIsInputGroup) {
      // structure is form-group > label | input-group
      const inputGroup = parent;
      this.renderer.appendChild(this.formGroup, label);
      this.renderer.insertBefore(inputGroup.parentElement, this.formGroup, inputGroup);
      this.renderer.appendChild(this.formGroup, inputGroup);
    } else {
      // structure is form-group > label | input
      this.renderer.appendChild(this.formGroup, label);
      this.renderer.insertBefore(parent, this.formGroup, input);
      this.renderer.appendChild(this.formGroup, input);
    }

    this.enableDisableLabel(label);
  }

  ngOnDestroy() {
    if (this.formGroup) this.renderer.removeChild(this.renderer.parentNode(this.formGroup), this.formGroup);
  }

  private addClasses(el: HTMLElement, classnames: string[]) {
    classnames.forEach((classname) => {
      this.renderer.addClass(el, classname);
    });
  }

  private getFormGroupClassnames(): string[] {
    if (this.containerClass && this.containerClass instanceof Array) {
      return ['form-group', 'compact-form-control', ...this.containerClass];
    } else if (this.containerClass) {
      return ['form-group', 'compact-form-control', this.containerClass as string];
    } else {
      return ['form-group', 'compact-form-control'];
    }
  }

  private getLabelClassnames(): string[] {
    if (this.labelClass && this.labelClass instanceof Array) {
      return [...this.labelClass];
    } else if (this.labelClass) {
      return [this.labelClass as string];
    } else {
      return [];
    }
  }

  private getLabelText() {
    return this.label ? this.label : this._label ? this._label : null;
  }

  private enableDisableLabel(label: string) {
    if (!this.control || !this.control.control) {
      return;
    }

    if (this.control.control.disabled) {
      this.renderer.addClass(label, 'disabled');
    }

    // eslint-disable-next-line
    const that = this;

    const originalDisable = this.control.control.disable;
    this.control.control.disable = function () {
      that.renderer.addClass(label, 'disabled');
      // eslint-disable-next-line
      originalDisable.apply(this, arguments as unknown as Parameters<typeof originalDisable>);
    };

    const originalEnable = this.control.control.enable;
    this.control.control.enable = function () {
      that.renderer.removeClass(label, 'disabled');
      // eslint-disable-next-line
      originalEnable.apply(this, arguments as unknown as Parameters<typeof originalDisable>);
    };
  }

  private getInputId() {
    return this.id ? this.id : `id-${Math.random()}`;
  }
}
