import { Injectable } from '@angular/core';
import { I18NextPipe, PipeOptions } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';
import { showToastr, ShowToastrOptions } from '../rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastrHelperService {
  constructor(
    private i18next: I18NextPipe,
    private toastr: ToastrService,
  ) {}

  /**
   * This has to be an arrow function. Otherwise, `this` is undefined in some cases.
   *
   * @example
   * class Component {
   *   constructor(private toastrHelper: ToastrHelper, private service: Service) {}
   *
   *   delete(whatever: Resource) {
   *     const {showToastr} = this.toastrHelper;
   *     this.service.pipe(
   *       showToastr({
   *         success: {
   *           message: '_my-domain.success-messsage'
   *         },
   *         error: {
   *           title: '_my-domain.failure-title',
   *           message: '_my-domain.failure-message'
   *         }
   *       })
   *     )
   *   }
   * }
   */
  showToastr = <T, E>(options: Omit<ShowToastrOptions<T, E>, 'i18next' | 'toastr'>) =>
    showToastr({ ...options, i18next: this.i18next, toastr: this.toastr });

  success(message, title?, override?: Partial<IndividualConfig>, i18nextOptions?: I18NextToastOptions) {
    this.toastr.success(
      this.i18next.transform(message, i18nextOptions?.message),
      title ? this.i18next.transform(title, i18nextOptions?.title) : null,
      override,
    );
  }

  error(message, title?, override?: Partial<IndividualConfig>, i18nextOptions?: I18NextToastOptions) {
    this.toastr.error(
      this.i18next.transform(message, i18nextOptions?.message),
      title ? this.i18next.transform(title, i18nextOptions?.title) : null,
      override,
    );
  }

  warning(message, title?, override?: Partial<IndividualConfig>, i18nextOptions?: I18NextToastOptions) {
    this.toastr.warning(
      this.i18next.transform(message, i18nextOptions?.message),
      title ? this.i18next.transform(title, i18nextOptions?.title) : null,
      override,
    );
  }

  info(message, title?, override?: Partial<IndividualConfig>, i18nextOptions?: I18NextToastOptions) {
    this.toastr.info(
      this.i18next.transform(message, i18nextOptions?.message),
      title ? this.i18next.transform(title, i18nextOptions?.title) : null,
      override,
    );
  }
}

export interface I18NextToastOptions {
  title?: PipeOptions;
  message?: PipeOptions;
}
