import { MonoTypeOperatorFunction, timer } from 'rxjs';
import { finalize, take } from 'rxjs/operators';

/**
 * Operator method for when a call is taking longer than defined by paramter delay.
 *
 * When the timer timesout a callback method will be called, so the caller is able to change some
 * UI state based on the fact that the current call is taking longer than anticipated.
 *
 * Usage Example: (where `longPlayingView` is a view that's hidden by default and contains some info
 * asking the user for patience)
 * <code>
 *   longBackendCallObservable.pipe(
 *     longPlaying(() => this.longPlayingView.show()),
 *     ... // handle longBackendCallObservable result
 *   )
 * </code>
 */
export function longPlaying<T>(fn: () => void, delay: number = 2000): MonoTypeOperatorFunction<T> {
  const obs = timer(delay).pipe(take(1)).subscribe(fn);

  return finalize(() => obs.unsubscribe());
}
