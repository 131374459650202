<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Typography</h1>
    </div>
  </div>
</div>
<div class="toolkit__body">
  <div class="toolkit__body__content" nxhScrollspyContainer>
    <div
      class="toolkit__body__content__block"
      id="block1"
      nxhScrollspyTarget="block1"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Typography</h2>

      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock1" (click)="showExampleBlock1 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock1" (click)="showExampleBlock1 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>

      <div *ngIf="showExampleBlock1">
        <div class="card">
          <div class="card-body">
            <div class="card-body__container">
              <nxh-alert
                type="success"
                alertTitle="Notice that every element in our application starts with a capital. If you want to start an element with a lowercase, use the class 'no-capitalization'."
              >
              </nxh-alert>
            </div>
            <div class="card-body__container">
              <div class="typo hero-title">Hero title</div>
              <div class="typo page-title">Page title</div>
              <div class="typo sub-title">Sub title</div>
              <div class="typo modal-title">Modal title</div>
              <div class="typo card-title">Card title</div>
              <div class="typo form-subtitle">Form subtitle</div>
              <div class="typo heavy-text">Heavy text</div>
              <div class="typo small-title">Small title</div>
              <div class="typo label-on-data">Label on data</div>
              <div class="typo body-text">Data / Body</div>
              <div class="typo label-on-form">Label on form</div>
              <div class="typo mask-on-form">Mask on form</div>
              <div class="typo text-link">Text link</div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock1">
        <pre><code language="html" [highlight]="block1Code"></code></pre>
      </div>
    </div>
  </div>

  <div class="toolkit__body__scrollspy">
    <ul>
      <li (click)="scrollTo('block1')" [class.is-active]="(selected$ | async) === 'block1'">Typography</li>
    </ul>
  </div>
</div>
