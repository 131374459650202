<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Task checkbox</h1>
      <p>
        this is a checkbox component which has an alternative style. inputs:<br />
        <b>id</b>: unique id to which the checkbox answers (no default);<br />
        <b>value</b>: boolean (default false);<br />
        <b>tooltip</b>: string (no default);<br />
        <b>disabled</b>: boolean (default false);<br />
      </p>
    </div>
  </div>
</div>
<div class="toolkit__body">
  <div class="toolkit__body__content" nxhScrollspyContainer>
    <div
      class="toolkit__body__content__block"
      id="block1"
      nxhScrollspyTarget="block1"
      (scrollInViewPort)="scrolledInView($event)"
    >
      <h2 class="page-title">Task check</h2>
      <div class="toolkit__body__content__block__tabbox">
        <button nxh-button buttonType="icon" [class.active]="showExampleBlock1" (click)="showExampleBlock1 = true">
          <fa-icon icon="image"></fa-icon>
        </button>
        <button nxh-button buttonType="icon" [class.active]="!showExampleBlock1" (click)="showExampleBlock1 = false">
          <fa-icon icon="code"></fa-icon>
        </button>
      </div>

      <div *ngIf="showExampleBlock1">
        <div class="card">
          <div class="card-body">
            <form [formGroup]="form">
              <div formArrayName="items" *ngFor="let item of items.controls; let i = index" class="nh-row">
                <p class="nh-col-10">{{ tasks[i] }}</p>
                <nxh-task-check
                  [id]="i"
                  [value]="item.value"
                  formControlName="{{ i }}"
                  tooltip="this is a tooltip"
                  class="nh-col-2"
                ></nxh-task-check>
              </div>
            </form>
            {{ form.value | json }}
          </div>
        </div>
      </div>
      <div *ngIf="!showExampleBlock1">
        <pre><code [highlight]="block1Code" language="html"></code></pre>
      </div>
    </div>
  </div>

  <div class="toolkit__body__scrollspy">
    <ul>
      <li (click)="scrollTo('block1')" [class.is-active]="(selected$ | async) === 'block1'">Basic check</li>
    </ul>
  </div>
</div>
