import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StickerDirective } from '@nexuzhealth/shared-ui-toolkit/sticker';
import { StackComponent } from './stack.component';

@NgModule({
  imports: [CommonModule, StickerDirective],
  declarations: [StackComponent],
  exports: [StackComponent],
})
export class StackModule {}
