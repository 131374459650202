import { CalendarOptions } from '@fullcalendar/core';
import { ResourceInput } from '@fullcalendar/resource';

interface BaseCalendarEvent {
  // id is a unique identifier needed in TemplateHelperService to check if the event is a new event or it is the same event with a changed property
  id?: string;
  resourceId?: string;
  start: Date;
  end?: Date;
  allDay?: boolean;
  classNames?: string[];
  backgroundColor?: string;
}

export interface TimeGridCalendarEvent extends BaseCalendarEvent {
  allDay?: boolean;
  extendedProps: ExtendedProps & {
    title?: string;
    badge?: string;
    content?: string;
    duration?: string;
  };
}

export interface ResourceTimelineCalendarEvent extends BaseCalendarEvent {
  extendedProps?: ExtendedProps & {
    start?: Date;
    end?: Date;
  };
}

export interface ExtendedProps {
  [key: string]: any;
}

export interface CalendarResource {
  id: ResourceInput['id'];
  title: ResourceInput['title'];
  extendedProps?: ExtendedProps;
}

export enum TimeGridViewType {
  RESOURCE_TIME_GRID_DAY = 'resourceTimeGridDay',
  TIME_GRID_DAY = 'timeGridDay',
  TIME_GRID_WEEK = 'timeGridWeek',
}

export type CalendarConfig = CalendarOptions;

export interface DayHeaderContent {
  date: Date;
  isToday: boolean;
}
