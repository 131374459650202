import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PijnschaalService {
  constructor() {}

  fakePijnSchalen() {
    return of([
      {
        date: 1,
        painValue: 6,
      },
      {
        date: 2,
        painValue: 4,
      },
      {
        date: 3,
        painValue: 6,
      },
      {
        date: 4,
        painValue: 5,
      },
      {
        date: 5,
        painValue: 4,
      },
      {
        date: 6,
        painValue: 6,
      },
      {
        date: 7,
        painValue: 5,
      },
      {
        date: 8,
        painValue: 3,
      },
      {
        date: 9,
        painValue: 4,
      },
      {
        date: 10,
        painValue: 7,
      },
      {
        date: 11,
        painValue: 6,
      },
      {
        date: 12,
        painValue: 5,
      },
      {
        date: 13,
        painValue: 6,
      },
      {
        date: 14,
        painValue: 7,
      },
      {
        date: 15,
        painValue: 9,
      },
      {
        date: 16,
        painValue: 8,
      },
      {
        date: 17,
        painValue: 5,
      },
      {
        date: 18,
        painValue: 4,
      },
      {
        date: 19,
        painValue: 5,
      },
      {
        date: 20,
        painValue: 6,
      },
    ]);
  }
}
