import { EmptyStateModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/empty-state';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatepickerModule } from '@nexuzhealth/shared-ui-toolkit/datepicker';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimeoutStateModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/timeout-state';
import { SkeletonLoaderModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/skeleton-loader';
import { CustomDatePipeModule } from '@nexuzhealth/shared-ui-toolkit/l10n/custom-date';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { DataListModule } from '@nexuzhealth/shared-ui-toolkit/data-list';
import { SharedReferenceDataFeatureAddressModule } from '@nexuzhealth/shared/reference-data/feature-address';
import { FormatPersonNamePipeModule } from '@nexuzhealth/shared-ui-toolkit/pipes/format-person-name';
import { AvatarModule } from '@nexuzhealth/shared-ui-toolkit/avatar';
import { PillModule } from '@nexuzhealth/shared-ui-toolkit/pill';
import { StatusMessageModule } from '@nexuzhealth/shared-ui-toolkit/status-message';
import { AgendaNavigatorComponent } from './components/agenda-navigator/agenda-navigator.component';
import { DayPlannerComponent } from './components/day-planner/day-planner.component';
import { WeekPlannerComponent } from './components/week-planner/week-planner.component';
import { DayPlannerBubbleComponent } from './components/day-planner-bubble/day-planner-bubble.component';

@NgModule({
  imports: [
    EmptyStateModule,
    CommonModule,
    SharedUtilI18nModule,
    IconsModule,
    NgbModule,
    DragDropModule,
    FormsModule,
    DatepickerModule,
    TimeoutStateModule,
    SkeletonLoaderModule,
    CustomDatePipeModule,
    ButtonModule,
    DataListModule,
    SharedReferenceDataFeatureAddressModule,
    FormatPersonNamePipeModule,
    AvatarModule,
    PillModule,
    StatusMessageModule,
  ],
  declarations: [AgendaNavigatorComponent, WeekPlannerComponent, DayPlannerComponent, DayPlannerBubbleComponent],
  exports: [AgendaNavigatorComponent, WeekPlannerComponent, DayPlannerComponent],
})
export class SharedUtilAgendaPlannerModule {}
