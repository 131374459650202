<div class="d-flex flex-row-reverse page-container align-items-stretch">
  <div class="w-50">
    <div>
      <pre><code [highlight]="code" language="html"></code>></pre>
    </div>

    <ul>
      <li (click)="scrollTo('info')" [class.text-primary]="(selected$ | async) === 'info'">info</li>
      <li (click)="scrollTo('success')" [class.text-primary]="(selected$ | async) === 'success'">success</li>
      <li (click)="scrollTo('warning')" [class.text-primary]="(selected$ | async) === 'warning'">warning</li>
      <li (click)="scrollTo('danger')" [class.text-primary]="(selected$ | async) === 'danger'">danger</li>
      <li (click)="scrollTo('primary')" [class.text-primary]="(selected$ | async) === 'primary'">primary</li>
      <li (click)="scrollTo('secondary')" [class.text-primary]="(selected$ | async) === 'secondary'">secondary</li>
    </ul>
  </div>

  <!-- <div class="d-flex flex-column w-50 top-page" cdkScrollable> -->
  <!-- if there are more then 1 scrollables on the page -->
  <div class="d-flex flex-column w-50 top-page" nxhScrollspyContainer>
    <div class="card" id="info" nxhScrollspyTarget="info" (scrollInViewPort)="scrolledInView($event)">
      <div class="card-header">
        <div class="card-header__title">info</div>
      </div>
      <div class="card-body bg-info"></div>
    </div>

    <div class="card" id="success" nxhScrollspyTarget="success" (scrollInViewPort)="scrolledInView($event)">
      <div class="card-header">
        <div class="card-header__title">success</div>
      </div>
      <div class="card-body bg-success"></div>
    </div>

    <div class="card" id="warning" nxhScrollspyTarget="warning" (scrollInViewPort)="scrolledInView($event)">
      <div class="card-header">
        <div class="card-header__title">warning</div>
      </div>
      <div class="card-body bg-warning"></div>
    </div>

    <div class="card" id="danger" nxhScrollspyTarget="danger" (scrollInViewPort)="scrolledInView($event)">
      <div class="card-header">
        <div class="card-header__title">danger</div>
      </div>
      <div class="card-body bg-danger"></div>
    </div>

    <div class="card" id="primary" nxhScrollspyTarget="primary" (scrollInViewPort)="scrolledInView($event)">
      <div class="card-header">
        <div class="card-header__title">primary</div>
      </div>
      <div class="card-body bg-primary"></div>
    </div>

    <div class="card" id="secondary" nxhScrollspyTarget="secondary" (scrollInViewPort)="scrolledInView($event)">
      <div class="card-header">
        <div class="card-header__title">secondary</div>
      </div>
      <div class="card-body bg-secondary"></div>
    </div>
  </div>
</div>
