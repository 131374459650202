import { Component } from '@angular/core';

@Component({
  selector: 'nxh-icon-loader-demo',
  templateUrl: './icon-loader-demo.component.html',
  styleUrls: ['./icon-loader-demo.component.scss'],
})
export class IconLoaderDemoComponent {
  code = `<nxh-icon-loader></nxh-icon-loader>`;
  pulseCode = `<nxh-icon-loader type="pulse"></nxh-icon-loader>`;
  constructor() {}
}
