import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SelectPointEvent } from '@nexuzhealth/shared-ui-toolkit/charts';
import { BehaviorSubject } from 'rxjs';
import { PijnschaalService } from './pijnschaal.service';

@Component({
  selector: 'nxh-pijnschaal',
  templateUrl: './pijnschaal.component.html',
  styleUrls: ['./pijnschaal.component.scss'],
})
export class PijnschaalComponent implements OnInit {
  @ViewChild('canvas') canvas: ElementRef;
  activeId = -1;
  activeTableId = null;
  dataSubj = new BehaviorSubject({
    tableData: [],
    painValues: [],
    painDates: [],
  });

  constructor(private _pijnschaal: PijnschaalService) {}

  ngOnInit() {
    this._pijnschaal.fakePijnSchalen().subscribe((res) => {
      this.dataSubj.next({
        tableData: res,
        painValues: [res.map((result) => result.painValue)],
        painDates: res.map((result) => result.date),
      });
    });
  }

  onSelectPoint(event: SelectPointEvent) {
    if (event.points?.length > 0) {
      this.activeId = event.points[0].index;
    }
  }

  changeData() {
    const newArr = [];
    for (let index = 0; index < 20; index++) {
      newArr.push(Math.round(Math.random() * 10));
    }
    this.dataSubj.next({ ...this.dataSubj.getValue(), painValues: [newArr] });
  }
}
