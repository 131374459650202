import { Inject, Injectable, InjectionToken } from '@angular/core';
import { addMonths, addYears } from 'date-fns';
import { BehaviorSubject } from 'rxjs';

/* eslint-disable @nx/workspace-do-not-use-localstorage-directly */

const FUNCTIONAL_COOKIES = 'functionalCookies';

export type CookieType = 'localStorage' | 'sessionStorage';
export enum FunctionalCookieValuesEnum {
  accept = 'accept',
  decline = 'decline',
}
export type FunctionalCookieValues = FunctionalCookieValuesEnum.accept | FunctionalCookieValuesEnum.decline;

export type CookieConfig = {
  essentialCookies: Record<string, { storage: CookieType; description: string }>;
  functionalCookies: Record<string, { storage: CookieType; description: string }>;
  cookieDomain: string;
};

export const COOKIE_CONFIG_TOKEN = new InjectionToken<CookieConfig>('cookie-config');

interface FunctionalCookie {
  value: string;
  expires: Date;
}

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  constructor(@Inject(COOKIE_CONFIG_TOKEN) private cookieConfig: CookieConfig) {
    this.applyActionsBasedOnFunctionalCookiesValue();
  }

  shouldShowCookieBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * @name CookieService#acceptFunctionalCookies
   *
   * @description
   * Allow functional cookies. Sets the FUNCTIONAL_COOKIES cookie to 'accept'
   */
  acceptFunctionalCookies() {
    document.cookie = this.buildCookieString(FUNCTIONAL_COOKIES, FunctionalCookieValuesEnum.accept);
    this.applyActionsBasedOnFunctionalCookiesValue();
  }

  /**
   * @name CookieService#declineFunctionalCookies
   *
   * @description
   * Refuse functional cookies. Sets the FUNCTIONAL_COOKIES cookie to 'decline'
   */
  declineFunctionalCookies() {
    document.cookie = this.buildCookieString(FUNCTIONAL_COOKIES, FunctionalCookieValuesEnum.decline);
    this.applyActionsBasedOnFunctionalCookiesValue();
  }

  /**
   * @name CookieService#forceShowCookieBar
   *
   * @description
   * Forces the cookie bar to show even if cookies have already been accepted or declined.
   */
  forceShowCookieBar() {
    this.shouldShowCookieBar.next(true);
  }

  /**
   * @name CookieService#setFunctionalCookie
   *
   * @description
   * sets a functional cookie value in local or session storage as a key/value pair
   *
   * @param key - the name of the functional cookie
   * @param value - the value of the functional cookie
   */
  setFunctionalCookie(key: string, value: string): void {
    if (this.functionalCookiesAreAllowedToWrite()) {
      const cookieType = this.cookieConfig.functionalCookies[key];
      if (cookieType?.storage === 'sessionStorage') {
        sessionStorage.setItem(key, value);
      } else if (cookieType?.storage === 'localStorage') {
        localStorage.setItem(key, value);
      } else {
        console.warn(`${key} is not configured in cookieConfig, ignoring`);
      }
    }
  }

  getFunctionalCookie(key: string): string | null {
    const cookieType = this.cookieConfig.functionalCookies[key];
    if (cookieType == null || !this.functionalCookiesAreAllowedToRead()) return null;
    return cookieType.storage === 'sessionStorage' ? sessionStorage.getItem(key) : localStorage.getItem(key);
  }

  /**
   * @name CookieService#setEssentialCookie
   *
   * @description
   * sets an essential cookie value in local or session storage as a key/value pair
   *
   * @param key - the name of the essential cookie
   * @param value - the value of the essential cookie
   */
  setEssentialCookie(key: string, value: string): void {
    const cookieType = this.cookieConfig.essentialCookies[key];
    if (cookieType?.storage === 'sessionStorage') {
      sessionStorage.setItem(key, value);
    } else if (cookieType?.storage === 'localStorage') {
      localStorage.setItem(key, value);
    } else {
      console.warn(`${key} is not configured in cookieConfig, ignoring`);
    }
  }

  removeCookie(key: string): void {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  }

  private removeAllFunctionalCookies() {
    for (const key in this.cookieConfig.functionalCookies) {
      if ({}.hasOwnProperty.call(this.cookieConfig.functionalCookies, key)) {
        this.removeCookie(key);
      }
    }
  }

  getEssentialCookie(key: string): string | null {
    const cookieType = this.cookieConfig.essentialCookies[key];
    if (cookieType == null) return null;
    return cookieType.storage === 'sessionStorage' ? sessionStorage.getItem(key) : localStorage.getItem(key);
  }

  private buildCookieString(name: string, value: string): string {
    const cookiePath = '/';
    const oneYearLater = addYears(new Date(), 1);

    const cookieValue: FunctionalCookie = { value: value, expires: oneYearLater };

    let str = encodeURIComponent(name) + '=' + encodeURIComponent(JSON.stringify(cookieValue));
    str += ';path=' + cookiePath;
    str += ';domain=' + this.cookieConfig.cookieDomain;
    str += ';expires=' + oneYearLater.toUTCString();

    return str;
  }

  private applyActionsBasedOnFunctionalCookiesValue() {
    const cookie = this.readValueOfFunctionalCookiesCookie();

    // should we show the cookie bar?
    const cookieSet = cookie != null && cookie.value in FunctionalCookieValuesEnum;
    if (cookieSet) {
      // cookie is set, show cookie bar only if today is within 1 month of expires
      const lessThanMonthBeforeExpires = new Date() > addMonths(cookie.expires, -1);
      this.shouldShowCookieBar.next(lessThanMonthBeforeExpires);
    } else {
      // cookie is not set or wrong value -> always show cookiebar
      this.shouldShowCookieBar.next(true);
    }

    // should we clear all functional cookies?
    // clear if cookie.value == decline
    // OR no cookie
    if (cookie == null || cookie?.value === FunctionalCookieValuesEnum.decline) {
      this.removeAllFunctionalCookies();
    }
  }

  private functionalCookiesAreAllowedToRead(): boolean {
    const cookie = this.readValueOfFunctionalCookiesCookie();
    return cookie != null && (cookie.value === 'true' || cookie.value === FunctionalCookieValuesEnum.accept);
  }

  private functionalCookiesAreAllowedToWrite(): boolean {
    const cookie = this.readValueOfFunctionalCookiesCookie();
    return cookie != null && cookie.value === FunctionalCookieValuesEnum.accept;
  }

  private readValueOfFunctionalCookiesCookie(): FunctionalCookie | null {
    const cookieArray = document.cookie.split('; ');

    for (const cookie of cookieArray) {
      if (cookie.indexOf(FUNCTIONAL_COOKIES) >= 0) {
        const value = cookie.split('=')[1];
        if (value === 'true') {
          return { value, expires: new Date() };
        }
        try {
          return JSON.parse(decodeURIComponent(value)) as FunctionalCookie;
        } catch {
          return null;
        }
      }
    }
    return null;
  }
}
