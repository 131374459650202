import { NgModule } from '@angular/core';

import { ScrollspyContainerDirective } from './scrollspy-container.directive';
import { ScrollspyTargetDirective } from './scrollspy-target.directive';

@NgModule({
  exports: [ScrollspyContainerDirective, ScrollspyTargetDirective],
  declarations: [ScrollspyContainerDirective, ScrollspyTargetDirective],
})
export class ScrollspyModule {}
