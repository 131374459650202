// Suppressed - deprecated component
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';

const DEFAULT_DELAY = 2000;

/**
 * @deprecated Only used by NavItemPatientSearch. Should be moved to feature module
 */
@Component({
  selector: 'nxh-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  @Input() longPlaying = false;
  // A delay in ms. When set it is used as delay for longPlaying, when present but empty a default value will be used.
  // When absent long playing is nog enabled automatically.
  @Input() longPlayingDelay: number | string;
  private subscription: Subscription;

  ngOnInit(): void {
    if (this.longPlayingDelay === '') this.longPlayingDelay = DEFAULT_DELAY; // default value!

    if (+this.longPlayingDelay > 0) {
      this.subscription = timer(+this.longPlayingDelay)
        .pipe(take(1))
        .subscribe(() => (this.longPlaying = true));
    }
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) this.subscription.unsubscribe();
  }
}
