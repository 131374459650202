<h1>Counter</h1>
<br />
<br />
<nxh-alert type="warning" alertTitle="For last documentation check Storybook">
  <nxh-alert-description>
    <a href="https://storybook.nexuzhealth.io/?path=/docs/components-counter--default" target="_blanc">Storybook</a>
  </nxh-alert-description>
</nxh-alert>
<br />
<br />

<nxh-counter [value]="6">
  <div>hallloooo</div>
</nxh-counter>

<pre>
  <code language="html" [highlight]="code1"></code>
</pre>

<b>Using the counter without text</b><br /><br />

<nxh-counter [value]="6"></nxh-counter>

<pre>
  <code language="html" [highlight]="code2"></code>
</pre>

<b>Abbreviating the counter</b><br /><br />

<nxh-counter [value]="3456545" [maxDigits]="3">
  <div>hallloooo</div>
</nxh-counter>

<pre>
  <code language="html" [highlight]="code3"></code>
</pre>

<b>Changing colors depending on value</b><br /><br />

<nxh-counter [value]="6" [statusMap]="{ success: 5, danger: 8 }">
  <div>hallloooo</div>
</nxh-counter>

<pre>
  <code language="html" [highlight]="code4"></code>
</pre>

<nxh-counter [value]="10" [statusMap]="{ success: 5, danger: 8 }">
  <div>hallloooo</div>
</nxh-counter>

<pre>
  <code language="html" [highlight]="code5"></code>
</pre>

<b>Changing the position</b><br /><br />

<nxh-counter [value]="254" [position]="'right'">
  <div>hallloooo</div>
</nxh-counter>

<pre>
  <code language="html" [highlight]="code6"></code>
</pre>

<nxh-counter [value]="254" [position]="'left'">
  <div>hallloooo</div>
</nxh-counter>

<pre>
  <code language="html" [highlight]="code7"></code>
</pre>

<b>Using the counter in a tab</b><br /><br />

<nxh-tabnav [topLevel]="true">
  <nxh-tab [link]="'tab1'">tab 1</nxh-tab>
  <nxh-tab [link]="'tab2'">
    <nxh-counter [value]="324">tab 2</nxh-counter>
  </nxh-tab>
</nxh-tabnav>

<pre>
  <code language="html" [highlight]="code8"></code>
</pre>
