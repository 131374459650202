import { Component } from '@angular/core';

@Component({
  selector: 'nxh-tabs-demo',
  templateUrl: './tabs-demo.component.html',
  styleUrls: ['./tabs-demo.component.scss'],
})
export class TabsDemoComponent {
  code = `
<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <li ngbNavItem>
    <a ngbNavLink>Tab nr 1</a>
    <ng-template ngbNavContent><div class="card-body">Lorem ipsum tab1</div></ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink>Tab nr 2</a>
    <ng-template ngbNavContent><div class="card-body">Lorem ipsum tab2</div></ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
  `;

  constructor() {}
}
