import { Component, EventEmitter, Input, Optional, Output, Self } from '@angular/core';
import { ActivatedRoute, Params, RouterLink } from '@angular/router';
import { RouterHelperService } from '@nexuzhealth/shared-util';

type NavMode = 'default' | 'fixed' | 'custom' | 'deprecated-fixed';

@Component({
  selector: 'nxh-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  @Input() fallback: string | string[] = '../';
  @Input() fallbackQueryParams: Params | null = null;

  /**
   * Root uri for relative navigation, used when navigating to the fallback route. Defaults to the current
   * ActivatedRoute.
   */
  @Input() fallbackRelativeTo: ActivatedRoute | null = null;
  @Input() tooltip = '_backbutton.return';

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter<void>();

  constructor(
    private activatedRoute: ActivatedRoute,
    private routerHelper: RouterHelperService,
    @Self() @Optional() private routerLinkDirective?: RouterLink,
  ) {}

  get mode(): NavMode {
    switch (true) {
      case !!this.routerLinkDirective:
        return 'fixed';
      case this.click.observers.length > 0:
        return 'custom';
      default:
        return 'default';
    }
  }

  goBack() {
    this.routerHelper.back(this.fallback, {
      relativeTo: this.fallbackRelativeTo || this.activatedRoute,
      queryParams: this.fallbackQueryParams,
    });
  }
}
