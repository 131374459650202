import { Component } from '@angular/core';

@Component({
  selector: 'nxh-tab1',
  templateUrl: './tab1.component.html',
  styleUrls: ['./tab1.component.scss'],
})
export class Tab1Component {
  constructor() {}
}
