export enum AccessRole {
  clerk = 'tenants/system/accessroles/common/clerk',
  clinical = 'tenants/system/accessroles/common/clinical',
  demonic = 'tenants/system/accessroles/common/demonic',
  generalPractitioner = 'tenants/system/accessroles/common/general_practitioner',
  homeNurse = 'tenants/system/accessroles/common/home_nurse',
  medicalAssistant = 'tenants/system/accessroles/common/medical_assistant',
  serviceDesk = 'tenants/system/accessroles/common/service_desk',
  practicalNurse = 'tenants/system/accessroles/common/practical_nurse',
  nurseAssistant = 'tenants/system/accessroles/common/nurse_assistant',
  organisationPlanning = 'tenants/system/accessroles/common/organisation_planning',
  userManagement = 'tenants/system/accessroles/common/user_management',
  requestsApproval = 'tenants/system/accessroles/common/requests_approval',
  officeCoordinator = 'tenants/system/accessroles/common/office_coordinator',
  suborganisationAdmin = 'tenants/system/accessroles/common/suborganisation_admin',
  organisationManagement = 'tenants/system/accessroles/common/organisation_management',
  organisationFinancial = 'tenants/system/accessroles/common/organisation_financial',
  organisationPatients = 'tenants/system/accessroles/common/organisation_patients',
  financialSettings = 'tenants/system/accessroles/common/financial_settings',
  invoiceManagement = 'tenants/system/accessroles/common/invoice_management',
  invoicingDryRun = 'tenants/system/accessroles/common/invoicing_dryrun',
  sourcefileManagement = 'tenants/system/accessroles/common/sourcefile_management',
}

export enum OrganisationAccessRole {
  serviceProvider = 'service-provider',
  recognizedServiceProvider = 'recognized-service-provider',
  generalPractitionerLicence = 'gp-licence',
  homeNursingLicence = 'hn-licence',
  hospitalLicence = 'hosp-licence',
}
