import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nxh-timeout-state',
  templateUrl: './timeout-state.component.html',
  styleUrls: ['./timeout-state.component.scss'],
})
export class TimeoutStateComponent {
  @Output() customTimeoutReloadAction: EventEmitter<any> = new EventEmitter();
  @Input() hasReload = true;

  reload() {
    if (this.customTimeoutReloadAction.observers.length > 0) {
      this.customTimeoutReloadAction.emit();
    } else {
      window.location.reload();
    }
  }
}
