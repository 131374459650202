<div class="highlight-block" [formGroup]="formGroup">
  <nh-control label="{{ title | i18next }}" class="dayparts-container">
    <nxh-single-toggle-list
      nxhDataTest="daypart-single-list"
      [formControl]="$any(formGroup.get(formControlName))"
      [options]="partOptions"
      [pills]="true"
    >
      <ng-template nxhToggleListOption let-option>
        <div class="part-option">
          <div class="part-option__title">{{ option.label | i18next }}</div>
          <div class="part-option__subtitle">
            {{ option.range.from | formatClockTime }} - {{ option.range.to | formatClockTime }}
          </div>
        </div>
      </ng-template>
    </nxh-single-toggle-list>
    <val-errors></val-errors>
  </nh-control>
</div>
