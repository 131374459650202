<h1>recurrence</h1>

As <code>RecurrenceComponent</code> is a ControlValueAccessor, it can be embeded in forms. It talks
'AppointmentTypeRecurrence', meaning its value is an <code>AppointmentTypeRecurrence</code>. My guess is at some point
this name will change. This is how you set it up:

<pre>
  <code [highlight]="setupCode" language="html"></code>
</pre>

To not use timeslots, you set its [useTimeslots] attribute to false. To limit the timeslots of the manual tab, you pass
a period with the [periodsOfApplication] attribute. To set an intial value/tab, set one of the
<code>DEFAULT_RECURRENCE_VALUES</code>.

<br />
<br />
<b>Example:</b>

<div class="card">
  <div class="card-header">form</div>
  <form [formGroup]="form" nhForm #f="nhForm" (ngSubmit)="onSubmit(f.helper)">
    <div class="card-body">
      <nh-fieldset>
        <nh-control [label]="'whatever'">
          <input formControlName="whatever" />
          <val-errors></val-errors>
        </nh-control>

        <nh-control [label]="'recurrences'">
          <nxh-recurrences [useTimeslots]="true" formControlName="recurrences"></nxh-recurrences>
        </nh-control>
      </nh-fieldset>
    </div>
    <div class="card-footer">
      <div class="card-footer__controls">
        <nh-submit-button></nh-submit-button>
      </div>
    </div>
  </form>
</div>

<div class="update-controls nh-row">
  <div>Change value:</div>
  <button class="btn btn-primary" (click)="setDay()">set day</button>
  <button class="btn btn-primary" (click)="setWeek()">set week</button>
  <button class="btn btn-primary" (click)="setMonth1()">set month (1)</button>
  <button class="btn btn-primary" (click)="setMonth2()">set month (2)</button>
  <button class="btn btn-primary" (click)="setManual()">set manual</button>
</div>

<div class="card">
  <div class="card-header">result</div>
  <div class="card-body">
    <pre>{{ form.value | json }}</pre>
  </div>
</div>
