import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export const getLookForwardSourceClassname = (name?: string) => {
  return name ? `lookforward-focus__${name}` : 'lookforward-focus';
};

@Directive({
  selector: '[nxhLookforwardFocus]',
})
export class LookforwardFocusDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input('nxhLookforwardFocus') source!: string;
  @Input() focusClasses: string[] = [];
  @Input() focusClass = '';

  focusSubject = new BehaviorSubject(false);
  private destroy$ = new Subject<void>();

  constructor(private el: ElementRef<HTMLElement>) {}

  ngAfterViewInit(): void {
    const className = getLookForwardSourceClassname(this.source);
    const srcEls = document.querySelectorAll(`.${className}`);
    srcEls.forEach((el) => {
      el.addEventListener('focus', () => this.focusSubject.next(true));
      el.addEventListener('blur', () => this.focusSubject.next(false));
    });
  }

  ngOnInit(): void {
    const classList = ['has-focus', ...this.focusClasses, this.focusClass].filter((clazz) => !!clazz);

    const hasFocus$ = this.focusSubject.asObservable().pipe(debounceTime(100), takeUntil(this.destroy$));

    hasFocus$.subscribe((focus) => {
      if (focus) {
        this.el.nativeElement.classList.add(...classList);
      } else {
        this.el.nativeElement.classList.remove(...classList);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
