import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { ConfirmModule } from '@nexuzhealth/shared-ui-toolkit/confirm';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { AddRemoveButtonsComponent } from './add-remove-buttons.component';

@NgModule({
  imports: [CommonModule, NgbTooltipModule, SharedTechFeatureE2eModule, ConfirmModule, ButtonModule, IconsModule],
  exports: [AddRemoveButtonsComponent],
  declarations: [AddRemoveButtonsComponent],
})
export class AddRemoveButtonsModule {}
