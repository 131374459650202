import {
  Plugin,
  Chart,
} from 'chart.js';

//here we use typescripts declaration merging to make the PluginOptions known to chartjs
// https://www.typescriptlang.org/docs/handbook/declaration-merging.html
declare module 'chart.js' {
  interface PluginOptionsByType<TType> {
    verticalLine: VerticalLineOptions;
  }
}

export const verticalLinePlugin: Plugin<'line', VerticalLineOptions> = {
  id: 'verticalLine',
  beforeDraw(chart: Chart<'line'>, args, options: VerticalLineOptions) {
    const { ctx } = chart;
    ctx.save();
    const timeScaleX = chart.scales['x'];
    const verticalLinePointsFn: () => VerticalLinePositionAndColor[] = options.verticalLinePoints || (() => []);
    for (const verticalLinePoint of verticalLinePointsFn()) {
      ctx.strokeStyle = verticalLinePoint.color || '#535858';
      const verticalLineXPosition = timeScaleX.getPixelForValue(verticalLinePoint.position);
      ctx.strokeRect(verticalLineXPosition, chart.chartArea.top, 0, chart.chartArea.height);
    }
    ctx.restore();
  },
};

export type VerticalLineOptions = {
  verticalLinePoints: () => VerticalLinePositionAndColor[];
};

export type VerticalLinePositionAndColor = {
  position: number;
  color: string;
  label: string;
};
