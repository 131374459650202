import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nxh-hotkeys-modal',
  templateUrl: './hotkeys.component.html',
  styleUrls: ['./hotkeys.component.scss'],
})
export class HotkeysComponent {
  @Input() hotkeys: { [key: string]: string }[] = [];

  constructor(private activeModal: NgbActiveModal) {}

  public close() {
    this.activeModal.close();
  }

  formatHotkey(hotkeyNotation: string) {
    return hotkeyNotation.replace(/\./g, ' + ');
  }
}
