import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { DividerComponent } from './divider.component';

@NgModule({
  imports: [CommonModule],
  exports: [DividerComponent],
  declarations: [DividerComponent],
})
export class DividerModule {}
