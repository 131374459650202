import { Component } from '@angular/core';

@Component({
  selector: 'nxh-tab3',
  templateUrl: './tab3.component.html',
  styleUrls: ['./tab3.component.scss'],
})
export class Tab3Component {
  constructor() {}
}
