<div class="alert__container {{ 'alert__container--' + alertClass }}">
  <div class="alert__icon" *ngIf="alertIcon">
    <fa-icon [icon]="alertIcon" />
  </div>

  <div class="alert__content">
    <nxh-alert-item
      [alertTitle]="alertTitle"
      [closeable]="closeable"
      [description]="description"
      (closeAlert)="closeAlert.emit()"
      *ngIf="alertItemVisible"
    >
      <ng-content select="nxh-alert-title" ngProjectAs="nxh-alert-title" />
      <ng-content select="nxh-alert-description" ngProjectAs="nxh-alert-description" />
    </nxh-alert-item>

    <ng-content select="nxh-alert-item" />
  </div>
</div>
