<p>At the moment of writing, we discern the following categories of settings:</p>

<h1>component settings</h1>

<p>
  Components are shared while allowing specific configuration per application. Typically, this is accomplished by
  providing input() variables, e.g. <code>&#64;Input() displayedColumns: string[]</code>. The configuration itself
  resides in the <code>components-config.ts</code> file, which can be found in each application's src directory. Here's
  a <b>possible</b> config:
</p>

<pre>
  <div><code language="typescript" [highlight]="componentsConfigFile"></code></div>
</pre>

<p>
  To use the config, the <code>SettingsService</code> is injected in a container component, which grabs the settings of
  interest, and injects these into the dumb component's inputs.
</p>

<pre>
  <div><code language="typescript" [highlight]="componentsConfigCode"></code></div>
</pre>

<h1>navigation config, sidemenu config</h1>

<p>
  For the moment navigation and sidemenu config is specified in
  <code>GpNavItemsResolveService, HnNavItemsResolveService, GpMenuItemsResolveService</code> and
  <code>HnMenuItemsResolveService</code>. In future releases we might move this config to the
  <code>components-config.ts</code> file.
</p>

<h1>user preferences</h1>

<p>
  At the moment of writing, this is limited to the user's language. Check the <code>UserPreferencesService</code>
  for more details
</p>

<h1>system settings</h1>

<nxh-alert
  type="warning"
  alertTitle="Note that system settings are still work in progress. E.g. we haven't figured out how to dynamically
  configure firebase, and how to deal with differences between applications in system variables"
></nxh-alert>

<p>
  System config deals with environment-specific (DEV, TEST, PROD...) settings like endpoints, keys etc... System
  settings in fact reside on the backend, and are loaded before the application boots. When the application boots, there
  is a request for a config.json file, which the backend dynamically creates based (a.o.) on its system variables.
</p>

<pre>
  <code language="typescript" [highlight]="settingsFile"></code>
</pre>

<p>
  During development, the files <code>config.remote.json</code> or <code>config.json</code> are used. As you can see,
  there is a lot of overlap with the existing <code>environment.ts</code> files. These are kept for backwards
  compatibility, but will be removed later on.
</p>

<p>
  Note that system config and user config needs to be loaded before the app boots. This is accomplished in the
  <code>AppModule</code>, where we have configured an <code>APP_INITIALIZER</code>. Notice the deps: these are so-called
  <code>Preloadable</code>s, i.e. they have a <code>preload()</code> method. The order of the preloadables is important:
  they are executed from left to right. This is necessary, because for example in order to load the user preferences, we
  first need the url where to load them from.
</p>

<pre>
  <code language="typescript" [highlight]="initializeSettingsCode"></code>
</pre>

<p>After loading the settings, they are stored in the <code>SettingsService</code>, and can be used like so:</p>

<pre>
  <code language="typescript" [highlight]="settingsCode"></code>
</pre>
