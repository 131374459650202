import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { ToggleGroupDirective } from './toggle-group.directive';
import { MultiToggleListComponent } from './multi-toggle-list/multi-toggle-list.component';
import { ToggleListOptionDirective } from './toggle-list-option.directive';
import { ToggleOptionDirective } from './toggle-option.directive';
import { SingleToggleListComponent } from './single-toggle-list/single-toggle-list.component';
import { ToggleListOptionComponent } from './toggle-list-option/toggle-list-option.component';
import { ToggleListOptionDescriptionDirective } from './toggle-list-option/toggle-list-option-description.directive';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedUtilI18nModule,
    SharedTechFeatureE2eModule,
    ToggleListOptionComponent,
    ToggleListOptionDescriptionDirective,
    SingleToggleListComponent,
    ToggleGroupDirective,
    MultiToggleListComponent,
    ToggleOptionDirective,
  ],
  declarations: [ToggleListOptionDirective],
  exports: [
    ToggleOptionDirective,
    MultiToggleListComponent,
    ToggleListOptionDirective,
    SingleToggleListComponent,
    ToggleListOptionComponent,
    ToggleListOptionDescriptionDirective,
    SingleToggleListComponent,
  ],
})
export class ToggleModule {}
