import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { TimeoutStateComponent } from './timeout-state.component';

@NgModule({
  imports: [CommonModule, IconsModule, SharedUtilI18nModule],
  exports: [TimeoutStateComponent],
  declarations: [TimeoutStateComponent],
})
export class TimeoutStateModule {}
