<li>
  <a
    [routerLink]="link"
    [ngClass]="customClass"
    [skipLocationChange]="skipLocationChange"
    [queryParams]="queryParams"
    [routerLinkActiveOptions]="tabnav.routerLinkActiveOptions"
    routerLinkActive="active"
    #rla="routerLinkActive"
  >
    <ng-content />
  </a>
</li>
