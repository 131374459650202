import { Component } from '@angular/core';
import { ScrollspyService } from '@nexuzhealth/shared-ui-toolkit/scrollspy';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'nxh-helper-classes-demo',
  templateUrl: './helper-classes-demo.component.html',
  styleUrls: ['./helper-classes-demo.component.scss'],
})
export class HelperClassesDemoComponent {
  amount = 2;
  showExampleBlock1 = true;
  selected$ = new BehaviorSubject('block1');
  block1Code = `
  // max amount can go from 1 to 5 lines
  <element class="maxlines maxlines-(amount)">text that is waaaay to long</element>

  `;
  constructor(private scrollspyService: ScrollspyService) {}

  scrollTo(id: string) {
    this.selected$.next(id);
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id: string) {
    this.selected$.next(id);
  }
}
