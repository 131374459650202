<div class="exit-form-container"></div>
<div class="exit-form rounded">
  <div class="exit-form__header">
    <span>{{ '_exit-form.popup-title' | i18next }}</span>
    <button nxh-button buttonType="icon" buttonStatus="neutral" (click)="decline()">
      <fa-icon icon="times" />
    </button>
  </div>

  <div class="exit-form__content">
    <span>{{ '_exit-form.popup-content' | i18next }}</span>
  </div>

  <div class="exit-form__footer">
    <button class="btn btn-leave btn-primary" [nxhFocus]="true" (click)="accept()">
      {{ '_exit-form.popup-accept' | i18next }}
    </button>
    <button nxh-button buttonType="subtle-button" buttonStatus="neutral" (click)="decline()">
      {{ '_exit-form.popup-decline' | i18next }}
    </button>
  </div>
</div>
