import { Component } from '@angular/core';

@Component({
  selector: 'nxh-empty-state-demo',
  templateUrl: './empty-state-demo.component.html',
  styleUrls: ['./empty-state-demo.component.scss'],
})
export class EmptyStateDemoComponent {
  codePatients = `
<nxh-empty-state icon="flag">
  <div class="empty__title">Geen patiënt gevonden</div>
  <div class="empty__description">Er bestaat geen patiëntendossier voor zoekopdracht <strong>‘Sifie Verhshst’</strong></div>
  <div class="empty__reasons">Hier komt eventueel een bepaalde reden</div>
  <div class="empty__solution">Hier komt eventueel een bepaalde oplossing</div>
</nxh-empty-state>
  `;
  codeMedical = `
<nxh-empty-state >
  <div class="empty__title">Deze patiënt heeft geen medicatie therapieën</div>
  <div class="empty__description">
      <a [routerLink]="[ '/patients/list' ]">Registreer medicatie</a> om het dossier up-to-date te houden.
  </div>
</nxh-empty-state>
  `;

  constructor() {}
}
