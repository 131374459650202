import { NgModule } from '@angular/core';

import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { EllipsisWithTooltipDirective } from './ellipsis-with-tooltip.directive';

@NgModule({
  imports: [NgbTooltipModule],
  exports: [EllipsisWithTooltipDirective],
  declarations: [EllipsisWithTooltipDirective],
})
export class EllipsisDirectiveModule {}
