import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { getLookForwardSourceClassname } from './lookforward-focus.directive';

@Directive({
  selector: '[nxhLookforwardFocusSource]',
})
export class LookforwardFocusSourceDirective implements OnInit {
  @Input('nxhLookforwardFocusSource') name!: string;

  constructor(private el: ElementRef<HTMLElement>) {}

  ngOnInit(): void {
    this.el.nativeElement.classList.add(getLookForwardSourceClassname(this.name));
  }
}
