<nxh-stack alignItems="center" gap="lg" nowrap="true" class="radio-option-container">
  <div class="radio-button" [ngSwitch]="active" *ngIf="showRadio">
    <fa-icon *ngSwitchCase="true" [icon]="['fas', 'circle-dot']" [fixedWidth]="true" class="active" />
    <fa-layers *ngSwitchDefault [fixedWidth]="true">
      <!-- Not stacking 2 solids because we want to retain the background! -->
      <fa-icon [icon]="'circle'" />
      <fa-icon [icon]="'circle'" transform="shrink-2" />
      <fa-icon [icon]="'circle'" transform="shrink-3" />
    </fa-layers>
  </div>

  <nxh-stack direction="col" alignItems="start" class="option-container">
    <div *ngIf="label" class="toggle-list-option-label">{{ label }}</div>
    <div *ngIf="description || toggleListOptionDescription" class="toggle-list-option-description">
      {{ description }}
      <ng-content select="[nxhToggleListOptionDescription]" />
    </div>
    <ng-content />
  </nxh-stack>
</nxh-stack>
