import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';

import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { CustomDatePipeModule } from '@nexuzhealth/shared-ui-toolkit/l10n/custom-date';
import { PillModule } from '@nexuzhealth/shared-ui-toolkit/pill';
import { CalendarDayGridComponent } from './calendar-day-grid/calendar-day-grid.component';
import { CalendarResourceTimelineComponent } from './calendar-resource-timeline/calendar-resource-timeline.component';
import { MinResourceLabelHeightPipe } from './calendar-resource-timeline/min-resource-label-height.pipe';
import { CalendarTimeGridComponent } from './calendar-time-grid/calendar-time-grid.component';
import { EventBackgroundFadePipe } from './calendar-time-grid/event-background-fade.pipe';

@NgModule({
  imports: [CommonModule, FullCalendarModule, IconsModule, CustomDatePipeModule, PillModule],
  exports: [CalendarResourceTimelineComponent, CalendarTimeGridComponent, CalendarDayGridComponent],
  declarations: [
    CalendarResourceTimelineComponent,
    CalendarTimeGridComponent,
    CalendarDayGridComponent,
    MinResourceLabelHeightPipe,
    EventBackgroundFadePipe,
  ],
})
export class CalendarModule {}
