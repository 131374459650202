<p>Resize the screen and hover over the cards to see the ellipsis with tooltip in action</p>

<div class="card tooltip-on-ellipsis-container" style="max-width: 20rem">
  <span [ngbTooltip]="content" nxhEllipsisWithTooltip>
    {{ content }}
  </span>
</div>

<br />

<div class="card tooltip-on-ellipsis-container" style="max-width: 80rem">
  <span [ngbTooltip]="content" nxhEllipsisWithTooltip>
    {{ content }}
  </span>
</div>

<pre>
  <code [highlight]="defaultCode" language="html"></code>
</pre>

<h1>dynamicall trigger re-evaluation tooltip</h1>

<p>
  It is also possible to trigger the evaluation whether the tooltip should be shown by calling
  <code>SetEllipsisWithTooltip</code>code's <code>detectTooltip()</code> method:
</p>
you can use <code>'lines'</code> to define the amount of lines you want to show the default is <code>1</code> and the
max is <code>5</code>
<div class="card tooltip-on-ellipsis-container" [style.max-width]="maxWidth">
  <span [ngbTooltip]="content" nxhEllipsisWithTooltip #ewt>
    {{ content }}
  </span>
</div>
<br />
<button class="btn btn-primary" (click)="changeSize()">change size</button>

<pre>
  <code [highlight]="dynamicCode" language="html"></code>
</pre>

<p>
  Note that in a later phase the application will call this when opening/closing the MUD! So don't add this behavior for
  now.
</p>
