import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { SkeletonLoaderModule } from '@nexuzhealth/shared-ui-toolkit/loading-states/skeleton-loader';
import { FormatInitialsPipeModule } from '@nexuzhealth/shared-ui-toolkit/pipes/format-initials';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AvatarComponent } from './avatar.component';
import { AvatarFullNameDirective } from './avatar-full-name.directive';
import { AvatarNoFullNameDirective } from './avatar-no-full-name.directive';

@NgModule({
  imports: [CommonModule, SkeletonLoaderModule, SharedUtilI18nModule, FormatInitialsPipeModule, ButtonModule, FontAwesomeModule],
  exports: [AvatarComponent, AvatarFullNameDirective, AvatarNoFullNameDirective],
  declarations: [AvatarComponent, AvatarFullNameDirective, AvatarNoFullNameDirective],
})
export class AvatarModule {}
