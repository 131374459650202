import { Pipe, PipeTransform } from '@angular/core';
import { formatBold } from '@nexuzhealth/shared-util';

/**
 * Pipe to make part of the text bold, typically used in autocomplete components.
 * As this pipe introduces various <span> tags, you have to make sure you use this in combination with [innerHTML]!
 *
 * Eg. <div [innerHTML]="patient.personName.displayName | bold: (term$ | async)"></div>
 */
@Pipe({
  name: 'bold',
})
export class BoldPipe implements PipeTransform {
  transform(value: string, config?: string | { queryText?: string; position: 'start' | 'global' }): string {
    return formatBold(value, config);
  }
}
