import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LoadingButtonModule } from '@nexuzhealth/shared-ui-toolkit/loading-button';
import { SubmitButtonComponent } from './submit-button.component';

/**
 * @deprecated Use nh-submit-button instead: https://uitoolkit.dev.nexuzhealth.pro/validation
 */
@NgModule({
  imports: [CommonModule, LoadingButtonModule],
  exports: [SubmitButtonComponent],
  declarations: [SubmitButtonComponent],
})
export class SubmitButtonModule {}
