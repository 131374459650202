<ng-container *ngIf="(control.touched || control.dirty) && errors.length > 0">
  <ng-container [ngSwitch]="hasCustomErrorMessage()">
    <span class="input-error" *ngSwitchCase="true">
      {{ customErrorMessage }}
    </span>
    <span class="input-error" *ngSwitchDefault>
      {{ firstError() | i18next: firstErrorContext }}
    </span>
  </ng-container>
</ng-container>
