import { Component, OnInit } from '@angular/core';
import { BloeddrukService } from './bloeddruk.service';

@Component({
  selector: 'nxh-bloeddruk',
  templateUrl: './bloeddruk.component.html',
  styleUrls: ['./bloeddruk.component.scss'],
})
export class BloeddrukComponent implements OnInit {
  tableData = [];
  values;
  labels = [];
  colors = [
    {
      borderColor: '#51A5DE',
      pointBorderColor: '#51A5DE',
      pointHoverBorderColor: '#51A5DE',
      pointBackgroundColor: '#51A5DE',
      pointHoverBackgroundColor: '#51A5DE',
    },
    {
      borderColor: '#d12f30',
      pointBorderColor: '#d12f30',
      pointHoverBorderColor: '#d12f30',
      pointBackgroundColor: '#d12f30',
      pointHoverBackgroundColor: '#d12f30',
    },
  ];
  constructor(private _bloeddruk: BloeddrukService) {}

  ngOnInit() {
    this._bloeddruk.fakeBloeddrukWaardes().subscribe((res) => {
      this.tableData = res;
      const bloodValue1 = res.map((result) => result.bloodValue1);
      const bloodValue2 = res.map((result) => result.bloodValue2);
      this.labels = res.map((result) => result.date.toString());
      this.values = [bloodValue1, bloodValue2];
    });
  }
}
