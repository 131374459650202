import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'nxh-pro-form',
  templateUrl: './pro-form.component.html',
  styleUrls: ['./pro-form.component.scss'],
})
export class ProFormComponent implements OnInit {
  @Input() parentFormGroup: UntypedFormGroup;
  @Input() licenseNumber: string;

  constructor() {}

  ngOnInit() {
    this.parentFormGroup.addControl('licenseNumber', new UntypedFormControl(this.licenseNumber, Validators.required));
  }
}
