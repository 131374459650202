import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { COMMON_LANGUAGES } from '@nexuzhealth/shared-util';
import { UserPreferencesState, UserPreferencesStore } from '../state/preferences-store.service';

const COMMON_LANGUAGES_BY_LOCALE: { [locale: string]: COMMON_LANGUAGES } = {
  nl: COMMON_LANGUAGES.dutch,
  fr: COMMON_LANGUAGES.french,
  en: COMMON_LANGUAGES.english,
  de: COMMON_LANGUAGES.german,
};

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesQuery extends Query<UserPreferencesState> {
  preferredLanguage$ = this.select('preferredLanguage');

  constructor(protected store: UserPreferencesStore) {
    super(store);
  }

  getPreferredLanguage(): string {
    return this.getValue().preferredLanguage;
  }

  getPreferredLanguageId(): COMMON_LANGUAGES | null {
    const lang = this.getValue().preferredLanguage;
    return lang ? COMMON_LANGUAGES_BY_LOCALE[lang] : null;
  }

  selectPreferredLanguageId(): Observable<COMMON_LANGUAGES | null> {
    return this.select('preferredLanguage').pipe(map((lang) => (lang ? COMMON_LANGUAGES_BY_LOCALE[lang] : null)));
  }
}
