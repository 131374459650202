import { inject, Injectable, LOCALE_ID } from '@angular/core';
import { formatDate as angularFormatDate } from '@angular/common';

export type CustomDatePipeFormats =
  | 'shortDate'
  | 'longDate'
  | 'monthDate'
  | 'shortTime'
  | 'mediumTime'
  | 'longTime'
  | 'shortDateTime'
  | 'longDateTime'
  | 'shortWeekdayDate'
  | 'shortWeekdayDateTime'
  | 'longWeekdayDate'
  | 'longWeekdayDateTime';

const custom_formats: { [key in CustomDatePipeFormats]?: string } = {
  shortDate: 'dd/MM/yyyy', // overwritten because by default NL format is d/M/yyyy
  monthDate: 'MMMM yyyy',
  shortDateTime: 'dd/MM/yyyy, HH:mm',
  longDateTime: 'dd MMMM yyyy, HH:mm',
  shortWeekdayDate: 'EE dd/MM/yyyy',
  shortWeekdayDateTime: 'EE dd/MM/yyyy, HH:mm',
  longWeekdayDate: 'EEEE dd MMMM yyyy',
  longWeekdayDateTime: 'EEEE dd MMMM yyyy, HH:mm',
};

export const FAR_AWAY_AS_STRING = '9999-12-31T23:59:59Z';
export const FAR_AWAY = new Date(FAR_AWAY_AS_STRING);
export const LONG_AGO_AS_STRING = '1900-01-01T00:00:00Z';
export const LONG_AGO = new Date(LONG_AGO_AS_STRING);

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private locale = inject(LOCALE_ID);

  formatDate(
    value: Date | string | number | undefined | null,
    format: CustomDatePipeFormats | string = 'shortDate',
    timezone?: string,
    locale = this.locale,
  ) {
    if (!value) {
      return '';
    }

    const customFormat = getCustomFormat(format);
    const result = angularFormatDate(value, customFormat, locale, timezone);

    if (format === 'shortWeekdayDateTime') {
      return capitalizeWeekday(result);
    }

    return result;
  }
}

function getCustomFormat(format: CustomDatePipeFormats | string) {
  const custom = custom_formats[format];
  return custom ? custom : format;
}

function capitalizeWeekday(result: string) {
  const index = result.search(/\d/);
  return result.slice(0, index).toUpperCase() + result.slice(index);
}

export function isFarAway(date: string | Date | undefined) {
  if (!date) {
    return false;
  }
  return new Date(date).getTime() === FAR_AWAY.getTime();
}

export function isLongAgo(date: string | Date | undefined) {
  if (!date) {
    return false;
  }
  return new Date(date).getTime() === LONG_AGO.getTime();
}
