import { Injectable } from '@angular/core';
import { action, arrayUpsert, Store, StoreConfig } from '@datorama/akita';
import { OrganisationAccessRole, User, UserContext, UserSettingsBody } from '@nexuzhealth/shared-domain';
import { CookieService, moaprEssentialCookieKeys } from '@nexuzhealth/shared-settings-data-access-cookies';

const key_user_context_name = moaprEssentialCookieKeys.nh_auth_user_context_name;

export interface AuthState {
  userContextName: string;
  user: User;
  userContexts: UserContext[];
  settings: UserSettingsBody[];
  organisationAccessRoles: OrganisationAccessRole[];
}

export function createInitialAuthState(cookieService: CookieService): AuthState {
  return {
    userContextName: null,
    user: null,
    userContexts: null,
    settings: null,
    organisationAccessRoles: null,
    ...restoreLocal(cookieService),
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor(private cookieService: CookieService) {
    super(createInitialAuthState(cookieService));
  }

  @action('setUser')
  setUser(user: User): void {
    this.update({ user });
  }

  @action('setUserContexts')
  setUserContexts(userContexts: UserContext[]): void {
    this.update({ userContexts });
  }

  @action('[API/User Auto Import] Set user settings')
  setUserSettings(settings: UserSettingsBody[]): void {
    this.update({ settings });
  }

  @action('setUserContext')
  setUserContextName(userContextName: string): void {
    this.update({ userContextName });
    this.storeLocal(userContextName);
  }

  @action('[API/User Auto Import] Set user auto import eHealthbox')
  setUserAutoImportEHealthBox(enableAutoImport: boolean): void {
    this.update((state) => ({
      ...state,
      settings: arrayUpsert(
        state.settings,
        'ehealth-box-automatic-import-enabled',
        { value: enableAutoImport.toString() },
        'settingId',
      ),
    }));
  }

  @action('setOrganisationRoles')
  setOrganisationRoles(organisationAccessRoles: OrganisationAccessRole[]): void {
    this.update({ organisationAccessRoles });
  }

  @action('logout')
  logout() {
    this.removeLocal();
    this.update(createInitialAuthState(this.cookieService));
  }

  private storeLocal(value) {
    this.cookieService.setEssentialCookie(key_user_context_name, JSON.stringify(value));
  }

  private removeLocal() {
    this.cookieService.removeCookie(key_user_context_name);
  }
}

function restoreLocal(cookieService: CookieService) {
  return {
    userContextName: loadLocal(cookieService),
  };
}

function loadLocal(cookieService: CookieService) {
  try {
    const value = cookieService.getEssentialCookie(key_user_context_name);
    return value ? JSON.parse(value) : null;
  } catch (e) {
    cookieService.removeCookie(key_user_context_name);
    return undefined;
  }
}
