// Suppressed - component needs refactoring (HN-12077)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges,
  SkipSelf,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {
  DayPartOption,
  DayRecurrence,
  isDayRecurrence,
  isMonthRecurrence,
  isWeekRecurrence,
  MonthRecurrence,
  Period,
  TimeSchedule,
  TimeslotType,
  Timing,
  WeekRecurrence,
} from '@nexuzhealth/shared-domain';
import { FormHelperDirective, IGNORE_INVALIDABLE_CONTROL } from '@nexuzhealth/shared-ui-toolkit/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ToggleListOption } from '@nexuzhealth/shared-ui-toolkit/toggle';
import {
  DayRecurrenceFormValue,
  FormValue,
  MonthRecurrenceFormValue,
  RecurrenceTabType,
  TimingFormValue,
  WeekRecurrenceFormValue,
} from '../recurrences-form.domain';
import { RecurrencesFormService } from '../recurrences-form.service';
import { mapToDayRecurrence } from '../mappers/day-mapper.util';
import { mapToWeekRecurrence } from '../mappers/week-mapper.util';
import { mapToMonthRecurrence } from '../mappers/month.mapper.util';
import { mapToTiming } from '../mappers/timing-mapper.util';

// When there are multiple manual timings with a desired time, the desired time must be the same for all
const multipleTimingsDesiredTimesMustMatchValidator = (control: AbstractControl) => {
  const formArray = control as UntypedFormArray;
  const formArrayWithTimes = formArray.controls.filter((formGroup) => formGroup?.get('dayparts')?.get('time').value);
  // 1 or less => ok
  if (formArrayWithTimes.length <= 1) {
    return null;
  }
  const firstTime = formArrayWithTimes[0].get('dayparts').get('time').value;
  // in case of multiple times, they must all match
  if (!formArrayWithTimes.every((formGroup) => formGroup.get('dayparts').get('time').value === firstTime)) {
    return { 'desired-times-must-match': true };
  }
  return null;
};

// When there are multiple manual timings, the selection (daypart or desiredtime) must be the same for all
const multipleTimingsMustHaveSameSelectionValidator = (control: AbstractControl) => {
  const formArray = (control as UntypedFormArray).controls;
  // 1 or less => ok
  if (formArray.length <= 1) {
    return null;
  }

  if (formArray[0]?.get('dayparts')?.get('allDayOrTime')) {
    const firstSelection = formArray[0].get('dayparts').get('allDayOrTime').value;
    // in case of multiple selections, they must all match
    if (!formArray.every((formGroup) => formGroup.get('dayparts').get('allDayOrTime').value === firstSelection)) {
      return { 'selection-alldayortime-must-match': true };
    }
  }
  return null;
};

@Component({
  selector: 'nxh-recurrences',
  templateUrl: './recurrences.component.html',
  styleUrls: ['./recurrences.component.scss'],
  providers: [
    RecurrencesFormService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RecurrencesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RecurrencesComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecurrencesComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
  @Input() periodsOfApplication!: Period[];
  @Input() useTimeslots = false;
  @Input() weekdaysOptional = false;
  @Input() excludeTabs: RecurrenceTabType[] = [];
  @Input() keepHistoryOnSetValue = false;
  @Input() timeslotType = TimeslotType.RANGE;
  @Input() sameTimingsForAllDays = false;
  @Input() dayPartOptions: DayPartOption[];
  // If true, day part's second radio option is AllDay
  // If false, day part's second radio option is a clocktime input desired time
  @Input() useDayPartAllDay = true;
  // If true, validates time or at least 1 day part is required
  @Input() validateDayPartOrTime = true;
  // If true, validates that all manual timings have the same AllDayOrTime radio selection => this needs to be the case for automatically planning
  @Input() validateDayPartAllDayOrTimeSelection = false;
  @HostBinding(`class.${IGNORE_INVALIDABLE_CONTROL}`) ignoreInvalidable = true;

  public form = new UntypedFormGroup({
    name: new UntypedFormControl(),
    // recurrences get created at ngOnInit
    timings: new UntypedFormArray([]),
  });
  private destroy$$ = new Subject<void>();
  tabs!: ToggleListOption[];
  private receivedWriteValue!: TimeSchedule;

  get timingsArray() {
    return this.form.get('timings') as UntypedFormArray;
  }

  public selectRecurrence = new UntypedFormControl(RecurrenceTabType.weekly);
  public currentTabType$ = new BehaviorSubject<RecurrenceTabType>(this.selectRecurrence.value);

  private onChange?: (val: TimeSchedule) => void;
  private onValidatorChange?: () => void;

  private detachedGroups: {
    dayGroup: UntypedFormGroup | undefined;
    weekGroup: UntypedFormGroup | undefined;
    monthGroup: UntypedFormGroup | undefined;
    manualGroup: UntypedFormGroup[] | undefined;
  } = { dayGroup: undefined, weekGroup: undefined, monthGroup: undefined, manualGroup: undefined };

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private recurrencesFormService: RecurrencesFormService,
    @SkipSelf() @Optional() parentForm: FormHelperDirective,
  ) {
    parentForm?.submitButtonClicked$.subscribe(() => {
      this.form.markAllAsTouched();
    });
  }

  setActiveRecurrenceGroup(formControl: UntypedFormGroup | null) {
    if (formControl) {
      this.form.setControl('recurrences', formControl, { emitEvent: false });
    } else {
      this.form.removeControl('recurrences', { emitEvent: false });
    }
  }

  get activeRecurrenceGroup() {
    return this.form.get('recurrences') as UntypedFormGroup;
  }

  ngOnInit(): void {
    this.tabs = Object.values(RecurrenceTabType)
      .filter((type) => this.excludeTabs.indexOf(type) < 0)
      .map((type) => ({ value: type, label: type }));

    this.recurrencesFormService.setValidateDayPartOrTime(this.validateDayPartOrTime);
    this.recurrencesFormService.setUseTimeslots(this.useTimeslots);
    this.recurrencesFormService.setUseDayparts(this.dayPartOptions?.length > 0);
    this.recurrencesFormService.setWeekdaysOptional(this.weekdaysOptional);
    this.recurrencesFormService.setTimeSlotType(this.timeslotType);
    this.recurrencesFormService.setUseSameTimingsForAllDays(this.sameTimingsForAllDays);
    this.recurrencesFormService.setDayParts(this.dayPartOptions);
    this.recurrencesFormService.setUseDayPartAllDay(this.useDayPartAllDay);

    if (this.dayPartOptions?.length > 0) {
      if (!this.validateDayPartAllDayOrTimeSelection) {
        this.form.get('timings').addValidators(multipleTimingsMustHaveSameSelectionValidator);
      }
      if (!this.useDayPartAllDay) {
        this.form.get('timings').addValidators(multipleTimingsDesiredTimesMustMatchValidator);
      }
    }

    this.setActiveRecurrenceGroup(this.recurrencesFormService.createWeekRecurrenceGroup());

    // todo: we don't need weird currentTabType if we use pairWise
    this.selectRecurrence.valueChanges.subscribe((nextTabType) => {
      // keep track of current (soon to be previous) tab
      this.writeToDetachedGroups();

      // remove current tab
      this.setActiveRecurrenceGroup(null);
      this.timingsArray.clear();

      // initialize new tab (if the tab was previously selected, this one is reinstalled)
      this.setActiveRecurrenceGroupForTabType(nextTabType);

      // activate new tab so it can be rendered. but first allow the form to update.
      this.currentTabType$.next(nextTabType);
      this.callOnChange();
    });

    const form$ = this.form.valueChanges.pipe(map(() => this.form.getRawValue()));

    form$.pipe(takeUntil(this.destroy$$)).subscribe(() => this.callOnChange());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['periodsOfApplication'];
    if (change) {
      if (!change.isFirstChange()) {
        const timings = this.form.get('timings') as UntypedFormArray;
        this.recurrencesFormService.updatePeriodsOfApplication(this.periodsOfApplication, timings);
        if (this.onValidatorChange) {
          this.onValidatorChange();
        }
      } else {
        this.recurrencesFormService.setPeriodsOfApplication(this.periodsOfApplication);
      }
    }
    // When the day parts are loaded (this is now a BE call)
    if (changes['dayPartOptions'] && !changes['dayPartOptions'].isFirstChange()) {
      this.recurrencesFormService.setUseDayparts(this.dayPartOptions?.length > 0);
      this.recurrencesFormService.setDayParts(this.dayPartOptions);
      if (this.dayPartOptions?.length > 0) {
        if (!this.validateDayPartAllDayOrTimeSelection) {
          this.form.get('timings').addValidators(multipleTimingsMustHaveSameSelectionValidator);
        }
        if (!this.useDayPartAllDay) {
          this.form.get('timings').addValidators(multipleTimingsDesiredTimesMustMatchValidator);
        }
      }

      if (this.receivedWriteValue) {
        // Called during edit
        // If there is a receivedWriteValue, we rebuild the form with it (because now the day parts are loaded and part of the form)
        this.writeValue(this.receivedWriteValue);
      } else {
        // Called during create
        // When the day parts are loaded, we reset the detachedGroups and recreate the selected tab (because now the day parts are loaded and part of the form)
        this.detachedGroups.dayGroup = undefined;
        this.detachedGroups.weekGroup = undefined;
        this.detachedGroups.monthGroup = undefined;
        this.detachedGroups.manualGroup = undefined;
        this.setActiveRecurrenceGroupForTabType(this.currentTabType$.value);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  registerOnChange(fn: (_: TimeSchedule) => void): void {
    this.onChange = fn;
  }

  private setActiveRecurrenceGroupForTabType(tabType: RecurrenceTabType) {
    // initialize new tab (if the tab was previously selected, this one is reinstalled)
    switch (tabType) {
      case RecurrenceTabType.daily:
        this.setActiveRecurrenceGroup(
          this.detachedGroups.dayGroup || this.recurrencesFormService.createDayRecurrenceGroup(),
        );
        break;
      case RecurrenceTabType.weekly:
        this.setActiveRecurrenceGroup(
          this.detachedGroups.weekGroup || this.recurrencesFormService.createWeekRecurrenceGroup(),
        );
        break;
      case RecurrenceTabType.monthly:
        this.setActiveRecurrenceGroup(
          this.detachedGroups.monthGroup || this.recurrencesFormService.createMonthRecurrenceGroup(),
        );
        break;
      case RecurrenceTabType.manual:
        // eslint-disable-next-line no-case-declarations
        const groups: UntypedFormGroup[] = this.detachedGroups.manualGroup || [
          this.recurrencesFormService.createTimingControl(),
        ];
        this.recurrencesFormService.addTimings(this.timingsArray, groups);
    }
  }

  private writeToDetachedGroups(): void {
    const prevRecurrenceType = this.currentTabType$.getValue();
    switch (prevRecurrenceType) {
      case RecurrenceTabType.daily:
        if (this.activeRecurrenceGroup?.get('day')) {
          this.detachedGroups.dayGroup = this.activeRecurrenceGroup;
        }
        break;
      case RecurrenceTabType.weekly:
        if (this.activeRecurrenceGroup?.get('week')) {
          this.detachedGroups.weekGroup = this.activeRecurrenceGroup;
        }
        break;
      case RecurrenceTabType.monthly:
        if (this.activeRecurrenceGroup?.get('month')) {
          this.detachedGroups.monthGroup = this.activeRecurrenceGroup;
        }
        break;
      case RecurrenceTabType.manual:
        this.detachedGroups.manualGroup = [...this.timingsArray.controls] as UntypedFormGroup[];
        break;
    }
  }

  private callOnChange() {
    if (!this.onChange) {
      return;
    }

    const tabType = this.currentTabType$.getValue();
    const { recurrences, timings, ...value } = this.form.value as FormValue;
    if (tabType === RecurrenceTabType.daily) {
      this.onChange({
        name: value.name,
        recurrences: [mapToDayRecurrence(recurrences as DayRecurrenceFormValue, this.useDayPartAllDay)],
        timings: [],
      });
    } else if (tabType === RecurrenceTabType.weekly) {
      this.onChange({
        name: value.name,
        recurrences: [mapToWeekRecurrence(recurrences as WeekRecurrenceFormValue, this.useDayPartAllDay)],
        timings: [],
      });
    } else if (tabType === RecurrenceTabType.monthly) {
      this.onChange({
        name: value.name,
        recurrences: [mapToMonthRecurrence(recurrences as MonthRecurrenceFormValue, this.useDayPartAllDay)],
        timings: [],
      });
    } else if (tabType === RecurrenceTabType.manual) {
      this.onChange({
        name: value.name,
        recurrences: [],
        timings: (timings ?? [])
          .flatMap((timing: TimingFormValue) => mapToTiming(timing, this.useTimeslots, this.timeslotType))
          .filter((timing: Timing | null) => !!timing),
      });
    } else {
      throw new Error('unknown tab type ' + tabType);
    }
  }

  registerOnTouched(fn: any): void {
    /* empty by default */
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  writeValue(value?: TimeSchedule): void {
    // WriteValue is happening before the day parts are loaded => the day parts are not set in the form object
    // Store the writeValue object, so we can rebuild it when the day parts are loaded
    this.receivedWriteValue = value;

    if (this.keepHistoryOnSetValue) {
      this.writeToDetachedGroups();
    } else {
      // cleanup previous
      this.detachedGroups = {
        dayGroup: undefined,
        weekGroup: undefined,
        monthGroup: undefined,
        manualGroup: undefined,
      };
    }
    this.setActiveRecurrenceGroup(null);
    this.timingsArray.clear({ emitEvent: false });

    const valueIsNullOrIncomplete = !value || (value.timings?.length === 0 && value.recurrences?.length === 0);
    if (valueIsNullOrIncomplete) {
      this.form.patchValue({ name: value?.name }, { emitEvent: false });
      this.selectRecurrence.setValue(RecurrenceTabType.daily, { emitEvent: false });
      // period is 0, initial value should be set via new FormControl(DEFAULT_RECURRENCE_VALUES)
      this.setActiveRecurrenceGroup(
        this.recurrencesFormService.createDayRecurrenceGroup({
          period: 1,
          day: { timings: [] },
        }),
      );
      this.currentTabType$.next(RecurrenceTabType.daily);
      return;
    }

    const tabType = this.getTabType(value);

    this.form.patchValue({ name: value?.name }, { emitEvent: false });
    this.selectRecurrence.setValue(tabType, { emitEvent: false });

    switch (tabType) {
      case RecurrenceTabType.daily: {
        const dayRecurrence: DayRecurrence = value.recurrences[0] as DayRecurrence;
        this.setActiveRecurrenceGroup(this.recurrencesFormService.createDayRecurrenceGroup(dayRecurrence));
        break;
      }
      case RecurrenceTabType.weekly: {
        const weekRecurrence: WeekRecurrence = value.recurrences[0] as WeekRecurrence;
        this.setActiveRecurrenceGroup(this.recurrencesFormService.createWeekRecurrenceGroup(weekRecurrence));
        break;
      }
      case RecurrenceTabType.monthly: {
        const monthRecurrence: MonthRecurrence = value.recurrences[0] as MonthRecurrence;
        this.setActiveRecurrenceGroup(this.recurrencesFormService.createMonthRecurrenceGroup(monthRecurrence));
        break;
      }
      case RecurrenceTabType.manual: {
        this.recurrencesFormService.addManual(this.timingsArray, value?.timings);
        break;
      }
      default:
        console.error('unknown tab type', tabType);
    }

    this.currentTabType$.next(tabType);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(): ValidationErrors | null {
    return this.form.valid ? null : { 'recurrences-incomplete': true };
  }

  validateRecurrence(): void {
    this.form.markAllAsTouched();
    this.changeDetectorRef.markForCheck();
  }

  private getTabType(value?: TimeSchedule) {
    if (value && value.timings?.length > 0) {
      return RecurrenceTabType.manual;
    }
    if (isDayRecurrence(value?.recurrences[0])) {
      return RecurrenceTabType.daily;
    }
    if (isWeekRecurrence(value?.recurrences[0])) {
      return RecurrenceTabType.weekly;
    }
    if (isMonthRecurrence(value?.recurrences[0])) {
      return RecurrenceTabType.monthly;
    }
    return RecurrenceTabType.weekly;
  }
}
