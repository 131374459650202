import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { FormState, FormStore } from './form.store';

@Injectable({
  providedIn: 'root',
})
export class FormQuery extends Query<FormState> {
  constructor(store: FormStore) {
    super(store);
  }

  /**
   * Returns if there is atleast one form or modal that is dirty
   */
  isAnyDirty() {
    return this.isModalDirty() || this.isAnyFormDirty();
  }

  isModalDirty() {
    return this.getValue().isModalDirty;
  }

  isFormDirty(formName: string) {
    const outlets = Object.values(this.getValue().outlets);
    return outlets.some((formContext) => {
      return formContext[formName] || false;
    });
  }

  isOutletDirty(outletName = 'primary') {
    const outlet = this.getValue().outlets[outletName];
    return outlet ? Object.values(outlet).some((isDirty) => isDirty) : false;
  }

  private isAnyFormDirty() {
    const formContexts = Object.values(this.getValue().outlets);
    return formContexts.some((outlet) => Object.values(outlet).some((isDirty) => isDirty));
  }
}
