import { Component, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { TimeslotType } from '@nexuzhealth/shared-domain';
import { RecurrencesFormService } from '../recurrences-form.service';

@Component({
  selector: 'nxh-time-periods',
  templateUrl: './time-periods.component.html',
  styleUrls: ['./time-periods.component.scss'],
})
export class TimePeriodsComponent {
  @Input() parent!: UntypedFormGroup;
  @Input() label!: string;
  @Input() addIconTooltip?: string;
  @Input() removeIconTooltip?: string;

  constructor(private recurrencesFormService: RecurrencesFormService) {}

  get periods() {
    return this.parent?.get('timings') as UntypedFormArray;
  }

  addPeriod() {
    this.periods.push(this.recurrencesFormService.createTimeslotControl());
  }

  removePeriod(index: number) {
    this.periods.removeAt(index);
    if (this.periods.length === 0) {
      this.addPeriod();
    }
  }

  showEndDate() {
    return this.recurrencesFormService.getTimeSlotType() === TimeslotType.RANGE;
  }
}
