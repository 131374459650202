import { Inject, Injectable, Optional } from '@angular/core';
import { EnvironmentVariables, EnvironmentVariablesService } from '@nexuzhealth/shared-domain';
import { I18NextConfig, LogService, Preloadable } from '@nexuzhealth/shared-util';
import { get, has } from 'lodash-es';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DeepReadonly } from 'utility-types';
import { SettingsApiService } from '../api/settings-api.service';
import { ComponentSettings, ComponentSettingsToken, Config, SystemSettings } from '../shared/settings.model';

@Injectable({ providedIn: 'root' })
export class SettingsService implements Preloadable {
  // you should not be able to mutate these.
  private systemSettings: DeepReadonly<SystemSettings>;
  private componentSettings: DeepReadonly<ComponentSettings>;

  constructor(
    private api: SettingsApiService,
    private logger: LogService,
    @Optional() @Inject(EnvironmentVariablesService) private environmentVariables: EnvironmentVariables,
    @Optional() @Inject(ComponentSettingsToken) componentSettings: ComponentSettings = { components: {} },
  ) {
    this.componentSettings = Object.freeze(componentSettings);
  }

  get configUrl() {
    return this.api.backendUrl;
  }

  get configFilename() {
    return this.environmentVariables?.configFilename || 'config.json';
  }

  get mapboxAccessToken() {
    return this.systemSettings.mapboxAccessToken;
  }

  get application() {
    return this.systemSettings.application;
  }

  get authConfig(): DeepReadonly<Config> {
    return this.systemSettings.config;
  }

  get wasmConfig(): DeepReadonly<Pick<Config, 'endpoint'>> {
    return this.systemSettings.wasm;
  }

  get auth0config() {
    return this.systemSettings.auth0;
  }

  get i18nextConfig(): I18NextConfig {
    return this.systemSettings.i18next as I18NextConfig;
  }

  get loginEndpoint() {
    return this.systemSettings.loginEndpoint;
  }

  get logOutEndpoint() {
    return this.systemSettings.logOutEndpoint;
  }
  get logOutEhealthEndpoint() {
    return this.systemSettings.logOutEhealthEndpoint;
  }

  get myCarenetDashboard() {
    return this.systemSettings.myCarenetDashboard;
  }

  get zetesEidEndpoint() {
    return this.systemSettings.zetesEidEndpoint;
  }

  get zetesClientId() {
    return this.systemSettings.zetesClientId;
  }

  get idleTimeout() {
    return this.systemSettings.idleTimeout;
  }

  get custom() {
    return this.systemSettings.custom;
  }

  get integrations() {
    return this.systemSettings.integrations;
  }

  get pushNotificationsUrl() {
    const pushNotifsConfig = this.systemSettings.pushNotifs;
    const port = pushNotifsConfig.port ? ':' + pushNotifsConfig.port : '';
    return this.authConfig.endpoint + port + pushNotifsConfig.pathname;
  }

  get fullcalendarLicenseKey() {
    return this.systemSettings.fullcalendarLicenseKey;
  }

  get barometer() {
    return this.systemSettings.barometer;
  }

  public getSystemSettings(): DeepReadonly<SystemSettings> {
    return this.systemSettings;
  }

  getSystemSettingValue<T>(path: string, defaultValue?: T): Readonly<T> {
    return Object.freeze(get(this.systemSettings, path, defaultValue));
  }

  /**
   * if strict, checks if path acutally exists on compont settings. If not, throw error.
   *
   * TODO: This could replaced in the future with better typescript interference. See:
   * * https://boopathi.blog/typescript-typing-object-paths/
   * * https://github.com/Microsoft/TypeScript/issues/12290
   * * https://github.com/millsp/ts-toolbelt
   */
  getComponentSettingValue<T>(path: string, defaultValue?: T, strict: boolean = false): Readonly<T> {
    if (strict) {
      if (!has(this.componentSettings, path)) {
        throw new Error('No setting for component');
      }
    }
    return Object.freeze(get(this.componentSettings, path, defaultValue));
  }

  async getI18nextConfig(): Promise<I18NextConfig> {
    const systemSettings = await this.loadSystemSettings().toPromise();
    return systemSettings.i18next as I18NextConfig;
  }

  async preload(): Promise<{ system: SystemSettings; i18next: I18NextConfig }> {
    const systemSettings = await this.loadSystemSettings().toPromise();
    const i18next = this.systemSettings.i18next as I18NextConfig;

    return {
      system: systemSettings,
      i18next,
    };
  }

  private loadSystemSettings(): Observable<SystemSettings> {
    this.logger.debug(`[FrontendConfigService] fetching system settings from @${this.configUrl}${this.configFilename}`);
    return this.api.loadSystemSettings(this.configFilename).pipe(
      tap((systemSettings) => {
        this.logger.debug('[FrontendConfigService] system settings loaded', systemSettings);
        // consider adding this to store/caching this in localstorage
        this.systemSettings = Object.freeze(systemSettings);
      }),
      catchError((err) => {
        this.logger.debug(
          `[FrontendConfigService] error while fetching system settings from @${this.configUrl}${this.configFilename}`,
          err,
        );
        // either move to error page (window.location.href = 'error-page') or show failure message on
        // index.html (window.document.body.classList.add('failed'));
        return throwError(err || 'Server error');
      }),
    );
  }
}
