<ul ngbNav #nav="ngbNav" class="nav-tabs">
  <li ngbNavItem>
    <a ngbNavLink>Tab nr 1</a>
    <ng-template ngbNavContent>
      <div class="card-body">Lorem ipsum tab1</div>
    </ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink>Tab nr 2</a>
    <ng-template ngbNavContent>
      <div class="card-body">Lorem ipsum tab2</div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>

<pre>
  <code [highlight]="code" language="html"></code>
</pre>

Check <a href="https://ng-bootstrap.github.io/#/components/nav/overview">here</a> for more details.
