import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { InfiniteScrollDirective } from './infinite-scroll.directive';
import { PagingComponent } from './paging.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, SharedUtilI18nModule, ButtonModule, IconsModule],
  exports: [PagingComponent, InfiniteScrollDirective],
  declarations: [PagingComponent, InfiniteScrollDirective],
})
export class PagingModule {}
