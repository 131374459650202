import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nxh-timeout-state-demo',
  templateUrl: './timeout-state-demo.component.html',
  styleUrls: ['./timeout-state-demo.component.scss'],
})
export class TimeoutStateDemoComponent {
  codeTimeout = `
// Use this timeoutState when loading data takes too long
// visual design: https://projects.invisionapp.com/d/main/default/#/console/18745151/395841226

<nxh-timeout-state>
  <div class="timeout__title">Probleem bij laden</div>
  <div class="timeout__description">
    Herlaad de pagina om opnieuw te proberen.
  </div>
</nxh-timeout-state>
  `;

  constructor() {}
}
