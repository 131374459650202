import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { ScrollspyService } from '@nexuzhealth/shared-ui-toolkit/scrollspy';
import { SortModel } from '@nexuzhealth/shared-ui-toolkit/table';
import { TableLoaderComponent } from '@nexuzhealth/shared-ui-toolkit/loading-states/table-loader';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { delay, map, startWith, tap } from 'rxjs/operators';
import { TinyLoaderComponent, TinyLoaderStatus } from '@nexuzhealth/shared-ui-toolkit/loading-states/tiny-loader';
import { PageLoaderComponent } from '@nexuzhealth/shared-ui-toolkit/loading-states/page-loader';

@Component({
  selector: 'nxh-loading-states-demo',
  templateUrl: './loading-states-demo.component.html',
  styleUrls: ['./loading-states-demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingStatesDemoComponent implements OnInit {
  @ViewChild('pageLoader', { static: true, read: PageLoaderComponent }) pageLoader: PageLoaderComponent;
  @ViewChild('tinyLoader', { static: true, read: TinyLoaderComponent }) tinyLoader: TinyLoaderComponent;
  @ViewChild('tableLoader', { static: true, read: TableLoaderComponent }) tableLoader: TableLoaderComponent;

  TinyLoaderStatus = TinyLoaderStatus;
  dataSource = new BehaviorSubject<Book[]>([]);
  sortModel = new SortModel<Book>();
  pageLoading$: Observable<boolean>;
  smallText = false;

  pageLoaderManualCode = `
    // set this on top of page
    <nxh-page-loader #pageLoader></nxh-page-loader>

    // while the PageLoader is active (loading/error/empty) the rest of the page can be hidden
    <div class="card" [hidden]="pageLoader.active$ | async">

    // in the typescript you get a reference to the PageLoader and...
    @ViewChild('pageLoader', { static: true, read: PageLoaderComponent }) pageLoader: PageLoaderComponent;

    // ...start the pageLoad
    this.pageLoader.startLoading();

    // ...stop the pageLoad after data has been loaded. Pass 0 if you want to show the empty state.
    this.pageLoader.setLoaded(nbrOfResults);

    // ...stop the pageLoad after an error. This will show the error state.
    this.tableLoader.setError(err);
  `;

  pageLoaderInputApiCode = `
    // set this on top of page
    <nxh-page-loader [loading]="loading$ | async" [data]="data$ | async" [error]="error$ | async"></nxh-page-loader>
    // or
    <nxh-page-loader [loading]="loading$ | async" [loaded]="(data$ | async)?.length" [error]="error$ | async"></nxh-page-loader>
   `;

  filterEnabledCode = `
      // ...
      if (!this.isFilterEnabled()) {
        this.pageLoader.setLoaded(result.length);
      }
      // ...
    }

    private isFilterEnabled() {
      const formData = this.quickFilterForm.value;
      return !!formData.searchText || formData.categoryName.name !== this.allCategories.name;
    }
  `;

  withCustomTemplate = `
    <nxh-page-loader #pageLoader [emptyStateTemplate]="emptyPage"></nxh-page-loader>
    <ng-template #emptyPage>
      <div class="custom-empty-state">nothing to see here</div>
    </ng-template>
  `;

  tableLoaderCode = `
    // set this underneath the table
    <nxh-paging-table-loader #tableLoader></nxh-table-loader>

    // in the typescript you get a reference to the TableLoader and...
    @ViewChild('tableLoader', { static: true, read: TableLoaderComponent }) pageLoader: TableLoaderComponent;

    // ...start the tableLoad
    this.tableLoader.startLoading();

    // ...stop the tableLoad after data has been loaded. Pass 0 if you want to show the empty state.
    this.tableLoader.setLoaded(nbrOfResults);

    // ...stop the tableLoad after an error. This will show the error state.
    this.tableLoader.setError(err);
  `;

  tinyCode = `
  // LOADING
  tinyLoader.startLoading();
  // EMPTY
  tinyLoader.setLoaded(0)
  // ERROR
  tinyLoader.setError('shit happened')
  // WARNING
  tinyLoader.setState('warning')
  //  SUCCESS
  tinyLoader.setState('success')
  `;

  selected$ = new BehaviorSubject('block1');

  books$ = of([
    { title: 'Lord of the Rings', author: 'Tolkien' },
    { title: 'Do Androids Dream of Electric Sheep?', author: 'Philip K. Dick' },
    { title: 'The Great Gatsby', author: 'F. Scott Fitzgerald' },
    { title: 'The Hunger Games', author: 'Suzanne Collins' },
    { title: 'Twilight', author: 'Stephenie Meyer' },
    { title: 'Jane Eyre', author: 'Charlotte Brontë' },
    { title: 'Animal Farm', author: 'George Orwell' },
    { title: 'The Kite Runner', author: 'Khaled Hosseini' },
  ]);

  sort$ = this.sortModel.valueChanges().pipe(startWith(this.sortModel.value));

  constructor(private scrollspyService: ScrollspyService) {}

  scrollTo(id) {
    this.selected$.next(id);
    this.scrollspyService.scrollTo(id, { emitEvent: false });
  }

  scrolledInView(id) {
    this.selected$.next(id);
  }

  ngOnInit() {
    this.pageLoader.startLoading();
    this.tinyLoader.startLoading();

    this.loadBooks();
  }
  loadBooks() {
    combineLatest([this.books$, this.sort$])
      .pipe(
        tap(() => {
          this.tableLoader.startLoading();
          this.dataSource.next([]);
        }),
        map(([books, sort]) => {
          return books;
        }),
        delay(2000),
      )
      .subscribe({
        next: (books) => {
          this.dataSource.next(books);
          if (!this.pageLoader.isLoaded()) {
            this.pageLoader.setLoaded(books.length);
          }
          this.tableLoader.setLoaded(books.length);
        },
        error: (err) => {
          if (!this.pageLoader.isLoaded()) {
            this.pageLoader.setError(err);
          }
          this.tableLoader.setError(err);
        },
      });
  }

  trackByTitle(book) {
    return book.title;
  }

  setTinyLoaderError(errMsg) {
    this.tinyLoader.setError(new Error(errMsg));
  }

  setTableLoaderError(errMsg) {
    this.tableLoader.setError(new Error(errMsg));
  }
}

export interface Book {
  title: string;
  author: string;
}
