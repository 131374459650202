<h3>Toast</h3>

<p>
  For our toasts we use the <a href="https://github.com/scttcper/ngx-toastr">ngx-toastr</a> library. Typically you use
  it like so:
</p>

<pre>
      <code [highlight]="codeTS" language="typescript"></code>
</pre>

<p>
  Always having to translate the messages is cumbersome. That's why we have provided a wrapper,
  <code>ToastrHelperService</code>.
</p>

<pre>
      <code [highlight]="codeToastrHelper" language="typescript"></code>
</pre>

<h4>demo</h4>

<div class="demobox">
  <button class="btn btn-danger" (click)="showToastError()">Show toast ERROR</button>
  <button class="btn btn-warning" (click)="showToastWarning()">Show toast WARNING</button>
  <button class="btn btn-info" (click)="showToastInfo()">Show toast INFO</button>
  <button class="btn btn-success" (click)="showToastSuccess()">
    Show toast SUCCESS with progressbar and close button
  </button>
</div>

<br /><br />

<h4>Toast Options</h4>

<table class="table">
  <thead>
    <tr>
      <th>Option</th>
      <th>Type</th>
      <th>Default</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>toastComponent</td>
      <td>Component</td>
      <td>Toast</td>
      <td>Angular component that will be used</td>
    </tr>
    <tr>
      <td>closeButton</td>
      <td>boolean</td>
      <td>false</td>
      <td>Show close button</td>
    </tr>
    <tr>
      <td>timeOut</td>
      <td>number</td>
      <td>5000</td>
      <td>Time to live in milliseconds</td>
    </tr>
    <tr>
      <td>extendedTimeOut</td>
      <td>number</td>
      <td>1000</td>
      <td>Time to close after a user hovers over toast</td>
    </tr>
    <tr>
      <td>disableTimeOut</td>
      <td>boolean |</td>
      <td>'timeOut' | 'extendedTimeOut'</td>
      <td>false</td>
    </tr>
    <tr>
      <td>easing</td>
      <td>string</td>
      <td>'ease-in'</td>
      <td>Toast component easing</td>
    </tr>
    <tr>
      <td>easeTime</td>
      <td>string | number</td>
      <td>300</td>
      <td>Time spent easing</td>
    </tr>
    <tr>
      <td>enableHtml</td>
      <td>boolean</td>
      <td>false</td>
      <td>Allow html in message</td>
    </tr>
    <tr>
      <td>progressBar</td>
      <td>boolean</td>
      <td>false</td>
      <td>Show progress bar</td>
    </tr>
    <tr>
      <td>progressAnimation</td>
      <td><code>'decreasing' | 'increasing'</code></td>
      <td>'decreasing'</td>
      <td>Changes the animation of the progress bar.</td>
    </tr>
    <tr>
      <td>toastClass</td>
      <td>string</td>
      <td>'ngx-toastr'</td>
      <td>Class on toast</td>
    </tr>
    <tr>
      <td>positionClass</td>
      <td>string</td>
      <td>'toast-top-right'</td>
      <td>Class on toast container</td>
    </tr>
    <tr>
      <td>titleClass</td>
      <td>string</td>
      <td>'toast-title'</td>
      <td>Class inside toast on title</td>
    </tr>
    <tr>
      <td>messageClass</td>
      <td>string</td>
      <td>'toast-message'</td>
      <td>Class inside toast on message</td>
    </tr>
    <tr>
      <td>tapToDismiss</td>
      <td>boolean</td>
      <td>true</td>
      <td>Close on click</td>
    </tr>
    <tr>
      <td>onActivateTick</td>
      <td>boolean</td>
      <td>false</td>
      <td>
        Fires <code>changeDetectorRef.detectChanges()</code> when activated. Helps show toast from asynchronous events
        outside of Angular's change detection
      </td>
    </tr>
  </tbody>
</table>
