<h1>Icon loader component</h1>

<h2>regular spinning (default)</h2>
<p>the default loading</p>
<pre><code language="html" [highlight]="code"></code></pre>
<nxh-icon-loader></nxh-icon-loader>

<h2>pulsing</h2>
<p>a smoother loading experience</p>
<pre><code language="html" [highlight]="pulseCode"></code></pre>
<nxh-icon-loader type="pulse"></nxh-icon-loader>
