<h2>Counter Bar Card</h2>

<div class="nh-row">
  <div class="card nh-col-6">
    <div class="card-body">
      <div class="counter-bar-card">
        <div class="counter-bar-card__side-bar">1</div>
        <div class="counter-bar-card__content">My card content goes here</div>
      </div>
      <div class="counter-bar-card">
        <div class="counter-bar-card__side-bar">2</div>
        <div class="counter-bar-card__content form-group" [formGroup]="form">
          <input type="text" formControlName="firstname" nxhCompactFormControl="" label="First name" />
          <input type="text" formControlName="lastname" nxhCompactFormControl="" label="Last name" />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="nh-row">
  <pre><code [highlight]="codeExample" language="html"></code></pre>
</div>
