import { Component } from '@angular/core';
import { longPlaying } from '@nexuzhealth/shared-util';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'nxh-loading-spinner-demo',
  templateUrl: './loading-spinner-demo.component.html',
  styleUrls: ['./loading-spinner-demo.component.scss'],
})
export class LoadingSpinnerDemoComponent {
  longPlayingSubscription: Subscription;
  longPlaying = false;
  loading = false;
  results = false;

  longPlayingSubscription2: Subscription;
  loading2 = false;
  results2 = false;

  valueChanged2($event) {
    if ($event.target.value) {
      this.getFromBackend2();
    } else {
      this.loading2 = false;
      this.results2 = false;
    }
  }

  valueChanged($event) {
    if ($event.target.value) {
      this.getFromBackend();
    } else {
      this.loading = false;
      this.results = false;
    }
  }

  // This method simulates a call which can take a while (ei a backend call)
  private getFromBackend() {
    this.longPlaying = false;
    this.loading = true;
    this.results = false;

    if (this.longPlayingSubscription) this.longPlayingSubscription.unsubscribe();

    const subject = new Subject<void>();
    this.longPlayingSubscription = subject.pipe(longPlaying(() => (this.longPlaying = true))).subscribe(() => {
      this.loading = false;
      this.results = true;
    });

    setTimeout(() => subject.next(), 10000);
  }

  private getFromBackend2() {
    this.loading2 = true;
    this.results2 = false;

    if (this.longPlayingSubscription2) this.longPlayingSubscription2.unsubscribe();

    const subject = new Subject<void>();
    this.longPlayingSubscription2 = subject.subscribe(() => {
      this.loading2 = false;
      this.results2 = true;
    });

    setTimeout(() => subject.next(), 10000);
  }
}
