import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to make part of the number bold, typically used in autocomplete components.
 * As this pipe introduces various <span> tags, you have to make sure you use this in combination with [innerHTML]!
 *
 * Eg. <div [innerHTML]="patient.personName.displayName | bold: (term$ | async)"></div>
 */
@Pipe({
  name: 'boldNumber',
})
export class BoldNumberPipe implements PipeTransform {
  transform(value: string, regex?: string): any {
    if (!value || !regex) {
      return value;
    }

    if (typeof value !== 'string' || typeof regex !== 'string' || regex.substr(0).match('\\d') === null) {
      return value;
    }

    //Return the length without special characters.
    const length = regex.replace(new RegExp('(\\.|:|,|-)', 'g'), '').length;

    let occurences = 0;
    let newSearchTerm = '';
    for (const character of value) {
      if (/\d/.exec(character) !== null) {
        occurences++;
      }
      newSearchTerm = newSearchTerm + character;
      if (occurences === length) {
        break;
      }
    }

    // cf. https://stackoverflow.com/questions/55241693/apply-bold-text-on-part-of-string-angular
    const caseInsensitiveRegex = new RegExp(`(${newSearchTerm})`, 'gi');
    return value.replace(caseInsensitiveRegex, '<span class="fw-bold">$1</span>');
  }
}
