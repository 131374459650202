import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AdministrativeGenderQuery, CountryQuery } from '@nexuzhealth/shared-reference-data-data-access';
import { BlobService } from '@nexuzhealth/shared/tech/data-access-blob';
import { AvatarConfig } from '@nexuzhealth/shared-ui-toolkit/avatar';
import { FormQuery, FormStore } from '@nexuzhealth/shared-ui-toolkit/dirty-check/data-access';
import { PersonBlockComponentDataLookup } from '@nexuzhealth/shared-ui-toolkit/person';
import { default_timeout_warning_millis, LoadingStatesConfig } from '@nexuzhealth/shared-util';
import { NgbModalConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { I18NextPipe } from 'angular-i18next';
import { DisplayMode, ValdemortConfig } from 'ngx-valdemort';
import { fromEvent } from 'rxjs';
import { NgSelectConfig } from '@ng-select/ng-select';
import { AppTitleService } from './app-title.service';

@Injectable({ providedIn: 'root' })
export class SharedShellConfigService {
  constructor(
    private modalConfig: NgbModalConfig,
    private formQuery: FormQuery,
    private formStore: FormStore,
    private router: Router,
    private i18n: I18NextPipe,
    private avatarConfig: AvatarConfig,
    private blobService: BlobService,
    private loadingStatesConfig: LoadingStatesConfig,
    private valdemortConfig: ValdemortConfig,
    private titleService: AppTitleService,
    private personComponentDataLookup: PersonBlockComponentDataLookup,
    private countryQuery: CountryQuery,
    private genderQuery: AdministrativeGenderQuery,
    private ngSelectConfig: NgSelectConfig,
  ) {}

  setup(tooltipConfig: NgbTooltipConfig) {
    this.configRouter();
    this.configTooltip(tooltipConfig);
    this.configModal();
    this.setupDirtyChecks();
    this.configAvatar();
    this.configLoadingStates();
    this.configValidationErrors();
    this.setupPersonComponentDataLookup();
    this.setupNgSelect();
    // commented out until we fix https://jira.uz.kuleuven.ac.be/browse/FEG-867
    // this.configTitle();
  }

  private configRouter() {
    // initial navigation was postponed until dynamic config was loaded - cf.
    // https://github.com/angular/angular/issues/29828 and https://github.com/angular/angular/issues/14588
    this.router.initialNavigation();
  }

  private configTooltip(tooltipConfig: NgbTooltipConfig) {
    tooltipConfig.placement = ['auto'];
    tooltipConfig.container = 'body';
    tooltipConfig.triggers = 'hover';
  }

  private configModal() {
    this.modalConfig.backdrop = 'static';
    this.modalConfig.beforeDismiss = () => {
      if (this.formQuery.isModalDirty()) {
        const cont = confirm(this.i18n.transform('_exit-modal.title'));
        if (cont) {
          this.formStore.markModalPristine();
          return true;
        } else {
          return false;
        }
      }
      return true;
    };
  }

  private setupDirtyChecks() {
    fromEvent(window, 'beforeunload').subscribe(($event) => {
      if (this.formQuery.isAnyDirty()) {
        $event.returnValue = confirm();
      }
    });
  }

  private configAvatar() {
    this.avatarConfig.dataSource = this.blobService;
  }

  private configLoadingStates() {
    this.loadingStatesConfig.warningTimeoutTime = default_timeout_warning_millis;
  }

  private configValidationErrors() {
    this.valdemortConfig.errorClasses = 'input-error';
    this.valdemortConfig.displayMode = DisplayMode.ONE;
  }

  private configTitle() {
    this.titleService.setup();
  }

  private setupPersonComponentDataLookup() {
    this.personComponentDataLookup.datasource = {
      getCountry: (countryName) => this.countryQuery.getEntity(countryName)?.translationKey ?? '',
      getGender: (gender) => this.genderQuery.getEntity(gender)?.translationKey ?? '',
    };
  }

  private setupNgSelect() {
    this.ngSelectConfig.loadingText = this.i18n.transform('_loading-states.loading');
    this.ngSelectConfig.typeToSearchText = this.i18n.transform('_typeahead.noChoicesText');
  }
}
