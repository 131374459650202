<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Timeouts</h1>
    </div>
  </div>
</div>
<h1>Page load</h1>
<p>First we will simulate a page load. Set the network to "Slow 3G" and click below "Load Page" button.</p>
<button class="btn btn-primary" (click)="loadPage(pageLoader)">Load Page</button>

<div class="page-loader-example">
  <nxh-page-loader [warningTimeoutTime]="1000" #pageLoader></nxh-page-loader>

  <div [hidden]="pageLoader.active$ | async">
    <br />
    <pre>{{ dogs$ | async | json }}</pre>
  </div>
</div>

<p>
  For demonstration purposes, we have set the time after which a warning is shown to 1000 millis. This can be
  accomplished by changing <code>PageLoaderComponent</code>'s (and <code>TableLoaderComponent</code>'s)
  <code>[warningTimeoutTime]</code> attribute.
</p>
<pre>
  <code language="html" [highlight]="warningPageLoader"></code>
</pre>

<p>
  In case there is a timeout error, the PageLoader and TableLoader will show the already familiar TimeoutStateComponent.
  Click below button for a demonstration
</p>

<button class="btn btn-primary" (click)="loadPage(pageLoader2, true)">Load Page (but now with a timeout error)</button>

<div class="page-loader-example">
  <nxh-page-loader [warningTimeoutTime]="1000" #pageLoader2></nxh-page-loader>

  <div [hidden]="pageLoader2.active$ | async">
    <br />
    <pre>{{ dogs$ | async | json }}</pre>
  </div>
</div>

<p>
  Now we have, for demonstration purposes, changed the time after which the request times out. You will seldom do this,
  but it is nevertheless interesting to know how:
</p>
<pre>
  <code language="typescript" [highlight]="errorPageLoader"></code>
</pre>

<h1>Form submit</h1>

<p>When dealing with forms, we show the user timeout information via toasts. Submit the form below for a demo:</p>

<form [formGroup]="dogsForm" (ngSubmit)="submitPersonForm()" nxhBypassAutoValidate>
  <div class="form-group">
    <label>Name</label>
    <input class="form-control" formControlName="name" />
  </div>
  <button class="btn btn-primary" type="submit">submit</button>
  <div class="small">
    There's no backend for this form, but because the submit will timeout first, that is not a problem (but don't forget
    to change your network to 'slow 3g')
  </div>
</form>
<br />

<p>
  The reason why you see multiple alerts, is because the time after which the first timeout warning is shown, is set to
  a very small number.
</p>

<h1>Overwriting the defaults</h1>

<p>
  For demonstration purposes we have already changed some defaults. The default <code>warningMillis</code> (time after
  which we show the first warning message) can be changed by passing it to the PageLoaderComponent/TableLoaderComponent,
  or to the <code>FormHelperService</code>'s submit() method when submitting a form:
</p>

<pre>
  <code language="html" [highlight]="customWarningMillis"></code>
</pre>

<p>
  The default <code>errorMillis</code> (time after which a request times out) can be set by adding a
  <code>TimeoutInfo</code>
  object to a request header:
</p>

<pre>
  <code language="typescript" [highlight]="settingRequestHeader"></code>
</pre>

<p>
  To disable the warnings, you can pass a <code>TimeoutInfo</code> object with <code>warningMillis</code> set to -1. The
  same can be accomplished with the PageLoader and TableLoader, which have an input attribute
  <code>warningTimeoutTime</code>
</p>

<h1>Utilities</h1>

<div class="card">
  <div class="card-body card-body--striped">
    <div class="card-body__container">
      <div class="card-body__container__content">
        <div class="data-list">
          <div class="data-list__row">
            <div class="data-list__key">timeout-utils/timeouts</div>
            <div class="data-list__value">
              Rxjs operator to add the timeout behavior to an Observable. Use it in combination with a
              <code>TimeoutInfo</code> (to set the timing params), a <code>TimeoutListener</code> (to show a warning or
              error) and a <code>stopWarning Observable</code> (to denote when to stop showing warning messages - for
              example when switching pages).
            </div>
          </div>
          <div class="data-list__row">
            <div class="data-list__key">timeout-utils/timeoutHeaders</div>
            <div class="data-list__value">
              You can change the default timeout times by setting an HttpHeader with a specific
              <code>TimeoutInfo</code> object. This function creates such an object. To disable timeout warnings you can
              set the warningMillis -1, e.g. <code language="typescript" [highlight]="'{warningMillis: -1}'"></code>.
            </div>
          </div>
          <div class="data-list__row">
            <div class="data-list__key">TimeoutInfo</div>
            <div class="data-list__value">
              Various timeout utility methods accept a <code>TimeoutInfo</code> object, to change the default:
              <ul>
                <li>warningMillis: time after which the first warning is shown.</li>
                <li>intervalMillis: time between consecutive warnings.</li>
                <li>errorMillis: time after which the Observable times out.</li>
              </ul>
            </div>
          </div>
          <div class="data-list__row">
            <div class="data-list__key">SetTimeoutsService.at()</div>
            <div class="data-list__value set-timeouts-service-example">
              <div>
                Creates a <code>timeouts</code> operator with a default <code>TimeoutListener</code>. The default
                listener uses toasts to inform the user. Here's an example from <code>InvoicePageComponent</code>:
              </div>
              <pre>
                <code language="typescript" [highlight]="setTimeoutsServiceCode"></code>
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
