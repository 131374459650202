<ng-container [formGroup]="address">
  <div class="nh-row">
    <div class="form-group nh-col-6 nh-col-md-12">
      <input
        type="text"
        placeholder="1234 Main St"
        formControlName="address1"
        nxhCompactFormControl="address 1"
        [required]="true"
      />
    </div>
    <div class="form-group nh-col-6 nh-col-md-12">
      <input
        type="text"
        placeholder="Apartment, studio, or floor"
        formControlName="address2"
        nxhCompactFormControl="address 2"
      />
    </div>
  </div>
  <div class="nh-row" [class.has-error-message]="address.hasError('city-not-in-country')">
    <div class="form-group nh-col-6 nh-col-md-12">
      <label [attr.for]="prefixId('city')">City</label>
      <input type="text" class="form-control" [attr.id]="prefixId('city')" formControlName="city" />
    </div>
    <div class="form-group nh-col-2 nh-col-md-12">
      <label [attr.for]="prefixId('zip')">Zip</label>
      <input type="text" class="form-control" [attr.id]="prefixId('zip')" formControlName="zip" />
    </div>
    <div class="form-group nh-col-4 nh-col-md-12">
      <label [attr.for]="prefixId('country')">Country</label>
      <input type="text" class="form-control" [attr.id]="prefixId('country')" formControlName="country" />
    </div>
  </div>
  <div *ngIf="address.hasError('city-not-in-country')">
    <small class="text-danger small typeahead-error"> city-not-in-country </small>
  </div>
  <div>
    <small id="addressHelp" class="form-text text-muted"
      >Give a country other then 'belgium' and a city other then 'louvain' to trigger a validation error</small
    >
  </div>
</ng-container>
