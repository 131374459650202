import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { FormControlDirective, FormControlName, FormGroupDirective, FormGroupName } from '@angular/forms';
import { guid } from '@datorama/akita';
import { ValidationErrorsComponent } from 'ngx-valdemort';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { INVALIDABLE_CONTROL } from '../../../shared/form-helper.domain';
import { disabledChanges } from '../../../shared/form-helper.utils';

const checkboxLikeTypes = ['radio', 'checkbox'];

const noFormControlEls =
  'NXH-(SELECT-|NUMBER-FIELD|EMAIL-ADDRESS|TIME-PICKER|PATIENT-SEARCH|DATE-PICKER|DOCUMENT-EDITOR|PARTIAL-DATE|TOGGLE-LIST|MULTI-TOGGLE-LIST|SINGLE-TOGGLE-LIST|PHONE-NUMBER|COUNTDOWN-INPUT|PRODUCT-SEARCH|ADDRESS|RECURRENCE|DATE-TIME-PICKER|RANGE-PICKER|MULTI-LEVEL-DROPDOWN|CARE-SEARCH|ERROR-SEARCH)';
const elsRegex = new RegExp(noFormControlEls);

const noFormControlClasses = 'btn-group-toggle|no_form-control-class';
const classesRegex = new RegExp(noFormControlClasses);

// public for testing
export function shouldAddFormControlClass(nativeElement: HTMLElement) {
  return !elsRegex.test(nativeElement.nodeName) && !classesRegex.test(nativeElement.className);
}

function shouldAddId(nativeElement: HTMLInputElement) {
  return !checkboxLikeTypes.includes(nativeElement.type);
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nh-control',
  templateUrl: './control.component.html',
  styleUrls: ['./control.component.scss'],
})
export class ControlComponent implements OnChanges, AfterContentInit, OnDestroy {
  @Input() label?: string;
  @Input() id = guid();
  @Input({ transform: booleanAttribute }) noBottomMargin = false;
  @Input({ transform: booleanAttribute }) required = false;
  @Input({ transform: booleanAttribute }) reserveSpaceForLabel = false;
  /**
   * Can be used to hide the label.
   */
  @Input({ transform: booleanAttribute }) noLabel = false;
  @Input() direction: 'col' | 'row' = 'col';
  @Input() tooltip?: string;
  @Input() tooltipIcon: IconProp = 'info-circle';

  /**
   * @deprecated This is really not necessary
   */
  @Input() contentFirst = false;

  // consider using @ContentChildren(FormControlName, { descendants: false }) if we need controls that do not appear
  // as first elemenent underneath the formgroup
  @ContentChild(FormGroupName) fgn?: FormGroupName;
  @ContentChild(FormGroupName, { read: ElementRef }) fgnAsElRef?: ElementRef;
  @ContentChild(FormGroupDirective) fgd?: FormControlDirective;
  @ContentChild(FormGroupDirective, { read: ElementRef }) fgdAsElRef?: ElementRef;
  @ContentChild(FormControlName) fcn?: FormControlName;
  @ContentChild(FormControlName, { read: ElementRef }) fcnAsElRef?: ElementRef;
  @ContentChild(FormControlDirective) fcd?: FormControlDirective;
  @ContentChild(FormControlDirective, { read: ElementRef }) fcdAsElRef?: ElementRef;
  @ContentChild(ValidationErrorsComponent) errorsComponent?: ValidationErrorsComponent;

  disabled$ = new BehaviorSubject(false);
  private destroySubj = new Subject<void>();

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['label'] && !changes['label'].isFirstChange()) {
      if (this.errorsComponent) {
        this.errorsComponent.label = this.label || null;
      }
    }
  }

  ngAfterContentInit(): void {
    const nativeElement = this.getNativeElement();

    if (nativeElement) {
      if (shouldAddFormControlClass(nativeElement)) {
        this.renderer.addClass(nativeElement, 'form-control');
      }

      if (shouldAddId(nativeElement)) {
        this.renderer.setAttribute(nativeElement, 'id', this.id);
      }

      this.renderer.addClass(nativeElement, INVALIDABLE_CONTROL);
    }

    const control = this.getFormControl();
    if (!control) {
      return;
    }

    if (this.errorsComponent) {
      if (!this.errorsComponent.label) {
        this.errorsComponent.label = this.label || null;
      }
      this.errorsComponent.control = control;
    }

    // this is necessary in case the ControlComponent was used in a component with OnPush ChangeDetection and its
    // status is changed asynchronously, e.g. through async validation
    control.statusChanges.pipe(takeUntil(this.destroySubj)).subscribe(() => {
      this.cdr.markForCheck();
    });

    disabledChanges(control)
      .pipe(takeUntil(this.destroySubj))
      .subscribe((disabled) => this.disabled$.next(disabled));
  }

  ngOnDestroy(): void {
    this.destroySubj.next();
    this.destroySubj.complete();
  }

  private getNativeElement() {
    const fs = [this.fgnAsElRef, this.fgdAsElRef, this.fcnAsElRef, this.fcdAsElRef];
    return fs.find((el) => {
      return !!el;
    })?.nativeElement;
  }

  private getFormControl() {
    const fs = [this.fgd, this.fgn, this.fcd, this.fcn];
    return fs.find((fc) => {
      return !!fc;
    })?.control;
  }
}
