import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedUtilI18nModule } from '@nexuzhealth/shared-util';
import { IconsModule } from '@nexuzhealth/shared-ui-toolkit/icons';
import { ButtonModule } from '@nexuzhealth/shared-ui-toolkit/button';
import { NotFoundComponent } from './not-found/not-found.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

@NgModule({
  imports: [CommonModule, SharedUtilI18nModule, IconsModule, RouterModule, ButtonModule],
  declarations: [PageNotFoundComponent, NotFoundComponent],
  exports: [NotFoundComponent, PageNotFoundComponent],
})
export class NotFoundModule {}
