<div class="toolkit__heading card">
  <div class="card-body">
    <div class="card-body__container">
      <h1 class="hero-title">Dirty check</h1>
      <p>Prevent leaving the page while there are pending changes.</p>
    </div>
  </div>
</div>

<p>
  First, you have to register a <code>CanDeactivateGuard</code> when configuring the routing of your (page) component.
  Should the user navigate away from the page, the <code>CanDeactivateGuard</code> will check whether this is permitted
  or not.
</p>
<pre>
  <code language="typescript" [highlight]="routeCode"></code>
</pre>

<nxh-alert
  type="info"
  alertTitle="When using the new nhForm directive that's all you have to do. Below information is applicable for the old forms only!"
></nxh-alert>

<br />
<br />

<p>
  The <code>CanDeactivateGuard</code> will consult the <code>FormStore</code> whether your page is still dirty.
  <b>When using the "older" forms</b>, your page has to register a handle in the FormStore. Typically, this is
  accomplished like so:
</p>
<pre>
  <code language="typescript" [highlight]="formCode"></code>
</pre>
<p>Whenever there is a change to the form, we update the FormStore specifying whether that form is dirty or not.</p>

<p>
  Note that the mechanism doesn't check whether your values are actually changed. E.g. making a change and then undoing
  that change, won't unmark the form as dirty!
</p>

<p>
  Note that in general it is <b>not</b> necessary for a page to remove itself from the <code>FormStore</code>, as this
  is taken care of by the <code>CanDeactivateGuard</code>.
</p>

<h1>Modals</h1>

<p>
  For modals, the mechanism is almost the same, but in stead of using the method <code>setFormDirty()</code>, you call
  <code>setModalDirty()</code> (or its counterpart, <code>markModalDirty()</code> and <code>markModalPristine()</code>).
  Here's some example code:
</p>

<pre>
  <code language="typescript" [highlight]="modalCode"></code>
</pre>

<p>
  When you have a 'newer' form on the page, you can set the FormHelperDirective' config parameter <i>isModal</i> = true
  .
</p>

<h1>Sidebar</h1>

<p>
  For forms in the sidebar, you have to add <i>canDeactivateOutlet</i> to the route's configuration and set it to
  <i>sidebar</i>. Newer forms will automatically pick this up. For older forms, you *also* have to pass the outlet name
  with the setFormDirty() method.
</p>

<pre>
  <code language="typescript" [highlight]="sidebarCode"></code>
</pre>
