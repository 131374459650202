<div class="icon-pill-content-container">
  <ng-content select="fa-icon" />

  <span class="pill-content">
    <ng-content />
  </span>
</div>

<div class="pill-buttons">
  <ng-content select="button" />
  <button
    #edit
    nxh-button
    [buttonType]="'icon'"
    [ngbTooltip]="editTooltip"
    [disableTooltip]="!editTooltip"
    (click)="editClick.emit()"
    *ngIf="editable"
  >
    <fa-icon icon="pen" />
  </button>
  <button
    #close
    nxh-button
    [buttonType]="'icon'"
    [ngbTooltip]="closeTooltip"
    [disableTooltip]="!closeTooltip"
    (click)="closeClick.emit()"
    *ngIf="closeable"
  >
    <fa-icon icon="times" />
  </button>
</div>
