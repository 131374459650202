<nxh-line-chart
  [labels]="(dataSubj | async).painDates"
  [datasets]="(dataSubj | async).painValues"
  (selectPoint)="onSelectPoint($event)"
></nxh-line-chart>

<br />
<button class="btn btn-primary" (click)="changeData()">refresh data</button>
<br />
<br />

<div class="card">
  <table class="table">
    <thead>
      <tr>
        <th>Date</th>
        <th>Pain values</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let item of (dataSubj | async).tableData; let i = index"
        [ngClass]="{ active: activeId === i }"
        (mouseover)="activeTableId = i"
        (mouseout)="activeTableId = null"
      >
        <td>{{ item.date }}</td>
        <td>{{ item.painValue }}</td>
      </tr>
    </tbody>
  </table>
</div>
