import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ChevronService } from '../chevron.service';
import { CollapseService } from '../collapse.service';

@Component({
  selector: 'nxh-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CollapseService, ChevronService],
  exportAs: 'collapseRef',
})
export class CollapseComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  @Output() collapseChange = new EventEmitter<boolean>();

  @Input()
  set disabled(value: boolean | undefined | null) {
    if (value) {
      this.collapseService.disable();
    } else {
      this.collapseService.enable();
    }
  }

  @Input() defaultOpen = false;

  @Input()
  set isOpen(value: boolean | undefined | null) {
    if (value) {
      this.collapseService.open();
    } else {
      this.collapseService.close();
    }
  }

  get isOpen() {
    return this.collapseService.isOpen;
  }

  isOpen$ = this.collapseService.isOpen$;

  constructor(private collapseService: CollapseService) {}

  ngOnInit() {
    if (this.defaultOpen) {
      this.collapseService.open();
    }
  }

  ngAfterViewInit() {
    this.collapseService.isOpen$.pipe(takeUntil(this.destroy$)).subscribe((isOpen) => this.collapseChange.emit(isOpen));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggle() {
    this.collapseService.toggle();
  }

  open() {
    this.collapseService.open();
  }

  close() {
    this.collapseService.close();
  }

  enable() {
    this.collapseService.enable();
  }

  disable() {
    this.collapseService.disable();
  }
}
