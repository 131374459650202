import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DomUtilService {
  getDimensions(el: HTMLElement): Dimensions {
    return {
      offsetWidth: el.offsetWidth,
      scrollWidth: el.scrollWidth,
      offsetHeight: el.offsetHeight,
      scrollHeight: el.scrollHeight,
    };
  }

  getRootFontSize() {
    const root = document.querySelector(':root');
    return getComputedStyle(root).getPropertyValue('font-size');
  }
}

export interface Dimensions {
  offsetWidth: number;
  scrollWidth: number;
  offsetHeight: number;
  scrollHeight: number;
}
