<h2>Back Button</h2>

<h4>navigation modes</h4>
<p>The back button has three modes of navigation:</p>

<ul>
  <li>default</li>
  <li>fixed return</li>
  <li>custom</li>
</ul>

<h6>Default</h6>
<h1>
  <nxh-back-button></nxh-back-button>
  Let's go to the previous page (or if that one does not exist, to the default fallback page: ../)
</h1>
<h1>
  <nxh-back-button fallback="/button"></nxh-back-button>
  Or... with fallback
</h1>
<p>
  Uses dynamic routing data of the window history to go to the previous page. A fallback route in the
  <code>fallback</code> property is recommended for when there is no history (e.g. when opened in a new tab or window)
  if none is provided it will go up one level.
</p>
<pre><code language="html" [highlight]="default"></code></pre>

<h6>Fixed return</h6>
<h1>
  <nxh-back-button [routerLink]="['/button']"></nxh-back-button>
  Using Angular's [routerLink]: let's go to buttons
</h1>
<p>The back button will ignore the window history and take a route via angular's <code>routerLink</code>..</p>
<pre><code [highlight]="fixed" language="html"></code></pre>

<h6>Custom</h6>
<h1>
  <nxh-back-button (click)="surprise()"></nxh-back-button>
  Use click handler for custom navigation
</h1>
<p>
  when custom logic is required, bind an event listener to the <code>click</code> event to execute the routing manually.
</p>
<pre><code [highlight]="custom" language="html"></code></pre>

<h4>tooltip</h4>
<p>the tooltip is provided, but can be overwritten by providing a i18n key in the <code>tooltip</code> property.</p>
