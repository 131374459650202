<h1>Calendar</h1>

<br />

<nxh-single-toggle-list
  [options]="[
    { label: 'timegrid', value: 'time-grid' },
    { label: 'resource timeline', value: 'resource-timeline' },
  ]"
  [(ngModel)]="selectedCalendar"
></nxh-single-toggle-list>

<br />

<ng-container *ngIf="selectedCalendar === 'time-grid'">
  <h2>TimeGrid</h2>

  <p>The TimeGrid calendar has the following inputs:</p>

  <ul>
    <li>viewType: a <code>CalendarTimeGridViewType</code> enum specifying whehter we want the DAY or the WEEK view</li>
    <li>range: a <code>Range</code> object specifying the from and to date</li>
    <li>
      events: typically an array of <code>TimeGridCalendarEvent</code>s. You *can* pass an array of anything really, but
      then you have to provide an <code>eventDataTransform()</code> function. A
      <code>TimeGridCalendarEvent</code> consists out of 2 parts: 1 part is meant for the fullcalendar rendering engine
      (fields like start, end, fullDay, color...), another part, the field <code>extendedProps</code> contains
      business-specific attributes. Note that by default the extendedProps contain the fields title, content,
      duration... These are the fields used to render a single cell. In case one specifies a custom eventContentTemplate
      (see below), extendedProps might have to contain other information.
    </li>
    <li>
      eventDataTransform: optional function you have to pass if your events don't adhere to the TimeGridCalendarEvent
      api.
    </li>
    <li>
      eventContentTemplate: optional TemplateRef to be used to render an "event" cell. This template will receive a
      <code>EventContentContext</code> object containing the cell's <code>CalendarEvent</code>.
    </li>
    <li>config: optional <code>CalendarConfig</code> to overwrite some of the default config options.</li>
  </ul>

  <p>There is one output:</p>

  <ul>
    <li>
      selectEvent: called when the user selects an event. Contains the TimeGridCalendarEvent and the source HTMLElement.
    </li>
  </ul>

  <nxh-alert type="info">
    <nxh-alert-description>
      The Calendar is based upon fullcalendar.js. To keep things simple, we chose not to expose the full API of
      fullcalendar. However, do not hesitate to contact us should you need some extra functionality, e.g. to extend
      CalendarConfig
    </nxh-alert-description>
  </nxh-alert>

  <pre>
    <code language="html" [highlight]="gridCode"></code>
  </pre>

  <nxh-calendar-time-grid
    [viewType]="viewType"
    [range]="range"
    [events]="gridEvents"
    (selectEvent)="onSelectEvent($event)"
  ></nxh-calendar-time-grid>
</ng-container>

<ng-container *ngIf="selectedCalendar === 'resource-timeline'">
  <h2>Resource Timeline</h2>

  The Resource Timeline has the following inputs:

  <ul>
    <li>range: a <code>Range</code> object specifying the from and to date</li>
    <li>events: an array of <code>ResourceCalendarEvent</code>s.</li>
    <li>resources: an array of <code>CalendarResource</code>s.</li>
    <li>config: optional <code>CalendarConfig</code> to overwrite some of the default config options.</li>
  </ul>

  <pre>
    <code language="html" [highlight]="timelineCode"></code>
  </pre>

  <nxh-calendar-resource-timeline
    [range]="range"
    [resources]="resources"
    [resourceHeader]="'timeline'"
    [events]="timelineEvents"
  ></nxh-calendar-resource-timeline>
</ng-container>
