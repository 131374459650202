<div class="form-group" [class.form-group--no-margin]="noBottomMargin">
  <label *ngIf="label" class="radio-group__label" [class.required]="required" [class.disabled]="disabled$$ | async"
    >{{ label }}
  </label>
  <div
    class="radio-group__container"
    [class.radio-group--row]="direction === 'row'"
    [class.radio-group--col]="direction === 'col'"
    [class.radio-group--no-label]="!label"
    [class.radio-group--no-top-margin]="noTopMargin"
  >
    <ng-content select="nh-radio" />
  </div>
  <div>
    <ng-content />
  </div>
</div>
