import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ReferenceService } from '../state/reference.service';

@Injectable({
  providedIn: 'root',
})
export class ReferenceResolveService {
  constructor(private referenceService: ReferenceService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    [
      this.referenceService.getProfessionValues(),
      this.referenceService.getHealthCareWorkerProfessionValues(),
      this.referenceService.getCountryValues(),
      this.referenceService.getCityValues(),
      this.referenceService.getMaritalStatusValues(),
      this.referenceService.getNationalityValues(),
      this.referenceService.getLanguageValues(),
      this.referenceService.getAdministrativeGenderValues(),
      this.referenceService.getMediumValues(),
    ].forEach((o$) => o$.subscribe());
  }
}
