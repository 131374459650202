import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedTechFeatureE2eModule } from '@nexuzhealth/shared-tech-feature-e2e';
import { CompactFormControlDirectiveModule } from '@nexuzhealth/shared-ui-toolkit/compact-form-control';
import { ValidationModule } from '@nexuzhealth/shared-ui-toolkit/validation';
import { FormsModule } from '@angular/forms';
import { NumberFieldComponent } from './number-field.component';

@NgModule({
  imports: [CommonModule, SharedTechFeatureE2eModule, ValidationModule, CompactFormControlDirectiveModule, FormsModule],
  exports: [NumberFieldComponent],
  declarations: [NumberFieldComponent],
})
export class NumberFieldModule {}
