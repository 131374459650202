import { Component } from '@angular/core';

@Component({
  selector: 'nxh-divider-demo',
  templateUrl: './divider-demo.component.html',
  styleUrls: ['./divider-demo.component.scss'],
})
export class DividerDemoComponent {
  constructor() {}
}
